<template>
  <!-- <div class="back">
    <div class="left"></div>
    <div class="right"></div>
  </div>

  <img :src="icons.logo" alt="" class="absolute left-4 top-5" />
  <div class="border w-[100vw] h-[100vh] flex items-center justify-center">
    <div class="shadow rounded-[24px] pb-[40px] px-[80px]"> -->
      <auth-body>
        <form @submit.prevent="handleSubmit" class="ui gap-[12px] form">
        <Text :size="24" :weight="700" class="loginHeader text-center"
          >Forgot Password</Text
        >
        <!-- <div class="flex flex-col my-4 gap-[24px]">
          <input type="email" placeholder="Email" v-model="email" />
        </div> -->

        <div>
          <label class="ue-input-label" for="">Email address</label>
          <input
            type="email"
            placeholder="Enter your email address"
            v-model="email"
            class="ue-input-field"
            required
          />
        </div>
        <!-- <div v-if="error" class="error">{{ error }}</div> -->
        <p class="w-full text-right">
          <router-link to="/admin" class="authSpan"><Text :color="'red'" :weight="600">Sign in</Text></router-link>
        </p>

        <button v-if="!isPending" class="ui btn w-full ue-btn-gradient fluid mpcblue">
          Send reset password email
        </button>
        <button v-if="isPending" disabled class="ui button fluid mpcblue">
          Loading
        </button>

        <div class="branding">
          <div class="arrow bounce"></div>
          <!-- Powered by MyPocketCounsel&copy; -->
        </div>
      </form>
      </auth-body>
    <!-- </div>
  </div> -->
</template>
  
  <script>
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { projectAuth } from "@/firebase/config";
import Text from "@/components/Text.vue";
import { icons } from "@/assets/icons";
import { useToast } from "vue-toast-notification";
import AuthBody from './AuthBody.vue';

export default {
  setup() {
    const router = useRouter();
    const email = ref("");
    const password = ref("");
    const toast = useToast({ position: "top-right" });
    const isPending = ref(false);
    const handleSubmit = async () => {
      isPending.value = true;
      try {
        await sendPasswordResetEmail(projectAuth, email.value);
        toast.success("Please check your email for a password reset link");
        router.push('/admin')
      } catch (error) {
        if (error.message.includes("Firebase: Error")) {
          // console.log(error.message.replace("auth/",'').replace("-", " "));
          toast.error(
            error.message
              .replace("Firebase: Error", "")
              .replace("auth/", "")
              .replace("-", " ")
              .replace("(", "")
              .replace(")", "")
          );
        }
      } finally {
        isPending.value = false;
      }
    };
    return { email, password, handleSubmit, icons, isPending };
  },
  components: { Text, AuthBody },
};
</script>
  
  <style>
.form {
  width: 400px;
}
/* .btn {
  background: linear-gradient(
    rgba(246, 1, 0, 1),
    rgba(246, 1, 0, 1),
    rgba(255, 145, 3, 1)
  );
} */
</style>