<template>
  <layout :hasTopNav="false">
    <div class="px-[40px] bg-light w-full h-full mainContent">
      <TopNav :searchBar="false" :PrevNextIcon="false" />
      <div class="d-flex justify-content-between p-3 w-full">
        <Text :size="27" :weight="700"> Payment</Text>
        <time-drop-down :getDate="(e) => (timeFrame = e)" />
      </div>

      <section class="metricsBar w-full">
        <div v-for="metric in metricList" :key="metric.title" class="metric">
          <div class="metric-info">
            <Text v-if="metric.details" :size="32" :weight="600">{{
              metric.details?.length
            }}</Text>
            <Text :size="15" :weight="600">{{ metric.title }}</Text>
          </div>
          <div class="metric-icon">
            <i :class="metric.iconClass"></i>
            <span
              class="cursor-pointer"
              v-if="metric.details"
              @click="() => (selectedMetric = metric)"
            >
              view
            </span>
            <!-- <div class="w-[50px] h-[50px] border rounded-full gradienticon">
              <span class="material-symbols-outlined text-[white] sidebar-icon">
                {{ metric.icon }}
              </span>
            </div> -->
          </div>
        </div>
      </section>

      <Text class="mt-5" weight="600" :size="17">{{
        selectedMetric?.title
      }}</Text>
      <table class="order-table">
        <thead>
          <tr>
            <th class="table-header"></th>
            <th class="table-header">Order ID</th>
            <th class="table-header">Date</th>
            <th class="table-header">Customer Name</th>

            <th class="table-header">Service</th>
            <th class="table-header">Payment method</th>
            <th class="table-header">Amount due</th>
            <th class="table-header">Wallet used</th>
            <th class="table-header">Total Price</th>
            <th class="table-header">ToP UP</th>
            <th class="table-header">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(order, index) in paginatedOrders.toReversed()"
            :key="order.id"
            class="table-row"
            :class="index % 2 === 0 ? 'even-row' : 'odd-row'"
          >
            <td class="table-data flex gap-2">
              {{ index + 1 + 15 * (currentPage - 1) }}
            </td>

            <td class="table-data">
              <OrderID :order="order" />
            </td>
            <td class="table-data">
              {{
                dayjs(order.date?.replaceAll('"', "")).format("MMM DD. hh:mmA ")
              }}
            </td>

            <td class="table-data">
              <customer-name :id_key="'id'" :customer="order.customer" />
            </td>
            <td class="table-data">{{ order.locationType }}</td>
            <!-- <td v-else class="table-data">N/A</td> -->
            <td class="table-data">{{ order?.paymentMethod }}</td>
            <td class="table-data">{{ formatPrice(order?.amount_due) }}</td>
            <td class="table-data">{{ formatPrice(order?.walletUsed) }}</td>
            <td class="table-data">{{ formatPrice(order?.total_price) }}</td>
            <td class="table-data">{{ formatPrice(order?.paidByTopup) }}</td>

            <td class="table-data">
              <router-link
                :to="`/operator/orders/${order?.id}`"
                class="action-link !underline"
                >View</router-link
              >
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination
        class="py-3"
        :currentPage="currentPage"
        :totalPages="totalPages"
        @page-change="goToPage"
      />
    </div>
  </layout>
</template>



<script setup>
import { formatDate, formatPrice, getOrdersByTimeFrame, sum } from "@/util";
import { ref, computed, watchEffect } from "vue";

import TopNav from "@/components/TopNav.vue";
import Text from "@/components/Text.vue";
import Layout from "@/partials/Layout/layout.vue";
import { store } from "@/views/store/store.js";
import TimeDropDown from "../Dashboard/TimeDropDown.vue";
import dayjs from "dayjs";
import Pagination from "@/components/Pagination.vue";
import CustomerName from "@/components/CustomerName.vue";
import OrderID from "@/components/OrderID.vue";
import {
  get,
  ref as dbRef,
  getDatabase,
  query,
  orderByKey,
  startAt,
  endAt,
} from "firebase/database";

const timeFrame = ref();
const orders = ref([]);
const selectedMetric = ref({});

watchEffect(async () => {
  orders.value = await getOrdersByTimeFrame(
    timeFrame.value?.start,
    timeFrame.value?.end
  );
});

const payments = ref({
  paystack_transfer_and_wallet: {
    length: 0,
    total: 0,
    orders: [],
  },
  moniepoint_transfer_and_wallet: {
    length: 0,
    total: 0,
    orders: [],
  },

  wallettopup_and_wallet: {
    length: 0,
    total: 0,
    orders: [],
  },
  card_and_wallet: {
    length: 0,
    total: 0,
    orders: [],
  },

  confirmed_via_bank: {
    length: 0,
    total: 0,
    orders: [],
  },
  confirmed_via_wallet: {
    length: 0,
    total: 0,
    orders: [],
  },
  card: {
    length: 0,
    total: 0,
    orders: [],
  },
  transfer: {
    length: 0,
    total: 0,
    orders: [],
  },
  wallet: {
    length: 0,
    total: 0,
    orders: [],
  },
  paystack_transfer: {
    length: 0,
    total: 0,
    orders: [],
  },
});

const getTotal = (orders = [], amountKey = "total_price") => {
  if (typeof amountKey === "string") {
    // console.log(
    //   orders.map((order) => parseInt(order[amountKey])),
    //   amountKey,
    //   sum(orders.map((order) => parseInt(order[amountKey])))
    // );
    return sum(orders.map((order) => parseInt(order[amountKey])));
  } else {
    typeof amountKey === "function";
    return amountKey();
  }
};
const getDetails = (orders = [], amountKey = "total_price") => ({
  total: getTotal(orders, amountKey),
  length: orders?.length,
  orders,
});

const metricList = ref([]);
const paystackTotal = ref(0);
watchEffect(async () => {
  const start = dayjs(timeFrame.value?.start).format("MM-DD-YYYY");
  const end = dayjs(timeFrame.value?.end).format("MM-DD-YYYY");
  const quer = query(
    dbRef(getDatabase(), `paystackHistory/`),
    orderByKey(),
    startAt(start),
    endAt(end)
  );
  const tot = (await get(quer)).val();
  const first_level = Object.values(tot || {});
  // const second_level = Object.values()
  // console.log(start, end,);
  // paystackTotal.value = sum(Object.values(tot || {}));
  // console.log(tot, first_level);
  paystackTotal.value = 0;
  for (const key in tot) {
    if (Object.hasOwnProperty.call(tot, key)) {
      const element = tot[key];
      for (const key2 in element) {
        if (Object.hasOwnProperty.call(element, key2)) {
          const element2 = element[key2];
          console.log({ element2 });
          if (element2) paystackTotal.value += element2;
        }
      }
    }
  }
  // first_level.map()
  // console.log(paystackTotal.value);
});

watchEffect(() => {
  const isTransfer = (order = {}) => order?.paymentMethod == "transfer";
  const isWallet = (order = {}) => order?.walletUsed >= 1 || order?.paidByTopup;
  const isWalletUsed = (order = {}) => order?.walletUsed >= 1;
  const isCard = (order = {}) =>
    order?.paymentMethod == "card" && !isWalletUsed(order);
  const isCardOnly = (order = {}) =>
    order?.paymentMethod == "card" && !isWalletUsed(order);
  const isTransferOnly = (order = {}) =>
    order?.paymentMethod == "transfer" && !isWalletUsed(order);
  const isManuallyConfirmed = (order = {}) =>
    Object.values(order?.timeline || [])?.find((timeline) =>
      timeline.message.includes("confirmed payment")
    );
  const isConfirmedByBank = (order = {}) =>
    isManuallyConfirmed(order) && order?.confirmation_type == "Bank Alert";
  const isConfirmedByWallet = (order = {}) =>
    isManuallyConfirmed(order) &&
    order?.confirmation_type == "Wallet Deduction";
  const isCardAndWallet = (order = {}) =>
    order?.paymentMethod == "card" && isWalletUsed(order);
  const isPaystackTransfer = (order = {}) =>
    isTransfer(order) && !isManuallyConfirmed(order) && !isWalletUsed(order);
  const isPaystackTransferAndWallet = (order = {}) =>
    isTransfer(order) &&
    isWalletUsed(order) &&
    !isManuallyConfirmed(order) &&
    order?.total_price - order?.walletUsed;
  const isTransferAndWalletMoniePoint = (order = {}) =>
    isTransfer(order) && isWalletUsed(order) && isManuallyConfirmed(order);
  const isTopUp = (order = {}) => order?.paidByTopup;

  let paystack_transfer_and_wallet = orders.value.filter(
    isPaystackTransferAndWallet
  );
  let moniepoint_transfer_and_wallet = orders.value.filter(
    isTransferAndWalletMoniePoint
  );
  let wallettopup_and_wallet = orders.value.filter(isWallet);
  let card_and_wallet = orders.value.filter(isCardAndWallet);
  let confirmed_via_bank = orders.value.filter(isConfirmedByBank);
  let confirmed_via_wallet = orders.value.filter(isConfirmedByWallet);
  let card = orders.value.filter(isCardOnly);
  let paystack_transfer = orders.value.filter(isPaystackTransfer);
  let transfer = orders.value.filter(isTransferOnly);
  let wallet = orders.value.filter(isWallet);
  let wallet_topup = orders.value.filter(isTopUp);

  payments.value.paystack_transfer_and_wallet = getDetails(
    paystack_transfer_and_wallet
  );
  payments.value.moniepoint_transfer_and_wallet = getDetails(
    moniepoint_transfer_and_wallet
  );
  payments.value.wallettopup_and_wallet = getDetails(wallettopup_and_wallet);
  payments.value.card_and_wallet = getDetails(card_and_wallet);
  payments.value.confirmed_via_bank = getDetails(
    confirmed_via_bank,
    "amount_due"
  );
  payments.value.confirmed_via_wallet = getDetails(confirmed_via_wallet);
  payments.value.card = getDetails(card);
  payments.value.paystack_transfer = getDetails(paystack_transfer);
  // payments.value.wallet = getDetails(wallet, "walletUsed");
  payments.value.wallet = {
    length: wallet?.length,
    total: sum(
      wallet?.map((order) => {
        if (order?.paidByTopup) {
          // console.log(+ order?.amount_due, order?.id,'top up');
          return( parseInt(order?.walletUsed )+ parseInt(order?.amount_due)) || order?.total_price;
        } else {
          return order?.walletUsed;
        }
      })
    ),
    orders: wallet,
  };

  metricList.value = [
    {
      title: `Manual confirmation (via bank alert) - (${formatPrice(
        payments.value.confirmed_via_bank.total
      )}) and (${payments.value.confirmed_via_bank.length})`,
      details: payments.value.confirmed_via_bank,
      icon: "Fastfood",
    },
    {
      title: `Pay via Wallet - (${formatPrice(
        payments.value.wallet.total
      )}) and (${payments.value.wallet.length})`,
      details: payments.value.wallet,
      icon: "Fastfood",
    },
    {
      title: `Paystack card  - (${formatPrice(
        payments.value.card.total
      )}) and (${payments.value.card.length})`,
      details: payments.value.card,
      icon: "Fastfood",
    },
    {
      title: `Paystack transfer  - (${formatPrice(
        payments.value.paystack_transfer.total
      )}) and (${payments.value.paystack_transfer.length})`,
      details: payments.value.paystack_transfer,
      icon: "Fastfood",
    },
    // - (${formatPrice(0)}) and (${0})
    // {
    //   title: `Wallet Top -  (${formatPrice(
    //     payments.value.wallet_topup?.total
    //   )}) and (${payments.value.wallet_topup?.length}) `,
    //   details: payments.value.wallet_topup,
    //   icon: "Fastfood",
    // },
    {
      title: `Wallet and card  - (${formatPrice(
        payments.value.card_and_wallet.total
      )}) and (${payments.value.card_and_wallet.length})`,
      details: payments.value.card_and_wallet,
      icon: "Fastfood",
    },
    {
      title: `Wallet and transfer (Paystack) - (${formatPrice(
        payments.value.paystack_transfer_and_wallet.total
      )}) and (${payments.value.paystack_transfer_and_wallet.length}) `,
      details: payments.value.paystack_transfer_and_wallet,
      icon: "Fastfood",
    },
    {
      title: `Wallet and transfer (MoniePoint)  (${formatPrice(
        payments.value.moniepoint_transfer_and_wallet.total
      )}) and (${payments.value.moniepoint_transfer_and_wallet.length})`,
      details: payments.value.moniepoint_transfer_and_wallet,
      icon: "Fastfood",
    },
    {
      title: `Paystack payments (${formatPrice(paystackTotal.value)})`,
      // details: payments.value.moniepoint_transfer_and_wallet,
      // icon: "Fastfood",
    },
  ];

  // -----------------------
  selectedMetric.value = metricList.value[0];
  // -----------------------
});

// Pagination

const ordersPerPage = 15;
const currentPage = ref(1);
const totalPages = computed(() =>
  Math.ceil(selectedMetric.value?.details?.length / ordersPerPage)
);

const goToPage = (page) => {
  if (page >= 1 && page <= totalPages.value) {
    currentPage.value = page;
  }
};

const paginatedOrders = computed(() => {
  const startIndex = (currentPage.value - 1) * ordersPerPage;
  const endIndex = startIndex + ordersPerPage;

  return selectedMetric.value?.details?.orders.slice(startIndex, endIndex);
});
watchEffect(() => {
  selectedMetric.value;
  currentPage.value = 1;
});
</script>
          
 
<style scoped lang="scss">
.mainContent {
  background-color: #f2f2f2;

  flex-direction: column;

  .metricsBar {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    // gridr
    grid-gap: 2%;
    row-gap: 2%;
    margin: auto;
    justify-content: space-evenly;
    // column-gap: 12px;
  }
  .metric {
    display: flex;
    justify-content: space-between;
    color: black;
    padding: 10px;
    border-radius: 14px;
    background: #fff;
    align-items: center;
    margin: 5px 0;
  }
  .metric-info {
    text-align: left;
    padding: 0 12px;
  }
  .sum_rev {
    display: flex;
    // gap: 32px;
    justify-content: space-between;
    width: 100%;
    & > div {
      width: calc(50% - 16px);
      height: 453px;
      flex-shrink: 0;
      border-radius: 16px;
      background: #fff;
    }
  }
  .cus_top {
    display: flex;
    justify-content: space-between;
    & > div {
      border-radius: 16px;
      background: #fff;
      height: 458px;
      flex-shrink: 0;
    }
    .graph1 {
      width: calc(50% - 8px);
    }
    .top_selling {
      width: calc(50% - 8px);
    }
  }

  .recent_orders {
    width: 100%;
    height: 642px;
    flex-shrink: 0;
    border-radius: 16px;
    background: #fff;
  }
  .gradienticon {
    align-items: center;
    display: flex;
    justify-content: center;
    background: linear-gradient(253deg, #ff9103 29.53%, #f60100 103.89%);
  }
}
</style>
      