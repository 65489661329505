<template>
  <div class="w-full h-full top_selling_item !relative">
    <nav>
      <div class="left">
        <Text :weight="600" :size="18">Top Restaurants</Text>
      </div>
      <div class="rightttt">
        <time-drop-down :getDate="(e) => (timeFrame = e)" />
      </div>
    </nav>
    <main>
      <div
        v-if="!top_restaurant.length"
        class="h-[100%] flex items-center justify-center"
      >
        <Text :weight="700" :size="24"> No orders yets</Text>
      </div>
      <div
        class="card"
        v-for="(restaurant, i) in top_restaurant"
        :key="restaurant.name"
      >
        <div class="left gap-[19px]">
          <div class="number h-full flex items-center">#{{ i + 1 }}</div>
          <div class="center flex py-2 flex-col justify-between h-full w-full">
            <Text :size="16" :weight="600" class="top">
              {{ restaurant.name }}
            </Text>
            <div class="bottom w-full flex justify-between">
              <!-- <Text :weight="500" :size="14"> Orders : {{ restaurant.number_of_orders }} </Text> -->
              <Text :weight="500" :size="14"> {{ restaurant.count }}x</Text>
            </div>
          </div>
        </div>
        <img :src="restaurant.img" alt="" />
      </div>
    </main>
  </div>
</template>
  
  <script setup>
import Text from "@/components/Text.vue";
import TimeDropDown from "./TimeDropDown.vue";
import { watch, watchEffect } from "vue";
import { ref } from "vue";
import { getOrdersByTimeFrame } from "@/util";
import { get , getDatabase, ref as dbRef} from "firebase/database";

const timeFrame = ref({});
const meals = ref([]);
const top_restaurant = ref({ restaurant: "", qty: 0 });

watchEffect(async () => {
  const { start, end } = timeFrame.value;
  const ordersInTimeFrame = await getOrdersByTimeFrame(start, end);

  const items = ordersInTimeFrame
    ?.flatMap((order) => order?.items)
    ?.map((item) => ({
      restaurant: item?.resturant,
      qty: item?.quantity - (item?.cancelled || item?.canceled || 0),
    }));
  const restaurantSet = new Set();
  let resCount = {};
  items.forEach((item) => {
    const res = item?.restaurant;
    const qty = item?.qty;
    if (restaurantSet.has(res)) {
      resCount[res] = resCount[res] + qty;
    } else {
      restaurantSet.add(res);
      resCount[res] = qty || 0;
    }
  });

  const counts = Object.entries(resCount || {}).map((x) => ({
    name: x[0],
    count: x[1],
  }));
  //   console.log(counts);
  top_restaurant.value = counts?.sort((a, b) => b.count - a.count);

  top_restaurant.value.forEach(async(res,i) => {
    top_restaurant.value[i].img = await (await get(dbRef(getDatabase(),`/restaurants/${res.name}/logo`))).val()
  });
  //   for (const key in resCount) {

  //     const element = resCount[key];
  //     if (element >= top_restaurant.qty) {
  //       top_restaurant.qty = element;
  //       top_restaurant.restaurant = key;
  //     }
  //   }
  //   console.log(top_restaurant);
});
</script>
  
  <style lang="scss" >
.top_selling_item {
  font-family: Work Sans;
  height: 100%;
  padding: 33px 35px;
  width: 100%;
  display: flex;
  gap: 24px;
  justify-content: space-between;
  flex-direction: column;

  nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // position: absolute;top: 10px;width: 100%;
    .left {
      // top: 30px;
      // position: absolute;
      // left: 10px;
    }
    left: 10px;
    .rightttt {
      // top: 30px;
      // position: absolute;
      // // width: 75px;
      // right: 10px;
    }
  }
  main {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    padding-right: 4px;
    margin-top: 30px;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      width: 4px;
    }
    .card {
      display: flex;
      justify-content: space-between;
      //   align-items: center;
      width: 100%;
      flex-direction: row;
      height: 1;
      border: none;
      img {
        width: 64px;
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        height: 65px;
        // align-self: flex-end;
        display: block;
      }
      .left {
        height: 100%;
        display: flex;
        gap: 13px;
        align-items: center;
        width: fit-content;
      }
    }
  }
}
</style>