<template>
  <div class="back">
    <img
      :src="icons.login_right_inner"
      alt=""
      class="!fixed right-0 opacity-[.2]"
    />
    <img
      :src="icons.login_left_inner"
      alt=""
      class="fixed left-0 opacity-[.2]"
    />
    <img
      :src="icons.login_right_outer"
      alt=""
      class="!fixed right-0 opacity-[.2]"
    />
    <img
      :src="icons.login_left_outer"
      alt=""
      class="fixed left-0 opacity-[.2]"
    />
  </div>

  <img :src="icons.logo" alt="" class="absolute left-4 top-5" />
  <div class="border w-[100vw] h-[100vh] flex items-center justify-center">
    <div
      class="shadow rounded-[24px] h-[60%]  flex flex-col justify-center items-center auto-container"
    >
      <slot />
    </div>
    <div class="absolute bottom-[25px] flex gap-2">
      <Text :color="'#F60100'">Terms of Service </Text>
      <Text :color="'rgba(0, 0, 0, 0.40)'">and</Text>
      <Text :color="'#F60100'"> Privacy Policy</Text>
    </div>
  </div>
</template>
  
  <script>
// import { ref } from "@vue/reactivity";
// import { useRouter } from "vue-router";
// import { signInWithEmailAndPassword } from "firebase/auth";
// import { projectAuth } from "@/firebase/config";
import Text from "@/components/Text.vue";
import { icons } from "@/assets/icons";
// import { get, ref as dbRef, getDatabase } from "firebase/database";
// import { useToast } from "vue-toast-notification";
// import { store } from "../store/store";
// import { permission } from "@/permission";

export default {
  setup() {
    // const router = useRouter();
    // const email = ref("");
    // const password = ref("");
    // const toast = useToast({ position: "top-right" });
    // const isPending = ref(false);
    // const handleSubmit = async () => {
    //   isPending.value = true;
    //   try {
    //     const { user } = await signInWithEmailAndPassword(
    //       projectAuth,
    //       email.value,
    //       password.value
    //     );
    //     const res = await get(dbRef(getDatabase(), "admin_users"));
    //     const admin_users = Object.values(res.val());
    //     const admin_user = admin_users.find(
    //       (adminUser) => adminUser.uid === user.uid
    //     );
    //     if (admin_user) {
    //       store.commit("login", admin_user);
    //       toast.success(`Welcome back ${admin_user.first_name} `);
    //       const permit = permission(admin_user.permissions);
    //       router.push(permit.first_page.path);
    //     } else {
    //       toast.error("User is not an admin");
    //     }
    //   } catch (error) {
    //     if (error.message.includes("Firebase: Error")) {
    //       // console.log(error.message.replace("auth/", "").replace("-", " "));
    //       toast.error(
    //         error.message
    //           .replace("Firebase: Error", "")
    //           .replace("auth/", "")
    //           .replace("-", " ")
    //           .replace("(", "")
    //           .replace(")", "")
    //       );
    //     }
    //   } finally {
    //     isPending.value = false;
    //   }
    // };
    return { icons };
  },
  components: { Text },
};
</script>
  
  <style lang="scss">
.form {
  width: 400px;
}
.back {
  position: fixed;
  display: flex;
  align-items: center;
  height: 100vh;
  transform: translate(-50, -50);
}
.auto-container{
  padding: 120px;
  @media screen and (max-width:992px)  {
    width: 96%;
    padding: 5px;
  }
}

</style>