<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
  <path d="M17.4997 20.417H18.9581C20.5622 20.417 21.8747 19.1045 21.8747 17.5003V2.91699H8.74974C6.56224 2.91699 4.65182 4.12741 3.66016 5.90658" stroke="auto" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M2.91797 24.792C2.91797 27.2128 4.87214 29.167 7.29297 29.167H8.7513C8.7513 27.5628 10.0638 26.2503 11.668 26.2503C13.2721 26.2503 14.5846 27.5628 14.5846 29.167H20.418C20.418 27.5628 21.7305 26.2503 23.3346 26.2503C24.9388 26.2503 26.2513 27.5628 26.2513 29.167H27.7096C30.1305 29.167 32.0846 27.2128 32.0846 24.792V20.417H27.7096C26.9076 20.417 26.2513 19.7607 26.2513 18.9587V14.5837C26.2513 13.7816 26.9076 13.1253 27.7096 13.1253H29.5909L27.0971 8.76491C26.8402 8.31869 26.4706 7.94778 26.0253 7.68929C25.5799 7.4308 25.0745 7.2938 24.5596 7.29199H21.8763V17.5003C21.8763 19.1045 20.5638 20.417 18.9596 20.417H17.5013" stroke="auto" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M2.91797 11.667H11.668M2.91797 16.042H8.7513M2.91797 20.417H5.83464M11.668 32.0837C12.4415 32.0837 13.1834 31.7764 13.7304 31.2294C14.2773 30.6824 14.5846 29.9405 14.5846 29.167C14.5846 28.3934 14.2773 27.6516 13.7304 27.1046C13.1834 26.5576 12.4415 26.2503 11.668 26.2503C10.8944 26.2503 10.1526 26.5576 9.60557 27.1046C9.05859 27.6516 8.7513 28.3934 8.7513 29.167C8.7513 29.9405 9.05859 30.6824 9.60557 31.2294C10.1526 31.7764 10.8944 32.0837 11.668 32.0837ZM23.3346 32.0837C24.1082 32.0837 24.85 31.7764 25.397 31.2294C25.944 30.6824 26.2513 29.9405 26.2513 29.167C26.2513 28.3934 25.944 27.6516 25.397 27.1046C24.85 26.5576 24.1082 26.2503 23.3346 26.2503C22.5611 26.2503 21.8192 26.5576 21.2722 27.1046C20.7253 27.6516 20.418 28.3934 20.418 29.167C20.418 29.9405 20.7253 30.6824 21.2722 31.2294C21.8192 31.7764 22.5611 32.0837 23.3346 32.0837ZM32.0846 17.5003V20.417H27.7096C26.9076 20.417 26.2513 19.7607 26.2513 18.9587V14.5837C26.2513 13.7816 26.9076 13.1253 27.7096 13.1253H29.5909L32.0846 17.5003Z" stroke="auto" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>