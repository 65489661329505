<template>
  <Layout>
    <div class="px-[40px] bg-light w-full h-full mainContent">
      <TopNav :PrevNextIcon="false" />
      <div class="d-flex justify-content-between p-3 w-full">
        <Text :size="27" :weight="700"> Referrals </Text>
        <!-- <time-drop-down :getDate="(e) => (timeFrame = e)" /> -->
      </div>
      <table class="order-table">
        <thead>
          <tr>
            <th class="table-header"></th>
            <th class="table-header">Referree</th>
            <th class="table-header">Referred</th>
            <th class="table-header">Code</th>

            <!-- <th class="table-header">Service</th>
          <th class="table-header">Payment method</th>
          <th class="table-header">Amount due</th>
          <th class="table-header">Wallet used</th>
          <th class="table-header">Total Price</th>
          <th class="table-header">ToP UP</th> -->
            <th class="table-header">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(referral, index) in paginatedReferrals.toReversed()"
            :key="referral.id"
            class="table-row"
            :class="index % 2 === 0 ? 'even-row' : 'odd-row'"
          >
            <td class="table-data flex gap-2">
              {{ index + 1 + 15 * (currentPage - 1) }}
            </td>

            <td class="table-data">-----------------------</td>
            <td class="table-data">
              {{
                dayjs(referral.date?.replaceAll('"', "")).format(
                  "MMM DD. hh:mmA "
                )
              }}
            </td>

            <td class="table-data">
              <customer-name :id_key="'id'" :customer="referral.customer" />
            </td>
            <td class="table-data">{{ referral.locationType }}</td>
            <!-- <td v-else class="table-data">N/A</td> -->
            <td class="table-data">{{ referral?.paymentMethod }}</td>
            <td class="table-data">{{ formatPrice(referral?.amount_due) }}</td>
            <td class="table-data">{{ formatPrice(referral?.walletUsed) }}</td>
            <td class="table-data">{{ formatPrice(referral?.total_price) }}</td>
            <td class="table-data">{{ formatPrice(referral?.paidByTopup) }}</td>

            <td class="table-data">
              <!-- <router-link
              :to="`/operator/orders/${order?.id}`"
              class="action-link !underline"
              >View</router-link
            > -->
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination
        class="py-3"
        :currentPage="currentPage"
        :totalPages="totalPages"
        @page-change="goToPage"
      />
    </div>
  </Layout>
</template>

<script setup>
import Pagination from "@/components/Pagination.vue";
import Text from "@/components/Text.vue";
import TopNav from "@/components/TopNav.vue";
import Layout from "@/partials/Layout/layout.vue";
import { computed, watchEffect } from "vue";

import {
  onValue,
  ref as dbRef,
  getDatabase,
  set,
  get,
} from "firebase/database";
import { ref } from "vue";
const referrals = ref([]);
watchEffect(async () => {
  const res = (await get(dbRef(getDatabase(), "referrals"))).val();
  console.log(res);
  referrals.value =  res
});

const paginatedReferrals = computed(() => {
  return [];
});
</script>

<style lang="scss" scoped>
</style>