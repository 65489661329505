<template>
  <v-dialog v-model="delivery_modal">
    <div class="bg-white rounded p-3 mx-auto w-[400px]">
      <MealModal
        title="Change Delivery fee?"
        :content="`Are you certain that you want to change delivery fee to ${delivery_fee} ?`"
        :rightClick="() => setDeliveryFee()"
        firstBtn="No, Cancel"
        secondBtn="YES, Confirm"
        :closeBtn="false"
        :tickBtn="true"
        :oncancel="() => (delivery_modal = false)"
      />
    </div>
  </v-dialog>
  <v-dialog v-model="service_modal">
    <div class="bg-white rounded p-3 mx-auto w-[400px]">
      <MealModal
        title="Change Service fee?"
        :content="`Are you certain that you want to change service fee to ${service_fee} ?`"
        :rightClick="() => setServiceFee()"
        firstBtn="No, Cancel"
        secondBtn="YES, Confirm"
        :closeBtn="false"
        :tickBtn="true"
        :oncancel="() => (service_modal = false)"
      />
    </div>
  </v-dialog>

<div class="flex gap-2">
  <div class="border flex flex-col justify-between w-[fit-content] py-3 rounded px-3 mt-2">
    <Text :size="17" :weight="600">Delivery Fee</Text>
    <input v-model="delivery_fee" type="number" class="ue-input-field !h-[30px] !w-[200px]" />
    <ue-button
      @click="() => (delivery_modal = prev.delivery_fee != delivery_fee)"
      class="mt-2 w-[100px]"
    >
      Save</ue-button
    >
  </div>
  <div class="border flex flex-col justify-between w-[fit-content] py-3 rounded px-3 mt-2">
    <Text :size="17" :weight="600">Service Fee</Text>
    <input v-model="service_fee" class="ue-input-field !h-[30px] !w-[200px]"  type="number"  />
    <ue-button class="mt-2 w-[100px]" @click="() => (service_modal =  prev.service_fee != service_fee)">
      Save</ue-button
    >
  </div>
</div>
</template>

<script setup>
import MealModal from "@/components/MealModal.vue";
import Text from "@/components/Text.vue";
import UeButton from "@/components/UeButton.vue";
import { get, getDatabase, ref as dbRef, set } from "firebase/database";
import { watchEffect, ref } from "vue";
import { useToast } from "vue-toast-notification";

const service_fee = ref(0);
const delivery_fee = ref(0);
const delivery_modal = ref(false);
const service_modal = ref(false);
const prev = ref({ service_fee: 0, delivery_fee: 0 });

const toast = useToast({ position: "top-right" });

watchEffect(()=>{
  delivery_fee.value = Math.abs(delivery_fee.value)
  service_fee.value = Math.abs(service_fee.value)
  
  // delivery_fee.value =
})

const setDeliveryFee = async () => {
  try {
    await set(dbRef(getDatabase(), "/delivery_fee"), delivery_fee.value);
    delivery_modal.value = false;
    prev.value.delivery_fee = delivery_fee.value
    toast.success("Delivery fee changed successfully");
  } catch (error) {
    console.log(error);
  }
};
const setServiceFee = async () => {
  try {
    await set(dbRef(getDatabase(), "/service_fee"), service_fee.value);
    service_modal.value = false;
    prev.value.service_fee = service_fee.value
    toast.success("Service fee changed successfully");
  } catch (error) {
    console.log(error);
  }
};

watchEffect(async () => {
  try {
    const res = await get(dbRef(getDatabase(), "/"));
    const data = res?.val();
    prev.value.delivery_fee = res.val()?.delivery_fee;
    prev.value.service_fee = res.val()?.service_fee;
    delivery_fee.value = data?.delivery_fee;
    service_fee.value = data?.service_fee;
  } catch (error) {
    console.log(error);
  }
});
</script>

<style lang="scss" scoped>
</style>