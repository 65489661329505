<template>
  <v-dialog v-model="modal">
    <div class="mx-auto bg-white rounded p-2 w-[400px]">
      <MealModal
        v-if="modal?.type == 'DELETEBANNER'"
        :title="`Delete this banner ?`"
        :content="`Are you sure you want to delete this banner from the app`"
        :rightClick="() => deleteBanner()"
        firstBtn="No, Cancel"
        secondBtn="YES, Confirm"
        :closeBtn="true"
        :tickBtn="false"
        :oncancel="() => (modal = null)"
      />

      <div v-if="modal?.type == 'ADDBANNER'" class="flex flex-col">
        <Text :size="20" class="text-center mb-4">Add new banner image</Text>

        <ue-select
          :options="restaurants"
          label="name"
          v-model="newBanner.name"
          placeholder="Links to?"
        >
          <template #option="option">
            <span :key="option.name">{{ option.name }} </span>
          </template>
        </ue-select>

        <input
          type="file"
          name=""
          id="banner_input"
          class="hidden"
          @input="fileInput"
        />

        <label
          for="banner_input"
          class="block border rounded p-2 cursor-pointer mt-3"
        >
          + image
        </label>
        <div v-if="preview">
          <img :src="preview" class="w-[400px] h-[200px]" />
        </div>
        <span v-if="loadingMessage"> {{ loadingMessage }}</span>
        <UeButton
          class="w-[fit-content] mx-auto mt-3"
          @click="addBanner"
          :disabled="loadingState"
        >
          {{ loadingState ? "..." : "Add" }}
        </UeButton>
      </div>
    </div>
  </v-dialog>

  <div class="bg-white p-2 mb-3 w-100 rounded">
    <div class="flex justify-between mb-3">
      <Text :size="20" weight="600"> Update App Banners</Text>
      <div class="flex gap-2">
        <UeButton @click="() => (modal = { type: 'ADDBANNER', data: null })">
          Add banner
        </UeButton>
        <UeButton @click="() => savePosition()"> Save position </UeButton>
      </div>
    </div>
    <div
      class="flex gap-3 items-center relative"
      style="overflow: auto !important; min-width: 100% !important"
    >
      <draggable v-model="banners" class="min-w-[100%] gap-3 flex">
        <template #item="{ element: banner }">
          <div class="rounded flex flex-col justify-between min-w-[300px]">
            <div class="flex rounded-t items-center border justify-between p-2">
              <Text> {{ banner?.name || "Links to no where" }}</Text>
              <div class="flex items-center gap-3">
                <span
                  @click="
                    () => {
                      modal = { type: 'DELETEBANNER', data: banner };
                    }
                  "
                  class="text-dark bg-white font-large cursor-pointer rounded-circle material-symbols-outlined"
                >
                  cancel
                </span>
              </div>
            </div>

            <img :src="banner?.img" class="w-[300px] h-[200px] rounded-b" />
          </div>
        </template>
      </draggable>
    </div>
  </div>
</template>

<script setup>
import Text from "@/components/Text.vue";
import UeButton from "@/components/UeButton.vue";
import { get, ref as dbRef, getDatabase, set } from "firebase/database";
import { ref, watchEffect } from "vue";
import MealModal from "@/components/MealModal.vue";
import { useToast } from "vue-toast-notification";
import { projectStorage } from "@/firebase/config";
import draggable from "vuedraggable";
import {
  uploadBytes,
  ref as uploadRef,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

const banners = ref([]);
const modal = ref(null);
const newBanner = ref({ name: "", type: "", img: "" });
const restaurants = ref();
const preview = ref(null);
const seletedImg = ref(null);
const loadingState = ref(false);
const loadingMessage = ref("");
watchEffect(async () => {
  const res = await get(dbRef(getDatabase(), "restaurants"));
  if (res.val()) restaurants.value = Object.keys(res.val());
});
watchEffect(async () => {
  const res = await get(dbRef(getDatabase(), "scroll"));
  banners.value = res?.val();
});

const fileInput = (e) => {
  const file = e.target.files[0];
  preview.value = URL.createObjectURL(file);
  seletedImg.value = file;
};
const toast = useToast({ position: "top-right" });
const addBanner = async () => {
  loadingState.value = true;
  if (!newBanner.value?.name) {
    const proceed = window.confirm("Proceed without linking");
    if (!proceed) return;
  }

  if (!seletedImg.value) {
    toast.info("Can not proceed without img");
    return;
  }
  loadingMessage.value = "please wait while image is being uploaded";
  const storagePath = `banner/${new Date().getTime()}`;
  const storageRef = uploadRef(projectStorage, storagePath);
  try {
    await uploadBytes(storageRef, seletedImg.value);
    const downloadURL = await getDownloadURL(storageRef);
    loadingMessage.value = "Adding banner to app...";
    newBanner.value.img = downloadURL;
    newBanner.value.storagePath = storagePath;
    newBanner.value.type = "";
    if (newBanner.value.name) newBanner.value.type = "restaurant";
    console.log(newBanner.value);
    const newIndex = banners.value?.length || 0;
    await set(dbRef(getDatabase(), `scroll/${newIndex}`), newBanner.value);
    loadingMessage.value = "Success";
    setTimeout(() => {
      loadingState.value = false;
      modal.value = null;
      loadingMessage.value = "";
      banners.value.push(newBanner.value);
    }, 2000);
  } catch (error) {
    console.log(error);
  }
};

const savePosition = async () => {
  console.log(banners.value);
 try {
  await set(dbRef(getDatabase(), `scroll`), banners.value);
  toast.success('Position updated successful')
 } catch (error) {
  
 }
};

const deleteBanner = async () => {
  const banner = modal.value.data;
  const editedBanner = banners.value?.filter(
    (singeBanner) => singeBanner?.img != banner?.img
  );
  if (editedBanner.length) {
    try {
      const imageRef = uploadRef(projectStorage, banner?.storagePath);
      await deleteObject(imageRef);
      await set(dbRef(getDatabase(), `scroll`), editedBanner);
      banners.value = editedBanner;
      modal.value = null;
    } catch (error) {}
  } else {
    toast.info("App must have a least one banner");
  }
};

// watchEffect(() => {
//   loadingMessage.value;
// if(loadingMessage.value){}
//     toast.info(loadingMessage.value);
// });
</script>

<style lang="scss" scoped>
</style>