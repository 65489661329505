
import { useToast } from 'vue-toast-notification';
import { createStore } from "vuex"
import { store } from "./store";
import { extra_permissions_keys } from '../main/Operator/Accounts/extra_permissions';

// import { useRouter } from "vue-router";




// const router = useRouter()
const toast = useToast({ position: 'top-right' })

let timer;
export const confirmAction = createStore({
    state() {
        return {
            modalState: false,
            confirmText: "",
            textEntered: "",
            proceedFunction: null,
            errorMessage: ""

        }
    },
    mutations: {
        toggleModal: (state, payload) => {
            state.modalState = !state.modalState
            state.textEntered = ""
            state.errorMessage = ""
            if (payload) {
                const { onProceed, confirmText } = payload

                if(store.state.user?.extra_permissions?.includes(extra_permissions_keys.override_confirmation_modal)){
                    state.modalState = false
                    onProceed()
                }else{
                    state.proceedFunction = onProceed; state.confirmText = confirmText
                }
                
            }
        },
        enterText: (state, payload) => {
            // console.log({ payload })
            state.textEntered = payload
            state.errorMessage = ""
        },
        onProceed: (state, payload) => {
            // console.log(state)
            if (state.confirmText.toLowerCase() === state.textEntered.toLowerCase()) {
                state.proceedFunction()
                state.modalState = false
                clearTimeout(timer)
            } else {
                state.errorMessage = "Wrong text entered!"
                timer = setTimeout(() => {
                    state.errorMessage = ""
                }, 3000)
            }
        }
    },
    actions: {
    },
    // plugins: [createPersistedState()]
})