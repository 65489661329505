<template>
  <p :style="styles">
    <slot />
  </p>
</template>


<script >
import { ref, watchEffect } from "vue";
export default {
  props: {
    size: Number,
    weight: Number,
    lHeight: Number,
    color: String,
  },
  setup(props) {
    const styles = ref({
      fontSize: props.size + "px",
      fontWeight: props.weight,
      lineHeight: props.lHeight,
      color: props.color,
    });
    watchEffect(() => {
      styles.value = {
        fontSize: props.size + "px",
        fontWeight: props.weight,
        lineHeight: props.lHeight,
        color: props.color,
      };
    });
    return {
      styles,
    };
  },
};
</script>

<style lang="scss" scoped>
p {
  font-family: Work Sans;
  margin: 0;
}
</style>