<template>
  <div :class="styles.variety">
    <div :class="styles.left">
      <Text color="white" :size="35" :weight="700">A variety of meals to choose from</Text>
      <Text  color="white" :class="styles.contentText" :weight="500">
        Order different meals from various restaurants, ready to prepare your
        chosen meal.
      </Text>
    </div>
    <!-- <div :class="styles.left"> -->

    <carousel :items-to-show="1" class="w-50" :autoplay="3000" :transition="9" :wrap-around="true">
      <slide v-for="slide in 5" :key="slide">
        <img :src="icons.home.variety(slide)" alt=""  class="!w-[90%] " />
      </slide>

      <template #addons>
        <!-- <navigation /> -->
        <!-- <pagination /> -->
      </template>
    </carousel>
    <!-- <img :src="icons.home.variety(2)" alt="" /> -->
    <!-- </div> -->
  </div>
</template>

<script setup>
import { icons } from "@/assets/icons";
import Text from "@/components/Text.vue";
import { ref } from "vue";
import styles from "./home.module.scss";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
const images = [icons];
const value = ref(0);
</script>

<style lang="scss" scoped>
// @import url("node_modules/@egjs/vue3-flicking/dist/flicking-inline.css");
</style>