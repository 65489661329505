<template>
  <head>
    <link
      rel="stylesheet"
      href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
    />
  </head>

  <layout :hasTopNav="false">
    <v-dialog v-model="addridermodal" width="auto">
      <add-rider
        :onClose="
          () => {
            addridermodal = false;
            // getRiders();
          }
        "
      />
    </v-dialog>
    <div class="px-[40px] bg-light w-full h-full" v-if="!id">
      <TopNav :onSearch="search" />
      <div class="d-flex justify-content-between orderClass p-3 w-full">
        <div class="w-full">
          <div class="flex w-full items-center justify-between">
            <h1>
              <Text :size="27" :weight="700"> Riders</Text>
            </h1>
            <div>
              <button
                class="button"
                :class="{ active: status === 'completed' }"
                @click="() => (addridermodal = true)"
              >
                Add Rider
              </button>
            </div>
          </div>
        </div>
      </div>
      <section class="orderList !p-0 !bg-white">
        <div>
          <table class="order-table">
            <thead>
              <tr>
                <th class="table-header"></th>
                <!-- <th class="table-header">Order ID</th> -->
                <!-- <th class="table-header">Date</th> -->
                <th class="table-header">Name</th>
                <th class="table-header">Phone</th>
                <th class="table-header">Email</th>
                <th class="table-header">Status</th>
                <th class="table-header">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(rider, index) in paginatedRiders"
                :key="rider.id"
                class="table-row"
                :class="`${index % 2 === 0 ? 'even-row' : 'odd-row'} ${
                  rider.deleted ? 'detetedRider' : ''
                }  `"
              >
                <td class="table-data">{{ index + 1 }}</td>
                <td class="table-data">
                  {{ rider.first_name + " " + rider.last_name }}
                </td>
                <td class="table-data">{{ rider.phone }}</td>
                <td class="table-data">{{ rider.email }}</td>

                <td class="table-data">{{ rider.status }}</td>
                <td class="table-data">
                  <router-link
                    :to="`/logistics/riders/${rider?.id}`"
                    class="action-link !underline"
                    >View</router-link
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <Pagination
            class="py-3"
            :currentPage="currentPage"
            :totalPages="totalPages"
            @page-change="goToPage"
          />
        </div>
      </section>
    </div>

    <div v-else><rider /></div>
    <!-- <OrderModal v-else-if="selectedOrder?.id" :selectedOrder="selectedOrder" /> -->
  </layout>
</template>
          
        <script>
import { ref, computed, watchEffect, onMounted } from "vue";
import Pagination from "@/components/Pagination.vue";
// import OrderModal from "./OrderModal.vue";
import TopNav from "@/components/TopNav.vue";
import Modal from "@/components/Modal.vue";
import { useRoute, useRouter } from "vue-router";
import InputField from "@/components/InputField.vue";
import Text from "@/components/Text.vue";
import { sum, formatDate, getUeOrderId } from "@/util";
import Status from "@/components/Status.vue";
import Layout from "@/partials/Layout/layout.vue";
import { store } from "@/views/store/store";
import AddRider from "./AddRider.vue";
import Rider from "./Rider.vue";
import { getRiders } from "@/onAllScreenLoadActions";
// import { getDatabase, onValue, ref as dbRef } from "firebase/database";

export default {
  components: {
    TopNav,
    Pagination,
    // OrderModal,
    Modal,
    InputField,
    Text,
    Status,
    Layout,
    AddRider,
    Rider,
  },
  setup() {
    const riders = ref(store.state.riders);
    const selectedOrder = ref({});
    const route = useRoute();
    const router = useRouter();
    const searchValue = ref("");
    const id = ref(route.params.id);
    const addridermodal = ref(false);

    // onMounted(() => {
    //   getRiders();
    // });

    watchEffect(() => {
      riders.value = store.state.riders;
    });
    watchEffect(() => {
      id.value = route.params.id;
    });
    const search = (e) => {
      searchValue.value = e;
    };
    watchEffect(() => {
      const val = searchValue.value;
      const checkValue = (string) =>
        string.toLowerCase().includes(val.toLowerCase());
      if (searchValue.value) {
        riders.value = store.state.riders.filter(
          (x) =>
            checkValue(x.first_name) ||
            checkValue(x.last_name) ||
            checkValue(x.phone) ||
            checkValue(x.email)
        );
      } else {
        riders.value = store.state.riders;
      }
    });

    const openAddRider = () => {
      // store.commit("openModal", "");

      addridermodal.value;
    };
    // store.dispatch('updateRiders')
    // Pagination
    const ridersPerPage = 15;
    const currentPage = ref(1);
    const totalPages = computed(() =>
      Math.ceil(riders.value.length / ridersPerPage)
    );

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };

    const paginatedRiders = computed(() => {
      const startIndex = (currentPage.value - 1) * ridersPerPage;
      const endIndex = startIndex + ridersPerPage;
      return riders.value.slice(startIndex, endIndex);
    });

    return {
      ridersPerPage,
      currentPage,
      totalPages,
      goToPage,
      paginatedRiders,
      sum,
      openAddRider,
      selectedOrder,
      router,
      search,
      formatDate,
      getUeOrderId,
      addridermodal,
      getRiders,
      id,
    };
  },
};
</script>
          
          <style scoped>
/* active and completed button */
.button {
  padding: 10px 30px;
  border-radius: 5px;
  border: 1px #f60100 solid;
  background-color: #fff;
  color: #f60100;
  margin-left: 20px;
}
.button.active {
  background: linear-gradient(180deg, #ff9103 0%, #f60100 100%);
  color: white;
  border: none;
}

.pagination-container {
  display: flex;
  column-gap: 10px;
}
.paginate-buttons {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(217, 217, 217);
  color: black;
}
.paginate-buttons:hover {
  background-color: #d8d8d8;
}
.active-page {
  background-color: #3498db;
  border: 1px solid #3498db;
  color: white;
}
.active-page:hover {
  background-color: #2988c8;
}
.detetedRider {
  color: rgba(0, 0, 0, 0.3);
  opacity: 0.3;
}

.order-table {
  width: 100%;
  border-collapse: collapse;
  /* border: 1px solid #ccc; */
}

.table-row {
  height: 60px;
}

.table-row.even-row {
  background-color: #f2f2f2;
}

.table-row.odd-row {
  background-color: #fff;
}

.status-button {
  padding: 5px 10px;
  background: linear-gradient(
    202deg,
    rgba(255, 145, 3, 0.24) 0%,
    rgba(246, 1, 0, 0.24) 100%
  );

  border: none;
  color: #333;
  font-weight: bold;
}

.status-button2 {
  padding: 5px 10px;
  background: linear-gradient(
    202deg,
    rgba(74, 224, 117, 0.428) 0%,
    rgba(0, 246, 78, 0.24) 100%
  );

  border: none;
  color: #333;
  font-weight: bold;
}

.action-link {
  text-decoration: none;
  color: #f60100;
}
</style>