<template>
  <div class="doughtnut w-full">
    <!-- <div class="chart">
      <Doughnut :data="data" :options="options" :borderWidth="20" />
    </div> -->
    <div class="flex flex-col w-full number h-[100px] justify-between">
      <div class="flex gap-3 items-center w-full">
        <text class="w-[20%] whitespace-nowrap">Confirmed </text>
        <div class="w-[70%]">
          <v-progress-linear
            width="80%"
            class="border"
            bg-color="grey-darken-4"
            color="green-darken-3"
            :model-value="getWidth('confirmedOrders')"
          ></v-progress-linear>
        </div>
        <text :size="10" class="w-[10%] text-center" :weight="500">{{
          numbers.confirmedOrders
        }}</text>
      </div>
      <div class="flex gap-3 items-center w-full">
        <text class="w-[20%] whitespace-nowrap">Prepared </text>
        <div class="w-[70%]">
          <v-progress-linear
            width="80%"
            class="border"
            bg-color="grey-darken-4"
            color="orange"
            :model-value="getWidth('preparedOrders')"
          ></v-progress-linear>
        </div>
        <text :size="10" class="w-[10%] text-center" :weight="500">{{
          numbers.preparedOrders
        }}</text>
      </div>
      <div class="flex gap-3 items-center w-full">
        <text class="w-[20%] whitespace-nowrap">Assembled </text>
        <div class="w-[70%]">
          <v-progress-linear
            width="80%"
            class="border"
            bg-color="grey-darken-4"
            color="red"
            :model-value="getWidth('assembledOrders')"
          ></v-progress-linear>
        </div>
        <text :size="10" class="w-[10%] text-center" :weight="500">{{
          numbers.assembledOrders
        }}</text>
      </div>
      <div class="flex gap-3 items-center w-full">
        <text class="w-[20%] whitespace-nowrap">Dispatched </text>
        <div class="w-[70%]">
          <v-progress-linear
            width="80%"
            class="border"
            bg-color="grey-darken-4"
            color="red"
            :model-value="getWidth('dispatchedOrders')"
          ></v-progress-linear>
        </div>
        <text :size="10" class="w-[10%] text-center" :weight="500">{{
          numbers.dispatchedOrders
        }}</text>
      </div>
      <div class="flex gap-3 items-center w-full">
        <text class="w-[20%] whitespace-nowrap">Delivered </text>
        <div class="w-[70%]">
          <v-progress-linear
            width="80%"
            class="border"
            bg-color="grey-darken-4"
            color="red"
            :model-value="getWidth('deliveredOrders')"
          ></v-progress-linear>
        </div>
        <text :size="10" class="w-[10%] text-center" :weight="500">{{
          numbers.deliveredOrders
        }}</text>
      </div>
      <div class="flex gap-3 items-center w-full">
        <text class="w-[20%] whitespace-nowrap">Picked up </text>
        <div class="w-[70%]">
          <v-progress-linear
            width="80%"
            class="border"
            bg-color="grey-darken-4"
            color="red"
            :model-value="getWidth('pickedupOrders')"
          ></v-progress-linear>
        </div>
        <text :size="10" class="w-[10%] text-center" :weight="500">{{
          numbers.pickedupOrders
        }}</text>
      </div>
    </div>
  </div>
</template>

<script  >
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "vue-chartjs";
import Text from "@/components/Text.vue";
// import * as chartConfig from "./chartConfig.js";

const data = {
  labels: ["Cancelled", "Completed", "Active"],
  datasets: [
    {
      backgroundColor: ["#F60100", "#FF9103", "#008F7E"],
      data: [10, 60, 30],
    },
  ],
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  cutout: 35,
  borderWidth: 10,
  plugins: {
    legend: {
      display: false,
    },
  },
};
ChartJS.register(ArcElement, Tooltip);
// Chart.defaults.global.elements.arc.borderWidth = 1;

export default {
  name: "App",
  components: {
    Doughnut,
    Text,
  },
  props: {
    numbers: Object,
    // preparedOrders: Number,
    // assembledOrders: Number,
    // dispatchedOrders: Number,
    // deliveredOrders: Number,
    // pickedupOrders: Number,
  },
  setup(props) {
    const getWidth = (value) => {
      if(props.numbers[value]){

        return  (props.numbers[value] / props.numbers.allOrders) * 100;
      }else{
        return 0
      }
    };
    return {
      data,
      options,
      props,
      numbers: props.numbers,
      getWidth,
    };
  },
};
</script>
<style lang='scss'>
.doughtnut {
  display: flex;
  align-items: start;

  .chart {
    width: 150px;
    height: 150px;
  }
  .number {
    // display: flex;
    gap: 16px;
    p {
      white-space: nowrap;
    }
  }
}
</style>
