
<template>
  <v-dialog v-model="cancelAddonModal">
    <div
      class="bg-white rounded mx-auto p-3 w-[400px] justofy-center flex flex-col gap-2"
    >
      <Text :weight="600" :size="18" class="text-center">
        {{ current.name }} ({{
          getItemQty({ subItem: current, mainItem: item })
        }})
      </Text>

      <Text :weight="400" :size="12" class="text-center">
        Cancel some {{ current.name }} out of this order
      </Text>
      <Text :weight="400" :size="12" class="text-center">
        Price: {{ formatPrice(current?.amount) }}
      </Text>
      <!-- {{ count }} {{ current?.cancelled }} -->
      <Text
        v-if="count != (current?.cancelled || 0)"
        :weight="400"
        :size="12"
        class="text-center"
      >
        customer will be
        {{ count < (current?.canceled || 0) ? "charged" : "refunded" }}
      </Text>
      <!-- {{ current?.qty * props.item.quantity  }} -->
      <!-- <div class="border"> -->
      <quantity-counter
        class="w-[fit-content] mx-auto"
        :count="count"
        :max="max"
        :onchange="edit"
      />

      <!-- </div> -->
      <div class="flex justify-evenly w-100 mt-3">
        <div
          class="border px-2 rounded w-25 text-center cursor-pointer"
          @click="
            () => {
              cancelAddonModal = false;
              current = {};
            }
          "
        >
          Cancel
        </div>
        <div
          class="border px-2 rounded w-25 text-center cursor-pointer"
          @click="() => cancel()"
        >
          {{ cancelLoading ? "Loading..." : "Save" }}
        </div>
      </div>
    </div>
  </v-dialog>
  <div class="item-meal">
    <div><img :src="props.img" alt="" />{{ props.imgUrl }}</div>
    <div class="flex-col items-center">
      <Text :color="'#F60100'" :size="14" :weight="500" class="uppercase">
        {{ Array(props.category).join(",` `") }}
      </Text>
      <Text
        :color="'#000000'"
        :size="21"
        :weight="600"
        class="capitalize flex items-center"
      >
        {{ props.mealname }}
        <div
          @click="
            () => {
              if (
                !store.state?.user?.extra_permissions?.includes(
                  extra_permissions_keys.cancel_single_item
                )
              ) {
                toast.info(
                  'You don\'t have permission to perform this action! '
                );
                return;
              }
              if (
                item?.prepared ==
                  item?.quantity - (item?.cancelled || item?.canceled) &&
                item?.prepared != 0
              ) {
                toast.info('Please unprepare item');
              } else {
                cancelAddonModal = true;
                current = item;
              }
            }
          "
        >
          <item-meal-canceled :addon="item" :item="item" />
        </div>

        <button
          @click="
            () => {
              editOrder.commit('openAddExtraModal', true);
              editOrder.commit('setItem', { item, itemIndex: props.itemIdex });
            }
          "
          v-if="editable"
          class="btn"
        >
          +
        </button>
      </Text>
      <div v-if="item?.note">Note: {{ item?.note }}</div>
      <span v-if="item?.extraPortion">
        EXTRA PORTION - {{ item?.extraPortion?.name }}
        <!-- @
        {{ formatPrice(item?.extraPortion?.amount) }} -->
      </span>
      <div
        v-for="addon in props.addons"
        class="flex items-center gap-1"
        :key="addon.name"
        style="white-space: nowrap"
      >
        <Text :weight="700" :size="14" class="!uppercase">
          {{ addon.food == "extra" ? "add-ons" : addon.food }}
        </Text>
        -
        <Text class="flex items-center gap-2" :weight="700" :size="14">
          {{ addon.name }}
          <div
            @click="
              () => {
                if (order?.items_cancelling) {
                  toast.info('Please rectify previous action');
                } else {
                  cancelAddonModal = true;
                  current = addon;
                }
              }
            "
          >
            <item-meal-canceled :addon="addon" :item="item" />
          </div>
        </Text>
      </div>
    </div>
  </div>
  <add-extra :order="order" :item="item" />
</template>

<script scoped>
import Text from "@/components/Text.vue";
import {
  set,
  ref as dbRef,
  getDatabase,
  get,
  update,
  remove,
} from "firebase/database";
import { computed, ref, watchEffect } from "vue";
import { useToast } from "vue-toast-notification";
import QuantityCounter from "./QuantityCounter.vue";
import ItemMealCanceled from "./ItemMealCanceled.vue";
import {
  formatPrice,
  getOrderID,
  writeOrderTotalInTimeline,
  getItemQty,
} from "@/util";
import { editOrder } from "@/views/store/editOrder";
import AddExtra from "./AddExtra.vue";
import { WalletTransaction } from "@/views/main/Operator/Customers/updateWallet";
import { createTimeLine, store } from "@/views/store/store";
import { createCancelledSummary } from "@/views/main/Operator/Cancelled/createCancelledSummary";
import { extra_permissions_keys } from "@/views/main/Operator/Accounts/extra_permissions";
export default {
  props: {
    img: String,
    category: String,
    mealname: String,
    addons: Array,
    order: Object,
    itemIdex: Number,
    item: Object,
  },
  components: {
    Text,
    QuantityCounter,
    ItemMealCanceled,
    AddExtra,
  },
  setup(props) {
    const cancelled = ref();
    // watchEffect(() => {});
    const toast = useToast({ position: "top-right" });
    const cancelAddonModal = ref(false);
    const item = ref(props.item);
    const current = ref({});
    const count = ref(0);
    const order = ref(props.order);
    const cancelLoading = ref(false);

    watchEffect(() => {
      item.value = props.item;
      order.value = props.order;
      const isNumber = typeof current.value.cancelled === "number";
      if (isNumber) {
        count.value = current.value.cancelled;
      } else {
        count.value = 0;
      }
    });

    const max = computed(() => {
      // console.log(getItemQty({
      //   subItem: current.value.quantity,
      //   mainItem: props.item.quantity,
      // }))
      return getItemQty({
        subItem: current.value,
        mainItem: props.item,
      });
    });
    const mark_addon = async (item) => {
      if (props.order?.status?.Dispatched?.state) {
        toast.info("This order has been dispatched");
      } else {
        if (props.order?.refund_completed) {
          toast.info("A refund has already been made on this order");
        } else {
          const path = `/orders/${props.order.id}/items/${props.itemIdex}/${item.type}/${item.index}/cancelled`;
          const query = dbRef(getDatabase(), path);
          const res = await get(query);
          cancelled.value = res.val();

          try {
            if (cancelled.value == null) {
              await set(query, false);
              cancelled.value = false;
            } else {
              // toggle;
              await set(query, !cancelled.value);
              cancelled.value = !cancelled.value;
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
    };

    const cancel = async () => {
      if (props.order?.status?.Dispatched?.state) {
        toast.info("This order has been dispatched");
      } else {
        if (props.order?.refund_completed) {
          toast.info("A refund has already been made on this order");
        } else {
          let path = `/orders/${props.order.id}/items/${props.itemIdex}/${current.value.type}/${current.value.index}/cancelled`;
          if (typeof current.value.index == "undefined")
            path = `/orders/${props.order.id}/items/${props.itemIdex}/cancelled`;

          if (cancelLoading.value) {
            toast.info("Please be patient while its processing");
            return;
          }

          // console.log(current.value.index);
          const query = dbRef(getDatabase(), path);
          cancelLoading.value = true;
          const res = await get(query);
          cancelled.value = res.val() || 0;
          // console.log(first)
          let state = {
            new: current.value.cancelled || 0,
            old: cancelled.value,
          };
          const isSame = state.new == state.old;
          const isCredit = state.new > state.old;
          const isDebit = state.new < state.old;
          // console.log(state);
          // console.log({ isCredit, isDebit, isSame });
          let user = {};
          user = (
            await get(
              dbRef(getDatabase(), `users/${props.order?.customer?.id}`)
            )
          ).val();

          const wallet = new WalletTransaction(user);

          try {
            let message = "";
            let num = Math.abs(state.new - state.old);
            let isMainItem = current.value?.resturant;
            let useItemName = `${isMainItem ? "" : ` in ${item.value?.name}`}`;

            // console.log(useItemName);
            const fn = async (amount) => {
              // console.log(order?.value?.total_price)
              let new_total = 0;
              if (isDebit) new_total = order?.value?.total_price + amount;
              if (isCredit) new_total = order?.value?.total_price - amount;

              await set(query, current.value.cancelled);
              remove(
                dbRef(
                  getDatabase(),
                  `orders/${props.order?.id}/items_cancelling`
                )
              );
              set(
                dbRef(getDatabase(), `orders/${props.order?.id}/total_price`),
                new_total
              );
              createCancelledSummary({
                order: props.order,
                message,
                amount,
              });
              createTimeLine({
                orderId: props.order?.id,
                user_type: "admin",
                uid: store.state.user.uid,
                name:
                  store.state.user.first_name +
                  " " +
                  store.state.user.last_name,
                message: `${message}, order total ${writeOrderTotalInTimeline(
                  new_total
                )}`,
              });
              cancelLoading.value = false;
              cancelAddonModal.value = false;
              current.value = {};
            };
            let amount = num * parseInt(current.value?.amount);
            if (isCredit) {
              message = `cancelled ${num} ${current.value?.name} (${formatPrice(
                amount
              )}) ${useItemName}`;

              const initiateItemsCancelling = () => {
                set(
                  dbRef(
                    getDatabase(),
                    `orders/${props.order?.id}/items_cancelling`
                  ),
                  {
                    amount,
                    message,
                    transactionType: "refund",
                    transactionRectification: "charge",
                  }
                );
              };
              await wallet.depositToWallet(
                amount,
                `refund for ${current.value?.name} ${useItemName} ${getOrderID(
                  props.order
                )}`,
                () => fn(amount),
                initiateItemsCancelling
              );
            }
            if (isDebit) {
              message = `restored ${num} ${current.value?.name} (${formatPrice(
                amount
              )}) ${useItemName}`;
              const initiateItemsCancelling = () => {
                set(
                  dbRef(
                    getDatabase(),
                    `orders/${props.order?.id}/items_cancelling`
                  ),
                  {
                    amount,
                    message,
                    transactionType: "charge",
                    transactionRectification: "refund",
                  }
                );
              };
              await wallet.withdrawFromWallet(
                amount,
                `charged for restore of ${
                  current.value?.name
                } ${useItemName} ${getOrderID(props.order)}`,
                () => fn(amount),
                null,
                initiateItemsCancelling
              );
            }
            if (isSame) {
              cancelLoading.value = false;
              cancelAddonModal.value = false;
              current.value = {};
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
    };

    const edit = async (e) => {
      current.value.cancelled = e;
    };

    const editable = computed(() => {
      return (
        editOrder.state.order?.id == order.value.id &&
        item.value.prepared != item.value.quantity - (item.value.canceled || 0)
      );
    });
    return {
      props,
      mark_addon,
      cancelled,
      cancelAddonModal,
      current,
      cancel,
      edit,
      count,
      item,
      formatPrice,
      editOrder,
      order,
      editable,
      cancelLoading,
      toast,
      createCancelledSummary,
      store,
      extra_permissions_keys,
      max,
      getItemQty,
    };
  },
};
</script>

<style lang="scss" scoped>
.item-meal {
  width: 400px;
  gap: 20px;
  display: flex;
  align-items: center;
  img {
    width: 88px;
    height: 88px;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    object-fit: cover;
  }
}
</style>