<template>
  <div class="w-full h-full revbody">
    <nav>
      <div class="left">
        <Text :weight="600" :size="20">Revenue</Text>
      </div>
      <time-drop-down :getDate="(res) => (timeframe = res)" />
    </nav>
    <div class="reveheader">
      <Text :size="12" :weight="500">Income</Text>
      <Text :size="23" :weight="700">{{ formatPrice(revenue) }}</Text>
    </div>
    <main>
      <area-chart :timeframe="timeframe" />
    </main>
  </div>
</template>

<script>
import Text from "@/components/Text.vue";

import AreaChart from "./AreaChart.vue";
import TimeDropDown from "./TimeDropDown.vue";
import { formatPrice, getOrdersByTimeFrame } from "@/util";
import { ref, watchEffect } from "vue";

export default {
  components: { Text, AreaChart, TimeDropDown },
  props: {},
  setup() {
    const timeframe = ref({});
    const revenue = ref(0);

    watchEffect(async() => {
      const ordersInTimeFrame =  await getOrdersByTimeFrame(
        timeframe.value.start,
        timeframe.value.end
      );
      let totalList = ordersInTimeFrame.map((x) => x.total_price);
      if (totalList.length) {
        revenue.value = totalList.reduce((a, b) => a + b);
      }else{
        revenue.value = 0
      }
    });

    return {
      timeframe,revenue, formatPrice
    };
  },
};
</script>

<style lang="scss" >
.revbody {
  font-family: Work Sans;
  height: 100%;
  padding: 33px 35px;
  width: 100%;
  display: flex;
  gap: 24px;
  justify-content: space-between;
  flex-direction: column;

  nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .reveheader {
    border-radius: 8px;
    width: 100%;
    height: 48px;
    flex-shrink: 0;
    display: flex;
    align-items: none;
    flex-direction: column;
    padding: 0 15px;
    justify-content: none;
  }
  main {
    display: flex;
    flex-direction: column;
    height: 100%;
    // justify-content: space-evenly;
    section {
      &:nth-child(1) {
        // height: 100px;
        margin: 12px 0;
        align-items: center;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
</style>