<template>
  <div class="bg-white p-2 mb-3 w-100 rounded">
    <div class="flex justify-between mb-3">
      <Text :size="20" weight="600"> Update App Explore Arrangement</Text>
      <div class="flex gap-2">
        <!-- <UeButton @click="() => (modal = { type: 'ADDexplore', data: null })">
            Add explore
          </UeButton> -->
        <UeButton @click="() => savePosition()"> Save position </UeButton>
      </div>
    </div>
    <div
      class="flex gap-3 items-center relative"
      style="min-width: 100% !important"
    >
      <draggable v-model="explores" class="min-w-[100%] gap-3 flex flex-wrap">
        <template #item="{ element: explore }">
          <div
            class="rounded flex flex-col justify-between min-w-[100px] cursor-move"
          >
            <div class="flex rounded-t items-center border justify-between p-2">
              <Text> {{ explore?.name }}</Text>

              <span class="cursor-pointer underline" @click="()=>selectedExplore=explore?.name">edit</span>
            </div>
            <img :src="explore?.image" class="w-[100px] h-[50px] rounded-b" />
          </div>
        </template>
      </draggable>
    </div>
    <v-dialog v-model="selectedExplore" persistent>
    
        <selected-explore :explore="selectedExplore" :onClose="()=>selectedExplore = null"/>

    </v-dialog>
  </div>
</template>
  
  <script setup>
import Text from "@/components/Text.vue";
import UeButton from "@/components/UeButton.vue";
import { get, ref as dbRef, getDatabase, set } from "firebase/database";
import { ref, watchEffect } from "vue";
import { useToast } from "vue-toast-notification";
import draggable from "vuedraggable";
import SelectedExplore from './SelectedExplore.vue'

const explores = ref([]);
const selectedExplore = ref(null)
//   const modal = ref(null);
//   const restaurants = ref();

//   watchEffect(async () => {
//     const res = await get(dbRef(getDatabase(), "restaurants"));
//     if (res.val()) restaurants.value = Object.keys(res.val());
//   });
watchEffect(async () => {
  const res = await get(dbRef(getDatabase(), "explore"));
  explores.value = res?.val();
});

const toast = useToast({ position: "top-right" });

const savePosition = async () => {
  // console.log(explores.value?.map(explore=>explore?.name));
  try {
    await set(dbRef(getDatabase(), `explore`), explores.value);
    toast.success("Position updated successful");
  } catch (error) {
    console.log(error);
  }
};
</script>
  
  <style lang="scss" scoped>
</style>