<template>
  <div v-if="table">
    <table class="order-table">
      <thead>
        <tr>
          <th class="table-header"></th>
          <th class="table-header">Order ID</th>
          <th class="table-header">Date</th>
          <th class="table-header">Customer Name</th>
          <th class="table-header">Service</th>
          <th class="table-header">Payment Method</th>
          <th class="table-header">Tag</th>
          <th class="table-header">Amount due</th>
          <th class="table-header text-center">Confirm Payment</th>
          <th class="table-header">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(order, index) in paginatedOrders"
          :key="order.id"
          class="table-row"
          :class="index % 2 === 0 ? 'even-row' : 'odd-row'"
        >
          <td class="table-data">
            {{ index + 1 + 15 * (currentPage - 1) }}
          </td>
          <td class="table-data">
            {{ isPendingButProbablyPaid(order) ? "✓" : "" }}
            <!-- {{ getOrderID(order) }} -->
            <OrderID :order="order" />
          </td>
          <td class="table-data">
            {{ formatDate(order.status?.AwaitingPayment?.time || order?.date) }}
          </td>
          <td class="table-data">
            <!-- {{ `${order?.customer?.firstName}  ${order?.customer?.lastName}` }} -->
            <customer-name :customer="order?.customer" />
          </td>
          <td class="table-data">{{ order.locationType }}</td>
          <td class="table-data">
            {{ order?.paymentMethod }}
          </td>
          <!-- <td
            v-if="order.locationType?.toLowerCase() === 'delivery'"
            class="table-data"
          >
          </td> -->

          <td class="table-data">
            <customer-tag :uid="order?.customer?.id" :order="order" />
          </td>
          <td class="table-data">
            <!-- <customer-tag :uid="order?.customer?.id" :order="order" />  -->
            {{ formatPrice(order.amount_due || (order?.total_price - order?.walletUsed)) }}
          </td>
          <td class="table-data">
            <confirm-payment
              :order="order"
              class="flex justify-center"
              :on-success="() => props.onConfirmOrder()"
            >
              <input-field
                :type="'roundcheck'"
                :disabled="isConfirmed(order)"
                :checked="isConfirmed(order)"
              />
            </confirm-payment>
          </td>

          <!-- <td class="table-data">{{ order.paymentMethod }}</td> -->
          <td class="table-data">
            <router-link
              :to="`/${path.split('/')[1]}/unconfirmed/${order?.id}`"
              class="action-link !underline"
              >View</router-link
            >
          </td>
        </tr>
      </tbody>
    </table>
    <!-- {{ store.state.searchValue }} -->
    <Pagination
      class="py-3"
      :currentPage="currentPage"
      :totalPages="totalPages"
      @page-change="goToPage"
    />
    <div class="flex justify-center">
      <button
        v-if="hasUndefinedOrder"
        class="mx-auto p-2 self-center m-2 bg-[red] text-white w"
        @click="deleteUndefinedOrders"
      >
        Delete undefined orders
      </button>
      <button
        :class="`mx-auto p-2 self-center m-2 ${
          loading_progress ? 'bg-light' : 'bg-[red]'
        } text-white`"
        :disabled="loading_progress"
        @click="deletePendingOrdersLaterThan2hours"
      >
        Clear pending orders later than 2 hours
      </button>
    </div>
  </div>

  <SingleUnconfirmed v-else />
</template>

<script setup>
import Pagination from "@/components/Pagination.vue";
import CustomerTag from "@/components/CustomerTag.vue";
import { formatDate, getOrderID, formatPrice } from "@/util";
import { searchOrder } from "@/util/filterOrder";
import { createTimeLine, store } from "@/views/store/store";
import { computed } from "vue";
import ConfirmPayment from "./ConfirmPayment.vue";
import { ref, watchEffect } from "vue";
import InputField from "@/components/InputField.vue";
import SingleUnconfirmed from "./SingleUnconfirmed.vue";
import { useRoute } from "vue-router";
import { getDatabase, set, ref as dbRef, remove } from "firebase/database";
import dayjs from "dayjs";
import { useToast } from "vue-toast-notification";
import { removeUserPendingTransaction } from "@/views/main/Operator/Orders/removeUserPendingTransaction";
import CustomerName from "@/components/CustomerName.vue";
import OrderID from "@/components/OrderID.vue";

const props = defineProps({
  onConfirmOrder: {
    type: Function,
    default: () => null,
  },
});

const orders = ref([]);
const table = ref(true);

const loading_progress = ref(0);
const { path } = useRoute();
const toast = useToast({ position: "top-right" });

watchEffect(async () => {
  // orders.value =;

  orders.value = await searchOrder(
    store.state.unconfirmedOrders,
    store.state.searchValue
  );
});

// Pagination
const ordersPerPage = 15;
const currentPage = ref(1);
const totalPages = computed(() =>
  Math.ceil(orders.value.length / ordersPerPage)
);

const goToPage = (page) => {
  if (page >= 1 && page <= totalPages.value) {
    currentPage.value = page;
  }
};
const isConfirmed = (order) => {
  return !order.status.AwaitingPayment.state && order.status.Confirmed.state;
};

const isPendingButProbablyPaid = (order) => {
  return !order.status.AwaitingPayment.state && !order.status.Confirmed.state;
};

const paginatedOrders = computed(() => {
  const startIndex = (currentPage.value - 1) * ordersPerPage;
  const endIndex = startIndex + ordersPerPage;
  return orders.value.slice(startIndex, endIndex);
});
const hasUndefinedOrder = ref(false);
watchEffect(() => {
  hasUndefinedOrder.value = store.state.undefined_orders.length;
});

const deleteUndefinedOrders = async () => {
  try {
    await remove(dbRef(getDatabase(), "orders/undefined"));
    toast.success("Undefined order found and removed");
  } catch (error) {
    console.log(error);
  }
};

const deletePendingOrdersLaterThan2hours = async () => {
  let isAny = false;

  const orders = store.state.unconfirmedOrders.filter((order) => {
    const pendingTime = order.status?.AwaitingPayment?.time?.replaceAll(
      '"',
      ""
    );
    const now = dayjs();
    const isPass2hours = Math.abs(dayjs(pendingTime).diff(now, "hours")) >= 2;
    return isPass2hours;
  });
  if (orders.length) {
    toast.info("Please wait while orders are cleared...");
    toast.info("Please stay on this page and don't refresh...");
  }

  orders.forEach(async (element) => {
    isAny = true;
    const fn_s = async () => {
      await removeUserPendingTransaction({
        customer_uid: element?.customer?.id,
        order: element,
      });
      await createTimeLine({
        orderId: element?.id,
        user_type: "auto",
        uid: "N/A",
        name: null,
        message: `Order was cancelled by \"clearing\" `,
      });
      await set(
        dbRef(getDatabase(), `orders/${element?.id}/status/Cancelled/`),
        {
          state: true,
          time: JSON.stringify(dayjs().toISOString()),
        }
      );

      loading_progress.value++;
    };
    await fn_s();
  });

  if (orders.length) {
    toast.success("Successfull");
    loading_progress.value = 0;
  }

  if (!orders.length || !isAny) {
    console.log(orders.length);
    toast.info("Non available");
  }
};
</script>

<style lang="scss" scoped>
</style>