<template>
  <!-- <img :src="icons.home.orange" alt="" :class="styles.bgImg" /> -->

  <header class="home-header">
    <div :class="styles.left">
      <Text
        :weight="700"
        :class="`${styles.animateText} ${styles.headerText}`"
        :style="{ opacity: opacity }"
        class="manage-wrap"
      >
        {{ headerTexts[currentHeader] }}</Text
      >
      <Text :weight="500" :class="styles.contentText">
        Order food from favourite restaurants near you.
      </Text>
      <div :class="styles.stores">
       <a :href="playstore" target="_blank"> <img :src="icons.home.playstore" alt="" :class="imgs" /></a>
       <a :href="appstore"  target="_blank"> <img :src="icons.home.applestore" alt="" :class="imgs" /></a>
      </div>
    </div>

    <img :src="icons.home.phone" alt="" :class="styles.rightImg" />
  </header>
</template>

<script setup>
import { icons } from "@/assets/icons";
import Text from "@/components/Text.vue";
import styles from "./home.module.scss";
import { onMounted, ref, watchEffect } from "vue";
import { useMediaQuery } from "@vueuse/core";
import { appstore, playstore } from "./links";
const headerTexts = [
  "Sudden cravings?",
  "No time to cook?",
  "Dinner plans in shambles?",
  "Late-night munchies?",
  "Long day at work?",
  "Games night?",
  "Movie night feast?",
  "Spontaneous get-together?",
  "Busy day, no time to cook?",
];
const currentHeader = ref(0);
const isLargeScreen = useMediaQuery("(min-width: 1024px)");

watchEffect(() => {
  isLargeScreen.value;
  console.log(isLargeScreen.value);
});
const opacity = ref(1);
onMounted(async () => {
  setInterval(() => {
    opacity.value = 0.3;

    // console.log(opacity.value);

    currentHeader.value++;

    if (currentHeader.value == headerTexts.length - 1) {
      currentHeader.value = 0;
    }
    opacity.value = 1;

    // console.log(opacity.value);
  }, 3000);
});
// - Order food from favourite restaurants near you.
</script>

<style lang="scss" scoped>

.home-header{
  @media screen and (max-width: 700px) {
  //  height: 400px;
  }
}

.manage-wrap {
  // white-space: nowrap;
  @media screen and (max-width: 700px) {
    white-space: pre-wrap;
  }
  // @media screen and (max-width:992px) {
  //   display: none;
  // }
}
</style>