import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { projectAuth } from './firebase/config'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import 'vuetify/dist/vuetify.min.css'
import 'vuetify/dist/vuetify.min.js'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import CanvasJSChart from '@canvasjs/vue-charts';

// import Toast from "vue-toastification";
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';

//vuejs google maps 
import VueGoogleMaps from '@fawmi/vue-google-maps'

// import "@egjs/vue3-flicking/dist/flicking.css";
// Or, if you have to support IE9
// import "@egjs/vue3-flicking/dist/flicking-inline.css";



//global styles
import "bootstrap/dist/css/bootstrap.css";
import "semantic-ui-css/semantic.min.css";
import "bootstrap/dist/js/bootstrap.js"
import './assets/main.css'
import store from '@/views/store'

import { store as vuexStore } from '@/views/store/store'
import './index.scss'

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

// import Text from '@/components/Text.vue'

import {
    mdiCheckboxBlankOutline,
    mdiCheckboxMarked,
    mdiCheckboxMarkedOutline,
} from '@mdi/js'

// vuetify options:
const icons = {
    iconfont: 'mdiSvg',
    defaultAssets: false,
    values: {
        checkboxOn: mdiCheckboxMarked,
        checkboxOff: mdiCheckboxBlankOutline,
        checkboxIndeterminate: mdiCheckboxMarkedOutline,
    }
}

const vuetify = createVuetify({
    components,
    directives, icons
})
// router.

if (window.Cypress) {
    // Add `store` to the window object only when testing with Cypress
    window.store = vuexStore
}
let app;
projectAuth.onAuthStateChanged(() => {
    if (!app) {
        app = createApp(App)
        app.use(router);
        app.use(store);
        app.use(vuetify);
        // app.component(Text);
        app.use(CanvasJSChart);
        app.use(ToastPlugin);
        // app.use(Vuesax);
        app.component("ue-select", vSelect);
        app.use(VueGoogleMaps, {
            load: {
                key: 'AIzaSyAUR5lKBWKOCHVAe1c5iN4e5vo0OL7_GxY',libraries: "places"
            }
        })
        // app.use(Toast, {});xs
        app.mount('#app');
    }
})

