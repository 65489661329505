

<template>
  <layout :hasTopNav="false" class="ingre">
    <v-dialog v-model="openModal"
      ><update-meal-inventory :onClose="() => (openModal = false)"
    /></v-dialog>
    <div class="px-4 overflow-x-hidden h-[100%] relative">
      <top-nav :arrowback="true" :searchBar="false" />
      <header class="flex justify-between">
        <Text :size="27" :weight="600">{{ meal_recipe?.meal?.name }}</Text>
        <Text :size="17" :weight="400" class="flex">
          <move-inventory :recipe="meal_recipe"
        /></Text>
      </header>

      <Text class="!mt-[50px]" :size="20" :weight="600">Reason</Text>
      <ue-table :columns="columns" :list="history" :perPage="10" />

      <div class="flex justify-center my-5 w-full">
        <!-- <v-btn
          class="ue-btn-gradient w-50 !py-0 !h-[43px] rounded-[30px]"
          @click="() => (openModal = true)"
          >Meal Prep</v-btn
        > -->
        <ue-button :variant="'lg'" @click="() => (openModal = true)"
          >Meal Prep</ue-button
        >
      </div>
    </div>
  </layout>
</template>

<script>
import layout from "@/partials/Layout/layout.vue";
import Text from "@/components/Text.vue";
import { ref, watchEffect } from "vue";
import { get, getDatabase, ref as dbRef } from "firebase/database";
import { useRoute } from "vue-router";
import { getImg } from "@/util";
import TopNav from "@/components/TopNav.vue";
import UpdateMealInventory from "./UpdateMealInventory.vue";
import UeTable from "@/components/UeTable.vue";
import MoveInventory from "./MoveInventory.vue";
import UeButton from '@/components/UeButton.vue';

export default {
  components: {
    layout,
    Text,
    TopNav,
    UpdateMealInventory,
    UeTable,
    MoveInventory,
    UeButton,
  },
  setup() {
    const route = useRoute();
    const meal_recipe = ref({});
    const openModal = ref(false);
    const history = ref([]);
    const columns = [
      { name: "Date", key: "date" },
      { name: "Time", key: "time" },
      {
        name: "User",
        key: "user",
        render: (data) => `${data?.user?.first_name} ${data?.user?.last_name} `,
      },
      { name: "Action", key: "action", cellClass: "capitalize" },
      {
        name: "Quantity",
        key: "action",
        render: (data) => (data.action == "damage" ? "-" : "") + data.quantity,
      },
      { name: "Reason", key: "reason" },
    ];

    watchEffect(async () => {
      openModal.value;
      const data = await get(
        dbRef(getDatabase(), `/inventory/recipes/${route.params.id}`)
      );
      if (data.val()) {
        // console.log(data.val());
        meal_recipe.value = data.val();
        if (meal_recipe.value?.history?.length)
          history.value = meal_recipe.value.history.reverse();
      }
    });

    return {
      meal_recipe,
      getImg,
      openModal,
      columns,
      history,
    };
  },
};
</script>

<style lang="scss" scoped>
.ingre {
  img {
    width: 64px;
    max-height: 64px !important;
  }
}
</style>