<template>
  <layout :hasTopNav="false">
    <v-dialog v-model="openDeleteModal">
      <div class="bg-white p-3 w-[400px] mx-auto rounded">
        <MealModal
          title="Delete this staff?"
          :content="'Are you certain you want to delete this staff?'"
          :rightClick="() => deleteStaff()"
          firstBtn="No, Cancel"
          secondBtn="YES, Confirm"
          :index="cancelid"
          :oncancel="() => (openDeleteModal = false)"
          :closeBtn="true"
          :tickBtn="false"
          :id="route.id"
        />
      </div>
    </v-dialog>

    <v-dialog v-model="openForceLogoutModal">
      <div class="bg-white p-3 w-[400px] mx-auto rounded">
        <MealModal
          title="Log staff out ?"
          :content="'Are you certain you want to force logout this staff?'"
          :rightClick="() => forceLogout()"
          firstBtn="No, Cancel"
          secondBtn="YES, Confirm"
          :index="cancelid"
          :oncancel="() => (openForceLogoutModal = false)"
          :closeBtn="true"
          :tickBtn="false"
          :id="route.id"
        />
      </div>
    </v-dialog>
    <div class="px-[40px] w-full">
      <top-nav :arrowback="true" :searchBar="false" />
      <div class="flex w-full justify-between">
        <Text v-if="route.id" :size="24" :weight="600">{{
          route.id == "existing-user" ? "Add existing user" : "Edit staff"
        }}</Text>
        <Text v-if="!route.id" :size="24" :weight="600">Add staff</Text>
      </div>
      <form class="flex mt-5 flex-col w-full gap-[12px]" @submit="proceed">
        <div class="w-full" v-if="route.id !== 'existing-user'">
          <label for="">First name</label>
          <input required v-model="formValues.first_name" />
        </div>
        <div class="w-full" v-if="route.id !== 'existing-user'">
          <label for="">Last name</label>
          <input required v-model="formValues.last_name" />
        </div>
        <div class="w-full" v-if="route.id !== 'existing-user'">
          <label for="">Email</label>
          <input required v-model="formValues.email" type="email" />
        </div>
        <div class="w-full" v-if="route.id !== 'existing-user'">
          <label for="">Phone</label>
          <input required v-model="formValues.phone" type="tel" />
        </div>
        <div class="w-full" v-if="!route.id && route.id !== 'existing-user'">
          <label for="">Temporary password</label>
          <input required v-model="password" type="tel" disabled />
        </div>
        <!-- existing user -->
        <div class="w-full" v-if="route.id === 'existing-user'">
          <label for="">Email</label>
          <input required v-model="existing_email" type="email" />
        </div>
        <!-- existing user -->
        <div class="rounded-[12px] w-full bg-white p-4">
          <Text :weight="600" :size="20">Permissions</Text>
          <hr class="my-4" />
          <div class="flex gap-[40px]">
            <div class="flex flex-col gap-[8px] cursor-pointer">
              <div class="flex">
                <check-box
                  @input="(e) => update_all_permission('operator', e)"
                  :label="''"
                  :checked="isAllSelected('operator', operator_routes)"
                />
                <Text :weight="600" :size="18">Operator</Text>
              </div>
              <check-box
                v-for="route_detail in operator_routes"
                @input="() => update_permission(route_detail.name)"
                :key="route_detail.path"
                :id="route_detail.path"
                :label="route_detail.name.replace('Operator', '')"
                :checked="formValues.permissions.includes(route_detail.name)"
              />
            </div>
            <div class="flex flex-col gap-[8px]">
              <div class="flex">
                <check-box
                  @input="(e) => update_all_permission('logistics', e)"
                  :label="''"
                  :checked="isAllSelected('logistics', logistics_routes)"
                />
                <Text :weight="600" :size="18">Logistics</Text>
              </div>
              <check-box
                v-for="route_detail in logistics_routes"
                @input="() => update_permission(route_detail.name)"
                :key="route_detail.path"
                :id="route_detail.path"
                :label="route_detail.name.replace('Logistics', '')"
                :checked="formValues.permissions.includes(route_detail.name)"
              />
            </div>
            <div class="flex flex-col gap-[8px]">
              <div class="flex">
                <check-box
                  @input="(e) => update_all_permission('inventory', e)"
                  :label="''"
                  :checked="isAllSelected('inventory', inventory_routes)"
                />
                <Text :weight="600" :size="18">Inventory</Text>
              </div>
              <check-box
                v-for="route_detail in inventory_routes"
                @input="() => update_permission(route_detail.name)"
                :key="route_detail.path"
                :id="route_detail.path"
                :label="route_detail.name.replace('Inventory', '')"
                :checked="formValues.permissions.includes(route_detail.name)"
              />
            </div>
            <div class="flex flex-col gap-[8px]">
              <div class="flex">
                <check-box
                  @input="(e) => update_all_permission('kitchen', e)"
                  :label="''"
                  :checked="isAllSelected('kitchen', kitchen_routes)"
                />
                <Text :weight="600" :size="18">Kitchen</Text>
              </div>
              <check-box
                v-for="route_detail in kitchen_routes"
                @input="() => update_permission(route_detail.name)"
                :key="route_detail.path"
                :id="route_detail.path"
                :label="route_detail.name.replace('Kitchen', '')"
                :checked="formValues.permissions.includes(route_detail.name)"
              />
            </div>
            <div class="flex flex-col gap-[8px]">
              <div class="flex">
                <check-box
                  @input="(e) => update_all_permission('assembler', e)"
                  :label="''"
                  :checked="isAllSelected('assembler', assembler_routes)"
                />
                <Text :weight="600" :size="18">Assembler</Text>
              </div>
              <check-box
                v-for="route_detail in assembler_routes"
                @input="() => update_permission(route_detail.name)"
                :key="route_detail.path"
                :id="route_detail.path"
                :label="route_detail.name.replace('Assembler', '')"
                :checked="formValues.permissions.includes(route_detail.name)"
              />
            </div>
          </div>
          <extra-permissions
            :admin="formValues"
            :triggerSave="(extra_permissions) => triggerSave(extra_permissions)"
          />
        </div>
        <button
          v-if="!route.id"
          class="my-4 bg-[orange] py-3 rounded-pill w-75 mx-auto ue-btn-gradient"
          :disabled="loading"
        >
          {{ loading ? "Loading..." : "Add staff" }}
        </button>
        <button
          v-else
          class="my-4 bg-[orange] py-3 rounded-pill w-75 mx-auto ue-btn-gradient"
          :disabled="loading"
        >
          {{
            loading
              ? "Loading..."
              : route.id === "existing-user"
              ? "Add existing user"
              : "Save changes"
          }}
        </button>
      </form>
      <extra-details :staff="formValues" />
      <div
        v-if="route.id && route.id !== 'existing-user'"
        class="flex mt-3 w-full"
      >
        <button
          class="bg-danger px-3 items-center text-white rounded-pill w-[45%] mx-auto mb-4 py-3"
          @click="() => (openForceLogoutModal = true)"
        >
          Force logout 
        </button>
        <button
          class="bg-danger px-3 items-center text-white rounded-pill w-[45%] mx-auto mb-4 py-3"
          @click="() => (openDeleteModal = true)"
        >
          Delete Staff
        </button>
      </div>
    </div>
    <div></div>
  </layout>
</template>

<script>
import TopNav from "@/components/TopNav.vue";
import InputField from "@/components/InputField.vue";
import Text from "@/components/Text.vue";
import CheckBox from "@/components/CheckBox.vue";
import { ref, watchEffect } from "vue";
import { uuid } from "vue-uuid";
import { createUserWithEmailAndPassword, deleteUser } from "firebase/auth";
import { projectAuth, projectFunctions } from "@/firebase/config";
import {
  set,
  ref as dbRef,
  getDatabase,
  get,
  remove,
  query,
  equalTo,
  orderByChild,
} from "firebase/database";
import { httpsCallable } from "firebase/functions";
import { useToast } from "vue-toast-notification";
import Layout from "@/partials/Layout/layout.vue";
import { useRoute } from "vue-router";
import MealModal from "@/components/MealModal.vue";
import router, {
  operator_routes,
  logistics_routes,
  kitchen_routes,
  assembler_routes,
  inventory_routes,
} from "@/router";
import { store } from "@/views/store/store";
import ExtraPermissions from "./ExtraPermissions.vue";
import ExtraDetails from "./ExtraDetails.vue";

export default {
  components: {
    TopNav,
    InputField,
    Text,
    CheckBox,
    Layout,
    MealModal,
    ExtraPermissions,
    ExtraDetails,
  },

  setup() {
    const formValues = ref({
      email: null,
      password: null,
      first_name: null,
      last_name: null,
      phone: null,
      permissions: [],
      // role: null,
    });
    const route = useRoute().params;
    const loading = ref(false);
    const openDeleteModal = ref(false);
    const openForceLogoutModal = ref(false);
    const password = uuid.v4().replaceAll("-", "").substring(0, 8);
    const existing_email = ref("");
    const trigger_save = ref(false);

    watchEffect(() => {
      //load user details into the
      const get_user_to_be_edit = async () => {
        const admin_res = await get(
          dbRef(getDatabase(), `admin_users/${route.id}`)
        );
        if (admin_res.val()) {
          let admin_user = admin_res.val();
          delete admin_user.uid;
          formValues.value = admin_user;
          if (admin_user.permissions)
            formValues.value.permissions = Object.values(
              admin_user?.permissions
            );
          if (!admin_user.permissions) formValues.value.permissions = [];
          // console.log(formValues.value.permissions);
        }
      };
      if (route.id) get_user_to_be_edit();
    });

    const update_permission = (value) => {
      let permissions = formValues.value.permissions;
      const isExist = permissions.filter(
        (permission) => permission == value
      ).length;
      if (isExist) {
        formValues.value.permissions = permissions.filter(
          (permission) => permission != value
        );
      } else {
        formValues.value.permissions.push(value);
      }
    };

    const update_all_permission = (value, e) => {
      const all_pages = [
        ...operator_routes,
        ...logistics_routes,
        ...kitchen_routes,
        ...assembler_routes,
        ...inventory_routes,
      ].map((page) => page.name);
      const checked = e.target.checked;
      const only_pages = all_pages.filter((page) =>
        page.toLowerCase().includes(value)
      );

      only_pages.map((page) => {
        if (checked) {
          const isExist = formValues.value.permissions.filter(
            (permission) => permission == page
          ).length;
          if (!isExist) {
            formValues.value.permissions.push(page);
          }
        } else {
          let temp_permissions = formValues.value.permissions;
          let page_index = temp_permissions.indexOf(page);
          if (page_index != -1) delete temp_permissions[page_index];
          formValues.value.permissions = temp_permissions;
        }
      });

      // formValues.value.permissions.filter;
      // let permissions = formValues.value.permissions;
      // // const isExist = permissions.filter(
      // //   (permission) => permission == value
      // // ).length;
      // formValues.value.permissions.map(per)
      // if (isExist) {
      //   formValues.value.permissions = permissions.filter(
      //     (permission) => permission != value
      //   );
      // } else {
      //   formValues.value.permissions.push(value);
      // }
    };

    const toast = useToast({ position: "top-right" });

    const sendMailToAdminStaff = httpsCallable(
      projectFunctions,
      "sendMailToAdminStaff"
    );

    const addExistingCustomer = async (e) => {
      e.preventDefault();
      try {
        const res = await get(
          query(
            dbRef(getDatabase(), "users"),
            orderByChild("email"),
            equalTo(existing_email.value?.toLowerCase())
          )
        );
        const admin_res = await get(dbRef(getDatabase(), "admin_users"));
        const users = Object.values(res.val());
        const admin_users = Object.values(admin_res.val());
        const is_admin_before = admin_users?.find(
          (user) => user.email === existing_email.value
        );
        const user = users?.find(
          (user) =>
            user.email.toLowerCase() === existing_email.value.toLowerCase()
        );
        // const user = users?.find((user) => user.email === existing_email.value);
        if (is_admin_before) {
          toast.error("This user is already an admin");
        } else {
          if (!user) {
            console.log(user, users);
            toast.error("This customer does not exist");
          } else {
            if (!formValues.value.permissions.length) {
              toast.error("Please ensure at leasst one permission is selected");
            } else {
              // console.log(user);
              set(dbRef(getDatabase(), `admin_users/${user.uid}`), {
                first_name: user.firstName,
                last_name: user.lastName,
                uid: user.uid,
                email: user.email,
                phone: user.phoneNumber,
                permissions: formValues.value.permissions,
              });
              await sendMailToAdminStaff({
                email: existing_email.value,
                first_name: user.name,
                type: "existing",
                link: window.location.origin,
              });
              toast.success("User added successfully");
              router.push("/operator/users-and-permissions");
            }
          }
        }
      } catch (error) {
        // new Error(error).message;
        console.log(error);
        toast.error("Sorry an error occured");
      }
    };

    const submit = async (e) => {
      e.preventDefault();
      loading.value = true;

      formValues.value.password = password;

      try {
        // console.log(formValues.value.permissions);
        if (!formValues.value.permissions?.length) {
          toast.error("User must have at least one permission");
        } else {
          const { user } = await createUserWithEmailAndPassword(
            projectAuth,
            formValues.value.email,
            formValues.value.password
          );
          delete formValues.value.password;
          set(dbRef(getDatabase(), `admin_users/${user.uid}`), {
            ...formValues.value,
            uid: user.uid,
          });

          await sendMailToAdminStaff({
            email: formValues.value.email,
            password,
            first_name: formValues.value.first_name,
            type: "new",
            link: window.location.origin,
          });

          toast.success("Admin staff created successfully");
          router.push("/operator/users-and-permissions");
        }
      } catch (error) {
        const err = error.message
          .replaceAll(/[^a-zA-Z ]/g, " ")
          .replace("Firebase", "")
          .replace("auth", "");
        toast.error(err);
      } finally {
        loading.value = false;
      }
    };

    const forceLogout = async () => {
      try {
        await remove(dbRef(getDatabase(), `admin_users/${route.id}/token`));
        openForceLogoutModal.value = false
        toast.success('User forced to log out')
      } catch (error) {}
    };
    const deleteStaff = async () => {
      // alert(8)
      try {
        const admin_res = await get(dbRef(getDatabase(), "admin_users"));
        let admin_users = admin_res.val();
        const isLoggedInUsed = store.state.user.uid == route.id;

        if (admin_users) {
          let user_list = Object.entries(admin_users);
          const new_users_list = user_list.filter(
            (user) => user[0] !== route.id
          );
          let new_users_obj = Object.fromEntries(new_users_list);
          set(dbRef(getDatabase(), `admin_users`), new_users_obj);
          if (isLoggedInUsed) {
            router.push("/admin");
            store.dispatch("logout");
          } else {
            router.push("/operator/users-and-permissions");
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    const triggerSave = (extra_permissions) => {
      console.log(extra_permissions, "yyy");
      formValues.value.extra_permissions = extra_permissions;
    };
    const editUser = async (e) => {
      e.preventDefault();
      loading.value = true;
      trigger_save.value = true;

      try {
        const payload = {
          uid: route.id,
          ...formValues.value,
        };
        console.log(payload);
        set(dbRef(getDatabase(), `admin_users/${route.id}`), payload);
        toast.success("User details editted successfully");
      } catch (error) {
        console.log(error);
      } finally {
        loading.value = false;
        // trigger_save.value = false;
      }
    };

    const proceed = (e) => {
      if (route.id) {
        route.id === "existing-user" ? addExistingCustomer(e) : editUser(e);
      } else {
        submit(e);
      }
    };

    const isAllSelected = (name, list = []) => {
      const checked_ones = formValues.value.permissions.filter((permission) =>
        permission.toLowerCase().includes(name)
      ).length;
      // console.log(checked_ones,list.length);
      return checked_ones == list.length;
    };

    return {
      formValues,
      operator_routes,
      kitchen_routes,
      assembler_routes,
      logistics_routes,
      inventory_routes,
      update_permission,
      submit,
      loading,
      existing_email,
      addExistingCustomer,
      route,
      editUser,
      deleteStaff,
      forceLogout,
      openForceLogoutModal,
      openDeleteModal,
      password,
      proceed,
      update_all_permission,
      isAllSelected,
      triggerSave,
    };
  },
};
</script>

<style lang="scss" scoped>
input {
  border: 1px solid lightgray;
  border-radius: 8px;
  padding: 12px;
  width: 100%;
}
</style>