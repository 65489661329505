
import { store } from "./views/store/store"

const permissions = Object.values(store.state?.user?.permissions || [])
// console.log(routes)
// const getFirstPermittedPath = (key) => {
//     const dashboardPermittedPages = permissions?.filter((permission) =>
//         permission?.includes(key)
//     )
//     const dashboardPermittedRoutes = routes.filter(route => route.name.includes(key))
//     // console.log(key,dashboardPermittedPages, routes.filter(route => route.name.includes(key)))

//     let firstPermittedPath = ""
//     dashboardPermittedRoutes.forEach(route => {
//         console.log(route)
//     })
//     return ""

// }
export const dashboards = permissions?.length ? [
    {
        name: "Operator",
        key: "/operator",
        path: "/operator",
        // firstPermittedPath: getFirstPermittedPath("Operator"),
        permitted: permissions?.filter((permission) =>
            permission?.includes("Operator")
        ).length || 0,
    },
    {
        name: "Kitchen",
        key: "/kitchen",
        path: "/kitchen/orders",
        // firstPermittedPath: getFirstPermittedPath("Kitchen"),
        permitted: permissions?.filter((permission) =>
            permission?.includes("Kitchen")
        ).length || 0,
    },
    {
        name: "Assembler",
        key: "/assembler",
        path: "/assembler/orders",
        // firstPermittedPath: getFirstPermittedPath("Assembler"),
        permitted: permissions?.filter((permission) =>
            permission?.includes("Assembler")
        ).length || 0,
    },
    {
        name: "Logistics",
        key: "/logistics",
        path: "/logistics/dashboard",
        // firstPermittedPath: getFirstPermittedPath("Logistics"),
        permitted: permissions?.filter((permission) =>
            permission?.includes("Logistics")
        ).length || 0,
    }, {
        name: "Inventory",
        key: "/inventory",
        path: "/inventory/ingredients",
        // firstPermittedPath: getFirstPermittedPath("Inventory"),
        permitted: permissions?.filter((permission) =>
            permission?.includes("Inventory")
        ).length || 0,
    },
] : []
