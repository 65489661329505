import { store } from "@/views/store/store";
import dayjs from "dayjs";
import { endAt, equalTo, get, getDatabase, limitToLast, orderByChild, query, ref, startAt } from "firebase/database";


const today = new Date();
let defaultStart = new Date(`${today.toDateString()} 12:00 AM`).getTime();
let defaultEnd = new Date(`${today.toDateString()} 11:59 PM`).getTime();


defaultStart = new Date(`${today.toDateString()} 12:00 AM`).getTime();
defaultEnd = new Date(`${today.toDateString()} 11:59 PM`).getTime();



export const getOrdersByTimeFrame = async (start = defaultStart, end = defaultEnd, allowCancelled = false, unit) => {
    // console.log(dayjs(start).format('DD-MMM-YYYY hh:mmA'), dayjs(end).format('DD-MMM-YYYY hh:mmA'))
    const startVal = JSON.stringify(
        dayjs(start).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
    )
    const endVal = JSON.stringify(
        dayjs(end).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
    )
    const res = await get(query(ref(getDatabase(), 'orders'), orderByChild("date"), startAt(startVal), endAt(endVal)))
    const v = Object.values(res?.val() || {})
    const filterWithCancelled = (order) => allowCancelled ? true : !order?.status?.Cancelled.state
    const usable = v?.filter(order => filterWithCancelled(order) && !order?.status?.AwaitingPayment?.state && order?.status?.Confirmed?.state && !order?.isSchedule).filter(order => order?.items?.length)
    // console.log(usable.length, v.length, usable)
    // console.log(usable)
    return usable || []

    // if (start?.toString().length > 2) {
    //     return store.state.orders.filter(x => new Date(x.date.replaceAll('"', '')).getTime() >= start && new Date(x.date.replaceAll('"', '')).getTime() <= end)
    // } else {
    //     return store.state.orders.filter(x => new Date(x.date.replaceAll('"', '')).getMonth() == start)
    // }

}
