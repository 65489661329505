<template>
  <div class="bg-white p-3 rounded-[12px] w-full h-full">
    <div class="d-flex justify-content-between orderClass w-full">
      <div class="w-full">
        <div class="flex w-full items-center justify-between">
          <h1>
            <Text :size="18" :weight="700"> Commission</Text>
          </h1>
        </div>
      </div>
    </div>
    <section class="orderList !p-0 !bg-white">
      <div>
        <table class="order-table">
          <thead>
            <tr>
              <th class="table-header">Date</th>
              <th class="table-header">Trips</th>
              <th class="table-header">Commission</th>
              <th class="table-header">Payment Status</th>
              <th class="table-header">Action</th>
            </tr>
          </thead>
          <tbody v-if="paginatedCommissions.length">
            <tr
              v-for="(commission, index) in paginatedCommissions"
              :key="commission.frame"
              class="table-row"
              :class="`${index % 2 === 0 ? 'even-row' : 'odd-row'}`"
            >
              <td class="table-data">{{ commission.frame }}</td>
              <td class="table-data">{{ commission.trips.length }}</td>
              <td class="table-data">
                {{ formatPrice(commission.trips.length * 300) }}
              </td>
              <td class="table-data">{{ commission.status }}</td>
              <td class="table-data cursor-pointer">
                <input-field
                  @click="() => pay(commission.trips, commission.status)"
                  :disabled="true"
                  :checked="commission.status == 'Paid'"
                  :type="'roundcheck'"
                />
                <!-- Pay -->
              </td>
            </tr>
          </tbody>
          <div v-else>Rider has no trip</div>
        </table>
        <Pagination
          class="py-3"
          :currentPage="currentPage"
          :totalPages="totalPages"
          @page-change="goToPage"
        />
      </div>
    </section>
  </div>
</template>
              
            <script>
import { ref, computed, watchEffect } from "vue";
import Pagination from "@/components/Pagination.vue";
import TopNav from "@/components/TopNav.vue";
import Modal from "@/components/Modal.vue";
import { useRoute, useRouter } from "vue-router";
import InputField from "@/components/InputField.vue";
import Text from "@/components/Text.vue";
import Status from "@/components/Status.vue";
import Layout from "@/partials/Layout/layout.vue";
import { ref as dbRef, getDatabase, set, onValue } from "firebase/database";
import { formatPrice } from "@/util";
import dayjs from "dayjs";
import { useToast } from "vue-toast-notification";

export default {
  components: {
    TopNav,
    Pagination,
    Modal,
    InputField,
    Text,
    Status,
    Layout,
  },
  setup() {
    const commissions = ref([]);
    const route = useRoute();
    const router = useRouter();
    const searchValue = ref("");
    const id = ref(route.params.id);
    const weeks = ref([]);

    watchEffect(async () => {
      // const snap = await get(dbRef(getDatabase(), "courier/" + id.value));
      onValue(dbRef(getDatabase(), "courier/" + id.value), (snap) => {
        // console.log(snap.val()?.trip_history);
        if (snap.val()?.trip_history) {
          let history = Object.values(snap.val().trip_history)
          .filter(
            (item) => item.deliveredTime
          );
          // console.log(history?.map(item => `${item.dispatchedTime} ${item.id}`))
          history.sort(
            (a, b) => new Date(a.deliveredTime) - new Date(b.deliveredTime)
          );

          console.log(history);
          const first_date = history[0].deliveredTime;
          const last_date = history[history.length - 1].deliveredTime;

          let monday_of_first_date =
            dayjs(first_date).date() - dayjs(first_date).day() + 1;

          let prev_sunday_of_last_date =
            dayjs(last_date).date() - dayjs(last_date).day();

          const monday_date_of_first_date = `${dayjs(first_date).year()}-${
            dayjs(first_date).month() + 1
          }-${monday_of_first_date}`;
          const prev_sunday_date_of_first_date = `${dayjs(last_date).year()}-${
            dayjs(last_date).month() + 1
          }-${prev_sunday_of_last_date}`;
          const next_sunday_of_last_date = dayjs(
            prev_sunday_date_of_first_date
          ).add(7, "days");

          let add_7_days = dayjs(monday_date_of_first_date);
          let last_sunday_of_the_week = dayjs(add_7_days).add(6, "days");
          let temp_list = [];

          while (add_7_days < next_sunday_of_last_date) {
            console.log("jjj");
            const trips = history.filter(
              (order) =>
                dayjs(order.deliveredTime) >= add_7_days &&
                dayjs(order.deliveredTime) <= last_sunday_of_the_week
            );
            temp_list.push({
              frame: `${dayjs(add_7_days).format("MMM DD")} - ${dayjs(
                last_sunday_of_the_week
              ).format("MMM DD")}`,
              start: add_7_days,
              end: last_sunday_of_the_week,
              trips,

              status: trips[0]?.paymentStatus
                ? "Paid"
                : last_sunday_of_the_week > Date.now()
                ? "Collating"
                : "Not paid",
            });

            add_7_days = dayjs(add_7_days).add(7, "days");
            last_sunday_of_the_week = dayjs(add_7_days).add(6, "days");
          }
          console.log(temp_list);
          commissions.value = temp_list
            .filter((commission) => commission.trips.length)
            .sort((a, b) => new Date(b.end) - new Date(a.end));
        }
      });
    });

    const search = (e) => {
      searchValue.value = e;
    };
    const toast = useToast();

    const pay = (trips, status) => {
      try {
        if (status == "Collating") {
          toast.info("Week commission still collating");
        } else {
          trips.map((trip) => {
            const path = `/courier/${id.value}/trip_history/${trip.id}/paymentStatus`;
            set(dbRef(getDatabase(), path), "Paid");
          });
          toast.success("Commission paid");
        }
      } catch (error) {}
    };

    const commissionsPerPage = 15;
    const currentPage = ref(1);
    const totalPages = computed(() =>
      Math.ceil(commissions.value.length / commissionsPerPage)
    );

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };

    const paginatedCommissions = computed(() => {
      const startIndex = (currentPage.value - 1) * commissionsPerPage;
      const endIndex = startIndex + commissionsPerPage;
      return commissions.value.slice(startIndex, endIndex);
    });

    return {
      commissionsPerPage,
      currentPage,
      totalPages,
      goToPage,
      paginatedCommissions,
      router,
      search,
      id,
      formatPrice,
      pay,
    };
  },
};
</script>
              
              <style scoped>
.button {
  padding: 10px 30px;
  border-radius: 5px;
  border: 1px #f60100 solid;
  background-color: #fff;
  color: #f60100;
  margin-left: 20px;
}
.button.active {
  background: linear-gradient(180deg, #ff9103 0%, #f60100 100%);
  color: white;
  border: none;
}

.pagination-container {
  display: flex;
  column-gap: 10px;
}
.paginate-buttons {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(217, 217, 217);
  color: black;
}
.paginate-buttons:hover {
  background-color: #d8d8d8;
}
.active-page {
  background-color: #3498db;
  border: 1px solid #3498db;
  color: white;
}
.active-page:hover {
  background-color: #2988c8;
}
.detetedRider {
  color: rgba(0, 0, 0, 0.3);
  opacity: 0.3;
}
.action-link {
  text-decoration: none;
  color: #f60100;
}
</style>