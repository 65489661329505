<template>
  <div
    class="justify-center items-center !font-bold w-[fit-content] flex px-2 text-white h-[30px] !font-[12px] !whitespace-nowrap"
    :style="{ backgroundColor: tag.color }"
  >
    {{ tag.content }}
  </div>
</template>

<script setup>
import { projectFunctions } from "@/firebase/config";
import { store } from "@/views/store/store";
import dayjs from "dayjs";
import {
  equalTo,
  get,
  getDatabase,
  orderByChild,
  query,
  ref as dbRef,
  limitToLast,
  set,
  onChildChanged,
  onValue,
  remove,
} from "firebase/database";
import { httpsCallable } from "firebase/functions";
import { watchEffect, ref } from "vue";
const props = defineProps({
  uid: String,
  order: Object,
});
const saveOrderHistory = httpsCallable(projectFunctions, "saveOrderHistory");
const tag = ref({
  color: "",
  content: "---",
});
const showOrder = ref(0);
const customerJoined = ref("");

watchEffect(() => {
  // console.log('------')
  if (props.order?.id && props.order?.tag) {
    // console.log('hhh')
    onValue(
      dbRef(getDatabase(), `orders/${props.order?.id}/status/Cancelled/state`),
      (snap) => {
        // console.log(snap.val(), `orders/${props.order?.id}/status/Cancelled/state`);
        const prev = props.order?.status?.Cancelled?.state;
        const newVal = snap.val();
        // console.log({prev , newVal})
        if (prev !== newVal) {
          // console.log("changed");
          remove(dbRef(getDatabase(), `orders/${props.order?.id}/tag`));
        } else {
          // console.log("not changed");
        }
      }
    );
  }
});

watchEffect(async () => {

  if (props?.uid && !props.order?.tag) {
    const res = await get(
      query(
        dbRef(getDatabase(), "orders"),
        orderByChild("customer/id"),
        equalTo(props.uid || ""),
        limitToLast(6)
      )
    );
    // console.log(res)
    tag.value.content = "No Order";
    tag.value.color = "Blue";
    const last_seven_days = dayjs().subtract(7, "days");
    const list = Object.values(res.val() || {});
    // console.log(list);
    if (res.size) {
      const orders = list.filter((order) => !order?.status?.Cancelled?.state);
      // console.log(orders);

      const dates = orders?.map((order) => {
        return order?.date?.replaceAll('"', "");
      });

      const orders_in_last_seven_days = dates.filter((date) => {
        const yes =
          new Date(date).getTime() > new Date(last_seven_days).getTime();
        return yes;
      }).length;

      if (orders.length === 1) {
        const today = dayjs().format("YYYY-MM-DD");
        tag.value.content = "First Order";

        // console.log("-----");

        if (store.state.user?.email === "kehindesalaudeen222@gmail.com") {
          const date = (
            await get(dbRef(getDatabase(), `users/${props.uid}/created`))
          )
            ?.val()
            ?.replaceAll('"', "");
          if (dayjs(date).format("YYYY-MM-DD") === today) {
            tag.value.content = "First Order.";
          }
        }

        tag.value.color = "green";
        const order = orders[0];
        if (
          dayjs(order?.date?.replaceAll('"', "")).format("YYYY-MM-DD") === today
        ) {
          saveOrderHistory({ userId: props.uid }); //do not temper, sensitive if called to often.
        }
      }
      if (orders.length > 1) {
        tag.value.content = "Regular";
        tag.value.color = "black";
      }
      if (orders_in_last_seven_days >= 4) {
        tag.value.content = "VIP";
        tag.value.color = "red";
      }
      if (props.order?.scheduleDateTime) {
        tag.value.content = "Scheduled";
        tag.value.color = "orange";
      }
    }

    const defaultTag_res = await get(
      dbRef(getDatabase(), `users/${props.uid}/isVip`)
    );

    if (defaultTag_res.val()) {
      tag.value.content = "VIP";
      tag.value.color = "red";
    }
    // console.log(tag.value);
    //save tag in order to avoid recalculation
    if (tag.value.content && props.order?.id)    set(dbRef(getDatabase(), `orders/${props.order?.id}/tag`), tag.value);
  }

  if (props.order?.tag) tag.value = props.order?.tag;
});
</script>

<style lang="scss" scoped>
</style>