<template>
  <div class="rounded-[12px] flex flex-col gap-3 bg-white p-4 w-full">
    <!-- <table class="order-table">
      <thead>
        <tr>
          <th class="table-header">Order ID</th>
          <th class="table-header">Date</th>
          <th class="table-header">Service</th>
          <th class="table-header">Courier</th>
          <th class="table-header">Status</th>
          <th class="table-header">Amount spent</th>
          <th class="table-header">MBP</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(order, index) in paginatedItems"
          :key="order.id"
          class="table-row"
          :class="index % 2 === 0 ? 'even-row' : 'odd-row'"
        >
          <td class="table-data">{{ order?.id }}</td>
          <td class="table-data">{{ formatDate(order?.date) }}</td>
          <td class="table-data">{{ order.locationType }}</td>

          <td
            v-if="order?.locationType?.toLowerCase() === 'delivery'"
            class="table-data"
          >
            {{ order?.courier?.name || "Unassigned" }}
          </td>
          <td v-else class="table-data">N/A</td>

          <td class="table-data">
            <status
              :items="order?.items"
              :status="order.status"
              :order="order"
            />
          </td>
          <td class="table-data text-center">
            {{ formatPrice(order.total_price) }}
          </td>
          <td class="table-data text-center h-[100%]">
            <div class="flex gap-1 items-center">
              {{ getMBP(order)[0]?.point }}
              <span style="font-size: 11px" v-if="getMBP(order).length"
                >x{{ getMBP(order).length }}</span
              >
            </div>
          </td>
        </tr>
      </tbody>
    </table> -->

    <div
      v-for="(history, index) in paginatedItems"
      :key="history.created"
    >
      <div class="flex w-[100%] justify-between">
        <div class="">
          <Text :size="17" :weight="600">
            {{ details(history).title }}
            <!-- {{ customer?.wallet?.history?.length - index
            }} -->
            <!-- {{customer?.wallet?.history?.length - (index + 1 + itemsPerPage * (currentPage - 1)) + 1}}  -->
          </Text>
          <Text> {{ details(history).date }} </Text>
        </div>
        <div class="class text-right">
          <Text :color="details(history).color" :size="17" :weight="600">
            {{ details(history).transaction_type == "credit" ? "+" : "-"
            }}{{ details(history).amount }} ({{
              formatPrice(
                balanceAt(history, customer?.wallet?.history?.length - (index + 1 + itemsPerPage * (currentPage - 1)) + 1)
              )
            }})
          </Text>
          <Text> {{ details(history).time }} </Text>
        </div>
      </div>
    </div>
    <Pagination
      class="py-3"
      :currentPage="currentPage"
      :totalPages="totalPages"
      @page-change="goToPage"
    />
  </div>
</template>

<script setup>
import Pagination from "@/components/Pagination.vue";
import Text from "@/components/Text.vue";
import { formatPrice } from "@/util";
import dayjs from "dayjs";
import { getDatabase, onValue, ref as dbRef } from "firebase/database";
import { computed } from "vue";
import { ref } from "vue";
import { watchEffect } from "vue";
const props = defineProps({
  customer: Object,
});

const customer = ref({});

const getTransactionType = (type) => {
  switch (type) {
    case "wallet-top-up":
      return "credit";
    case "wallet-reversal":
      return "credit";
    case "wallet-withdrawal":
      return "debit";
    case "order-purchase":
      return "debit";
    case "wallet-deduction":
      return "debit";
    default:
      return "credit";
  }
};

const balanceAt = (g, endIndex) => {
  let balance = 0;
  // let endIndex = 3
  const history = customer.value?.wallet?.history;
  for (let index = 0; index < endIndex; index++) {
    const element = history[index];
    // console.log(history[index], index);
    if (getTransactionType(element?.type) == "credit") {
      balance = balance + element.amount;
    } else {
      balance = balance - element.amount;
    }
  }
  return balance;
};

watchEffect(() => {
  customer.value = props.customer;
  // console.log(customer);
  onValue(
    dbRef(getDatabase(), `users/${customer.value.uid}/wallet/history`),
    (snapshot) => {
      if (customer.value.wallet) customer.value.wallet.history = snapshot.val();
    }
  );
});

const details = (history) => {
  const type = history?.type;
  const date_time = history?.created?.replaceAll('"', "");

  const remove = type.replaceAll("-", " ");
  const preceed = remove.charAt(0).toUpperCase() + remove.slice(1) + " ";
  let extra = "for " + history?.order;
  const all_text = preceed + extra;

  let title = all_text,
    date = dayjs(date_time).format("dddd DD MMMM"),
    time = dayjs(date_time).format("hh:mmA"),
    transaction_type,
    color,
    amount = formatPrice(history?.amount);

  switch (type) {
    case "wallet-top-up":
      title = preceed;
      color = "green";
      transaction_type = "credit";
      break;

    case "wallet-reversal":
      color = "green";
      transaction_type = "credit";
      break;

    case "wallet-withdrawal":
      color = "red";
      transaction_type = "debit";
      break;
    case "order-purchase":
      color = "red";
      transaction_type = "debit";
      break;
    case "wallet-deduction":
      title = preceed;
      color = "red";
      transaction_type = "debit";
      break;

    default:
      title = preceed;
      color = "green";
      transaction_type = "credit";
      break;
  }

  return {
    title,
    date,
    time,
    transaction_type,
    color,
    amount,
  };
};

const itemsPerPage = 15;
const currentPage = ref(1);
const totalPages = computed(() =>
  Math.ceil(customer.value.wallet?.history?.length / itemsPerPage)
);

const goToPage = (page) => {
  if (page >= 1 && page <= totalPages.value) {
    currentPage.value = page;
  }
};

const paginatedItems = computed(() => {
  const startIndex = (currentPage.value - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  return customer.value.wallet?.history?.toReversed()?.slice(startIndex, endIndex);
});
</script>

<style lang="scss" scoped>
</style>