
<template>
  <Layout :hasTopNav="false">
    <TopNav
      :onSearch="search"
      :searchBar="false"
      :PrevNextIcon="false"
      arrowback
    />
    <div class="w-full px-4 py-2 restau">
      <div class="flex w-full items-center justify-between">
        <h1>
          <Text :size="27" :weight="700">Order Batches</Text>
        </h1>
        <div class="flex gap-2">
          <time-drop-down :getDate="(e) => (timeFrame = e)" />
          <UeButton :disabled="loading" @click="runbatch">{{
            loading ? "Loading" : "Run batch"
          }}</UeButton>
        </div>
      </div>
      <div ref="map"></div>
      <div>
        <div
          v-for="(batch, i) in Object.entries(batches || {})"
          class="border w-[fit-content] my-2 p-2 rounded"
          :key="i"
        >
          <Text size="20" weight="600"> {{ batch[0] }}</Text>
          <div
            :class="`w-[20px] h-[20px]  `"
            :style="{
              backgroundColor: result?.find(
                (item) => item.batchName == batch[0]
              )?.color,
            }"
          ></div>
          <!-- <Text
            :size="14"
            weight="600"
            class="my-1"
            v-for="order in batch[1]"
            :key="order.orderId"
          >
            {{ order?.address }}
          </Text> -->

          <Text
            :size="14"
            weight="600"
            class="my-1"
            v-for="order in batch[1]"
            :key="order.orderId"
          >
            {{ order?.address }}
            <!-- {{
               
                ? order?.orderId?.join(", ") + ""
                :<O />
            }} -->
            <span v-if="Array.isArray(order?.orderId)">
              <OrderID
                class="px-1"
                v-for="orderId in order.orderId"
                :id="orderId"
                :key="orderId"
              />
            </span>
            <span v-else>
              <OrderID :id="order.orderId" />
            </span>
          </Text>
          <div class="flex gap-3 items-center">
            <ue-select
              class="ue-select !w-[200px]"
              :getOptionLabel="(user) => user.first_name"
              v-model="rider[batch[0]]"
              :options="[...riders]"
              placeholder="Assign rider"
            >
              <template #option="option">
                <span>{{ option.first_name }} {{ option.last_name }} </span>
              </template>
            </ue-select>
            <UeButton
              @click="
                () => assignBATCHtoRider(batch, rider[batch[0]], batch[0])
              "
            >
              {{ assign_rider_loading[batch[0]] ? "loading..." : "Assign" }}
            </UeButton>
            <Text weight="600" size="20px">
              {{ batch[1][0].rider?.name || "No rider" }}
            </Text>
            <UeButton @click="() => removeRider(batch, batch[0])">
              {{
                assign_rider_loading[batch[0]] ? "loading..." : "Remove rider"
              }}
            </UeButton>
          </div>
        </div>
        <GoogleMap
          api-key="AIzaSyAUR5lKBWKOCHVAe1c5iN4e5vo0OL7_GxY"
          style="width: 100%; height: 500px"
          :center="ueKitchen"
          class="pb-4"
          :zoom="13"
        >
          <Polyline
            :options="flightPath"
            v-for="(flightPath, i) in flightPaths"
            :key="i"
          />
          <Marker :options="kitchenMarkOptions" class="!bg-success" />
          <Marker
            v-for="deliveryPoint in deliveryPoints"
            :key="deliveryPoint.title"
            :options="deliveryPoint"
          />
          <!-- <Marker
            v-if="arrivedLocationMarkerOptions"
            :options="arrivedLocationMarkerOptions"
          />
          <Marker
            v-if="riderLocationMarkOptions"
            :options="riderLocationMarkOptions"
          /> -->
        </GoogleMap>
      </div>
    </div>
  </Layout>
</template>
  
  <script setup>
import Text from "@/components/Text.vue";

import TopNav from "@/components/TopNav.vue";
import UeButton from "@/components/UeButton.vue";
import Layout from "@/partials/Layout/layout.vue";
import { GoogleMap, Polyline, Marker } from "vue3-google-map";
import {
  get,
  getDatabase,
  ref as dbRef,
  set,
  onValue,
  push,
  remove,
} from "firebase/database";
import { onMounted, ref, watchEffect } from "vue";
import { useRoute } from "vue-router";
import { useToast } from "vue-toast-notification";
import draggable from "vuedraggable";
import TimeDropDown from "../../Operator/Dashboard/TimeDropDown.vue";
import { getOrdersByTimeFrame } from "@/util";
import { initMap } from "./direction";
import { httpsCallable } from "firebase/functions";
import { projectFunctions } from "@/firebase/config";
import { store } from "@/views/store/store";
import OrderID from "@/components/OrderID.vue";

const batches = ref([]);
const riders = ref([]);
const rider = ref({});
const loading = ref(false);

onMounted(() => {
  riders.value = store.state.riders;
});

const map = ref(null);
const timeFrame = ref({});
const orders = ref([]);
const directions = ref([]);
const ueKitchen = ref({ lat: 6.45392, lng: 3.47739 });
const flightPaths = ref([]);
const result = ref([]);
const kitchenMarkOptions = {
  position: { ...ueKitchen.value },
  label: "",
  title: "Urban Eats kitchen",
  icon: "https://img.icons8.com/color/48/map-pin.png",
};

const deliveryPoints = ref([]);
// const getBatches = async () => {
//   batches.value = (await get(dbRef(getDatabase(), "batching"))).val() || {};
// };

onMounted(async () => {
  onValue(dbRef(getDatabase(), "batching"), (res) => {
    // console.log(res?.val())
    batches.value =  res?.val() || []
  });
});

watchEffect(async () => {
  timeFrame.value?.start;

  const orders_ = await getOrdersByTimeFrame(
    timeFrame.value?.start,
    timeFrame.value?.end
  );
  orders.value = orders_
    .filter((order) => order?.locationType === "Delivery")
    .filter((order) => !order?.status?.Dispatched.state);

  deliveryPoints.value = orders.value.map((order) => {
    const location = {
      lat: order?.customer?.deliveryAddress?.latitude,
      lng: order?.customer?.deliveryAddress?.longitude,
    };
    return {
      position: { ...location },
      label: "",
      title: order?.id,
      icon: "https://img.icons8.com/color/48/map-pin.png",
    };
  });
  console.log(deliveryPoints.value, "jj");
  directions.value = [];
  Object.entries(batches.value || {})?.forEach((batch) => {
    const name = batch[0];
    const list = batch[1];

    let locations = list?.map((item) => ({
      lat: item.latitude,
      lng: item.longitude,
      //     ...item
    }));
    const origin = ueKitchen.value;
    const destination = locations[locations.length - 1];
    // console.log({ origin, destination });
    // locations.pop();
    const waypoints = locations
      .filter((location, i) => i !== locations.length - 1)
      .map((location) => ({
        location,
        stopover: true,
      }));
    if (locations.length)
      initMap(map.value, origin, destination, waypoints, (res) => {
        // console.log(res, "dd");
        // console.log(name)
        const data = { [name]: res };
        directions.value?.push(data);
      });
  });
});

function getRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
watchEffect(() => {
  directions.value.length;
  // console.log("777", directions.value);
  // directions.value;

  result.value = directions.value.map((direction, i) => {
    const batch = Object.entries(direction || {})[0];
    const batchName = batch[0];
    const batchData = batch[1];
    const color = getRandomColor();
    // batches.value[1][i].color = color
    // console.log({ batchName, batchData });
    const polylineFlightPath = batchData?.routes[0]?.overview_path?.map(
      (path) => ({ lat: path?.lat(), lng: path?.lng() })
    );
    return { ...batchData, batchName, polylineFlightPath, color };
  });

  flightPaths.value = result.value.map((result) => ({
    path: result?.polylineFlightPath,
    geodesic: true,
    strokeColor: result?.color,
    strokeOpacity: 1.0,
    strokeWeight: 5,
  }));
});

const smartBatching = httpsCallable(projectFunctions, "smartBatching");
const assignBatchToRider = httpsCallable(
  projectFunctions,
  "assignBatchToRider"
);
const removeBatchRider = httpsCallable(projectFunctions, "removeBatchRider");
const runbatch = async () => {
  loading.value = true;
  const locations = orders.value?.map((order) => ({
    latitude: order?.customer?.deliveryAddress?.latitude,
    longitude: order?.customer?.deliveryAddress?.longitude,
    address: order?.customer?.deliveryAddress?.houseAddress,
    orderId: order?.id,
  }));

  try {
    await smartBatching({ locations });
  } catch (error) {
  } finally {
    loading.value = false;
  }
};

const assign_rider_loading = ref({});
const toast = useToast({ position: "top-right" });
const assignBATCHtoRider = async (batch, rider, batchName) => {
  if (!rider) {
    toast.info("Please select a rider");
    return;
  }
  assign_rider_loading.value[batchName] = true;
  try {
    console.log(Object.entries({ [batchName]: batch[1] }));

    await assignBatchToRider({ batch: { [batchName]: batch[1] }, rider });
    assign_rider_loading.value[batchName] = false;
    toast.success("Rider assiged successfully ");
  } catch (error) {
    assign_rider_loading.value[batchName] = false;
  }
};

const removeRider = async (batch, batchName) => {
  try {
    assign_rider_loading.value[batchName] = true;
    await removeBatchRider({ batch: { [batchName]: batch[1] } });
    assign_rider_loading.value[batchName] = false;
    toast.success("Rider assiged successfully ");
  } catch (error) {
    assign_rider_loading.value[batchName] = false;
  }
};
</script>
  
  <style lang="scss" scoped>
.restau {
  .meal-img {
    width: 65px !important;
    height: 65px !important;
  }
}
</style>