
const renderIcon = (name) => {
  `https://fonts.google.com/icons?selected=Material%20Symbols%20Sharp%3A${name}%3AFILL%400%3Bwght%40400%3BGRAD%400%3Bopsz%4024`
    `https://fonts.google.com/icons?selected=Material%20Symbols%20Sharp%3A${name}%3AFILL%400%3Bwght%40400%3BGRAD%400%3Bopsz%4024`
}
export const icons = {
  dashboard: "dashboard",
  order: "order_approve",
  meal: "fastfood",
  customer: "groups",
  analyitics: "",
  notification: "",
  settings: "settings",
  map: 'location_on',
  meal_review: "reviews",
  rider_review: "reviews",
  rider_commission: 'payment',
  pick_up: "shopping_bag",
  rejected_order: 'inactive_order',
  users_permissions: "admin_panel_settings",
  ingredients: "shopping_basket",
  recipe: 'menu_book',
  prep: "cooking",
  vendors: 'storefront',
  cancel: 'cancel',
  gift_card: 'redeem',
  promo_code: 'heap_snapshot_thumbnail',
  wallet: 'wallet',
  restaurants:'storefront',
  push_notification:'notifications',
  manual_confirmation:'select_check_box',

  confirmed: require('./images/confirm.svg'),
  x: require('./images/x.svg'),

  logo: require('./images/logo.svg'),
  logo_black: require('./images/uelogoblack.png'),


  login_right_inner: require('./images/login_right_inner.png'),
  login_right_outer: require('./images/login_right_outer.svg'),

  login_left_outer: require('./images/login_left_outer.svg'),
  login_left_inner: require('./images/login_left_inner.svg'),

  applestore: require('./images/applestore.png'),
  googleplay: require('./images/googleplay.svg'),

  loading:require('./images/loading-slow-net.gif'),

  home: {
    phone: require('./images/home/phone.png'),
    whitelogo: require('./images/home/whitelogo.png'),

    ellipse: require('./images/home/ellipse.png'),
    earn: require('./images/home/EARN.png'),
    burger: require('./images/home/burger.png'),
    sally: require('./images/home/sally.png'),
    playstore: require('./images/home/playstore.svg'),
    applestore: require('./images/home/applestore.svg'),
    variety: (number) => require(`./images/home/variety/${number}.png`),
    robot: require('./images/home/robot.png'),
    orange: require('./images/home/orange.png'),
    qrcode: require('./images/home/qrcode.png'),
    downphone: require('./images/home/downphone.png'),
    upphone: require('./images/home/upphone.png'),
  },

  blog: {
    chowcity: {
      // image1: require('./images/blog/chowcity/image1.jpg')
    }
  }

}

