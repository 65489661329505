
<template>
  <div class="w-full w-[100%] bg-[#EFEFEF]">
    <layout :hasTopNav="false">
      <top-nav :onSearch="(e) => (searchValue = e)" />

      <div class="px-4 w-full">
        <header class="w-full py-3 px-[80px] rounded bg-white my-5">
          <div class="flex justify-between my-3">
            <Text :size="24" :weight="600">Reviews</Text>
            <div class="flex gap-3">
              <select
                class="border outline-none rounded py-2 px-4"
                @input="(e) => (sort = e.target.value)"
              >
                Rating:
                {{
                  sort
                }}
                <option value="">Select Sorting</option>
                <option
                  :value="i.value"
                  v-for="i in [
                    { label: 'Sort by rating : Highest', value: 'Highest' },
                    { label: 'Sort by rating : Lowest', value: 'Lowest' },
                    { label: 'Sort by date : Newest', value: 'Newest' },
                    { label: 'Sort by date : Oldest', value: 'Oldest' },
                  ]"
                  :key="i.value"
                >
                  {{ i.label }}
                </option>
              </select>
            </div>
          </div>
          <div
            class="flex gap-4 h-[auto] w-full justify-center py-3 rounded-md"
          >
            <div class="flex flex-col gap-3 w-[30%] p-4 h-full">
              <Text :size="16" :weight="600">Total Reviews</Text>
              <Text :weight="600" :size="32"
                >{{ Math.round(mealsReview.length * 100) / 100 }}
              </Text>
            </div>
            <div
              class="flex flex-col gap-3 w-[30%] p-4 h-full border-l border-r"
            >
              <Text :size="16" :weight="600">Average Rating</Text>
              <div class="flex items-center gap-3">
                <Text :weight="600" :size="32"
                  >{{ Math.round(totalRatings * 10) / 10 }}
                </Text>

                <star-rating
                  :read-only="true"
                  :show-rating="false"
                  :star-size="20"
                  :round-start-rating="false"
                  :rating="totalRatings"
                ></star-rating>
              </div>
            </div>
            <div class="flex flex-col gap-3 w-[35%] py-4 h-full">
              <div
                class="w-full flex flex-wrap items-center justify-between"
                v-for="number in Object.entries(numbers).reverse()"
                :key="number[0]"
              >
                <Text class="whitespace-nowrap block w-[10%]" :size="16"
                  >{{ number[0] }} star</Text
                >
                <div class="w-[62%] mx-auto border rounded-[24px] h-[7px]">
                  <div
                    class="bg-[gold] rounded-[24px] h-[7px]"
                    :style="{
                      width: mealsReview.length
                        ? `${(number[1] / mealsReview.length) * 100}%`
                        : '0%',
                    }"
                  ></div>
                </div>
                <Text class="block w-[10%]" :size="16">{{
                  mealsReview.length
                    ? `${Math.round((number[1] / mealsReview.length) * 100)}%`
                    : "0%"
                }}</Text>
              </div>
            </div>
          </div>
        </header>

        <main class="w-full flex flex-col items-center">
          <div
            class="flex w-[70%] flex-wrap border-b pb-5 mb-3 bg-white rounded px-3"
            v-for="review in mealsReview"
            :key="review.orderId"
          >
            <div class="flex flex-col gap-3 w-[30%]">
              <div class="flex flex-wrap flex-col whitespace-nowrap">
                <Text class="whitespace-nowrap" :weight="700" :size="24">{{
                  review.customer
                }}</Text>
                <Text class="whitespace-nowrap" :weight="600" :size="14">{{
                  review.phone
                }}</Text>
                <Text class="whitespace-nowrap" :weight="700" :size="14">{{
                  review.orderId
                }}</Text>
              </div>

              <div class="flex flex-col">
                <Text
                  :weight="600"
                  :size="14"
                  v-for="item in review.items"
                  :key="item.id"
                  >{{ item.resturant }} : {{ item.name }}</Text
                >
              </div>
            </div>
            <div class="w-[65%] flex flex-col gap-3">
              <div class="w-full flex gap-3">
                <star-rating
                  :read-only="true"
                  :show-rating="false"
                  :star-size="20"
                  :round-start-rating="false"
                  :rating="review.stars"
                ></star-rating>
                <Text>{{ formatDate(review.date) }}</Text>
              </div>
              <div>{{ review.comment || "No comment" }}</div>
            </div>
          </div>
        </main>
        <!-- <review-card
          name="Reviews"
          :phone="''"
          :reviews="mealsReview"
          :showOnlyFirstReview="false"
          :sorting="true"
        /> -->
      </div>
    </layout>
  </div>
</template>
  
  <script>
import layoutVue from "@/partials/Layout/layout.vue";
import Text from "@/components/Text.vue";
import ReviewCard from "@/components/ReviewCard.vue";
import { onMounted, ref, watch, watchEffect } from "vue";
import { store } from "@/views/store/store";
import TopNav from "@/components/TopNav.vue";
import { useRoute, useRouter } from "vue-router";
import StarRating from "vue-star-rating";
import { formatDate } from "@/util";
import {
  equalTo,
  get,
  getDatabase,
  orderByKey,
  query,
  ref as dbRef,
  startAt,
  endAt,
orderByChild,
limitToLast,
} from "firebase/database";

export default {
  components: {
    Layout: layoutVue,
    Text,
    ReviewCard,
    TopNav,
    StarRating,
  },
  setup(props) {
    const riders = ref([...store.state.riders]);
    const mealsReview = ref([]);
    const searchValue = ref("");
    const router = useRouter();
    const route = useRoute().params;
    const id = ref(null);
    const totalRatings = ref(0);
    const sort = ref("Newest");
    const numbers = ref({
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
    });

    onMounted(() => {});

    const search = (res) => {
      const e = searchValue.value.toLowerCase();
      if (e) {
        return res.filter(
          (x) =>
            x.customer.toLowerCase().includes(e) ||
            x.comment.toLowerCase().includes(e) ||
            x.orderId.toLowerCase().includes(e) ||
            x.phone.toLowerCase().includes(e) ||
            //convert items name and restaurants to string so it can search faster
            x.items
              .map((item) => item.name + " " + item.resturant)
              .join(" ")
              .toLowerCase()
              .includes(e)
        );
      } else {
        return res;
      }
    };

    const sortMeals = (res = []) => {
      const replace = (d) => (d ? d.replaceAll('"', "") : Date.now());
      if (res.length) {
        if (sort.value === "Oldest") {
          return res.sort(
            (a, b) =>
              new Date(replace(a.date)).getTime() -
              new Date(replace(b.date)).getTime()
          );
        } else if (sort.value === "Newest") {
          return res.sort(
            (a, b) =>
              new Date(replace(b.date)).getTime() -
              new Date(replace(a.date)).getTime()
          );
        } else if (sort.value === "Lowest") {
          return res.sort((a, b) => parseFloat(a.stars) - parseFloat(b.stars));
        } else if (sort.value === "Highest") {
          return res.sort((a, b) => parseFloat(b.stars) - parseFloat(a.stars));
        }
      } else {
        return res;
      }
    };

    onMounted(async () => {
      // const quer = query(
      //   dbRef(getDatabase(), "orders/"),
      //   orderByChild("foodReview"),
      //   // equalTo(100)
      //   startAt("!"),
      //   endAt("~"),
      //   // limitToLast(1)
      // );
      // const res = (await get(quer)).val();
      // console.log(Object.values(res || {}));
      // store.dispatch("")
    });

    watchEffect(() => {
      searchValue.value;
      // console.log(searchValue.value);
      let res = [];
      // console.log(  store.state.orders_with_meal_reviews)
      store.state.orders_with_meal_reviews
        .filter((x) => x.foodReview)
        //parse foodreview if it exists
        .map((y) => {
          res.push({
            customer: y.customer.firstName + " " + y.customer.lastName,
            ...JSON.parse(y.foodReview),
            stars: JSON.parse(y.foodReview).rating,
            comment: JSON.parse(y.foodReview).review,
            orderId: y.id,
            items: y.items,
            phone: y.customer.phone,
            date: JSON.parse(y.foodReview).date,
          });
        });

      let searchResult = search(res);
      mealsReview.value = sortMeals(searchResult);
      // console.log(mealsReview.value);
    });

    watchEffect(() => {
      if (mealsReview.value.length) {
        totalRatings.value =
          mealsReview.value
            ?.map((x) => parseFloat(x.stars))
            .reduce((a, b) => a + b) / mealsReview.value.length;

        const getNumberOfSingleStar = (start, end) =>
          mealsReview.value.filter(
            (x) => parseFloat(x.stars) >= start && parseFloat(x.stars) < end
          ).length;
        numbers.value["1"] = getNumberOfSingleStar(1, 2);
        numbers.value["2"] = getNumberOfSingleStar(2, 3);
        numbers.value["3"] = getNumberOfSingleStar(3, 4);
        numbers.value["4"] = getNumberOfSingleStar(4, 5);
        numbers.value["5"] = getNumberOfSingleStar(5, 5.1);
      }
    });

    return {
      riders,
      searchValue,
      router,
      id,
      route,
      mealsReview,
      numbers,
      totalRatings,
      formatDate,
      sort,
    };
  },
};
</script>
  
  <style lang="scss" scoped>
</style>