<template>
  <router-link :to="props.to" class="article-button">
    <button>
      <slot /></button
  ></router-link>
</template>

<script setup>
const props = defineProps({
  to: { type: String, default: "#" },
});
</script>

<style lang="scss" scoped>
.article-button{
  // display: none;
  display: flex;
  justify-content: center;
}

button {
  border-radius: 4.403px;
  background: var(
    --Primary,
    linear-gradient(180deg, #ff9103 -36.96%, #f60100 100%)
  );
  color: #ffffff;
  padding: 10px 40px;
  font-weight: 600;
  margin: auto;

}
</style>