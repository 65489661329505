<template>
    <div class="bg-white p-2 mb-3 w-100 rounded">
      <div class="flex justify-between mb-3">
        <Text :size="20" weight="600"> Forceful update</Text>
        <div class="flex gap-2 items-center">
          <UeButton @click="() => (edit = false)"> Cancel </UeButton>
          <UeButton @click="toggleForcefulUpdate"> {{ edit ? "Save" : "Edit" }} </UeButton>
        </div>
      </div>
      <div
        class="gap-1 items-center relative"
        style="overflow: auto !important; min-width: 100% !important"
      >
       
        <v-switch
          v-model="forceful_app_update"
          :label="`${
            forceful_app_update
            ? 'Stop forceful app updates'
            : 'Trigger forceful app updates'
          }`"
          hide-details
          inset
          :disabled="!edit"
          color="blue"
        ></v-switch>
      </div>
    </div>
  </template>
    
    <script setup>
  import Text from "@/components/Text.vue";
  import UeButton from "@/components/UeButton.vue";
  import { get, ref as dbRef, getDatabase, set } from "firebase/database";
  import { ref, watchEffect } from "vue";
  
  const forceful_app_update = ref(false);
  const edit = ref(false);

 
const toggleForcefulUpdate = async () => {
    console.log(forceful_app_update.value)
  if (edit.value) {
    await set(
      dbRef(getDatabase(), `/forceful_app_update`),
      forceful_app_update.value
    );
  }
  edit.value = !edit.value;
};
  watchEffect(async () => {
    edit.value;
    const res = (
      await get(dbRef(getDatabase(), `/forceful_app_update`))
    ).val();
    forceful_app_update.value = res;
  });
  </script>
    
    <style lang="scss" scoped>
  </style>