<template>
  <layout :hasTopNav="false">
    <div class="px-[40px] border bg-light w-full h-full">
      <top-nav :onSearch="search" :searchBar="false" :PrevNextIcon="false" />

      <v-dialog v-model="openModal">
        <div class="bg-white p-3 rounded w-[fit-content] mx-auto">
          <meal-modal
            title="Publish Push Notification?"
            :content="'Are you certain that you want to send this message to all your customers?'"
            :rightClick="() => send()"
            :oncancel="() => (openModal = false)"
            firstBtn="No, Cancel"
            secondBtn="YES, Confirm"
            :closeBtn="false"
            :tickBtn="true"
          />
        </div>
      </v-dialog>

      <Text :size="27" :weight="700" class="mb-3"> Push Notifications</Text>

      <div class="bg-white flex flex-col justif p-3 rounded">
        <div class="bg-white flex flex-col justif p-3 rounded">
          <Text :size="17" :weight="400" class="pl-4"> Title </Text>
          <div action="" class="p-3 rounded w-100 flex gap-5">
            <input
              type="text"
              class="ue-input-field !border w-75"
              v-model="title"
            />
          </div>
        </div>
        <div class="bg-white flex flex-col justif p-3 rounded">
          <Text :size="17" :weight="400" class="pl-4">
            Enter message to publish to customers
          </Text>
          <div action="" class="p-3 rounded w-100 flex gap-5">
            <input
              type="text"
              class="ue-input-field !border w-75"
              v-model="message"
            />
          </div>
        </div>
        <ue-button
          class="w-[100px] ml-[25px]"
          @click="() => (openModal = true)"
        >
          Send
        </ue-button>
      </div>

      <div class="flex flex-col gap-2 p-3 bg-white">
        <!-- <div v-for="(item, i) in noti_history" :key="i" style="font-size: 17px">
          {{ item.sender }} sent <br />
          Title: {{ item.title }}, Message : {{ item.body }}. Success count :{{
            item.successCount
          }}
          Failiure :{{ item.failiureCount }} at {{ item?.time }}
        </div> -->

        <table class="order-table">
          <thead>
            <tr>
              <th class="table-header"></th>
              <th class="table-header">Sender</th>
              <th class="table-header">Title</th>
              <th class="table-header">Message</th>
              <th class="table-header">Success</th>
              <th class="table-header">Failiure</th>
              <th class="table-header">Users</th>
              <th class="table-header">Time</th>
            </tr>
          </thead>
          <!-- {{ noti_history }} -->
          <tbody>
            <tr
              v-for="(history, index) in Object.values(noti_history || [])?.toReversed()"
              :key="index"
              class="table-row"
              :class="index % 2 === 0 ? 'even-row' : 'odd-row'"
            >
              <td class="table-data">
                {{ index + 1 }}
                <!-- {{ index + 1 + 15 * (currentPage - 1) }} -->
              </td>
              <td class="table-data">
                {{ history?.sender }}
              </td>
              <td class="table-data">
                {{ history?.title }}
              </td>
              <td class="table-data">
                {{ history?.body }}
              </td>
              <td class="table-data">
                {{ history?.successCount }}
              </td>
              <td class="table-data">
                {{ history?.failiureCount || history?.failiure }}
              </td>
              <td class="table-data">
                {{ history?.users }}
              </td>
              <td class="table-data">
                {{ history?.time }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- <OrderModal v-else-if="selectedOrder?.id" :selectedOrder="selectedOrder" :orders="orders"/> -->
  </layout>
</template>

<script setup>
import MealModal from "@/components/MealModal.vue";
import Text from "@/components/Text.vue";
import TopNav from "@/components/TopNav.vue";
import UeButton from "@/components/UeButton.vue";
import { projectFunctions } from "@/firebase/config";
import layout from "@/partials/Layout/layout.vue";
import { store } from "@/views/store/store";
import { get, getDatabase, ref as dbRef } from "firebase/database";
import { httpsCallable } from "firebase/functions";
import { onMounted, ref, watchEffect } from "vue";
import { useToast } from "vue-toast-notification";

const generalPushNotification = httpsCallable(
  projectFunctions,
  "generalPushNotification"
);

const message = ref("");
const title = ref("");
const noti_history = ref([]);

const toast = useToast({ position: "top-right" });
const openModal = ref(false);

const getNotis = async () => {
  noti_history.value = (
    await get(dbRef(getDatabase(), "notification_history"))
  ).val();
};

onMounted(()=>{
  getNotis()
})

const send = async () => {
  try {
    if (!title.value || !message.value) {
      toast.info("Notification must have a title and a message");
    } else {
      // alert("kkk");
      // console.log(title.value, message.value);
      openModal.value = false;
      toast.info("Sending push notification...");
      await generalPushNotification({
        body: message.value,
        title: title.value,
        sender: `${store.state.user.first_name} ${store.state.user.last_name}`,
      });
      message.value = "";
      title.value = "";
      toast.success("Notifation sent to users successfully");
    }
    getNotis();
  } catch (error) {
    console.log({ error });
    toast.error("Error sending notification");
  }
};
</script>

<style lang="scss" scoped>
</style>