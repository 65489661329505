
<template>
  <div class="w-full w-[100%] bg-[#EFEFEF]">
    <layout :hasTopNav="false">
      <top-nav
        :onSearch="(e) => (searchValue = e)"
        :searchBar="!Boolean(route.params?.id)"
        :arrowback="Boolean(route.params?.id)"
        :arrowbackpath="'back'"
      />

      <!-- List or riders -->

      <!-- sinngle rider v=review -->
      <!-- {{  route.params?.id }} -->
      <div class="px-4">
        <review-card
          v-for="rider in riders?.filter((x) =>
            route.params?.id ? x.id === route.params?.id : x
          )"
          :key="rider.id"
          :name="rider.first_name + '  ' + rider.last_name"
          :phone="rider.phone"
          :reviews="rider.reviews"
          :showOnlyFirstReview="!Boolean(route.params?.id)"
          :sorting="Boolean(route.params?.id)"
          :onClick="
            () =>
              router.push(route.params?.id ? '' : `${route.path}/${rider.id}`)
          "
        />
      </div>
    </layout>
  </div>
</template>
  
  <script>
import layoutVue from "@/partials/Layout/layout.vue";
import Text from "@/components/Text.vue";
import ReviewCard from "@/components/ReviewCard.vue";
import { ref, watchEffect } from "vue";
import { store } from "@/views/store/store";
import TopNav from "@/components/TopNav.vue";
import { useRoute, useRouter } from "vue-router";

export default {
  components: {
    Layout: layoutVue,
    Text,
    ReviewCard,
    TopNav,
  },
  props: {
    back: String,
  },
  setup(props) {
    const riders = ref([...store.state.riders]);
    const searchValue = ref("");
    const selectedRider = ref("");
    const router = useRouter();
    const route = useRoute();
    const id = ref(null);
    const back  = router.options.history.state.back
// console.log(router.options.history.)
    watchEffect(() => {
      const onlyriderswithreviews = store.state.riders;
      if (searchValue.value) {
        riders.value = onlyriderswithreviews.filter(
          (rider) =>
            rider.first_name.toLowerCase().includes(searchValue.value) ||
            rider.last_name.toLowerCase().includes(searchValue.value) ||
            rider.reviews?.length == searchValue.value
        );
      } else {
        riders.value = onlyriderswithreviews;
      }
    });

    return {
      riders,
      searchValue,
      router,
      id,
      route,
      selectedRider,props, back
    };
  },
};
</script>
  
  <style lang="scss" scoped>
</style>