<template>
  <input-field
    :type="'roundcheck'"
    class="mr-3 cursor-pointer"
    @click="() => (openModal = true)"
    :checked="recipe?.pending_inventory == 0"
  />
  <span style="font-size: 17px"> {{ recipe?.pending_inventory }} pending </span>
  <v-dialog v-model="openModal">
    <div class="rounded w-[fit-content] mx-auto p-5 bg-white">
      <MealModal
        :title="'Move to current inventory?'"
        :content="'Are you sure you want to move pending inventory to current?'"
        :firstBtn="'No, Cancel'"
        :secondBtn="'YES, Confirm'"
        :closeBtn="false"
        :tickBtn="true"
        :rightClick="() => move_to_current()"
        :oncancel="() => (openModal = false)"
      />
    </div>
  </v-dialog>
</template>

<script setup>
import InputField from "@/components/InputField.vue";
import MealModal from "@/components/MealModal.vue";
import { getDatabase, set, ref as dbRef } from "firebase/database";
import { ref, watchEffect } from "vue";
const props = defineProps({
  recipe: Object,
  onComplete: Function,
});
const recipe = ref();
watchEffect(() => {
  props.recipe;
  recipe.value = props.recipe;
  //   console.log(recipe.value);
});
const openModal = ref(false);
const move_to_current = async () => {
  //   console.log(recipe.value);
  const path = `inventory/recipes/${recipe.value.id}`;
  const move =
    recipe.value?.pending_inventory + recipe.value?.current_inventory || 0;
  // console.log(move,path,recipe.value?.pending_inventory ,recipe.value?.current_inventory )
  try {
    // console.log(move);
    if (move > 0) {
      await set(dbRef(getDatabase(), `${path}/current_inventory/`), move);
      await set(dbRef(getDatabase(), `${path}/pending_inventory/`), 0);
      recipe.value.pending_inventory = 0
      openModal.value = false;
      props.onComplete(move)
    } else {
      console.log("Error: ", "New inventory is " + move);
    }
  } catch (error) {
    console.log("Error :", error);
  }
};
</script>

<style lang="scss" scoped>
</style>