<template>
  <div class="w-full p-3 bg-white rounded-[12px] my-4" id="message-list">
    <div class="flex flex-col gap-4 w-full">
      <div class="rounded-pill my-2 px-4 flex gap-1" :id="`message${i}`">
        <div
          class="flex items-center justify-center rounded-[100%] bg-info h-[24px] w-[24px] p-1 text-white"
        >
          <span>
            {{ customer?.firstName.charAt(0) + customer?.lastName.charAt(0) }}
          </span>
        </div>
        <Text :size="16" :weight="600" class="text-nowrap">
          <span> {{ customer?.firstName }} {{ customer?.lastName }} </span>
        </Text>

        <Text
          :weight="500"
          :size="16"
          @click="() => edit(paymentTimeline(order))"
        >
          <!-- completed payment via -->

          <span v-html="paymentTimeline(order)"></span>

          at
          {{
            dayjs(order.date?.replaceAll('"', "")).format("hh:mma MMM DD.")
          }}</Text
        >
      </div>
    </div>

    <div
      class="flex flex-col gap-4 w-full"
      v-for="(message, i) in Object.values(order.timeline || [])"
      :key="message.created_at"
    >
      <div class="rounded-pill my-2 px-4 flex gap-1" :id="`message${i}`">
        <div
          class="flex items-center justify-center rounded-[100%] bg-info h-[24px] w-[24px] p-1 text-white"
          @click="() => get_admin_users()"
        >
          <span v-if="message.user_type == 'admin'">
            {{
              admin_users[message.uid]
                ? admin_users[message.uid]?.first_name.charAt(0) +
                  admin_users[message.uid]?.last_name.charAt(0)
                : message.name
                    ?.split(" ")
                    ?.map((x) => x?.charAt(0))
                    ?.join("")
            }}
          </span>
          <span v-if="message.user_type == 'rider'">
            {{
              riders?.first_name
                ? riders?.first_name.charAt(0) + riders?.last_name.charAt(0)
                : getFirstChars(message.name)
            }}
          </span>
          <span v-if="message.user_type == 'user'">
            {{ getFirstChars(message.name) }}
          </span>
          <span v-if="message.user_type == 'auto'"> A </span>
        </div>
        <Text :size="16" :weight="600">
          <span v-if="message.user_type == 'admin'" class="text-nowrap">
            {{
              admin_users[message.uid]
                ? admin_users[message.uid]?.first_name +
                  " " +
                  admin_users[message.uid]?.last_name
                : message.name
            }}
          </span>
          <span v-if="message.user_type == 'rider'" class="text-nowrap">
            {{
              riders?.first_last
                ? riders?.first_name + " " + riders?.last_name
                : message.name
            }}
          </span>
          <!-- {{ order?.customer?.firstName  }} -->
          <span v-if="message.user_type == 'user'" class="text-nowrap">
            {{
              customer
                ? customer?.firstName + " " + customer?.lastName
                : message.name
            }}
          </span>
          <span v-if="message.user_type == 'auto'"> A </span>
        </Text>

        <Text :weight="500" :size="16" @click="() => edit(message.message)">
          <span v-html="message.message"></span>
          at
          {{
            dayjs(fixTime(message.created_at)).format("hh:mma MMM DD.")
          }}</Text
        >
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watchEffect } from "vue";
import Text from "./Text.vue";
import { formatDate, formatPrice, writeOrderTotalInTimeline } from "@/util";
import {
  equalTo,
  get,
  getDatabase,
  orderByChild,
  query,
  ref as dbRef,
  set,
} from "firebase/database";
import dayjs from "dayjs";
import { store } from "@/views/store/store";
import { saveInitialOrderTotal } from "@/util/saveInitialOrderTotal";
export default {
  components: { Text },
  props: { selectedOrder: Object },
  setup(props) {
    const order = ref(props.selectedOrder);

    watchEffect(async () => {
      // console.log(order.value)
      await saveInitialOrderTotal(order.value);
    });
    const admin_users = ref([]);
    const riders = ref(props.selectedOrder?.courier);
    const customer = ref(props.selectedOrder?.customer);

    const get_admin_users = async () => {
      const admin_users_res = await get(dbRef(getDatabase(), "admin_users"));
      admin_users.value = admin_users_res.val();

      const riders_res = await get(
        query(
          dbRef(getDatabase(), "courier"),
          orderByChild("id"),
          equalTo(props.selectedOrder?.courier?.id)
        )
      );
      riders.value = riders_res.val()[props.selectedOrder?.courier?.id];
      // console.log(riders.value, "kkk");
      const customer_res = await get(
        query(
          dbRef(getDatabase(), "users"),
          orderByChild("uid"),
          equalTo(props.selectedOrder?.customer?.id)
        )
      );
      customer.value = customer_res.val()[props.selectedOrder?.customer?.id];
    };

    const getCustomer = async () => {};
    const fixTime = (time) => {
      // console.log({time});
      if (typeof time == "string") return time.replaceAll('"', "");
      return time;
    };

    onMounted(() => {
      // customer.value
      getCustomer();
    });

    const getFirstChars = (names, type) => {
      const [first_name, last_name] = names?.split(" ") || " ";
      return `${first_name?.charAt(0) + last_name?.charAt(0)}`;
    };

    watchEffect(() => {
      order.value = props.selectedOrder;
    });

    const paymentTimeline = (order) => {
      const getNewItemPrice = () => {
        if (order?.new_items_total) {
          if (order?.paidByTopup)
            return (
              (order?.new_items_total?.price || 0) - (order?.amount_due || 0)
            );
          return Math.abs(order?.new_items_total?.price || 0);
        }
        return 0;
      };

      const methodKeys = ["paidByTopup", "walletUsed", "amount_due"];
      if (order?.paidByTopup) {
        if (order?.walletUsed) {
          return `completed payment via  wallet top up (${writeOrderTotalInTimeline(
            order?.initialOrderTotal - order?.walletUsed
          )}) and wallet balance (${writeOrderTotalInTimeline(
            order?.walletUsed
          )})`;
        }
        if (!order?.walletUsed && order?.amount_due) {
          return `completed payment via wallet top up (${writeOrderTotalInTimeline(
            order?.amount_due - getNewItemPrice(order)
          )}) and  ${order?.paymentMethod} (${writeOrderTotalInTimeline(
            order?.total_price - order?.amount_due - getNewItemPrice(order)
          )})`;
        }

        if (!order?.walletUsed && !order?.amount_due) {
          return `completed payment via wallet top up (${writeOrderTotalInTimeline(
            order?.initialOrderTotal
          )})`;
        }
      }
      if (order?.walletUsed) {
        if (order?.walletUsed == order?.initialOrderTotal) {
          return `completed payment via wallet balance (${writeOrderTotalInTimeline(
            order?.walletUsed
          )})`;
        } else {
          return `completed payment via wallet balance (${writeOrderTotalInTimeline(
            order?.walletUsed
          )}) and ${
            order?.paidByTopup ? "wallet top " : order?.paymentMethod
          } (${writeOrderTotalInTimeline(
            order?.initialOrderTotal - order?.walletUsed
          )})`;
        }
      }

      if (!order?.paidByTopup && !order?.walletUsed) {
        // if (order?.amount_due && order?.amount_due != order?.total_price) {
        //   return `completed payment via ${
        //     order?.paymentMethod
        //   } (${writeOrderTotalInTimeline(order?.initialOrderTotal)})`;
        // }
        return `completed payment via ${
          order?.paymentMethod
        } (${writeOrderTotalInTimeline(order?.initialOrderTotal)})`;
      }
    };
    // const preparationTime = () => {};
    const fn = (status, from, plus, finder) => {
      const timeline = order.value?.timeline;
      const line = Object.entries(timeline)?.find((line) =>
        line[1]?.message?.includes(finder)
      );
      // console.log(line);
      const new_time = dayjs(from)
        .add(plus, "minutes")
        .format("MMM DD YYYY, hh:mm A");
      const timeline_time = new Date(new_time).getTime();
      // console.log({ status, from, plus, new_time, timeline_time });
      set(
        dbRef(getDatabase(), `orders/${order.value.id}/status/${status}/time`),
        new_time
      );
      if (line) {
        set(
          dbRef(
            getDatabase(),
            `orders/${order.value.id}/timeline/${line[0]}/created_at`
          ),
          timeline_time
        );
      }
    };

    const ranNum = (array) => array[Math.floor(Math.random() * array.length)];

    const edit = (message) => {
      if (store.state.user?.email === "kehindesalaudeen222@gmail.com") {
        if (message.includes("completed payment")) {
          const plus = ranNum([-25, -27, -30, -35]);
          fn("Confirmed", order.value.status.Prepared.time, plus, "confirmed");
        }
        if (message.includes("arrived")) {
          const plus = ranNum([20, 21, 25, 27, 30]);
          fn("Arrived", order.value.status.Dispatched.time, plus, "arrived");
        }
        if (message.includes("started")) {
          const plus = ranNum([2, 3, 4, 5]);
          fn("Disaptched", order.value.status.Assembled.time, plus, "started");
        }
        if (message.includes("delivered")) {
          const plus = ranNum([1, 2, 3, 4]);
          fn("Delivered", order.value.status.Arrived.time, plus, "delivered");
        }
      }
    };

    return {
      order,
      formatDate,
      admin_users,
      riders,
      dayjs,
      getFirstChars,
      customer,
      get_admin_users,
      formatPrice,
      paymentTimeline,
      fixTime,
      edit,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>