<template>
  <ConfirmAction />
  <div class="py-3">
    <v-dialog v-model="editOrder.state.addExtraModal" persistent>
      <div
        class="bg-white responsive-modal mx-auto rounded p-2 h-[auto] !overflow-y"
        style="overflow-y: scroll"
      >
        <div v-if="editOrder.state.restaurant[0]">
          <button
            @click="
              () => {
                editOrder.commit('openAddExtraModal', false);
                // editOrder.commit('setItem', {});
                editOrder.commit('openAddItemModal', true);
              }
            "
          >
            back
          </button>
        </div>
        <div class="w-100 flex justify-between mb-2">
          <Text :size="16" weight="600">{{ editOrder.state.item?.name }}</Text>
          <button
            class="self-end"
            @click="
              () => {
                editOrder.commit('openAddExtraModal', false);
                editOrder.commit('setRestaurant', []);
                editOrder.commit('selectOrder', {});
                editOrder.commit('setItem', {});
              }
            "
          >
            close
          </button>
        </div>

        <div class="flex flex-col gap-3" v-if="food?.extra">
          <div class="my">
            <Text>SIDES</Text>
            <hr />
            <div
              class="flex justify-between my-1"
              v-for="x in food?.extra"
              :key="x?.name"
            >
              <Text>{{ x?.name }}</Text>
              <div class="flex gap-2">
                <quantity-counter
                  v-if="
                    editOrder.state.updates['sides']
                      ?.map((y) => y?.name)
                      .includes(x?.name)
                  "
                  :min="1"
                  :count="isTaken(x, 'sides')?.qty || 1"
                  :disabled="isTaken(x, 'sides')"
                  :onchange="
                    (e) =>
                      editOrder.commit('setExtraQuantity', {
                        subItem: x,
                        qty: e,type:'sides'
                      })
                  "
                  :max="10"
                  class="flex justify-center"
                />
                <Text> +&#x20A6;{{ x?.amount }}</Text>
                <CheckBox
                  :disabled="isTaken(x, 'sides')"
                  @input="
                    () =>
                      editOrder.commit('selectItem', {
                        type: 'sides',
                        newItem: x,
                        food: 'extra ',
                      })
                  "
                />
              </div>
            </div>
          </div>

          <div class="my" v-for="(addon, i) in addons" :key="i">
            <div v-for="add in Object.entries(addon || {})" :key="add[0]">
              <Text> {{ add[0] }} </Text>
              <hr />
              <div
                class="flex justify-between my-1"
                v-for="x in add[1]"
                :key="x?.name"
              >
                <Text>{{ x?.name }}</Text>
                <div class="flex gap-2">
                  <quantity-counter
                    v-if="
                      editOrder.state.updates['addons']
                        ?.map((y) => y?.name)
                        .includes(x?.name)
                    "
                    :min="1"
                    :count="isTaken(x, 'addons')?.qty || 1"
                    :disabled="isTaken(x, 'addons')"
                    :onchange="
                      (e) =>
                        editOrder.commit('setExtraQuantity', {
                          subItem: x,
                          qty: e,type:'addons'
                        })
                    "
                    :max="10"
                    class="flex justify-center"
                  />
                  <Text> +&#x20A6;{{ x?.amount }}</Text>
                  <CheckBox
                    :disabled="isTaken(x, 'addons')"
                    @input="
                      () => {
                        const food = add[0];
                        editOrder.commit('selectItem', {
                          food,
                          newItem: x,
                          type: 'addons',
                        });
                      }
                    "
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="my" v-if="food?.customize">
            <Text>CUSTOMIZE</Text>
            <hr />
            <div
              class="flex justify-between my-1"
              v-for="x in food?.customize"
              :key="x?.name"
            >
              <Text>{{ x?.name }}</Text>
              <div class="flex gap-2">
                <quantity-counter
                  v-if="
                    editOrder.state.updates['customize']
                      ?.map((y) => y?.name)
                      .includes(x?.name)
                  "
                  :min="1"
                  :count="isTaken(x, 'customize')?.qty || 1"
                  :onchange="
                    (e) =>
                      editOrder.commit('setExtraQuantity', {
                        subItem: x,
                        qty: e,type:'customize'
                      })
                  "
                  :max="10"
                  class="flex justify-center"
                />
                <Text> +&#x20A6;{{ x?.amount }}</Text>
                <CheckBox
                  :disabled="isTaken(x, 'customize')"
                  @input="
                    () =>
                      editOrder.commit('selectItem', {
                        type: 'customize',
                        newItem: x,
                        food: 'customize',
                      })
                  "
                />
              </div>
            </div>
          </div>
        </div>

        <div v-if="food?.options" class="flex flex-col gap-3">
          <div
            class="my"
            v-for="option in Object.entries(food?.options)"
            :key="option[0]"
          >
            <Text>{{ option[0] }}</Text>
            <hr />
            <div
              class="flex justify-between my-1"
              v-for="x in option[1]"
              :key="x?.name"
            >
              <Text>{{ x?.name }}</Text>
              <div class="flex gap-2">
                <quantity-counter
                  v-if="
                    editOrder.state.updates['customize']
                      ?.map((y) => y?.name)
                      .includes(x?.name)
                  "
                  :min="1"
                  :count="isTaken(x, 'customize')?.qty || 1"
                  :onchange="
                    (e) =>
                      editOrder.commit('setExtraQuantity', {
                        subItem: x,
                        qty: e,type:'customize'
                      })
                  "
                  :max="10"
                  class="flex justify-center"
                />
                <Text> +&#x20A6;{{ x?.amount }}</Text>
                <CheckBox
                  :disabled="isTaken(x, 'customize')"
                  @input="
                    () =>
                      editOrder.commit('selectItem', {
                        type: `customize`,
                        food: `${option[0].toLowerCase()}`,
                        newItem: x,
                      })
                  "
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="w-100 flex justify-center mb-2"
          v-if="editOrder.state.restaurant[0]"
        >
          <quantity-counter
            :count="editOrder.state.quantity || 1"
            :onchange="(e) => editOrder.commit('setQuantity', e)"
            :max="10"
            class="flex justify-center w-[40%]"
          />
        </div>
        <Text
          weight="600"
          color="red"
          v-if="editOrder.state.proceedWithInSufficientWallet?.state"
        >
          Insufficient wallet balance!
          <br />
          Proceed with current wallet balance ({{
            formatPrice(
              editOrder.state.proceedWithInSufficientWallet?.wallet_amount
            )
          }}) and the remaining balance ({{
            formatPrice(
              editOrder.state.proceedWithInSufficientWallet?.bank_alert_amount
            )
          }}) will be confirmed via bank or just complete confirmation via bank
          alert
        </Text>
        <div class="flex justify-center flex-wrap mb-3">
          <button
            v-if="!editOrder.state.proceedWithInSufficientWallet?.state"
            :disabled="editOrder.state.loading"
            class="btn border mx-2"
            @click="
              confirmAction.commit('toggleModal', {
                onProceed: () =>
                  editOrder.commit('saveAddExtras', 'wallet balance'),
                confirmText: 'WALLET',
              })
            "
          >
            {{
              editOrder.state.loading ? "loading.." : "Save via wallet balance"
            }}
          </button>
          <button
            v-if="editOrder.state.proceedWithInSufficientWallet?.state"
            :disabled="editOrder.state.loading"
            class="btn font-bold mx-2 !bg-[red] text-white"
            @click="
              confirmAction.commit('toggleModal', {
                onProceed: () =>
                  editOrder.commit(
                    'saveAddExtras',
                    'wallet balance and bank alert'
                  ),
                confirmText: 'WALLET-BANK',
              })
            "
          >
            {{
              editOrder.state.loading ? "loading.." : "Save via wallet AND bank"
            }}
          </button>
          <button
            :disabled="editOrder.state.loading"
            class="btn border mx-2"
            @click="
              confirmAction.commit('toggleModal', {
                onProceed: () =>
                  editOrder.commit('saveAddExtras', 'bank alert'),
                confirmText: 'BANK ALERT',
              })
            "
          >
            {{ editOrder.state.loading ? "loading..." : "Save via bank alert" }}
          </button>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script setup>
import { editOrder } from "@/views/store/editOrder";
import { get, getDatabase, ref as dbRef } from "firebase/database";
import { ref, watchEffect } from "vue";
import CheckBox from "./CheckBox.vue";
import Text from "./Text.vue";
import QuantityCounter from "./QuantityCounter.vue";
import { confirmAction } from "@/views/store/confirmAction";
import ConfirmAction from "@/components/ConfirmAction.vue";
import { formatPrice } from "@/util";
const props = defineProps({
  order: Object,
  item: Object,
});

const foods = ref([]);
const item = ref({});
const order = ref({});
const food = ref({});
const addons = ref({});
// console.log(editOrder.state.item, "-----------");

watchEffect(async () => {
  item.value = editOrder.state.item;
  // console.log(item.value?.name);
  if (item.value?.name) {
    // console.log(item.value?.name);
    order.value = props.order;

    const res = await get(
      dbRef(getDatabase(), `/restaurants/${item.value?.resturant}/food`)
    );
    foods.value = res?.val();
    const category_list = foods.value[item.value?.foodType] || [];

    const addonsKeys = ["DRINK", "PROTEIN", "FRESH JUICE"];
    addons.value = addonsKeys.map((key) => {
      if (foods.value[key]) return { [key]: foods.value[key] };
    });

    food.value = category_list?.find(
      (category_item) => category_item?.name == item.value?.name
    );
  }
});

// watchEffect(() => {
//   // console.log(editOrder.state.item?.name, item?.value?.name,'kkk')
//   const itemInStore = editOrder.state.item;
//   // console.log(item?.value, "---", itemInStore);
//   if (itemInStore?.name && item?.value?.name) {
//     console.log("here");
//     addExtraModal.value =
//       itemInStore?.name == item.value?.name &&
//       itemInStore?.resturant == item.value?.resturant;
//   } else {
//     addExtraModal.value = false;
//   }
// });

const isTaken = (extra, type) => {
  // item.value = editOrder.state.item
  if (item.value?.name) {
    const list = editOrder.state.item[type] || [];
    return list.find((x) => x?.name == extra?.name) || false;
  }
};
</script>

<style lang="scss" scoped>
.responsive-modal {
  // w-[50%] sm:w-[95%]
  width: 50%;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
}
</style>