
<template>
  <Layout :hasTopNav="false">
    <TopNav
      :onSearch="search"
      :searchBar="false"
      :PrevNextIcon="false"
      arrowback
    />
    <div class="w-full px-4 py-2 restau">
      <div class="flex w-full items-center justify-between">
        <h1>
          <Text :size="27" :weight="700"> {{ restaurant_name }} </Text>
        </h1>
      </div>

      <div>
        <Text :weight="600" :size="17"> Category Order</Text>
        <draggable v-model="category_order" class="flex gap-3">
          <template #item="{ element: order }">
            <Text class="cursor-pointer" :size="14" weight="600" :key="order">
              {{ order }}
            </Text>
          </template>
        </draggable>

        <ue-button @click="saveCategoryOrder">Save category order</ue-button>
      </div>

      <div class="mt-2 items-center gap-[0px]">
        <Text :weight="600" :size="17">Food</Text>

        <div class="flex flex-col gap-3" v-if="restaurant?.food">
          <div
            v-for="item in Object.entries(restaurant?.food || {})"
            :key="item[0]"
          >
            <div class="flex w-100 justify-between">
              <Text :size="12" weight="600">
                {{ item[0] }}
              </Text>
            </div>

            <draggable
              class="flex flex-wrap bor gap-3"
              v-model="inner_category_order[item[0]]"
            >
              <template #item="{ element: meal }">
                <div class="border flex rounded" :key="meal.name">
                  <img class="meal-img rounded-l" :src="meal.image" alt="" />

                  <div class="px-2 f items-center">
                    <Text :size="14" weight="600">{{ meal?.name }}</Text>
                    <ue-button
                      v-if="!isPopular(meal)"
                      @click="() => addToPopular(meal, item[0])"
                    >
                      {{ isPopular(meal) ? "-" : "+" }} popular
                    </ue-button>

                    <Text v-if="isPopular(meal)" :size="14" weight="600"
                      >Popular</Text
                    >
                  </div>
                </div>
              </template>
            </draggable>
            <ue-button class="mt-2" @click="() => saveInnerCategory(item[0])">
              <Text :size="12"
                >Save <span class="text-lowercase">{{ item[0] }} order</span>
              </Text>
            </ue-button>
          </div>
        </div>
      </div>

      <div v-if="popular_order?.length" class="mt-3">
        <Text weight="600" :size="17">Popular</Text>

        <draggable class="flex flex-wrap bor gap-3" v-model="popular_order">
          <template #item="{ element: item }">
            <div class="border flex rounded cursor-pointer">
              <img class="meal-img rounded-l" :src="item.image" alt="" />
              <div class="px-2 flex flex-col">
                {{ item?.name }}
                <br />
                <ue-button @click="() => removeFromPopular(item)" variant="md">
                  - popular
                </ue-button>
              </div>
            </div>
          </template>
        </draggable>

        <ue-button class="mt-2" @click="() => savePopularOrder()">
          <Text :size="12"
            >Save <span class="text-lowercase">popular order</span>
          </Text>
        </ue-button>
      </div>

      <div v-if="popular_order?.length" class="mt-3">
        <Text weight="600" :size="17">People aslo added</Text>

        <draggable class="flex flex-wrap bor gap-3" v-model="people_also_added">
          <template #item="{ element: item }">
            <div class="border flex rounded cursor-pointer">
              <img class="meal-img rounded-l" :src="item.image" alt="" />
              <div class="px-2 flex flex-col">
                {{ item?.name }}
                <br />{{ item.restaurant }}
                <ue-button
                  class="w-[100px] mb-2 text-center"
                  @click="() => hidePeopleAlsoAdded(item)"
                  variant="md"
                >
                  {{ item?.hidden ?  "show":"hide"  }}
                </ue-button>
              </div>
            </div>
          </template>
        </draggable>

        <ue-button class="mt-2" @click="() => savePeopleAlsoAdded()">
          <Text :size="12">
            Save <span class="text-lowercase">people also added order</span>
          </Text>
        </ue-button>
      </div>
    </div>
  </Layout>
</template>

<script setup>
import Text from "@/components/Text.vue";
import TopNav from "@/components/TopNav.vue";
import UeButton from "@/components/UeButton.vue";
import Layout from "@/partials/Layout/layout.vue";
import {
  get,
  getDatabase,
  ref as dbRef,
  set,
  onValue,
  push,
  remove,
} from "firebase/database";
import { onMounted, ref, watchEffect } from "vue";
import { useRoute } from "vue-router";
import { useToast } from "vue-toast-notification";
import draggable from "vuedraggable";

const { params } = useRoute();
const restaurant_name = params.name;
const toast = useToast({ position: "top-right" });
const restaurant = ref({});
const category_order = ref([]);
const inner_category_order = ref({});
const popular_order = ref([]);
const people_also_added = ref([]);

const path = `restaurants/${restaurant_name}`;

const gatherPopular = ref([]);

const getRes = async () => {
  //   const res = await get(÷

  onValue(dbRef(getDatabase(), path), (res) => {
    const _res = res?.val();
    if (_res) {
      restaurant.value = _res;
      category_order.value = Object.values(_res?.order);
      const array = Object.entries(_res?.food || {});
      console.log({ array });
      array.forEach((element) => {
        // console.log(element[0], element[1]);
        const cate = element[0];
        const items = element[1];

        inner_category_order.value[cate] = [];

        items.forEach((item, index) => {
          inner_category_order.value[cate].push(item);
        });
      });

      popular_order.value = Object.values(_res?.popular || {});
    }
  });
};

watchEffect(async () => {
  getRes();
});
const getPeopleAlsoAdded = async () => {
  onValue(dbRef(getDatabase(), "people_also_added"), (res) => {
    const items = Object.values(res.val() || {});
    console.log({ items });
    people_also_added.value = items?.toSorted(
      (a, b) => a.position - b.position
    );
  });
};

onMounted(() => {
  getPeopleAlsoAdded();
});
const saveCategoryOrder = async () => {
  await set(dbRef(getDatabase(), `${path}/order`), category_order.value);
  toast.success(`${restaurant_name} category order updated`);
};

const saveInnerCategory = async (cate) => {
  await set(
    dbRef(getDatabase(), `${path}/food/${cate}`),
    inner_category_order.value[cate]
  );
  toast.success(`${cate} meals order updated`);
};

const isPopular = (item) => {
  const exist = popular_order.value.find((meal) => meal?.name === item?.name);
  return Boolean(exist);
};

const addToPopular = async (item, cate) => {
  const next = popular_order.value.length;

  const value = {
    amount: item?.amount || 0,
    available: item?.available,
    food: cate?.toUpperCase(),
    id: item?.id,
    image: item?.image,
    image_min: item?.image,
    name: item?.name,
  };
  const new_people_added = {
    ...value,
    restaurant: restaurant_name,
    position: people_also_added.value.length,
  };
  //   console.log(value, next, `${path}/popular/${next}`,);
  // console.log({new_people_added})
  await set(dbRef(getDatabase(), `${path}/popular/${next}`), value);
  await set(
    dbRef(getDatabase(), `people_also_added/${item?.name}`),
    new_people_added
  );
  toast.success(`${item?.name} added to popular `);
};

const removeFromPopular = async (item) => {
  const new_list = popular_order.value?.filter(
    (meal) => meal?.name !== item?.name
  );

  await set(dbRef(getDatabase(), `${path}/popular/`), new_list);
  await remove(dbRef(getDatabase(), `people_also_added/${item?.name}`));
  const new_people_also_added = people_also_added.value
    .filter((also_added_item) => also_added_item?.name != item?.name)
    .toSorted((a, b) => a.position - b.position)
    .map((item, position) => [
      item.name,
      {
        ...item,
        position,
      },
    ]);
  const val2 = Object.fromEntries(new_people_also_added);
  await set(dbRef(getDatabase(), `people_also_added/`), val2);
  toast.success(`Removed ${item?.name} from ${restaurant_name} popular `);
};

const savePopularOrder = async () => {
  await set(dbRef(getDatabase(), `${path}/popular/`), popular_order.value);
  toast.success(`People also added updated`);
};

const savePeopleAlsoAdded = async () => {
  const updated_position = people_also_added.value.map((item, position) => [
    item.name,
    {
      ...item,
      position,
    },
  ]);
  // console.log({updated_position})
  const value = Object.fromEntries(updated_position);
  console.log({ value });
  await set(dbRef(getDatabase(), `people_also_added/`), value);
  toast.success("Saved");
};

const hidePeopleAlsoAdded = async (item) => {
  const value = !item?.hidden;
  await set(
    dbRef(getDatabase(), `people_also_added/${item?.name}/hidden`),
    value
  );
  toast.success("Successful");
};
</script>

<style lang="scss" scoped>
.restau {
  .meal-img {
    width: 65px !important;
    height: 65px !important;
  }
}
</style>