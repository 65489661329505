<template>
  <div class="relative order-details">
    <Text
      v-if="allow_edit"
      :weight="600"
      class="cursor-pointer p-3 top-[-33px] absolute underline"
      @click="() => (allow_edit = false)"
      >Cancel</Text
    >
    <Text
      :weight="600"
      :class="`cursor-pointer p-3 absolute top-[-33px] underline ${
        allow_edit ? 'left-[50px]' : ''
      }`"
      @click="
        () => {
          allow_edit = !allow_edit;
          save();
        }
      "
      >{{ !allow_edit ? "Edit" : "Save" }}</Text
    >

    <div
      :key="route.params.id"
      class="flex items-center justify-between py-5 px-3 my-4 h-[auto] bg-white rounded-[16px] deet-con"
    >
      <div class="mr-1">
        <Text
          :weight="600"
          :size="15"
          class="flex gap-1"
          :color="'rgba(0, 0, 0, 0.74)'"
        >
          <span v-if="!allow_edit">
            <!-- {{
              selectedOrder?.customer?.firstName +
              " " +
              selectedOrder?.customer?.lastName
            }} -->
            <customer-name :customer="selectedOrder?.customer" />
          </span>
          <div v-else>
            <input v-model="formValues.firstName" class="!w-100" />
            <input v-model="formValues.lastName" class="!w-100" />
          </div>
        </Text>
        <Text :weight="600" :size="12" :color="'#F60100'">
          <span v-if="!allow_edit"> {{ selectedOrder?.customer?.phone }}</span>
          <input v-else v-model="formValues.phone" />
        </Text>
      </div>
      <div
        class="flex gap-2 items-center justify-center"
        v-if="selectedOrder?.locationType === 'Delivery'"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M12 12C12.55 12 13.021 11.804 13.413 11.412C13.805 11.02 14.0007 10.5493 14 10C14 9.45 13.804 8.979 13.412 8.587C13.02 8.195 12.5493 7.99933 12 8C11.45 8 10.979 8.196 10.587 8.588C10.195 8.98 9.99933 9.45067 10 10C10 10.55 10.196 11.021 10.588 11.413C10.98 11.805 11.4507 12.0007 12 12ZM12 19.35C14.0333 17.4833 15.5417 15.7873 16.525 14.262C17.5083 12.7367 18 11.3827 18 10.2C18 8.38333 17.4207 6.896 16.262 5.738C15.1033 4.58 13.6827 4.00067 12 4C10.3167 4 8.89567 4.57933 7.737 5.738C6.57833 6.89667 5.99933 8.384 6 10.2C6 11.3833 6.49167 12.7377 7.475 14.263C8.45833 15.7883 9.96667 17.484 12 19.35ZM12 22C9.31667 19.7167 7.31267 17.596 5.988 15.638C4.66333 13.68 4.00067 11.8673 4 10.2C4 7.7 4.80433 5.70833 6.413 4.225C8.02167 2.74167 9.884 2 12 2C14.1167 2 15.9793 2.74167 17.588 4.225C19.1967 5.70833 20.0007 7.7 20 10.2C20 11.8667 19.3373 13.6793 18.012 15.638C16.6867 17.5967 14.6827 19.7173 12 22Z"
            fill="#F60100"
          />
        </svg>
        <Text
          :weight="600"
          :size="15"
          :color="'rgba(0, 0, 0, 0.74)'"
          class="w-[250px]"
        >
          <span v-if="!allow_edit">
            <!-- {{ selectedOrder?.customer?.deliveryAddress?.houseNo || " " }} -->
            {{ selectedOrder?.customer?.deliveryAddress?.houseAddress || " " }}
          </span>

          <vue-google-autocomplete
            v-else
            :country="['ng']"
            id="map"
            classname=" my-4 !w-[250px]"
            placeholder="Enter new location... "
            v-on:placechanged="
              (e) => {
                console.log(e)((newplace = e));
              }
            "
          >
          </vue-google-autocomplete>
          <br />
          <span>
            {{ selectedOrder?.delivery_address?.address || "Default" }} -
            {{ formatPrice(selectedOrder?.delivery || 1700) }}
          </span>
          <br v-if="selectedOrder?.delivery_range"/>
          <span class="capitalize">
            {{ selectedOrder?.delivery_range?.replace("-"," ") || "" }} 
          </span>
        </Text>
      </div>
      <div>
        <Text
          :weight="600"
          :size="15"
          :color="'rgba(0, 0, 0, 0.74)'"
          class="text-nowrap"
        >
          {{ formatDate(selectedOrder?.date) }}
        </Text>
        <Text
          class="text-nowrap"
          :weight="600"
          :size="15"
          :color="'orange'"
          v-if="selectedOrder?.scheduleDateTime"
        >
          {{
            dayjs(
              extractScheduledOrderDate(selectedOrder?.scheduleDateTime)
            ).format("MMM DD, YYYY. hh:mm A")
          }}
        </Text>
      </div>
      <div>
        <Text
          :weight="600"
          :size="15"
          :color="'rgba(0, 0, 0, 0.74)'"
          class="text-nowrap"
          >₦ {{ selectedOrder?.total_price?.toLocaleString() }}</Text
        >
      </div>
      <div>
        <Text :weight="600" :size="15" :color="'#009A49'" class="text-nowrap">
          {{ time_left_details.isDone ? "Time spent : " : "Time left : " }}

          <!-- {{ diffByStatus(selectedOrder) }} -->
          <!-- {{ time_left_details.countDown }} -->
          <time-left
            :order="selectedOrder"
            :count-down="time_left_details.countDown"
            :type="time_left_details.type"
          />
        </Text>
      </div>

      <div>
        <Receipt :order="selectedOrder">
          <Text :size="17" class="underline cursor-pointer">{{
            selectedOrder?.printed ? "Printed" : "Print"
          }}</Text>
        </Receipt>
      </div>
    </div>
    <div class="w-full my-3 rounded-[12px] note-container justify-evenly">
      <div class="note-box p-3 bg-white border rounded">
        <Text
          class="my-2 flex justify-between"
          :size="12"
          :weight="600"
          :color="'grey'"
        >
          Customer's note
          <div
            @click="() => openNote('customer')"
            class="text-[black] btn cursor-pointer border"
          >
            {{ btnNote("customer") }}
          </div>
        </Text>
        <Text class="flex flex-col" :size="16" :weight="600">
          <textarea
            v-model="notes.customer"
            class="input border font-light outline-none p-2 rounded"
            v-if="noteToEdit == 'customer'"
          ></textarea>
          <span v-if="noteToEdit != 'customer'">
            {{ selectedOrder?.order_note || "Order has no note" }}</span
          >
        </Text>
      </div>
      <div class="note-box p-3 bg-white border rounded">
        <Text
          class="my-2 flex justify-between"
          :size="12"
          :weight="600"
          :color="'grey'"
        >
          Rider's note
          <div
            @click="() => openNote('rider')"
            class="text-[black] btn cursor-pointer border"
          >
            {{ btnNote("rider") }}
          </div>
        </Text>
        <Text class="flex flex-col" :size="16" :weight="600">
          <textarea
            class="input border font-light outline-none p-2 rounded"
            v-model="notes.rider"
            v-if="noteToEdit == 'rider'"
          />

          <span v-if="noteToEdit != 'rider'">
            {{
              selectedOrder.customer?.deliveryAddress?.direction ||
              "Rider has no note"
            }}
          </span>
        </Text>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watchEffect } from "vue";
import { useRoute } from "vue-router";
import {
  formatDate,
  diffByStatus,
  formatPrice,
  extractScheduledOrderDate,
} from "@/util";
import Text from "./Text.vue";
import Receipt from "./Receipt.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { set, ref as dbRef, get, getDatabase } from "firebase/database";
import { useToast } from "vue-toast-notification";
import TimeLeft from "@/components/TimeLeft.vue";
import CustomerName from "@/components/CustomerName.vue";
import dayjs from "dayjs";
import UeButton from "./UeButton.vue";
import { createTimeLine, store } from "@/views/store/store";

const selectedOrder = ref({});
const route = useRoute();
const allow_edit = ref(false);
const newplace = ref({});
// const id = route.params.id;
const props = defineProps({
  order: Object,
});
const formValues = ref({
  firstName: "",
  lastName: "",
  phone: "",
});
const notes = ref({
  rider: selectedOrder.value.customer?.deliveryAddress?.direction,
  customer: selectedOrder.value.order_note,
});

watchEffect(() => {
  notes.value = {
    rider: selectedOrder.value.customer?.deliveryAddress?.direction,
    customer: selectedOrder.value.order_note,
  };
});
const noteToEdit = ref(null);
const toast = useToast({ position: "top-right" });
const editAddress = async () => {
  if (newplace.value.longitude) {
    try {
      //     (
      //     await get(
      //       dbRef(getDatabase(), "orders/" + id + "/customer/deliveryAddress")
      //     )
      //   ).val();
      const prev = selectedOrder.value.customer.deliveryAddress;
      // console.log(prev);
      const newAddress = {
        ...prev,
        houseNo: newplace.value.street_number || "",
        houseAddress: `${newplace.value.street_number || ""} ${
          newplace.value.route || ""
        } ${newplace.value?.administrative_area_level_2 || ""}  ${
          newplace.value.locality || ""
        } `,
        latitude: newplace.value.latitude,
        longitude: newplace.value.longitude,
      };
      //   console.log("orders/" + id + "/customer/deliveryAddress");
      await set(
        dbRef(
          getDatabase(),
          "orders/" + selectedOrder.value.id + "/customer/deliveryAddress"
        ),
        newAddress
      );
      //   console.log(newAddress);
      toast.success("Location updated");
    } catch (error) {
      console.log(error);
      toast.success("Error in location");
    }
  }
};
const time_left_details = computed(() => {
  const type = route.path.split("/")[1];
  let countDown = 0,
    isDone = false;

  switch (type) {
    case "operator":
      countDown = selectedOrder.value?.locationType === "Delivery" ? 45 : 35;
      isDone =
        selectedOrder.value?.status?.Delivered?.state ||
        selectedOrder.value?.status?.Pickup?.state;
      break;
    case "logistics":
      countDown = 45;
      isDone = selectedOrder.value?.status?.Delivered?.state;
      break;
    case "kitchen":
      countDown = 15;
      isDone = selectedOrder.value?.status?.Prepared?.state;
      break;
    case "assembler":
      countDown = 15;
      isDone = selectedOrder.value?.status?.Assembled?.state;
      break;
    default:
      countDown = 45;
  }
  // console.log(selectedOrder.value, route.path, getPath);
  // console.log({ isDone, type, countDown });
  return {
    countDown,
    type,
    isDone,
  };
});

watchEffect(() => {
  selectedOrder.value = props.order;
  formValues.value.firstName = selectedOrder.value?.customer?.firstName;
  formValues.value.lastName = selectedOrder.value?.customer?.lastName;
  formValues.value.phone = selectedOrder.value?.customer?.phone;
});

const save = async () => {
  const query = dbRef(
    getDatabase(),
    `/orders/${selectedOrder.value.id}/customer`
  );

  if (!allow_edit.value) {
    if (
      formValues.value.phone.length < 10 ||
      formValues.value.phone.length < 11
    ) {
      toast.info("Invalid Phone  number");
    } else {
      try {
        await set(query, {
          ...selectedOrder.value.customer,
          ...formValues.value,
        });
        toast.success("Detials updated");
        await editAddress();
      } catch (error) {
        toast.error("Error in updating detials");
      }
    }
  }
};
const btnNote = (note) => {
  // console.log(note);
  if (noteToEdit.value == note) return "save";
  return "edit";
};
const openNote = (note) => {
  if (noteToEdit.value == note) {
    // console.log(notes.value);
    noteToEdit.value = null;
    let path = "";
    if (note == "customer")
      path = `orders/${selectedOrder.value.id}/order_note`;
    if (note == "rider")
      path = `orders/${selectedOrder.value.id}/customer/deliveryAddress/direction`;

    if (notes.value[note]) {
      set(dbRef(getDatabase(), path), notes.value[note]);
      createTimeLine({
        orderId: selectedOrder.value.id,
        user_type: "admin",
        uid: store.state.user.uid,
        name: store.state.user.first_name + " " + store.state.user.last_name,
        message: `edited the ${note}'s note`,
      });
    }
  } else {
    noteToEdit.value = note;
  }
};

// console.log(id);
// const props =  ÷
</script>

<style lang="scss" scoped>
.order-details {
  margin-top: 15px;
  input {
    outline: none;
    width: fit-content;
    border: 1px solid grey;
    padding: 5px 2px;
    margin: 5px;
    border-radius: 8px;
  }

  .deet-con {
    overflow-x: auto;
    @media screen and (max-width: 600px) {
      // flex-direction: column;
      gap: 10px;
    }
  }

  .note-container {
    display: flex;
    @media screen and (max-width: 600px) {
      flex-direction: column;
      gap: 10px;
    }
    .note-box {
      width: 43%;
      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }
  }
}
</style>