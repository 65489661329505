<template>
  <div>
    <div @click="openModal = true">
      <slot />
    </div>
    <ConfirmAction />
    <v-dialog v-model="openModal">
      <div
        class="bg-white rounded relative w-[fit-content] py-4 px-4 mx-auto flex flex-col py-3 gap-3"
      >
        <img
          class="confirm-image"
          src="https://firebasestorage.googleapis.com/v0/b/uber-eats-e496b.appspot.com/o/images%2Ftickcircle.png?alt=media&token=257c9f60-0cd7-465a-8abc-4b4294c0b863"
          alt=""
        />
        <div
          class="absolute top-4 right-5 cursor-pointer"
          @click="() => (openModal = false)"
        >
          X
        </div>
        <Text :weight="600" :size="30" class="text-center">
          Confirm Payment
        </Text>
        <Text :weight="400" :size="16" class="text-center">
          Are you sure you want to confirm payment for this order
        </Text>

        <Text :weight="400" :size="16" class="text-center">
          {{
            props.order.paymentMethod !== "card"
              ? formatPrice(props.order?.amount_due)
              : formatPrice(props.order?.total_price - props.order?.walletUsed)
          }}{{}}
        </Text>
        <div v-if="props.order.paymentMethod == 'card'">
          Payments that match this description:
          <div v-if="matchedPayments?.length" class="my-2">
            <div v-for="payment in matchedPayments" :key="payment.id">
              {{ payment?.customer?.first_name }}
              {{ payment?.customer?.last_name }}
              {{ payment?.customer?.email }} -
              {{ formatPrice(payment?.amount / 100) }} {{ payment?.status }}
              <div>
                Created at :
                {{ dayjs(payment?.createdAt).format("MMM DD. hh:mm:ssA") }}
                <br />
                Paid at :
                {{
                  payment?.paidAt
                    ? dayjs(payment?.paidAt).format("MMM DD. hh:mm:ssA")
                    : "------"
                }}
              </div>
            </div>
          </div>
          <div v-if="!matchedPayments?.length">No match found</div>
        </div>

        <!-- <div
          v-if="props.order.paymentMethod === 'card'"
          class="flex w-100 justify-center gap-3 mt-4"
        >
          <button
            :disabled="loading"
            class="cursor-pointer border rounded px-2 btn"
            :weight="400"
            :size="20"
            @click="confirm_by_paystack"
          >
            {{ loading ? "loading..." : "Verify payment in paystack" }}
          </button>
        </div> -->
        <div class="flex w-100 justify-center gap-3 mt-4">
          <button
            :disabled="loading"
            class="cursor-pointer border rounded px-2 btn"
            :weight="400"
            :size="20"
            @click="
              () => {
                confirmAction.commit('toggleModal', {
                  onProceed: () => confirm_and_debit(),
                  confirmText: 'WALLET',
                });
              }
            "
          >
            {{ loading ? "Loading..." : "Confirm & debit wallet" }}
          </button>
          <button
            :disabled="loading"
            class="cursor-pointer border rounded px-2 btn"
            :weight="400"
            :size="20"
            @click="
              () => {
                confirmAction.commit('toggleModal', {
                  onProceed: () =>
                    confirm({
                      timeline_text: `manual bank transfer (${formatPrice(
                        props.order?.amount_due
                      )})`,
                    }),
                  confirmText: 'BANK ALERT',
                });
              }
            "
          >
            {{ loading ? "loading..." : "     Confirm via bank alert" }}
          </button>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script setup>
import Text from "@/components/Text.vue";
import { formatPrice } from "@/util";
import { removeUserPendingTransaction } from "@/views/main/Operator/Orders/removeUserPendingTransaction";
import {
  createTimeLine,
  store,
  sendPushNotification,
} from "@/views/store/store";
import dayjs from "dayjs";
import { set, ref as dbRef, getDatabase, get } from "firebase/database";
import { onMounted, ref, watchEffect } from "vue";
import { useToast } from "vue-toast-notification";
import { confirmAction } from "@/views/store/confirmAction";
import ConfirmAction from "@/components/ConfirmAction.vue";
import axios from "axios";

const confirmation_type = ref("Bank Alert");

const loading = ref(false);
const openModal = ref(false);
const customer = ref({});
const added_time = ref(40);

const props = defineProps({
  order: Object,
  onSuccess: {
    type: Function,
    default: () => null,
  },
});
const toast = useToast({ position: "top-right" });
// console.log('kk---')

const getCustomer = async () => {
  const data = await get(
    dbRef(getDatabase(), `/users/${props.order?.customer?.id}/`)
  );

  // console.log(data.val(),`/users/${props.order?.customer?.id}/`);
  customer.value = data?.val();
};

watchEffect(() => {
  // console.log('j0000')
  getCustomer();
});

const confirm = async ({ timeline_text }) => {
  loading.value = true;

  await set(
    dbRef(
      getDatabase(),
      `orders/${props.order?.id}/status/AwaitingPayment/state`
    ),
    false
  );
  await set(
    dbRef(getDatabase(), `orders/${props.order?.id}/isProcessing`),
    props?.order?.customer?.id
  );
  if (!props.order?.isSchedule) {
    await set(
      dbRef(getDatabase(), `orders/${props.order?.id}/status/Confirmed/`),
      {
        state: true,
        time: JSON.stringify(
          dayjs(Date.now()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        ),
      }
    );
  }
  removeUserPendingTransaction({
    customer_uid: customer.value.uid,
    order: props.order,
  });
  await set(
    dbRef(getDatabase(), `orders/${props.order?.id}/confirmation_type/`),
    confirmation_type.value
  );

  // if (
  //   props?.delivery_range === "regular" ||
  //   props?.delivery_range === "extended"
  // )
  //   added_time.value = 40;
  if (
    props?.order?.delivery_range === "peak-regular" ||
    props?.order?.delivery_range === "peak-extended"
  ) {
    added_time.value = 60;
  }
  await set(
    dbRef(getDatabase(), `orders/${props.order?.id}/orderReminderTime/`),
    JSON.stringify(
      dayjs()
        .add(added_time.value, "minutes")
        .format("YYYY-MM-DDTHH:mm:ss.SSSZ")
    )
  );

  await createTimeLine({
    orderId: props.order.id,
    user_type: "admin",
    uid: store.state.user.uid,
    name: store.state.user.first_name + " " + store.state.user.last_name,
    message: `confirmed payment via ${timeline_text}`,
  });
  const token = (
    await get(
      dbRef(getDatabase(), `users/${props.order?.customer?.id}/pushToken`)
    )
  ).val();
  if (token) {
    sendPushNotification({
      token,
      title: ``,
      body: "The restaurant is preparing your order",
      orderId: props.order.id,
    });
  }
  toast.success("Payment confirmed");
  loading.value = false;
  openModal.value = false;
  props.onSuccess();
};

const confirm_and_debit = async () => {
  loading.value = true;
  const wallet_path = dbRef(
    getDatabase(),
    `users/${props.order?.customer.id}/wallet`
  );
  const prev_wallet_details = (await get(wallet_path)).val();
  // const prev_wallet_details = customer.value?.wallet;

  // console.log(prev_wallet_details,customer.value.wallet)
  //   console.log(props.order);
  const prev_balance = prev_wallet_details?.balance || 0;
  const prev_history = prev_wallet_details?.history || [];
  const charge =
    props.order.paymentMethod !== "card"
      ? props.order?.amount_due
      : props.order?.total_price - props.order?.walletUsed;
  console.log(prev_balance, charge, prev_balance < charge);
  if (prev_balance < charge) {
    toast.info("User doesn’t have enough wallet balance to cover order");
  } else {
    const payload = {
      ...prev_wallet_details,
      balance: prev_balance - charge,
      history: [
        ...prev_history,
        {
          order: props.order?.id,
          created: JSON.stringify(new Date().toISOString()),
          type: "order-purchase",
          amount: charge,
        },
      ],
    };
    //   console.log(payload);
    try {
      await set(wallet_path, payload);
      confirmation_type.value = "Wallet Deduction";
      await confirm({
        timeline_text: `wallet balance (${formatPrice(charge)})`,
      });
    } catch (error) {
      console.log(error);
    }
  }

  loading.value = false;
};
const matchedPayments = ref(null);

const confirm_by_paystack = async () => {
  // console.log(process.env.VUE_APP_PAYSTACK_SERVICE_KEY)
  const url = "https://api.paystack.co/transaction?perPage=30&page=1";
  try {
    const { data } = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_PAYSTACK_SERVICE_KEY}`,
      },
    });
    console.log(data?.data, "dddd");
    if (data?.data) {
      matchedPayments.value = data?.data?.filter(
        (item) =>
          item?.customer?.email == props.order?.customer?.email &&
          item?.amount / 100 == props.order.total_price
      );
      console.log(props.order?.customer?.email, props.order.total_price);
      console.log(matchedPayments.value, data?.data);
    }
  } catch (error) {
    console.log(error);
    toast.error("Error reaching paystack: " + error);
  }
};

onMounted(() => {
  confirm_by_paystack();
});
</script>

<style lang="scss" scoped>
.confirm-image {
  // img{
  margin: auto;
  width: 104px;
  // }
}
</style>