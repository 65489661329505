<template>
  <v-dialog v-model="meals.state.genericSoldOutModal" persistent>
    <!-- <div class="h-[100vh] w-[100vw] bg-grey fixed top-0 left-0 z-[-1]">

    </div> -->
    <div
      class="bg-white mx-auto w-[400px] rounded max-h-[700px] p-2"
      style="overflow-y: auto"
    >
      <div class="flex justify-between items-center mb-4">
        <Text weight="600"> {{ occurances?.meal?.name }} occurs in</Text>
        <span
          class="material-symbols-outlined border rounded-circle text-sm cursor-pointer"
          @click="
            () => {
              meals.commit('openGenericSoldOutModal', false);
            }
          "
        >
          close
        </span>
      </div>
      Untick where you don't want make update
      <div
        v-for="(occurance, i) in occurances.occured_in"
        :key="i"
        @click="() => ignoreOccurance(occurance, i)"
        class="border rounded p-1 my-1 cursor-pointer flex items-center justify-between gap-3"
      >
        <div>
          {{ occurance?.meal_item?.name }} ({{
            occurance?.meal_item?.restaurant_name
          }}) as {{ occurance?.extra_key || "main item" }}
        </div>

        <input type="checkbox" name="" id="" :checked="!occurance.ignore" />
      </div>
      <br />
      <Text class="lowercase">
        <span class="capitalize">This</span> action will
        <span class="font-bold">
          {{ occurances?.meal?.available ? "add" : "remove" }}
        </span>
        selected
        <span class="font-bold"> {{ occurances?.meal?.name }}</span>
        {{ occurances?.meal?.available ? "to" : "from" }} stoplist
      </Text>
      <br /><br />
      <ue-button @click="updateStock" :disabled="loading">
        {{ loading ? "Loading" : "Proceed" }}
      </ue-button>
    </div>
  </v-dialog>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { watchEffect } from "vue";
import UeButton from "@/components/UeButton.vue";
import {
  ref as dbRef,
  getDatabase,
  onValue,
  set,
  get,
} from "firebase/database";
import { meals } from "@/views/store/meals";
import Text from "@/components/Text.vue";
import { httpsCallable } from "firebase/functions";
import { projectFunctions } from "@/firebase/config";

//this only works with the getAllOccurancesOfItem  function

onMounted(() => {
  meals.commit("openGenericSoldOutModal", false);
});

const props = defineProps({
  occurances: {
    type: Object,
    default: {
      occured_in: [],
      meal: {},
    },
  },
});
const occurances = ref({ occured_in: [] });
const loading = ref(false);

watchEffect(() => {
  if (props.occurances) occurances.value = props.occurances;
});

const ignoreOccurance = (occurance, index) => {
  occurances.value.occured_in[index].ignore = !occurance?.ignore;
};

const updateStock = async () => {
  if (loading.value) return;
  loading.value = true;
  try {
    const toBeUpdated = occurances.value.occured_in?.filter(
      (occurance) => !occurance?.ignore
    );
    const meal = occurances.value?.meal;
    // const promises = toBeUpdated.map(async (element, i) => {
    //   return set(dbRef(getDatabase(), element?.stoplist_path), !meal.available);
    // });
    // await Promise.all(promises);

    const multipleUpdates = httpsCallable(projectFunctions, "multipleUpdates");
    const updates = toBeUpdated.map((change) => ({
      path: change.stoplist_path,
      value: !meal.available,
    }));
    await multipleUpdates({
      updates,
    });
    meals.commit("openGenericSoldOutModal", false);
    loading.value = false;
    // occurances.value = null;
  } catch (error) {
    loading.value = false;
    console.log(error);
  }
};
</script>

<style lang="scss" scoped>
</style>