import { WalletTransaction } from "@/views/main/Operator/Customers/updateWallet";
import { get, ref as dbRef, remove, getDatabase, } from "firebase/database";

const rectifyFailedCancelledItem = async (order) => {
    try {

        const action = order?.items_cancelling;
        const rectificationType = action.transactionRectification;
        const amount = action.amount;
        const user = (
            await get(dbRef(getDatabase(), `users/${order?.customer?.id}`))
        ).val();
        console.log(user);
        const wallet = new WalletTransaction(user);
        const removeItemsCancelling = async () => {
            await remove(
                dbRef(getDatabase(), `orders/${order?.id}/items_cancelling`)
            );
        };
        if (rectificationType === "refund")
            wallet.depositToWallet(
                amount,
                `rectification for failed wallet usage, ${order?.id}`,
                removeItemsCancelling,
            );
        if (rectificationType === "charge")
            wallet.withdrawFromWallet(
                amount,
                `rectification for failed wallet usage, ${order?.id}`,
                removeItemsCancelling
            );
        console.log({ amount, rectificationType });
    } catch (error) {
        console.log(error)
    }
};
export { rectifyFailedCancelledItem }