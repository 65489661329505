<template>
  <div class="w-full h-full customer_map">
    <nav>
      <div class="left">
        <Text :weight="600" :size="20">Order analytics</Text>
      </div>

      <time-drop-down :getDate="(res) => (timeframe = res)" />
    </nav>
    <div class="pl-5">
      <Text :size="12" :weight="500">Orders</Text>
      <Text :size="23" :weight="700">{{ totalOrders }}</Text>
    </div>
    <main>
      <order-chart :timeframe="timeframe" />
    </main>
  </div>
</template>

<script>
import Text from "@/components/Text.vue";

import BarChart from "./BarChart.vue";
// import { ref } from 'vue';
import TimeDropDown from "./TimeDropDown.vue";
import OrderChart from "./OrderChart.vue";
import { ref } from "vue";
import { watchEffect } from "vue";
import { getOrdersByTimeFrame } from "@/util";

export default {
  components: { Text, BarChart, TimeDropDown, OrderChart },

  setup() {
    const timeframe = ref({});
    const totalOrders = ref(0);

    watchEffect(async() => {
      const ordersInTimeFrame = await getOrdersByTimeFrame(
        timeframe.value.start,
        timeframe.value.end
      );
      totalOrders.value = ordersInTimeFrame.length;
      // let totalList = ordersInTimeFrame.map((x) => x.total_price);
      // if (totalList.length) {
      //   totalOrders.value = totalList.reduce((a, b) => a + b);
      // }else{
      //   totalOrders.value = 0
      // }
    });
    return {
      timeframe,
      totalOrders,
    };
  },
};
</script>

<style lang="scss" >
.customer_map {
  font-family: Work Sans;
  height: 100%;
  padding: 33px 35px;
  width: 100%;
  display: flex;
  gap: 24px;
  justify-content: space-between;
  flex-direction: column;

  nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  main {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
</style>