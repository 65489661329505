<template>
  <div>
    <div class="icon" @click="sub()" id="sub">-</div>
    <div>{{ counter }}</div>
    <div class="icon" @click="add()" id="add">+</div>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";
export default {
  props: {
    count: Number,
    min:Number,
    max: Number,
    onchange: Function,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const counter = ref(props.count || 0);
    watchEffect(() => {
      counter.value = props.count;
    });

    const add = () => {
      if (!props.disabled) {
        if (counter.value < props.max) {
          counter.value++;
          props.onchange(counter.value,'add');
        }
      }
    };
    const sub = () => {
      if (!props.disabled) {
        if (counter.value > (props.min || 0)) {
          counter.value--;
          props.onchange(counter.value,'subtract');
        }
      }
    };
    return { counter, add, sub, onchange };
  },
};
</script>

<style lang="scss" scoped>
div {
  display: flex;
  gap: 12px;
  .icon {
    background: green;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 800;
    cursor: pointer;
  }
}
</style>