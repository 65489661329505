<template>
  <div
    class="mx-auto rounded-[24px] relative bg-white w-[65%] h-[75vh] edit-recipe-inventory"
  >
    <header class="">
      <Text :color="'white'" :weight="600" :size="24">{{
        recipe?.meal?.name
      }}</Text>
    </header>
    <main>
      <div class="flex gap-[24px]">
        <Text :color="'black'" :weight="600" :size="20">Based on</Text>

        <v-btn
          :class="`${
            action == single_action ? 'ue-btn-gradient' : 'ue-btn-outline '
          } capitalize`"
          v-for="single_action in actions"
          :key="single_action"
          :variant="action == single_action ? `tonal` : 'outlined'"
          @click="() => (action = single_action)"
        >
          {{ single_action }}
        </v-btn>
      </div>
      <form class="w-[100%] py-5 h-[100%] relative">
        <!-- ############################################# -->
        <div class="flex flex-col w-75 gap-[30px]" v-if="action == 'prepare'">
          <flex-input
            v-for="(item, i) in formValues.items"
            :key="item.id"
            :label="''"
            :unit="item?.ingredient?.name"
          >
            <div class="w-100 ">
              <input
                :name="i"
                @input="calc_by_ingredient_values"
                type="number" class="w-100"
                v-model="item.quantity"
                min="0"
              />
              <div>{{ item?.ingredient?.unit?.short }}</div>
            </div>
          </flex-input>

          <flex-input :label="'Actual yield'" :unit="'Portion(s)'">
            <div class="">
              <input
                type="number"
                v-model="formValues.quantity"
                min="0"
                class="w-100"
                @input="calc_by_portion_value"
              />
            </div>
          </flex-input>

          <flex-input :label="'Counted by'">
            <input type="text" disabled v-model="name" class="w-100" />
          </flex-input>
        </div>
        <!-- ############################################# -->

        <!-- ############################################# -->
        <div class="flex flex-col gap-[50px]" v-if="action == 'damage'">
          <flex-input :label="'Damaged stock'" :unit="'Portion(s)'">
            <input
              type="number"
              v-model="formValues.quantity"
              :max="recipe.current_inventory"
              min="0"
            />
          </flex-input>

          <flex-input :label="' Reason behind damage'">
            <textarea v-model="formValues.reason"></textarea>
          </flex-input>
        </div>
        <!-- ############################################# -->

        <div class="flex w-full gap-3 justify-end absolute bottom-5">
          <v-btn
            class="ue-btn-outline"
            variant="outlined"
            @click="() => props.onClose()"
          >
            Cancel
          </v-btn>
          <v-btn
            class="ue-btn-gradient"
            :disabled="loading"
            variant="tonal"
            @click="submit"
          >
            {{ loading ? "Loading..." : action }}
          </v-btn>
        </div>
      </form>
    </main>
  </div>
</template>
  
  <script>
import Text from "@/components/Text.vue";
import { ref, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import { set, ref as dbRef, getDatabase, get } from "firebase/database";
import { store } from "@/views/store/store";
import dayjs from "dayjs";
import { useToast } from "vue-toast-notification";
import FlexInput from "./FlexInput.vue";

export default {
  components: { Text, FlexInput },
  props: {
    onClose: Function,
    recipe_id: String,
    redirect_to: String,
  },
  setup(props) {
    const actions = ["prepare", "damage"];
    const action = ref("prepare");
    const route = useRoute();
    const router = useRouter();
    const loading = ref(false);
    const toast = useToast({ position: "top-right" });
    const id = ref(null);
    const formValues = ref({
      items: [],
      reason: "",
      quantity: 0,
    });

    watchEffect(() => {
      if (props.recipe_id) {
        id.value = props.recipe_id;
      } else {
        id.value = route.params.id;
      }
    });

    const recipe = ref({});
    const item_path = `/inventory/recipes/${id.value}/`;

    watchEffect(async () => {
      action.value;
      const res = await get(dbRef(getDatabase(), item_path));
      recipe.value = res.val();
      formValues.value.items = res.val()?.items;
      formValues.value.quantity = res.val()?.portion;
      if (action.value == "damage") formValues.value.quantity = 0;
    });

    // watchEffect(() => {
    //   if (action.value == "damagee") formValues.value.quantity = 0;
    // });

    const calc_by_ingredient_values = (e) => {
      const name = e.target.name;
      const value = parseInt(e.target.value);
      //   console.log("value :", value);
      //   console.log("Base value :", recipe.value.items[name].quantity);
      const one_unit = value / recipe.value.items[name].quantity;
      const calculated_portion = recipe.value.portion * one_unit;
      recipe.value.items.map((item, i) => {
        if (i != name)
          formValues.value.items[i].quantity = item.quantity * one_unit;
      });
      formValues.value.quantity = calculated_portion;
    };

    const calc_by_portion_value = (e) => {
      const value = parseInt(e.target.value);
      const one_portion = value / recipe.value.portion;
      recipe.value.items.map((item, i) => {
        formValues.value.items[i].quantity = item.quantity * one_portion;
      });
    };

    const submit = async (e) => {
      const pending_inventory = recipe.value?.pending_inventory || 0;
      const current_inventory = recipe.value?.current_inventory || 0;
      loading.value = true;
      e.preventDefault();

      let history = recipe.value.history ? recipe.value.history : [];
      let user = store.state.user;
      let total = 0;
      let editted = {};
      let isDamage = action.value == "damage";

      if (action.value == "prepare") {
        total = formValues.value.quantity + pending_inventory;
        editted = { pending_inventory: total };
      }
      if (isDamage) {
        total = Math.abs(current_inventory - formValues.value.quantity);
        editted = { current_inventory: total };
      }

      //   delete user["permissions"];
      const new_recipe_history = {
        user,
        date: dayjs().format("MMM DD, YYYY"),
        time: dayjs().format("hh:mm A"),
        action: action.value,
        reason: formValues.value.reason,
        quantity: formValues.value.quantity,
        total,
      };

      try {
        if (isDamage && formValues.value.quantity > current_inventory) {
          toast.info(
            "Damaged quantity cannot be greater than current quantity"
          );
        } else {
          if (isDamage && !formValues.value.reason) {
            toast.info("Damage must have a reason");
          } else {
            let all_is_enough = false;

            if (action.value === "prepare") {
              //check if ingredient is enough

              const await_check_ingredient_is_enough = await Promise.all(
                formValues.value.items.map(async (item, i) => {
                  console.log(item?.ingredient)
                  const isCustom = item?.ingredient?.type?.id == "custom";

                  const inge_path = isCustom
                    ? `/inventory/recipes/${item.ingredient.name}/current_inventory`
                    : `/inventory/ingredients/${item.ingredient.name}/current_inventory`;
                  // const inge_path = `/inventory/ingredients/${item.ingredient.name}/current_inventory`;
                  const current_inventory = (
                    await get(dbRef(getDatabase(), inge_path))
                  ).val();
                  console.log(item.ingredient.name,current_inventory)
                  if (current_inventory < item.quantity) {
                    toast.info(`${item.ingredient.name} is not enough`);
                  }
                  return !(current_inventory < item.quantity);
                })
              );

              all_is_enough = await_check_ingredient_is_enough.every(
                (isTrue) => isTrue
              );

              //update ingredient current inventory and history

              if (all_is_enough) {
                formValues.value.items.map(async (item, i) => {
                  //an ingredient can be an actual ingredient or recipe
                  // their updates can be similar given the have current_inventory and history
                  const isCustom = item?.ingredient?.type?.id == "custom";

                  const inge_path = isCustom
                    ? `/inventory/recipes/${item.ingredient.name}/`
                    : `/inventory/ingredients/${item.ingredient.name}/`;
                  const ingredient = (
                    await get(dbRef(getDatabase(), inge_path))
                  ).val();
                  const new_total =
                    ingredient?.current_inventory - item?.quantity;
                  const new_ingredient_history = {
                    action: `Prep `,
                    quantity: item?.quantity,
                    reason: `prep ${recipe.value?.meal?.name}`,
                    // tally: false,
                    total: new_total,
                    user: store.state.user,
                    date: dayjs().format("MMM DD, YYYY"),
                    time: dayjs().format("hh:mm A"),
                  };
                  await set(dbRef(getDatabase(), inge_path), {
                    ...ingredient,
                    current_inventory: new_total,
                    history: [
                      ...(ingredient?.history || []),
                      new_ingredient_history,
                    ],
                  });
                });
              }
            }

            if (all_is_enough || isDamage) {
              history.push(new_recipe_history);
              const path = `/inventory/recipes/${recipe.value.id}`;
              await set(dbRef(getDatabase(), path), {
                ...recipe.value,
                history,
                ...editted,
              });

              toast.success("Recipe inventory updated successfully");
              props.onClose();
              if (route.params.id) router.push(`/inventory/prep/${id.value}`);
              if (!route.params.id) router.push(`/inventory/prep/`);
            }
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        loading.value = false;
      }
    };
    return {
      props,
      actions,
      action,
      formValues,
      submit,
      recipe,
      loading,
      calc_by_ingredient_values,
      calc_by_portion_value,
      name: `${store.state.user.first_name} ${store.state.user.last_name}`,
    };
  },
};
</script>
  
  <style lang="scss" scoped>
.edit-recipe-inventory {
  display: flex;
  flex-direction: column;

  header {
    width: 100%;
    height: 84px;
    flex-shrink: 0;
    border-radius: 16px 16px 0px 0px;
    background: #ff9103;
    padding: 40px;
    display: flex;
    align-items: center;
  }
  main {
    height: 100%;
    width: 100%;
    padding: 40px;
  }
  form {
    flex: 1;
    input,
    textarea {
      width: 266px;
      height: 33px;
      outline: none;
      //   border: 1px grey solid;
      padding: 12px;
      text-align: right;
      //   border-radius: 8px;
      border-radius: 6px;
      border: 1px solid #000;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
    textarea {
      height: 200px;
      text-align: left;
    }
  }
}
</style>