<template>
  <head>
    <link
      rel="stylesheet"
      href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
    />
  </head>

  <layout :hasTopNav="false">
    <div class="px-[40px] bg-light w-full h-full" v-if="!selectId">
      <TopNav :onSearch="search" />
      <div class="d-flex justify-content-between orderClass p-3 w-full">
        <div class="w-full">
          <div class="flex w-full items-center justify-between">
            <Text :size="27" :weight="700">Logistic Partners</Text>
            <add-partner />
          </div>
        </div>
      </div>
      <section class="orderList !p-0 !bg-white">
        <div>
          <table class="order-table">
            <thead>
              <tr>
                <th class="table-header">S/N</th>
                <th class="table-header">Name</th>
                <th class="table-header">Couriers</th>
                <th class="table-header">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(partner, i) in partners"
                :key="partner.key"
                class="table-row"
                :class="index % 2 === 0 ? 'even-row' : 'odd-row'"
              >
                <td class="table-data">{{ i + 1 }}</td>
                <td class="table-data">{{ partner.name }}</td>
                <td class="table-data">{{ Object.keys(partner?.riders || {})?.length || 0 }}</td>
                <td class="table-data">
                  <router-link
                    :to="`/${path.split('/')[1]}/partners/${partner?.key}`"
                    class="action-link !underline"
                  >
                    View
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <Pagination
            class="py-3"
            :currentPage="currentPage"
            :totalPages="totalPages"
            @page-change="goToPage"
          /> -->
        </div>
      </section>
    </div>
    <div v-else>loading...</div>
  </layout>
</template>
              
            <script setup>
import { ref, computed, watchEffect } from "vue";
// import Pagination from "@/components/Pagination.vue";
// import OrderModal from "./OrderModal.vue";
import TopNav from "@/components/TopNav.vue";
import Modal from "@/components/Modal.vue";

import { useRoute, useRouter } from "vue-router";

import Text from "@/components/Text.vue";
import AddPartner from "./AddPartner.vue";

import Layout from "@/partials/Layout/layout.vue";

import { get, ref as dbRef, getDatabase, onValue } from "firebase/database";
// import { getDatabase, onValue, ref as dbRef } from "firebase/database";

const route = useRoute();
const selectId = ref(route.params.id);
const { path } = route;
const partners = ref([]);

// watchEffect(async () => {
//   const res = (await get()).val();
//   partners.value = Object.values(res || {});
// });
onValue(dbRef(getDatabase(), `/logistics_partners`), (res) => {
  partners.value = Object.values(res.val() || {});
});

// Pagination
// const ordersPerPage = 15;
// const currentPage = ref(1);
// const totalPages = computed(() =>
//   Math.ceil(rejected_orders.value.length / ordersPerPage)
// );

// const goToPage = (page) => {
//   if (page >= 1 && page <= totalPages.value) {
//     currentPage.value = page;
//   }
// };

// const paginatedOrders = computed(() => {
//   const startIndex = (currentPage.value - 1) * ordersPerPage;
//   const endIndex = startIndex + ordersPerPage;
//   return rejected_orders.value.slice(startIndex, endIndex);
// });
</script>
              
              <style scoped>
/* active and completed button */
</style>