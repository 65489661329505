<template>
  <div class="flex justify-center items-center w-[100vw] h-[100vh]">
<Text :weight="600" :size="24">    Oop! Page not found</Text>
  </div>
</template>

<script>
import Text from '@/components/Text.vue';
export default {

    components:{Text},
  setup() {},
};
</script>

<style lang="scss" scoped>
</style>