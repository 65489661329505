<template>
  <div class="p-3 bg-white m-3">
    <GoogleMap
      api-key="AIzaSyAUR5lKBWKOCHVAe1c5iN4e5vo0OL7_GxY"
      style="width: 100%; height: 400px"
      :center="center"
      :zoom="13"
    >
      <Marker v-if="staffMarkOptions" :options="staffMarkOptions" />

      <!-- <Marker
        v-for="options in ridersLocationMarkOptions"
        :key="options.position.lat + options.label"
        :options="options"
      /> -->
    </GoogleMap>
  </div>
</template>

<script setup>
import { ref, watchEffect } from "vue";
import { GoogleMap, Polyline, Marker } from "vue3-google-map";
const props = defineProps({
  staff: Object,
});

const staff = ref({});
const center = ref({});
const staffMarkOptions = ref({});

watchEffect(() => {
  staff.value = props.staff;
  center.value = {
    lat: staff.value?.coords?.latitude,
    lng: staff.value?.coords?.longitude,
  };
  staffMarkOptions.value = {
    position: {
      lat: staff.value?.coords?.latitude,
      lng: staff.value?.coords?.longitude,
    },
    label: "",
    title: "Staff location",
    icon: "https://img.icons8.com/color/48/map-pin.png",
  };
});

// console.log({
//   lat: props.staff?.coords?.latitude,
//   lng: props.staff?.coords?.longitude,
// });
</script>

<style lang="scss" scoped>
</style>