<template>
  <div class="w-full h-full top_selling_item !relative">
    <nav>
      <div class="left">
        <Text :weight="600" :size="18">Average kitchen/assembler time</Text>
        <br />
        <Text :weight="600" :size="18">
          {{
           res
          }}
          mins</Text
        >
        <br />
      </div>
      <div class="rightttt">
        <time-drop-down :getDate="(e) => (timeFrame = e)" />
      </div>
    </nav>
    <main><BarChart :labels="timeFrame.frame" :data="yaxis" /></main>
  </div>
</template>
  
  <script>
import Text from "@/components/Text.vue";
// import TimeDropDownVue from "./TimeDropDown.vue";
import { computed, watch, watchEffect } from "vue";
import { ref } from "vue";
import { getOrdersByTimeFrame } from "@/util";
import TimeDropDown from "./TimeDropDown.vue";
import BarChart from "./BarChart.vue";
import dayjs from "dayjs";
export default {
  components: { Text, TimeDropDown, BarChart },
  setup() {
    const timeFrame = ref({});
    const yaxis = ref([]);
    const total_average_minutes = ref(0);

    watchEffect(async() => {
      const time = timeFrame.value.time;
      const start = timeFrame.value.start;
      const end = timeFrame.value.end;
      const frame = timeFrame.value.frame;
      const format = timeFrame.value.format;
      const res =await getOrdersByTimeFrame(start, end)

      const ordersInTimeFrame = res.filter(
        (order) => order?.status?.Assembled?.state
      )

      const getOrderDifference = (order) => {
        let difference = dayjs(
          order.status?.Assembled?.time.replaceAll('"', "")
        ).diff(order.status?.Confirmed?.time.replaceAll('"', ""), "minutes");
        return Math.abs(difference) || 0;
      };
      const calculateAverage = (numbers) => {
        // console.log(numbers);
        return numbers.length
          ? Math.round(numbers.reduce((a, b) => b + a) / numbers.length)
          : 0;
      };
      let list = [];

        frame?.map((x, i) => {
          const ordersInThatDay = ordersInTimeFrame.filter(
            (y) => dayjs(y.date.replaceAll('"', "")).format(format) === x
          );
          list[i] = calculateAverage(ordersInThatDay.map(getOrderDifference));
        });


      // if (time === "today") {
      //   list = [];
      //   frame.map((time, i) => {
      //     const ordersInThatHour = ordersInTimeFrame.filter(
      //       (y) =>
      //         new Date(y.date.replaceAll('"', "")).getHours() ===
      //         new Date(`1990-01-01 ${time}`).getHours()
      //     );
      //     // console.log({ordersInThatHour})
      //     list[i] = calculateAverage(ordersInThatHour.map(getOrderDifference));
      //   });
      // } else if (time === "yesterday") {
      //   list = [];
      //   frame.map((time, i) => {
      //     const ordersInThatHour = ordersInTimeFrame.filter(
      //       (y) =>
      //         new Date(y.date.replaceAll('"', "")).getHours() ===
      //         new Date(`1990-01-01 ${time}`).getHours()
      //     );
      //     list[i] = calculateAverage(ordersInThatHour.map(getOrderDifference));
      //   });
      // } else if (time === "this-week") {
      //   list = [];
      //   frame.map((x, i) => {
      //     const ordersInThatDay = ordersInTimeFrame.filter(
      //       (y) => dayjs(y.date.replaceAll('"', "")).format("ddd") === x
      //     );
      //     list[i] = calculateAverage(ordersInThatDay.map(getOrderDifference));
      //   });
      // } else if (time === "this-month" || time === "last-month") {
      //   list = [];
      //   frame.map((x, i) => {
      //     const ordersInThatMonth = ordersInTimeFrame.filter(
      //       (y) => dayjs(y.date.replaceAll('"', "")).format("D-MMM-YYYY") === x
      //     );
      //     list[i] = calculateAverage(ordersInThatMonth.map(getOrderDifference));
      //   });
      // } else if (time === "this-year" || time === 'life-time') {
      //   list = [];
      //   frame.map((x, i) => {
      //     const ordersInThatMonth = ordersInTimeFrame.filter(
      //       (y) => dayjs(y.date.replaceAll('"', "")).format("MMM YYYY") === x
      //     );
      //     list[i] = calculateAverage(ordersInThatMonth.map(getOrderDifference));
      //   });
      // }

      if (list.length) {
        yaxis.value = list;
        // console.log(yaxis.value);
        total_average_minutes.value = list.reduce((a, b) => a + b);
      } else {
        yaxis.value = list;
        total_average_minutes.value = 0;
      }
    });
    const res = computed(() => {
      const val = yaxis.value.length
        ? yaxis.value.reduce((a, b) => a + b) /
          yaxis.value.filter((notzero) => notzero > 0).length
        : 0;
      return isNaN(val) ? 0 : val?.toFixed(2);
    });
    return {
      timeFrame,
      total_average_minutes,
      yaxis,
      res,
    };
  },
};
</script>
  
  <style lang="scss" >
.top_selling_item {
  font-family: Work Sans;
  height: 100%;
  padding: 33px 35px;
  width: 100%;
  display: flex;
  gap: 24px;
  justify-content: space-between;
  flex-direction: column;

  nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // position: absolute;top: 10px;width: 100%;
    .left {
      // top: 30px;
      // position: absolute;
      // left: 10px;
    }
    left: 10px;
    .rightttt {
      // top: 30px;
      // position: absolute;
      // // width: 75px;
      // right: 10px;
    }
  }
  main {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    padding-right: 4px;
    margin-top: 30px;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      width: 4px;
    }
    .card {
      display: flex;
      justify-content: space-between;
      //   align-items: center;
      width: 100%;
      flex-direction: row;
      height: 1;
      border: none;
      img {
        width: 64px;
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        height: 65px;
        // align-self: flex-end;
        display: block;
      }
      .left {
        height: 100%;
        display: flex;
        gap: 13px;
        align-items: center;
        width: fit-content;
      }
    }
  }
}
</style>