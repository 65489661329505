<template>
  <head>
    <link
      rel="stylesheet"
      href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
    />
  </head>

  <layout :hasTopNav="false">
    <div class="order-container px-[40px] flex-col gap-[40px]" v-if="!selectId">
      <top-nav :onSearch="search" />
      <div class="d-flex justify-content-between orderClass">
        <h1>
          <text :color="'red'" :size="24" :weight="400"> {{ message }} </text>
          <span class="capitalize">{{ selectId }}</span>
        </h1>
        <div>
          <!-- <div>
            <button
              class="button"
              :class="{ active: selected === 'active' }"
              @click="select('active')"
            >
              Active Orders
            </button>
            <button
              class="button"
              :class="{ active: selected === 'incoming' }"
              @click="select('incoming')"
            >
              Incoming
            </button>
            <button
              class="button"
              :class="{ active: selected === 'completed' }"
              @click="select('completed')"
            >
              Completed Orders
            </button>

          </div> -->

          <status-button
            :buttons="[
              { message: 'Active Orders', key: 'active',  
              // tab_bracket: store.state?.order_tabs?.assemble.length 
            },
              { message: 'Incoming Orders', key: 'incoming', 
              //  tab_bracket: store.state?.order_tabs?.unconfirmedOrders.length 
              },
              { message: 'Unconfirmed Orders', key: 'unconfirmed',  
              // tab_bracket: store.state?.unconfirmedOrders.length
             },
              { message: 'Cancelled Orders', key: 'cancelled' },
              { message: 'Completed Orders', key: 'completed' },
            ]"
            :on-change="
              (data) => {
                selected = data.key;
                message = data.message;
              }
            "
          />

          <div class="d-flex justify-end mt-5">
            <time-left-dropdown
              v-if="selected == 'active' || selected == 'incoming'"
              :getTime="(time) => (selectedTime = time)"
            />
          </div>
        </div>
      </div>

      <section class="orderList">
        <div>
          <div
            v-if="
              selected == 'active' ||
              selected == 'completed' ||
              selected == 'incoming'
            "
          >
            <table class="order-table">
              <thead>
                <tr>
                  <th class="table-header"></th>
                  <th class="table-header">Order ID</th>
                  <th class="table-header">Date</th>
                  <th class="table-header">Customer Name</th>
                  <th class="table-header">Service</th>
                  <th class="table-header">Courier</th>
                  <th class="table-header">Status</th>
                  <th class="table-header">
                    {{ selected === "completed" ? "Time Spent" : "Time Left" }}
                  </th>
                  <th class="table-header">Action</th>
                  <th class="table-header">Print</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(order, index) in paginatedOrders"
                  :key="order.id"
                  class="table-row"
                  :class="index % 2 === 0 ? 'even-row' : 'odd-row'"
                >
                  <td class="table-data">
                    {{ index + 1 + 15 * (currentPage - 1) }}
                  </td>
                  <td class="table-data">{{ getOrderID(order) }}</td>
                  <td class="table-data">{{ formatDate(order?.date) }}</td>
                  <td class="table-data">
                    {{
                      `${order?.customer?.firstName} ${order?.customer?.lastName}`
                    }}
                  </td>
                  <td class="table-data">{{ order.locationType }}</td>
                  <td
                    v-if="order?.locationType?.toLowerCase() === 'delivery'"
                    class="table-data"
                  >
                    {{ order?.courier?.name || "Unassigned" }}
                  </td>
                  <td v-else class="table-data">N/A</td>

                  <td class="table-data">
                    <status
                      :items="order?.items"
                      :status="order.status"
                      :order="order"
                      :optimize="selected == 'completed'"
                    />
                  </td>
                  <td class="table-data">
                    <!-- {{ getAssembledTime(order) }} -->

                    <time-left
                      :order="order"
                      :count-down="15"
                      type="assembler"
                    />
                  </td>
                  <td class="table-data">
                    <router-link
                      :to="`/assembler/orders/${order?.id}`"
                      class="action-link !underline"
                      >View</router-link
                    >
                  </td>

                  <td class="table-data cursor-pointer">
                    <Receipt :order="order">
                      <span class="underline">{{
                        order?.printed ? "Printed" : "Print"
                      }}</span>
                    </Receipt>
                  </td>
                </tr>
              </tbody>
            </table>
            <Pagination
              :currentPage="currentPage"
              :totalPages="totalPages"
              @page-change="goToPage"
            />
          </div>
          <div>
            <unconfirmed-orders v-if="selected == 'unconfirmed'" />
            <cancelled-orders v-if="selected == 'cancelled'" />
          </div>
        </div>
      </section>
    </div>
    <div v-else>loading...</div>
  </layout>
</template>
        
      <script>
import { ref, computed, watchEffect } from "vue";
import Pagination from "@/components/Pagination.vue";
// import OrderModal from "./SingleOrder.vue";
import TopNav from "@/components/TopNav.vue";
import {
  formatDate,
  sum,
  getUeOrderId,
  getAssembledTime,
  getOrderID,
} from "@/util";
import Modal from "@/components/Modal.vue";
import { useRoute, useRouter } from "vue-router";
import countdown from "@/partials/countdown.vue";
import Text from "@/components/Text.vue";
import Status from "@/components/Status.vue";
import InputField from "@/components/InputField.vue";
import layout from "@/partials/Layout/layout.vue";
import { store } from "@/views/store/store";
import TimeLeftDropdown from "@/components/TimeLeftDropdown.vue";
import StatusButton from "@/components/StatusButton.vue";
import Receipt from "@/components/Receipt.vue";
import UnconfirmedOrders from "@/partials/UnconfirmedOrders.vue";
import CancelledOrders from "@/partials/CancelledOrders.vue";
import { searchOrder } from "@/util/filterOrder";
import TimeLeft from "@/components/TimeLeft.vue";

export default {
  components: {
    TopNav,
    Pagination,
    // OrderModal,
    Modal,
    countdown,
    layout,
    Text,
    Status,
    InputField,
    TimeLeftDropdown,
    Receipt,
    StatusButton,
    UnconfirmedOrders,
    CancelledOrders,
    TimeLeft,
  },

  setup() {
    // watchEffect(()=>{  store.dispatch('updateOrders')  })
    const orders = ref(store.state.orders);
    const selected = ref("active");
    const searchValue = ref("");
    const message = ref("Active Orders");
    // boolean controlling the order modal
    const modal = ref(false);
    //selectedModal
    const selectedOrder = ref({});
    const route = useRoute();
    const router = useRouter();
    const selectId = ref(route.params.id);
    const selectedTime = ref("");

    watchEffect(() => {
      selectId.value = route.params.id;
    });

    watchEffect(() => {
      if (selectId.value)
        selectedOrder.value = store.state.orders.find(
          (x) => x.id === selectId.value
        );
    });
    const select = (type) => {
      selected.value = type;
      message.value =
        type === "active"
          ? "Active Orders"
          : type === "incoming"
          ? "Incoming Orders"
          : "Completed Orders";
    };
    const search = (e) => {
      searchValue.value = e;
    };

    watchEffect(async () => {
      let filterByStatus = store.state.orders.filter((x) => {
        return selected.value === "active"
          ? x.items.length !== x.items.filter((x) => x.assembled).length &&
              sum(x.items.map((x) => x.prepared)) ===
                sum(x.items.map((x) => x.quantity)) -
                  sum(x.items.map((x) => (x.cancelled || x.canceled || 0)))
          : selected.value === "incoming"
          ? sum(x.items.map((x) => x.prepared)) <
            sum(x.items.map((x) => x.quantity)) -
              sum(x.items.map((x) => (x.cancelled || x.canceled || 0)))
          : x.items.length === x.items.filter((x) => x.assembled).length;
      });
      let timeFilter = selectedTime.value
        ? filterByStatus.filter(
            (x) => parseInt(x.preparation_time) == selectedTime.value
          )
        : filterByStatus;
      if (searchValue.value) {
        // orders.value = searchOrder(store.state.orders, searchValue.value);
        // orders.value = timeFilter.filter(
        //   (x) =>
        //     x.id?.toLowerCase().includes(searchValue.value?.toLowerCase()) ||
        //     x.customer?.name
        //       ?.toLowerCase()
        //       ?.includes(searchValue.value.toLowerCase())
        // );

        const res = await searchOrder(store.state.orders, searchValue.value);
        orders.value = res;
      } else {
        orders.value = timeFilter;
      }
    });

    // Pagination
    const ordersPerPage = 15;
    const currentPage = ref(1);
    const totalPages = computed(() =>
      Math.ceil(orders.value.length / ordersPerPage)
    );

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };
    watchEffect(() => {
      searchValue.value;
      selected.value
      currentPage.value = 1;
    });

    const paginatedOrders = computed(() => {
      const startIndex = (currentPage.value - 1) * ordersPerPage;
      const endIndex = startIndex + ordersPerPage;
      return orders.value.slice(startIndex, endIndex);
    });

    return {
      ordersPerPage,
      currentPage,
      totalPages,
      goToPage,
      paginatedOrders,
      select,
      selected,
      message,
      search,
      modal,
      selectedOrder,
      selectId,
      route,
      router,
      formatDate,
      getUeOrderId,
      selectedTime,
      getAssembledTime,
      getOrderID,store
    };
  },
};
</script>
        
        <style scoped>
.order-container {
  position: relative;
  background-color: #f5f5f5;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.orderClass {
  /* margin: 20px 30px 20px 40px; */
}
/* active and completed button */
.button {
  padding: 10px 30px;
  border-radius: 5px;
  border: 1px #f60100 solid;
  background-color: #fff;
  color: #f60100;
  margin-left: 20px;
}
.button.active {
  background: linear-gradient(180deg, #ff9103 0%, #f60100 100%);
  color: white;
  border: none;
}

.pagination-container {
  display: flex;
  column-gap: 10px;
}
.paginate-buttons {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(217, 217, 217);
  color: black;
}
.paginate-buttons:hover {
  background-color: #d8d8d8;
}
.active-page {
  background-color: #3498db;
  border: 1px solid #3498db;
  color: white;
}
.active-page:hover {
  background-color: #2988c8;
}
.orderList {
  /* margin: 20px; */
  border-radius: 20px;

  font-size: 12px;
  color: black;
}
.order-table {
  width: 100%;
  border-collapse: collapse;
  /* border: 1px solid #ccc; */
}

.table-row {
  height: 60px;
}

.table-row.even-row {
  background-color: #f2f2f2;
}

.table-row.odd-row {
  background-color: #fff;
}
.status-button {
  padding: 5px 10px;
  background: linear-gradient(
    202deg,
    rgba(255, 145, 3, 0.24) 0%,
    rgba(246, 1, 0, 0.24) 100%
  );

  border: none;
  color: #333;
  font-weight: bold;
}

.status-button2 {
  padding: 5px 10px;
  background: linear-gradient(
    202deg,
    rgba(74, 224, 117, 0.428) 0%,
    rgba(0, 246, 78, 0.24) 100%
  );

  border: none;
  color: #333;
  font-weight: bold;
}

.action-link {
  text-decoration: none;
  color: #f60100;
}
</style>