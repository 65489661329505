<template>
  <div :class="styles.cards">
    <div :class="styles.card" v-for="item in items" :key="item.head">
      <div :class="styles.imgcombine">
        <img :src="icons.home.ellipse" alt="" />
        <!-- <div style="background-image: url()"></div> -->
        <img
          :src="item.img"
          alt=""
          :style="{
            top: item.top + 'px',
            width: item.size + 'px',
          }"
        />
      </div>
      <!-- <div v-else :class="styles.imgcombine">
  
        <img :src="item.img" alt="" />
      </div> -->

      <Text :weight="700" class="!text-center cardHeaderText">
        {{ item.head }}
      </Text>
      <Text :weight="500" class="!text-center cardContentText">
        {{ item.text }}
      </Text>
    </div>
  </div>
</template>

<script setup>
import Text from "@/components/Text.vue";
import styles from "./home.module.scss";
import { icons } from "@/assets/icons";
const items = [
  {
    size: 200,
    top: 5,
    img: icons.home.burger,
    head: "Multiple restaurants ONE order!",
    text: "Place an order from multiple restaurants and receive them in a single delivery.",
  },
  {
    size: 200,
    top: 5,
    layered: true,
    img: icons.home.sally,
    head: "Live order tracking",
    text: "Satisfy your cravings with lightening delivery powered by our real-time delivery system. ",
  },
  {
    size: 300,
    top: 20,
    img: icons.home.robot,
    head: "Earn points on every order",
    text: "Redeem delicious food with your points. Every ₦10 spent = 1 MealBot Point",
  },
];
</script>

<style lang="scss" scoped>
.cardHeaderText {
  font-size: 30px;
  @media screen and (max-width: 500px) {
    font-size: 25px;
  }
}

.cardContentText {
  font-size: 20px;
  @media screen and (max-width: 500px) {
    font-size: 16px;
  }
}
</style>