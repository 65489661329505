<template>
  <div class="w-full h-full order_summary">
    <nav>
      <div class="left">
        <Text :weight="600" :size="20">Order Status</Text>
      </div>
      <time-drop-down :getDate="(res) => (timeframe = res)" />
    </nav>
    <!-- <header>
      <div>
        <div class="number">25</div>
        <Text :weight="600" :size="15">New Orders</Text>
      </div>
      <div>
        <Text
          :weight="600"
          :size="14"
          :color="'#F60100'"
          class=" "
          >Manage Orders</Text
        >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="11"
          viewBox="0 0 10 11"
          fill="none"
        >
          <path
            d="M3.71094 8.8832L6.4276 6.16654C6.74844 5.8457 6.74844 5.3207 6.4276 4.99987L3.71094 2.2832"
            stroke="black"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </header> -->
    <main>
      <section>
        <div>
          <Text :weight="600" :size="16">{{ activeOrders }}</Text
          ><Text :size="10" :weight="500">Active Orders</Text>
        </div>
        <div>
          <Text :weight="600" :size="16">{{ completeOrders }}</Text
          ><Text :size="10" :weight="500">Completed Orders</Text>
        </div>
        <div>
          <Text :weight="600" :size="16">{{ cancelledMeals }}</Text
          ><Text :size="10" :weight="500">Cancelled Meals</Text>
        </div>
      </section>
      <section>
        <dought-nut :numbers="numbers" />
      </section>
    </main>
  </div>
</template>

<script>
import Text from "@/components/Text.vue";

import DoughtNut from "./DoughtNut.vue";
import InputField from "@/components/InputField.vue";
import { ref, watchEffect } from "vue";
import TimeDropDown from "./TimeDropDown.vue";
import { getOrdersByTimeFrame } from "@/util";

export default {
  components: { Text, DoughtNut, InputField, TimeDropDown },
  setup() {
    // const time = ref({ label: "Today", value: "today" });
    const timeframe = ref({});
    const numbers = ref({});
    const allOrders = ref(0);
    const activeOrders = ref(0);
    const completeOrders = ref(0);
    const cancelledMeals = ref(0);
    const confirmedOrders = ref(0);
    const preparedOrders = ref(0);
    const assembledOrders = ref(0);
    const dispatchedOrders = ref(0);
    const pickedupOrders = ref(0);
    const deliveredOrders = ref(0);

    watchEffect(async() => {
      const ordersInTimeFrame = await getOrdersByTimeFrame(
        timeframe.value.start,
        timeframe.value.end
      );
      allOrders.value = ordersInTimeFrame.length;
      confirmedOrders.value = ordersInTimeFrame.filter(
        (x) => !x?.status.Prepared.state
      ).length;

      activeOrders.value = ordersInTimeFrame.filter((x) => {
        if (x.locationType === "Delivery") {
          return !x.status.Delivered.state;
        } else {
          return !x.status.Pickup.state;
        }
      }).length;

      completeOrders.value = ordersInTimeFrame.filter((x) => {
        if (x.locationType === "Delivery") {
          return x.status.Delivered.state;
        } else {
          return x.status.Pickup.state;
        }
      }).length;

      let cancelled = ordersInTimeFrame
        .map((x) => x.items.map((y) => y.canceled))
        .flat(1);
      if (cancelled.length) {
        cancelledMeals.value = cancelled.reduce((a, b) => a + b);
      }

      preparedOrders.value = ordersInTimeFrame.filter(
        (x) => x.status.Prepared.state && !x.status.Assembled?.state
      ).length;

      assembledOrders.value = ordersInTimeFrame.filter((x) => {
        if (x.locationType === "Delivery") {
          return x.status.Assembled?.state && !x.status.Dispatched?.state;
        } else {
          return x.status.Assembled?.state && !x.status.Pickup?.state;
        }
      }).length;

      dispatchedOrders.value = ordersInTimeFrame.filter((x) => {
        if (x.locationType === "Delivery") {
          return x.status.Dispatched?.state && !x.status.Delivered?.state;
        }
      }).length;

      deliveredOrders.value = ordersInTimeFrame.filter((x) => {
        if (x.locationType === "Delivery") {
          return x.status.Delivered?.state;
        }
      }).length;

      pickedupOrders.value = ordersInTimeFrame.filter((x) => {
        if (x.locationType !== "Delivery") {
          return x.status.Pickup?.state;
        }
      }).length;

      numbers.value = {
        allOrders,
        confirmedOrders,
        preparedOrders,
        assembledOrders,
        dispatchedOrders,
        deliveredOrders,
        pickedupOrders,
      };
    });

    return {
      // times,
      timeframe,
      activeOrders,
      completeOrders,
      cancelledMeals,
      numbers,
      allOrders,
    };
  },
};
</script>

<style lang="scss" >
.order_summary {
  font-family: Work Sans;
  height: 100%;
  padding: 33px 35px;
  width: 100%;
  display: flex;
  gap: 24px;
  justify-content: space-between;
  flex-direction: column;

  nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  header {
    border-radius: 8px;
    background: rgba(0, 154, 73, 0.23);
    width: 100%;
    height: 48px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    padding: 0 15px;
    justify-content: space-between;
    & > div {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 18px;
      height: 100%;
      .number {
        border-radius: 5px;
        background: #009a49;
        color: white;
        display: flex;
        padding: 6px 27px;
        align-items: flex-start;
      }
    }
  }
  main {
    display: flex;
    flex-direction: column;
    height: 100%;

    // justify-content: space-evenly;
    section {
      &:nth-child(1) {
        // height: 100px;
        margin: 12px 0;
        align-items: center;
        display: flex;
        justify-content: space-between;
        & > div {
          background: #fff;
          box-shadow: 6px 6px 16px 0px rgba(0, 0, 0, 0.03),
            -6px -6px 16px 0px rgba(52, 47, 47, 0.03);
          height: 65px;
          display: flex;
          flex-direction: column;
          width: 30%;
          gap: 0px !important;
          justify-content: center !important;
          padding: 12px;
        }
      }
      &:nth-child(2) {
        display: flex;
        margin-top: 10px;
        // align-items: center;
        // background: red;
        height: 100%;
      }
    }
  }
}
</style>