<template>
  <head>
    <link
      rel="stylesheet"
      href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
    />
  </head>

  <div class="sidebar">
    <img :src="icons.logo" class="logo" alt="" />

    <nav class="side-bar-nav">
      <div class="permissions">
        <!-- <Text :weight="600">Your Dashboards</Text> -->
        <div
          v-for="dashboard in dashboards.filter(
            (dashboard) => dashboard.permitted
          )"
          :key="dashboard.key"
        >
          <Text
            @click="() => router.push(getFirstPermittedPath(dashboard.name))"
            :weight="600"
            :size="18"
            class="py-1 hover cursor-pointer"
          >
            {{ dashboard.name }}  
          </Text>
          <ul>
            <router-link
              v-for="item in sidebar.filter(
                (bar) => bar.parent === dashboard.name.toLowerCase()
              )"
              :key="item?.name"
              :to="item.path"
            >
              <li
                class="side-bar-nav-link !flex"
                :class="route.path === item.path ? 'active' : ''"
              >
                <span class="material-symbols-outlined sidebar-icon">
                  {{ item.icon }}
                </span>
                <p class="capitalize">{{ item.name }}</p>
              </li>
            </router-link>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>
      
    <script>
import { ref, watchEffect } from "vue";
// import {kitch}
import { useRoute, useRouter } from "vue-router";
import { icons } from "@/assets/icons";
import Text from "./Text.vue";
import { store } from "@/views/store/store";
import { dashboards } from "@/dashboards";
import {
  assembler_routes,
  kitchen_routes,
  logistics_routes,
  operator_routes,
  inventory_routes,
  routes,
} from "@/router";
export default {
  setup() {
    const route = useRoute();
    // const store = inject("store");
    const current = ref(route.path);
    const router = useRouter();
    const permissions = Object.values(store.state.user.permissions);
    const sidebar = ref();
    const getRouteInSidebar = (route) => route.sidebar;
    const removePreceedingName = (route, remove) => ({
      ...route,
      name: route.name.toLowerCase().replaceAll(remove, ""),
    });
    const getSidebarWithPermission = (route) =>
      permissions?.includes(route.name);
    watchEffect(() => {
      store.state.user.permissions;
      current.value = route.path.toLowerCase();
      if (current.value.includes("kitchen")) {
        sidebar.value = kitchen_routes
          .filter(getSidebarWithPermission)
          .filter(getRouteInSidebar)
          .map((x) => removePreceedingName(x, "kitchen"));
      } else if (current.value.includes("logistics")) {
        sidebar.value = logistics_routes
          .filter(getSidebarWithPermission)
          .filter(getRouteInSidebar)
          .map((x) => removePreceedingName(x, "logistics"));
      } else if (current.value.includes("assembler")) {
        sidebar.value = assembler_routes
          .filter(getSidebarWithPermission)
          .filter(getRouteInSidebar)
          .map((x) => removePreceedingName(x, "assembler"));
      } else if (current.value.includes("operator")) {
        sidebar.value = operator_routes
          .filter(getSidebarWithPermission)
          .filter(getRouteInSidebar)
          .map((x) => removePreceedingName(x, "operator"));
      } else if (current.value.includes("inventory")) {
        sidebar.value = inventory_routes
          .filter(getSidebarWithPermission)
          .filter(getRouteInSidebar)
          .map((x) => removePreceedingName(x, "inventory"));
      }
    });

    const getFirstPermittedPath = (key) => {
      const dashboardPermittedPages = permissions?.filter((permission) =>
        permission?.includes(key)
      );
      const dashboardPermittedRoutes = routes.filter((route) =>
        route.name.includes(key)
      );

      let firstPermittedPath = "";
      for (let i = 0; i < dashboardPermittedRoutes.length; i++) {
        const route = dashboardPermittedRoutes[i];
        const first = dashboardPermittedPages.find(
          (page) => page === route.name
        );
        if (first) {
          console.log(route,first)
          firstPermittedPath = route.path
          break
        };
    
      }
      return firstPermittedPath
    };

    return {
      sidebar,
      icons,
      route,
      dashboards,
      router,
      permissions,
      getFirstPermittedPath,
    };
  },
  components: { Text },
};
</script>
      
      <style scoped lang='scss'>
.sidebar {
  width: 100%;
  background-color: #fff;
  padding: 0;
  gap: 0;
  height: 100%;
  overflow-y: auto;
  .logo {
    padding: 20px 0;
    padding-left: 40px;
  }
  ul {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 30px;
    li {
      display: flex;
      // width: 256px;
      // border: 1px solid red !important;
      padding: 9px 0 11px 40px;
      align-items: center;
      gap: 16px;
      color: #000 !important;
      cursor: pointer;
      /* Desktop/P1 */
      font-family: Kanit;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 155.556% */
      img {
        height: 24px;
        width: 24px;
      }
    }
  }
}
.side-bar ul {
  width: 100%;
}
nav {
  background-color: #fff;
  /* margin-left: -30px; */
}
.side-bar-logo {
  display: block;
  padding: 20px;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: #fff;
  text-decoration: none;
}

.side-bar-nav-link:hover,
.active {
  background: linear-gradient(
    90deg,
    rgba(255, 145, 3, 0.3) 0%,
    rgba(246, 1, 0, 0.3) 40.63%,
    rgba(246, 1, 0, 0) 100%
  );
}

.side-bar-nav-link.active {
  font-weight: 700;
  background: linear-gradient(
    90deg,
    rgba(255, 145, 3, 0.3) 0%,
    rgba(246, 1, 0, 0.3) 40.63%,
    rgba(246, 1, 0, 0) 100%
  );
}

.permissions {
  margin-top: 40px;
  padding-left: 40px;
}
@media screen and (max-width: 1030px) {
  .side-bar {
    display: none;
    width: 0%;
  }
}
</style>