
<template>
  <head>
    <link
      rel="stylesheet"
      href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
    />
  </head>

  <router-view :key="route.params?.id">
    <Layout :hasTopNav="false">
      <div class="bg-light h-[100%] ue-container">
        <Modal>
          <div v-if="modal == 'cancel-item'">
            <MealModal
              title="Cancel Meal Order?"
              :content="'Are you certain that you want to cancel this meal order?'"
              :rightClick="() => setCanledced()"
              firstBtn="No, Cancel"
              secondBtn="YES, Confirm"
              :index="cancelid"
              :closeBtn="true"
              :tickBtn="false"
            />
          </div>
          <div v-else-if="modal == 'complete-meal'">
            <MealModal
              :title="'All Meals Completed?'"
              :content="'Are you sure all meals has been prepared?'"
              :firstBtn="'No, Cancel'"
              :secondBtn="'YES, Confirm'"
              :closeBtn="false"
              :tickBtn="true"
              :rightClick="completeAll"
            />
          </div>
        </Modal>

        <top-nav
          :searchBar="false"
          :arrowback="true"
          :PrevNextIcon="true"
          :PrevNextList="prevnextlist"
        />
        <div class="d-flex justify-content-between items-center">
          <h2>Order ID {{ route.params?.id }}</h2>

          <CustomerTag
            :uid="selectedOrder?.customer?.id"
            :order="selectedOrder"
          />
        </div>

        <!-- For the process flow -->
        <div
          class="flex w-100 text-right"
          v-if="
            selectedOrder.locationType == 'Pickup' &&
            !selectedOrder?.status?.Pickup?.state
          "
        >
          <Text class="cursor-pointer" :weight="600" :size="17">
            <switch-to-delivery :order="selectedOrder">
              Switch to delivery
            </switch-to-delivery>
          </Text>
        </div>
        <progression-bar :order="selectedOrder" />

        <!-- Order Details -->

        <order-details :order="selectedOrder" />

        <items-table :type="'operator'" />
        <courier-deets :selectedOrder="selectedOrder" />
        <!-- <span v-if=" selectedOrder?.promoUsed?.code">  {{ selectedOrder?.promoUsed?.code }}</span> -->
        <Timeline :selectedOrder="selectedOrder" />

        <!-- <refund :order="selectedOrder" :refundAmount="selectedOrder.total_price" > -->
        <refund
          :order="selectedOrder"
          :refundAmount="selectedOrder.total_price"
          :refundType="'wallet-reversal'"
          v-if="cancelHidden"
          :onSuccess="
            async () => {
              try {
                removeCancelBtn = true;
                await cancelOrder(selectedOrder);
                removeCancelBtn = false;
                router.push('/operator/orders/');
              } catch (error) {
                removeCancelBtn = false;
              }
            }
          "
        >
          <div
            v-if="!removeCancelBtn"
            class="flex gap-2 bg-danger cursor-pointer py-3 w-100 justify-center items-center my-3 rounded text-white items-center"
          >
            Cancel order and refund wallet
          </div>
        </refund>

        <Map
          :order="selectedOrder"
          v-if="
            selectedOrder.locationType == 'Delivery' &&
            selectedOrder.status.Confirmed.state
          "
        />
      </div>
    </Layout>
  </router-view>
</template>
     <script scoped>
import { ref, computed, watchEffect } from "vue";
import Pagination from "@/components/Pagination.vue";
import OrderDetails from "@/components/OrderDetails.vue";
import TopNav from "@/components/TopNav.vue";
import Modal from "@/components/Modal.vue";
import { store } from "@/views/store/store";
import MealModal from "@/views/main/Kitchen/Meals/MealModal.vue";
import { useRoute, useRouter } from "vue-router";
import dayjs from "dayjs";
import QuantityCounter from "@/components/QuantityCounter.vue";
// import styles from "./styles.module.scss";
import Countdown from "@/partials/countdown.vue";
import ProgressionBar from "@/components/ProgressionBar.vue";
import Text from "@/components/Text.vue";
import ItemMeal from "@/components/ItemMeal.vue";
import {
  checkRepeatedTimeline,
  diffByStatus,
  formatDate,
  getUeOrderId,
  sum,
} from "@/util";
import InputField from "@/components/InputField.vue";
import SelectCourier from "./SelectCourier.vue";
import { onValue, ref as dbRef, getDatabase, set } from "firebase/database";
import ItemsTable from "@/partials/ItemsTable.vue";
import CourierDeets from "@/partials/CourierDeets.vue";
import Layout from "@/partials/Layout/layout.vue";
import Map from "../../Logistics/Orders/Map.vue";
import Timeline from "@/components/Timeline.vue";
import Receipt from "@/components/Receipt.vue";
import Refund from "../Cancelled/Refund.vue";
import { cancelOrder } from "./cancelOrder";
import CustomerTag from "@/components/CustomerTag.vue";

import SwitchToDelivery from "./SwitchToDelivery.vue";
import { extra_permissions_keys } from "../Accounts/extra_permissions";

export default {
  props: {
    selectedOrder: Object,
    modal: Boolean,
  },
  components: {
    TopNav,
    Pagination,
    Modal,
    MealModal,
    QuantityCounter,
    Countdown,
    ProgressionBar,
    Text,
    ItemMeal,
    InputField,
    SelectCourier,
    ItemsTable,
    CourierDeets,
    Layout,
    Map,
    Timeline,
    Receipt,
    OrderDetails,
    Refund,
    CustomerTag,
    SwitchToDelivery,
  },
  setup(props) {
    const selectedOrder = ref({});
    const selected = ref("active");
    const message = ref("Active Orders");
    const status = ref({});
    const items = ref([]);
    const modal = ref(store.state.modal.data);
    const router = useRouter();
    const prevnextlist = ref([]);
    const { id } = useRoute().params;
    const route = useRoute();
    const removeCancelBtn = ref(false);

    // const changes = ref([...props.selectedOrder.items]);
    watchEffect(() => {
      modal.value = store.state.modal.data;
    });
    const select = (type) => {
      selected.value = type;
      message.value = type === "active" ? "Active Orders" : "Completed Orders";
    };
    const fetchData = (id) => {
      onValue(
        dbRef(getDatabase(), "orders/" + route.params?.id),
        (snapshot) => {
          if (snapshot.val()) {
            selectedOrder.value = snapshot.val();
            items.value = selectedOrder.value.items;
            status.value = selectedOrder.value.status;
          }
        }
      );
    };
    router.afterEach((to, from) => {
      if (to.params?.id) fetchData(to.params?.id);
    });
    watchEffect(() => {
      const extra_keys = ['customize', 'addons', 'sides']
      const allItems = items.value.flatMap((item) => {
        
        const others = extra_keys.filter(key => item?.[key]).flatMap(key => item?.[key])?.map(extra => ({ ...extra, prepared: item.prepared }))
        return [...others, item];
      });
      // console.log({allItems});
      fetchData(id);
      // console.log(checkRepeatedTimeline(selectedOrder.value));
    });

    // clg

    watchEffect(() => {
      //create list of id's for the prevnext list operator

      //determine if its active or completed
      prevnextlist.value = store.state.orders.filter((x) => {
        let type = Boolean(
          selectedOrder.value?.status?.Delivered?.state ||
            selectedOrder.value?.status?.Pickup?.state
        );
        let res = x?.status?.Delivered?.state || x?.status?.Pickup?.state;
        return Boolean(res) == type;
      });
    });

    const cancelHidden = computed(() => {
      const cancel_order_after_dispatch =
        store.state.user?.extra_permissions?.includes(
          extra_permissions_keys.cancel_order_after_dispatch
        );

      if (selectedOrder.value?.locationType == "Delivery") {
        if (cancel_order_after_dispatch)
          return !selectedOrder.value.status?.Delivered?.state;
        return !selectedOrder.value.status?.Dispatched?.state;
      }
      if (selectedOrder.value?.locationType != "Delivery") {
        return !selectedOrder.value.status?.Pickup?.state;
      }
    });

    // Pagination
    const ordersPerPage = 5;
    const currentPage = ref(1);
    const totalPages = computed(() =>
      Math.ceil(orders.value.length / ordersPerPage)
    );

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };

    const paginatedOrders = computed(() => {
      const startIndex = (currentPage.value - 1) * ordersPerPage;
      const endIndex = startIndex + ordersPerPage;
      return orders.value.slice(startIndex, endIndex);
    });

    return {
      ordersPerPage,
      currentPage,
      totalPages,
      goToPage,
      paginatedOrders,
      select,
      selected,
      message,
      props,
      prevnextlist,
      items,
      modal,
      status,
      sum,
      router,
      route,
      selectedOrder,
      formatDate,
      getUeOrderId,
      diffByStatus,
      cancelOrder,
      removeCancelBtn,
      cancelHidden,
    };
  },
};
</script>
   
   <style scr="./style.scss">
.hide-search-on-top-nav {
  display: none;
}
.state {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 85px;
  height: 85px;
  border: 3px solid #ff9103;
  border-radius: 85px;
  color: #ff9103;
}
.state-active {
  background-color: #ff9103;
  color: white;
}
.order-container {
  /* margin-left:150px; */
  height: 100vh;
  position: relative;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  /* padding: 12px; */
}

/* active and completed button */
.button {
  padding: 10px 30px;
  border-radius: 5px;
  border: 1px #f60100 solid;
  background-color: #fff;
  color: #f60100;
  margin-left: 20px;
  justify-self: center;
}
.button.active {
  background: linear-gradient(180deg, #ff9103 0%, #f60100 100%);
  color: white;
  border: none;
}
.kitchenBtn {
  color: black;
  padding: 8px 20px;
  border-radius: 5px;
  border-radius: rgb(87, 85, 85) 1px solid;
  margin-right: 8px;
}
.pagination-container {
  display: flex;
  column-gap: 10px;
}
.paginate-buttons {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(217, 217, 217);
  color: black;
}
.paginate-buttons:hover {
  background-color: #d8d8d8;
}
.active-page {
  background-color: #3498db;
  border: 1px solid #3498db;
  color: white;
}
.active-page:hover {
  background-color: #2988c8;
}
.orderList {
  border-radius: 20px;
  /* padding: 3rem; */

  font-size: 12px;
  color: black;
}
.order-table {
  width: 100%;
  border-collapse: collapse;
  /* border: 1px solid #ccc; */
}

.table-header {
  background-color: white;
  text-align: left;
  padding: 10px;
  font-weight: bold;
  height: 70px;
}

.table-row {
  height: 60px;
}

.table-row.even-row {
  background-color: #f2f2f2;
}

.table-row.odd-row {
  background-color: #fff;
}

.table-data {
  padding: 10px;
}

.status-button {
  padding: 5px 10px;
  background: linear-gradient(
    202deg,
    rgba(255, 145, 3, 0.24) 0%,
    rgba(246, 1, 0, 0.24) 100%
  );

  border: none;
  color: #333;
  font-weight: bold;
}

.status-button2 {
  padding: 5px 10px;
  background: linear-gradient(
    202deg,
    rgba(74, 224, 117, 0.428) 0%,
    rgba(0, 246, 78, 0.24) 100%
  );

  border: none;
  color: #333;
  font-weight: bold;
}

.action-link {
  text-decoration: none;
  color: #f60100;
}

/* Solid border */
.row > * {
  padding-left: 0;
  padding-right: 0;
}
</style>
