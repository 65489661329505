<template>
  <div :class="styles.body">
    <div class="bg-[black]">
      <div class="nav">
        <!-- {{  }} -->
        <router-link to="/">
          <img :src="icons.home.whitelogo" class="logo" alt=""
        /></router-link>
      </div>
    </div>

    <div :class="`${props.contentType || 'blog'}-content`" class="py-5 flex gap-5 flex-col w-100">
      <slot />
    </div>

    <Footer />
  </div>
</template>
  
  <script setup>
import styles from "../home.module.scss";
import Footer from "../Footer.vue";
import NavBar from "../NavBar.vue";
import { icons } from "@/assets/icons";
const props = defineProps({
  width: { default: "70%", type: String },
  contentType : String
});
</script>
  
  <style lang="scss" scoped>

  .nav{
    height: fit-content;
    display: flex;
    align-items: center;

    .logo {
            width: 200px;
            // margin-top: 80px;
           

            @media screen and (max-width:700px) {
                width: 100px;
                width: 100px;
            }
        }
  }
.blog-content {
  padding: 0 350px;
  @media screen and (max-width: 992px) {
    padding: 0 150px;
  }
  @media screen and (max-width: 600px) {
    padding: 0 150px;
  }
  @media screen and (max-width: 500px) {
    padding: 0 30px;
  }
}
.container-content {
  padding: 0 100px;
  @media screen and (max-width: 992px) {
    padding: 0 50px;
  }
  @media screen and (max-width: 600px) {
    padding: 0 30px;
  }
  @media screen and (max-width: 500px) {
    padding: 0 30px;
  }
}
</style>