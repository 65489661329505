<template>
  <div>
    <button
      v-if="
        sum(items?.map((x) => x.prepared)) <=
          sum(items?.map((x) => x.quantity)) -
            sum(items?.map((x) => x.cancelled || x?.canceled || 0)) &&
        !(items.filter((x) => x.assembled).length === items.length)
      "
      class="status-button"
    >
      Prepared ({{ sum(items?.map((x) => x.prepared)) }}/{{
        sum(items?.map((x) => x.quantity)) - sum(items?.map((x) => x.cancelled || x?.canceled || 0))
      }})
    </button>

    <button
      v-else-if="
        items.filter((x) => x.assembled).length === items.length &&
        !(order.locationType === 'Delivery'
          ? status.Dispatched?.state
          : status.Pickup?.state)
      "
      class="status-button"
    >
      Assembled ({{
        sum(items?.map((x) => x.quantity)) -
        sum(items?.map((x) => x.cancelled || x?.canceled || 0)) -
        sum(items?.filter((x) => !x.assembled).map((x) => x.prepared))
      }}
      /
      {{
        sum(items?.map((x) => x.quantity)) - sum(items?.map((x) => x.cancelled || x?.canceled || 0))
      }})
    </button>
    <button
      v-else-if="
        items.filter((x) => x.assembled).length === items.length &&
        (order.locationType === 'Delivery'
          ? status.Dispatched.state && !status.Delivered.state
          : status.Pickup?.state)
      "
      :class="
        order.locationType === 'Delivery' ? 'status-button' : 'status-button2'
      "
    >
      {{ order.locationType === "Delivery" ? " Dispatched" : "Picked up" }}
    </button>
    <button
      v-else-if="
        items.filter((x) => x.assembled).length === items.length &&
        order.locationType === 'Delivery' &&
        status.Dispatched.state &&
        status.Delivered.state
      "
      class="status-button2"
    >
      Delivered 
    </button>
    <!-- {{ props.optimize }} -->
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";
import { sum } from "@/util";
import { getDatabase, ref as dbRef, get } from "firebase/database";
export default {
  props: {
    items: Array,
    status: Object,
    order: Object,
    optimize: Boolean,
  },
  setup(props) {
    const items = ref(props.items);
    const status = ref(props.status);
    const at = ref(null);
    watchEffect(() => {
      const fetcher = async () => {
        try {
          // const data = await get(
          //   dbRef(getDatabase(), "orders/" + props.order.id)
          // );
          const items_res = await get(
            dbRef(getDatabase(), `orders/${props.order.id}/items/`)
          );
          items.value = items_res.val();

          // status.value = data.val().status;
          // console.log(data.val(),props.order.id)
        } catch (error) {}
      };
      if (!props.optimize) fetcher();
    });

    watchEffect(async () => {
      if (!props.optimize) {
        const status_res = await get(
          dbRef(getDatabase(), `orders/${props.order.id}/status/`)
        );
        status.value = status_res.val();
      }
    });
    // const prepared = ref(props.status?.Prepared.state);
    // const assembled = ref(props.status?.Assembeled.state);
    // const dispatched = ref(props.status?.Dispatched.state);
    // const delivered = ref(props.status?.Delivered.state);
    // const pickedup = ref(props.status?.Pickup.state);

    // watchEffect(() => {
    //    prepared.value = props.status?.Prepared.state && sum(items?.map((x) => x.prepared)) !==
    //     sum(items.map((x) => x.quantity)) - sum(items.map((x) => x.cancelled || x?.canceled || 0));
    //    assembled.value = props.status?.Assembeled.state && items.filter((x) => x.assembled).length === items.length;
    //    dispatched.value = props.status?.Dispatched.state;
    //    delivered.value = props.status?.Delivered.state;
    //    pickedup.value = props.status?.Pickup.state;
    // });

    return {
      items,
      sum,
      status,
      order: props.order,props
      // pickedup,
      // prepared,
      // delivered,
      // dispatched,
      // assembled,
    };
  },
};
</script>

<style lang="scss" scoped>
.status-button {
  padding: 5px 10px;
  background: linear-gradient(
    202deg,
    rgba(255, 145, 3, 0.24) 0%,
    rgba(246, 1, 0, 0.24) 100%
  );

  border: none;
  color: #333;
  font-weight: bold;
  color: #f60100;
  text-align: center;
  font-family: Work Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.status-button2 {
  padding: 5px 10px;
  background: linear-gradient(
    202deg,
    rgba(74, 224, 117, 0.428) 0%,
    rgba(0, 246, 78, 0.24) 100%
  );

  border: none;
  color: #333;
  font-weight: bold;
}
</style>