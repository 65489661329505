<template>
  <head>
    <link
      rel="stylesheet"
      href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
    />
  </head>
  <router-view :key="route.path">
    <Layout :hasTopNav="false">
      <v-dialog v-model="cancelModal">
        <div class="bg-white rounded w-[fit-content] py-2 px-4 mx-auto">
          <MealModal
            :title="`Cancel Order`"
            :content="`Are you sure you want to cancel this unconfirmed order?`"
            :rightClick="() => setCancel(selectedOrder)"
            firstBtn="No, Cancel"
            secondBtn="YES, Confirm"
            :closeBtn="true"
            :tickBtn="false"
            :oncancel="() => (cancelModal = false)"
          />
        </div>
      </v-dialog>
      <div class="bg-light px-[40px]">
        <top-nav
          :searchBar="false"
          :arrowback="true"
          :arrowbackpath="`/${route.path.split('/')[1]}/orders`"
          :PrevNextIcon="true"
          :PrevNextList="prevnextlist"
        />
        <div class="d-flex justify-content-between">
          <h2>Order ID {{ route.params.id }}</h2>
          <!-- <td class="table-data cursor-pointer"> -->
          <!-- <Receipt :order="selectedOrder">
                <span class="underline cursor-pointer">{{
                  selectedOrder?.printed ? "Printed" : "Print"
                }}</span>
              </Receipt> -->
          <!-- </td> -->
        </div>

        <!-- For the process flow -->
        <!-- <progression-bar :order="selectedOrder" /> -->
        <br />
        <br />
        <order-details :order="selectedOrder" />
        <div class="flex gap-2">
          <confirm-payment
            :order="selectedOrder"
            :onSuccess="
              () => router.push(`/${route.path.split('/')[1]}/orders`)
            "
            class="w-50"
          >
            <div
              class="flex gap-2 bg-success cursor-pointer font-bold py-3 w-100 justify-center items-center my-3 rounded text-white items-center"
            >
              Confirm Payment
            </div>
          </confirm-payment>

          <!-- <Refund
            :order="selectedOrder"
            :refundAmount="selectedOrder.total_price"
            :refundType="'wallet-reversal'"
            class="w-50"
            :onSuccess="
              async () => {
                await cancelOrder(selectedOrder);
                router.push('/operator/orders/');
              }
            "
          > -->
          <div
            @click="() => (!cancel_loading ? (cancelModal = true) : null)"
            class="flex gap-2 bg-danger cursor-pointer py-3 w-50 justify-center items-center my-3 rounded text-white items-center"
          >
            {{ cancel_loading ? "Loading..." : " Cancel Order" }}
          </div>

          <!-- </Refund
            > -->
        </div>

        <!-- timer -->

        <items-table :type="'kitchen'" :key="selectedOrder.id" noaction />
        <Timeline :selectedOrder="selectedOrder" />

        <!-- <Timeline :selectedOrder="selectedOrder" /> -->
      </div>
    </Layout>
  </router-view>
</template>
       <script scoped>
import { ref, computed, watchEffect } from "vue";
import Pagination from "@/components/Pagination.vue";
import TopNav from "@/components/TopNav.vue";
import Modal from "@/components/Modal.vue";
import { store as vueStore } from "@/views/store/store";
import { useRoute, useRouter } from "vue-router";
import QuantityCounter from "@/components/QuantityCounter.vue";
//   import styles from "./styles.module.scss";
import Countdown from "@/partials/countdown.vue";
import ProgressionBar from "@/components/ProgressionBar.vue";
import Text from "@/components/Text.vue";
import ItemMeal from "@/components/ItemMeal.vue";
import { getUeOrderId, sum } from "@/util";
import { store } from "@/views/store/store";
import { ref as dbRef, getDatabase, onValue } from "firebase/database";
import ItemsTable from "@/partials/ItemsTable.vue";
import PrevNext from "@/components/PrevNext.vue";
import { onMounted } from "vue";
import Layout from "@/partials/Layout/layout.vue";
import Timeline from "@/components/Timeline.vue";
// import Receipt from "@/components/Receipt.vue";
import OrderDetails from "@/components/OrderDetails.vue";
import ConfirmPayment from "./ConfirmPayment.vue";
import Refund from "@/views/main/Operator/Cancelled/Refund.vue";
import { cancelOrder } from "@/views/main/Operator/Orders/cancelOrder";
import MealModal from "@/components/MealModal.vue";
import { useToast } from "vue-toast-notification";
import { walletRefund } from "@/views/main/Operator/Orders/refundWallet";
import { removeUserPendingTransaction } from "@/views/main/Operator/Orders/removeUserPendingTransaction";

export default {
  props: {
    selectedOrder: Object,
    modal: Boolean,
    orders: Array,
  },
  components: {
    TopNav,
    Pagination,
    Modal,
    MealModal,
    QuantityCounter,
    Countdown,
    ProgressionBar,
    Text,
    ItemMeal,
    ItemsTable,
    PrevNext,
    Layout,
    Timeline,
    OrderDetails,
    ConfirmPayment,
    Refund,
    Timeline,
  },
  setup(props) {
    const selected = ref("active");
    const message = ref("Active Orders");
    const status = ref({});
    const items = ref([]);
    const selectedOrder = ref({});
    const modal = ref(vueStore.state.modal.data);
    const router = useRouter();
    const { id } = useRoute().params;
    const prevnextlist = ref([]);
    const route = useRoute();
    const cancelModal = ref(false);
    const toast = useToast({ position: "top-right" });

    const cancel_loading = ref(false);

    router.afterEach((to, from) => {
      // console.log(to.params.id);
      onValue(dbRef(getDatabase(), "orders/" + to.params.id), (snapshot) => {
        if (snapshot.val()) {
          selectedOrder.value = snapshot.val();
          items.value = selectedOrder.value.items;
          status.value = selectedOrder.value.status;
        }
      });
    });
    watchEffect(() => {
      onValue(dbRef(getDatabase(), "orders/" + route.params.id), (snapshot) => {
        if (snapshot.val()) {
          // console.log(snapshot.val());
          selectedOrder.value = snapshot.val();
          items.value = selectedOrder.value.items;
          status.value = selectedOrder.value.status;
        }
      });
    });

    watchEffect(() => {
      modal.value = vueStore.state.modal.data;
    });

    const select = (type) => {
      selected.value = type;
      message.value = type === "active" ? "Active Orders" : "Completed Orders";
    };

    const setCancel = async (order) => {
      if (cancel_loading.value) {
        return;
      }
      try {
        cancelModal.value = false;
        cancel_loading.value = true;
        await cancelOrder(order);
        removeUserPendingTransaction({
          customer_uid: selectedOrder.value.customer.id,
          order: selectedOrder.value,
        });
        const paymentThatWentThrough =
          order?.total_price - (order?.amount_due || 0);
        console.log({ paymentThatWentThrough });
        if (order?.paymentMethod !== "card") {
          if (paymentThatWentThrough) {
            toast.info("Part payment was made");
            await walletRefund({ order, refundAmount: paymentThatWentThrough });
            toast.success("Part payment refunded");
          }
        } else {
          toast.info(
            "Please confirm if payment was received to refund customer!"
          );
        }

        cancel_loading.value = false;

        toast.success("Order cancelled successfully");

        router.push(`/${route.path.split("/")[1]}/orders`);
      } catch (error) {
        console.log({ error });
        cancel_loading.value = false;
      }
    };

    watchEffect(() => {
      //create list of id's for the prevnext list kitchen

      //determine if its active or completed
      prevnextlist.value = store.state.unconfirmedOrders;
      // .filter((x) => {
      //   let type = Boolean(selectedOrder.value?.status?.Prepared?.state);
      //   let res = Boolean(x?.status?.Prepared?.state);
      //   return res == type;
      // });
    });

    // Pagination
    const ordersPerPage = 5;
    const currentPage = ref(1);
    const totalPages = computed(() =>
      Math.ceil(orders.value.length / ordersPerPage)
    );

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };

    const paginatedOrders = computed(() => {
      const startIndex = (currentPage.value - 1) * ordersPerPage;
      const endIndex = startIndex + ordersPerPage;
      return orders.value.slice(startIndex, endIndex);
    });

    return {
      ordersPerPage,
      currentPage,
      totalPages,
      goToPage,
      paginatedOrders,
      select,
      selected,
      message,
      props,
      route,

      selectedOrder,
      items,
      modal,
      status,
      sum,
      router,
      getUeOrderId,
      id,
      prevnextlist,
      cancelOrder,
      cancelModal,
      setCancel,
      cancel_loading,
    };
  },
};
</script>
     
     