<template>
  <Line :data="data" :options="options" />
</template>

<script >
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "vue-chartjs";
import { ref, watchEffect } from "vue";
import { getOrdersByTimeFrame } from "@/util";
import dayjs from "dayjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export default {
  name: "App",
  components: {
    Line,
  },
  props: {
    timeframe: Object,
  },

  setup(props) {
    const yaxis = ref([]);
    const data = ref({
      labels: ["January", "February", "March", "April", "May", "June", "July"],
      datasets: [
        {
          label: "",
          fill: true,
          backgroundColor: (ctx) => {
            const canvas = ctx.chart.ctx;
            const gradient = canvas.createLinearGradient(255, 145, 3, 300);
            return gradient;
          },
          tension: 0.25,
          borderColor: "red",
          data: [...yaxis.value],
          fill: true,
        },
      ],
    });

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
    };

    watchEffect(async() => {
      const time = props.timeframe.time;
      const start = props.timeframe.start;
      const end = props.timeframe.end;
      const frame = props.timeframe.frame;
      const format = props.timeframe.format;
      const unit = ref("");
      const ordersInTimeFrame =await getOrdersByTimeFrame(start, end, unit.value);

      let list = [];
      frame?.map((x, i) => {
        let total_price_inthat_hour = ordersInTimeFrame
          .filter((y) => dayjs(y.date.replaceAll('"', "")).format(format) === x)
          .map((x) => x.total_price);
        if (total_price_inthat_hour.length) {
          list[i] = total_price_inthat_hour.reduce((a, b) => a + b);
        } else {
          list[i] = 0;
        }
      });

  
      data.value = { ...data.value, labels: frame };
      data.value = {
        ...data.value,
        datasets: [
          {
            label: "",
            fill: true,
            backgroundColor: (ctx) => {
              const canvas = ctx.chart.ctx;
              const gradient = canvas.createLinearGradient(255, 145, 3, 300);
              return gradient;
            },
            tension: 0.25,
            borderColor: "red",
            data: [...list],
            fill: true,
          },
        ],
      };
    });
    return { options, data };
  },
};
</script>
