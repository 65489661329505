<template>
  <Layout :hasTopNav="false" :key="date">
    <div class="bg-light ue-container" :key="date">
      <top-nav
        :searchBar="false"
        :arrowback="true"
        :PrevNextIcon="true"
        :PrevNextList="prevNextList"
        :PrevNextParamKey="'date'"
      />
      <div class="d-flex items-center justify-content-between">
        <h2>
          {{ dayjs(date).format("MMM DD, YYYY.") }}
        </h2>
      </div>
      <Line :data="data" :options="options" class="max-h-[500px]" :key="date" />
      <div class="mt-5 rounded w-100">
        <DownloadStatOrders :date="date" :orders="new_orders_list" />
      </div>
    </div>
  </Layout>
</template>
       <script setup>
import { ref, computed, watchEffect, onMounted } from "vue";
import TopNav from "@/components/TopNav.vue";
import { useRoute, useRouter } from "vue-router";
import Text from "@/components/Text.vue";
import { store } from "@/views/store/store";
import {
  ref as dbRef,
  endAt,
  get,
  getDatabase,
  orderByChild,
  query,
  startAt,
  limitToFirst,
  equalTo,
} from "firebase/database";

import Layout from "@/partials/Layout/layout.vue";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "vue-chartjs";

import { getDates, getOrdersByTimeFrame } from "@/util";
import dayjs from "dayjs";
import DownloadStatOrders from "./DownloadStatOrders.vue";

const stat = ref({});
const route = useRoute();
const router = useRouter();
const orders = ref([]);
const customer_first_order = ref({});
const new_customer_first_order = ref({});
const usersThisDay = ref([]);
const new_orders_list = ref({});
const date = ref(route.params.date);

watchEffect(async () => {
  date.value;
  const res = await get(dbRef(getDatabase(), `download_stats/${date.value}`));
  stat.value = res?.val();
});

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const data = ref({
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: "",
      fill: true,
      backgroundColor: (ctx) => {
        const canvas = ctx.chart.ctx;
        const gradient = canvas.createLinearGradient(255, 145, 3, 100);
        return gradient;
      },
      tension: 0.25,
      borderColor: "red",
      data: [],
      fill: true,
    },
  ],
});

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
};

const prevNextList = computed(() => {
  date.value;
  const list = [
    dayjs(date.value).subtract(1, "day").format("MMM-DD-YYYY"),
    date.value,
    dayjs(date.value).add(1, "day").format("MMM-DD-YYYY"),
  ];
  return list.map((li) => ({ id: li }));
});

router.afterEach((route) => {
  // console.log(route)
  date.value = route.params?.date;
  customer_first_order.value = {};
  new_customer_first_order.value = {}
  new_orders_list.value = {}
});

watchEffect(async () => {
  const start = new Date(date.value).setHours(0, 0, 0);
  const end = new Date(date.value).setHours(23, 59, 59);
  const format = "hh:mmA";
  let frame = getDates(start, end, "hours", format);
  const ordersInTimeFrame = await getOrdersByTimeFrame(start, end);
  // const createTimes = ref([]);

  const startVal = JSON.stringify(
    dayjs(start).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
  );
  const endVal = JSON.stringify(dayjs(end).format("YYYY-MM-DDTHH:mm:ss.SSSZ"));
  const res = await get(
    query(
      dbRef(getDatabase(), "users"),
      orderByChild("created"),
      startAt(startVal),
      endAt(endVal)
    )
  );
  const users = Object.values(res?.val() || {});
  usersThisDay.value = users;

  let list = [];
  const list2 = ref([]);
  const list3 = ref([]);
  const ddd = (date) => dayjs(date.replaceAll('"', ""));
  frame?.map((x, i) => {
    let usersThatCreatedInThatHour = 0;
    users.forEach((user) => {
      const created = ddd(user?.created).set("minute", 0).format(format);
      if (created === x) {
        usersThatCreatedInThatHour++;
        const history = user?.order_history;
        let orders = [];
        if (history) {
          if (history?.processing?.orders) orders = history?.processing?.orders;
          if (history?.completed?.orders)
            orders.push(...history?.completed?.orders);
        }
      }
    });
    if (usersThatCreatedInThatHour) {
      list[i] = usersThatCreatedInThatHour;
    } else {
      list[i] = 0;
    }
  });
  orders.value = ordersInTimeFrame;
  watchEffect(() => {
    date.value;
    // let list_3 = [];
    ordersInTimeFrame.map(async (order) => {
      const uid = order?.customer?.id;

      // const name = order?.customer?.firstName;
      //check if any order with this user id
      const res = await get(
        query(
          dbRef(getDatabase(), "orders"),
          orderByChild("customer/id"),
          equalTo(uid),
          limitToFirst(5) // no need to check all orders, the customer has to have placed only 1 order
        )
      );
      const user = usersThisDay.value.find((user) => user?.uid === uid) || {};
      const created = dayjs(user?.created?.replaceAll('"', ""));
      const yourOrders = Object.values(res.val() || {}).filter(
        (order) =>
          !order?.status?.Cancelled?.state &&
          !order?.status?.AwaitingPayment?.state
      );
      const theirFirstOrder = yourOrders[0];
      const userCreatedThisDay = created.format("MMM-DD-YYYY") === date.value;

      const isTheirFirstOrderThatDay =
        ddd(theirFirstOrder?.date).format("MMM-DD-YYYY") === date.value;

      if (isTheirFirstOrderThatDay) {
        customer_first_order.value[uid] = theirFirstOrder;
        new_orders_list.value[uid] = { ...theirFirstOrder, type: "First Order" };
      }
      if (isTheirFirstOrderThatDay && userCreatedThisDay) {
        new_customer_first_order.value[uid] = theirFirstOrder;
        new_orders_list.value[uid] = {
          ...theirFirstOrder,
          type: "New Customer First Order",
        };
      }
    });
  });

  watchEffect(() => {
    // run for every promise resolution when push to firstOrder
    const li2 = frame.map((x) => {
      const len = Object.values(new_customer_first_order.value || {}).filter(
        (order) => ddd(order?.date).set("minute", 0).format(format) === x
      ).length;
      if (len) return len;
      return 0;
    });

    const li3 = frame.map((x) => {
      const len = Object.values(customer_first_order.value || {}).filter(
        (order) => ddd(order?.date).set("minute", 0).format(format) === x
      ).length;
      if (len) return len;
      return 0;
    });
    list2.value = li2;
    list3.value = li3;
  });

  // data.value = { ...data.value, labels: frame };
  watchEffect(() => {
    data.value = {
      // ...data.value,
      labels: frame,
      datasets: [
        {
          label: "New customer First order",
          fill: true,
          backgroundColor: (ctx) => {
            const canvas = ctx.chart.ctx;
            const gradient = canvas.createLinearGradient(255, 145, 3, 100);
            return gradient;
          },
          tension: 0.25,
          borderColor: "green",
          data: [...list2.value],
          fill: true,
        },
        {
          label: "First order",
          fill: true,
          backgroundColor: (ctx) => {
            const canvas = ctx.chart.ctx;
            const gradient = canvas.createLinearGradient(255, 145, 3, 100);
            return gradient;
          },
          tension: 0.25,
          borderColor: "blue",
          data: [...list3.value],
          fill: true,
        },
        {
          label: "New customer",
          fill: true,
          backgroundColor: (ctx) => {
            const canvas = ctx.chart.ctx;
            const gradient = canvas.createLinearGradient(255, 145, 3, 300);
            return gradient;
          },
          tension: 0.25,
          borderColor: "red",
          data: [...list],
          fill: true,
        },
      ],
    };
  });
});
</script>
     <style lang="sass" scoped>
</style>

