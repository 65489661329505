<template>
  <input-field
    :id="'sle'"
    :label="' '"
    :options="timeLeftList"
    :type="'select'"
    @input="(e) => (selectedTime = e.target.value)"
    class="outline-none p-3"
    :placeholder="'Sort by time left'"
  ></input-field>
</template>

<script>
// import { timeLeftList } from "@/util";
import { ref, watchEffect } from 'vue';
import InputField from './InputField.vue';

export default {
  components: { InputField },
  props: {
    getTime: {
        type:Function, default:()=>null
    }
  },
  setup(props) {
    const selectedTime = ref("");
    watchEffect(()=>{
      selectedTime.value
      props.getTime(selectedTime.value)
    })
    const timeLeftList = [
      {label: 'Time left (High to Low)', value:'htl'},
      {label:'Time left (Low to  High)', value:'lth'}
    ]

    return {
      selectedTime,
      timeLeftList,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>