<template>
  <head>
    <link
      rel="stylesheet"
      href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
    />
  </head>

  <router-view :key="route.params.id">
    <Layout :hasTopNav="false">
      <div class="bg-light h-[100%] ue-container">
        <top-nav
          :searchBar="false"
          :arrowback="true"
          :PrevNextIcon="true"
          :PrevNextList="prevnextlist"
        />
        <div class="d-flex justify-content-between items-center">
          <h2>Order ID {{ route.params?.id }}</h2>

          <CustomerTag :uid="selectedOrder?.customer?.id" />
        </div>

        <!-- For the process flow -->
        <progression-bar :order="selectedOrder" />

        <!-- Order Details -->

        <order-details :order="selectedOrder" :key="route.params.id" />

        <courier-deets :selectedOrder="selectedOrder" />
        <!-- v-if="!selectedOrder?.status?.Delivered?.state" -->
        <Timeline :selectedOrder="selectedOrder" />
        <div class="w-full h-[auto] mb-5">
          <Map
            :order="selectedOrder"
            v-if="selectedOrder?.status?.Confirmed?.state"
          />
          <span></span>
        </div>
      </div>
    </Layout>
  </router-view>
</template>
       <script scoped>
import { ref, computed, watchEffect } from "vue";
import Pagination from "@/components/Pagination.vue";
import TopNav from "@/components/TopNav.vue";
import Modal from "@/components/Modal.vue";
import { store } from "@/views/store/store";
import MealModal from "@/views/main/Kitchen/Meals/MealModal.vue";
import { useRoute, useRouter } from "vue-router";
import dayjs from "dayjs";
import QuantityCounter from "@/components/QuantityCounter.vue";
// import styles from "./styles.module.scss";
import Countdown from "@/partials/countdown.vue";
import ProgressionBar from "@/components/ProgressionBar.vue";
import Text from "@/components/Text.vue";
import ItemMeal from "@/components/ItemMeal.vue";
import { formatDate, getTravelTime, getUeOrderId, sum } from "@/util";
import InputField from "@/components/InputField.vue";
//   import SelectCourier from "./SelectCourier.vue";
import {
  onValue,
  ref as dbRef,
  getDatabase,
  set,
  get,
} from "firebase/database";
import ItemsTable from "@/partials/ItemsTable.vue";
import CourierDeets from "@/partials/CourierDeets.vue";
import Map from "./Map";
import Layout from "@/partials/Layout/layout.vue";
// import VueGoogleAutocomplete from "vue-google-autocomplete";
import { useToast } from "vue-toast-notification";
import OrderDetails from "@/components/OrderDetails.vue";
import CustomerTag from "@/components/CustomerTag.vue";
import Timeline from "@/components/Timeline.vue";

export default {
  props: {
    selectedOrder: Object,
    modal: Boolean,
  },
  components: {
    TopNav,
    Pagination,
    Modal,
    MealModal,
    QuantityCounter,
    Countdown,
    ProgressionBar,
    Text,
    ItemMeal,
    InputField,
    //   SelectCourier,
    ItemsTable,
    CourierDeets,
    Map,
    Layout,
    OrderDetails,
    CustomerTag,
    Timeline,
  },
  setup(props) {
    const selectedOrder = ref({});
    // const selected = ref("active");
    // const message = ref("Active Orders");
    const status = ref({});
    const items = ref([]);
    const route = useRoute();
    const router = useRouter();
    const { id } = useRoute().params;
    const prevnextlist = ref([]);
    const newplace = ref({});
    const toast = useToast({ position: "top-right" });
    // const editAddress = async () => {
    //   if (newplace.value.longitude) {
    //     try {
    //       const prev = (
    //         await get(
    //           dbRef(getDatabase(), "orders/" + id + "/customer/deliveryAddress")
    //         )
    //       ).val();

    //       const newAddress = {
    //         ...prev,
    //         houseNo: newplace.value.street_number,
    //         houseAddress: `${newplace.value.route} ${newplace.value?.administrative_area_level_2}  ${newplace.value.locality} `,
    //         latitude: newplace.value.latitude,
    //         longitude: newplace.value.longitude,
    //       };

    //       await set(
    //         dbRef(getDatabase(), "orders/" + id + "/customer/deliveryAddress"),
    //         newAddress
    //       );
    //       toast.success("Location updated");
    //     } catch (error) {

    //       toast.success("Error in location");
    //     }
    //   }
    // };
    // watchEffect(() => {

    // });

    const fetchData = (id) => {
      console.log({ fff: id });
      onValue(dbRef(getDatabase(), "orders/" + id), (snapshot) => {
        if (snapshot.val()) {
          selectedOrder.value = snapshot.val();

          items.value = selectedOrder.value.items;
          status.value = selectedOrder.value.status;
        }
      });
    };
    router.afterEach((to, from) => {
      fetchData(to.params.id);
    });
    watchEffect(() => {
     const id = route.params.id;
      // console.log({ id },route.params.id);
      fetchData(id);
    });

    watchEffect(() => {
      //create list of id's for the prevnext list logistics
      let onlyDelivered = store.state.orders.filter((x) => x.status?.Delivered);
      //determine if its active or completed
      prevnextlist.value = onlyDelivered.filter(
        (x) =>
          selectedOrder.value?.status?.Delivered?.state ===
          x.status.Delivered.state
      );
    });

    // Pagination
    const ordersPerPage = 5;
    const currentPage = ref(1);
    const totalPages = computed(() =>
      Math.ceil(orders.value.length / ordersPerPage)
    );

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };

    const paginatedOrders = computed(() => {
      const startIndex = (currentPage.value - 1) * ordersPerPage;
      const endIndex = startIndex + ordersPerPage;
      return orders.value.slice(startIndex, endIndex);
    });

    return {
      ordersPerPage,
      // editAddress,
      currentPage,
      totalPages,
      goToPage,
      paginatedOrders,
      // select,
      // selected,
      // message,
      props,
      items,
      // modal,
      prevnextlist,
      status,
      sum,
      router,
      route,
      formatDate,
      getUeOrderId,
      getTravelTime,
      selectedOrder,
      newplace,
    };
  },
};
</script>
     
     <style scr="./style.scss">
.hide-search-on-top-nav {
  display: none;
}
.state {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 85px;
  height: 85px;
  border: 3px solid #ff9103;
  border-radius: 85px;
  color: #ff9103;
}
.state-active {
  background-color: #ff9103;
  color: white;
}
.order-container {
  /* margin-left:150px; */
  height: 100vh;
  position: relative;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  /* padding: 12px; */
}

/* active and completed button */
.button {
  padding: 10px 30px;
  border-radius: 5px;
  border: 1px #f60100 solid;
  background-color: #fff;
  color: #f60100;
  margin-left: 20px;
  justify-self: center;
}
.button.active {
  background: linear-gradient(180deg, #ff9103 0%, #f60100 100%);
  color: white;
  border: none;
}
.kitchenBtn {
  color: black;
  padding: 8px 20px;
  border-radius: 5px;
  border-radius: rgb(87, 85, 85) 1px solid;
  margin-right: 8px;
}
.pagination-container {
  display: flex;
  column-gap: 10px;
}
.paginate-buttons {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(217, 217, 217);
  color: black;
}
.paginate-buttons:hover {
  background-color: #d8d8d8;
}
.active-page {
  background-color: #3498db;
  border: 1px solid #3498db;
  color: white;
}
.active-page:hover {
  background-color: #2988c8;
}
.orderList {
  border-radius: 20px;
  /* padding: 3rem; */

  font-size: 12px;
  color: black;
}
.order-table {
  width: 100%;
  border-collapse: collapse;
  /* border: 1px solid #ccc; */
}

.table-header {
  background-color: white;
  text-align: left;
  padding: 10px;
  font-weight: bold;
  height: 70px;
}

.table-row {
  height: 60px;
}

.table-row.even-row {
  background-color: #f2f2f2;
}

.table-row.odd-row {
  background-color: #fff;
}

.table-data {
  padding: 10px;
}

.status-button {
  padding: 5px 10px;
  background: linear-gradient(
    202deg,
    rgba(255, 145, 3, 0.24) 0%,
    rgba(246, 1, 0, 0.24) 100%
  );

  border: none;
  color: #333;
  font-weight: bold;
}

.status-button2 {
  padding: 5px 10px;
  background: linear-gradient(
    202deg,
    rgba(74, 224, 117, 0.428) 0%,
    rgba(0, 246, 78, 0.24) 100%
  );

  border: none;
  color: #333;
  font-weight: bold;
}

.action-link {
  text-decoration: none;
  color: #f60100;
}

/* Solid border */
.row > * {
  padding-left: 0;
  padding-right: 0;
}
</style>
  