
import { set, ref as dbRef, getDatabase, get, push } from "firebase/database";
import { store } from '@/views/store/store'
export const createCancelledSummary = async ({ order, message, amount }) => {
    try {
        const payload = {
            orderId: order?.id,
            staff: store.state.user?.first_name + " " + store.state.user?.last_name,
            customer:`${order?.customer?.firstName} ${order?.customer?.lastName}`,
            customerId:order?.customer?.id,
            message,
            amount,
            date: new Date().getTime(),
            id: `${message}-${order?.id}`
        }
        // console.log(payload)
        await push(dbRef(getDatabase(), `cancelled_items_summary`), payload)
    } catch (error) {
        console.log({createCancelledSummaryError:error})
    }
}