<template>
  <head>
    <link
      rel="stylesheet"
      href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
    />
  </head>
  <router-view :key="route.path">
    <Layout :hasTopNav="false">
      <div class="bg-light ue-container">
        <Modal>
          <div v-if="modal == 'cancel-item'">
            <MealModal
              title="Cancel Meal Order?"
              :content="'Are you certain that you want to cancel this meal order?'"
              :rightClick="() => setCanledced()"
              firstBtn="No, Cancel"
              secondBtn="YES, Confirm"
              :index="cancelid"
              :closeBtn="true"
              :tickBtn="false"
              :id="selectedOrder.id"
            />
          </div>
          <div v-else-if="modal == 'complete-meal'">
            <MealModal
              :title="'All Meals Completed?'"
              :content="'Are you sure all meals has been prepared?'"
              :firstBtn="'No, Cancel'"
              :secondBtn="'YES, Confirm'"
              :closeBtn="false"
              :tickBtn="true"
              :rightClick="completeAll"
            />
          </div>
        </Modal>

        <top-nav
          :searchBar="false"
          :arrowback="true"
          :PrevNextIcon="true"
          :PrevNextList="prevnextlist"
        />
        <div class="d-flex items-center justify-content-between">
          <h2>Order ID {{ route.params.id }}</h2>
          <!-- <td class="table-data cursor-pointer"> -->
          <CustomerTag :uid="selectedOrder?.customer?.id" />
          <!-- </td> -->
        </div>

        <!-- For the process flow -->
        <progression-bar :order="selectedOrder" />

        <order-details :order="selectedOrder" />

        <!-- timer -->
        <div
          class="flex items-center justify-center py-3 my-4 bg-white rounded-[16px] flex-wrap"
        >
          <countdown
            :title="
              selectedOrder?.status?.Prepared?.state
                ? 'Time spent for preparation and assembly'
                : 'Time left for preparation and assembly'
            "
            :minunte="45"
            :seconds="'00'"
            :order="selectedOrder"
            class="my-5 w-full"
          ></countdown>
          <div class="w-[43%] sm:w-[100%] p-3 bg-white border rounded">
            <Text class="my-2" :size="12" :weight="600" :color="'grey'"
              >Customer's note</Text
            >
            <Text :size="16" :weight="600">
              {{ selectedOrder?.order_note || "Order has no note" }}</Text
            >
          </div>
        </div>

        <items-table :type="'kitchen'" :key="selectedOrder.id" />

        <Timeline :selectedOrder="selectedOrder" />
      </div>
    </Layout>
  </router-view>
</template>
     <script scoped>
import { ref, computed, watchEffect } from "vue";
import Pagination from "@/components/Pagination.vue";
import TopNav from "@/components/TopNav.vue";
import Modal from "@/components/Modal.vue";
import { store as vueStore } from "@/views/store/store";
import MealModal from "@/views/main/Kitchen/Meals/MealModal.vue";
import { useRoute, useRouter } from "vue-router";
import QuantityCounter from "@/components/QuantityCounter.vue";
import styles from "./styles.module.scss";
import Countdown from "@/partials/countdown.vue";
import ProgressionBar from "@/components/ProgressionBar.vue";
import Text from "@/components/Text.vue";
import ItemMeal from "@/components/ItemMeal.vue";
import { getUeOrderId, sum } from "@/util";
import { store } from "@/views/store/store";
import { ref as dbRef, getDatabase, onValue } from "firebase/database";
import ItemsTable from "@/partials/ItemsTable.vue";
import PrevNext from "@/components/PrevNext.vue";
import { onMounted } from "vue";
import Layout from "@/partials/Layout/layout.vue";
import Timeline from "@/components/Timeline.vue";
// import Receipt from "@/components/Receipt.vue";
import OrderDetails from "@/components/OrderDetails.vue";
import CustomerTag from "@/components/CustomerTag.vue";

export default {
  props: {
    selectedOrder: Object,
    modal: Boolean,
    orders: Array,
  },
  components: {
    TopNav,
    Pagination,
    Modal,
    MealModal,
    QuantityCounter,
    Countdown,
    ProgressionBar,
    Text,
    ItemMeal,
    ItemsTable,
    PrevNext,
    Layout,
    Timeline,
    OrderDetails,
    CustomerTag
},
  setup(props) {
    const selected = ref("active");
    const message = ref("Active Orders");
    const status = ref({});
    const items = ref([]);
    const selectedOrder = ref({});
    const modal = ref(vueStore.state.modal.data);
    const router = useRouter();
    const { id } = useRoute().params;
    const prevnextlist = ref([]);
    const route = useRoute();

    router.afterEach((to, from) => {
      console.log(to.params.id);
      onValue(dbRef(getDatabase(), "orders/" + to.params.id), (snapshot) => {
        if (snapshot.val()) {
          selectedOrder.value = snapshot.val();
          items.value = selectedOrder.value.items;
          status.value = selectedOrder.value.status;
        }
      });
    });
    watchEffect(() => {
      onValue(dbRef(getDatabase(), "orders/" + route.params.id), (snapshot) => {
        if (snapshot.val()) {
          // console.log(snapshot.val());
          selectedOrder.value = snapshot.val();
          items.value = selectedOrder.value.items;
          status.value = selectedOrder.value.status;
        }
      });
    });

    watchEffect(() => {
      modal.value = vueStore.state.modal.data;
    });
    const select = (type) => {
      selected.value = type;
      message.value = type === "active" ? "Active Orders" : "Completed Orders";
    };

    watchEffect(() => {
      //create list of id's for the prevnext list kitchen

      //determine if its active or completed
      prevnextlist.value = store.state.orders.filter((x) => {
        let type = Boolean(selectedOrder.value?.status?.Prepared?.state);
        let res = Boolean(x?.status?.Prepared?.state);
        return res == type;
      });
    });

    // const setPrepared = (prepare, itemIndex, order) => {
    //   store.dispatch("prepareItem", {
    //     prepare,
    //     id: selectedOrder.value.id,
    //     itemIndex,
    //     order,
    //   });
    // };
    // const cancel = (...args) => {
    //   vueStore.commit("openModal", "cancel-item");
    //   proceedCancel.value = args;
    // };
    // const setCanledced = (cancelcount, index, order) => {
    //   changes.value[index].canceled = cancelcount;
    //   changes.value[index].prepared =
    //     changes.value[index].quantity - cancelcount;
    // };

    // const saveChanges = () => {
    //   store.dispatch("saveCanceledMeals", {
    //     orderId: selectedOrder.value.id,
    //     changes: changes.value,
    //   });
    // };

    // const openCompleteConfirmation = () => {
    //   vueStore.commit("openModal", "complete-meal");
    // };

    // const completeAll = () => {
    //   store.dispatch("completeMealPreparation", {
    //     orderId: selectedOrder.value.id,
    //   });
    //   vueStore.commit("closeModal");
    // };

    // Pagination
    const ordersPerPage = 5;
    const currentPage = ref(1);
    const totalPages = computed(() =>
      Math.ceil(orders.value.length / ordersPerPage)
    );

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };

    const paginatedOrders = computed(() => {
      const startIndex = (currentPage.value - 1) * ordersPerPage;
      const endIndex = startIndex + ordersPerPage;
      return orders.value.slice(startIndex, endIndex);
    });

    return {
      ordersPerPage,
      currentPage,
      totalPages,
      goToPage,
      paginatedOrders,
      select,
      selected,
      message,
      props,
      route,
      styles,
      // setPrepared,

      selectedOrder,
      // setCanledced,
      items,
      // openCompleteConfirmation,
      // completeAll,
      // cancel,
      // cancelid,
      // proceedCancel,
      modal,
      status,
      // changes,
      // saveChanges,
      sum,
      router,
      getUeOrderId,
      id,
      prevnextlist,
    };
  },
};
</script>
   
   <style scr="./style.scss">
.hide-search-on-top-nav {
  display: none;
}
.state {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 85px;
  height: 85px;
  border: 3px solid #ff9103;
  border-radius: 85px;
  color: #ff9103;
}
.state-active {
  background-color: #ff9103;
  color: white;
}
.order-container {
  /* margin-left:150px; */
  height: 100vh;
  position: relative;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  /* padding: 12px; */
}

/* active and completed button */
.button {
  padding: 10px 30px;
  border-radius: 5px;
  border: 1px #f60100 solid;
  background-color: #fff;
  color: #f60100;
  margin-left: 20px;
  justify-self: center;
}
.button.active {
  background: linear-gradient(180deg, #ff9103 0%, #f60100 100%);
  color: white;
  border: none;
}
.kitchenBtn {
  color: black;
  padding: 8px 20px;
  border-radius: 5px;
  border-radius: rgb(87, 85, 85) 1px solid;
  margin-right: 8px;
}
.pagination-container {
  display: flex;
  column-gap: 10px;
}
.paginate-buttons {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(217, 217, 217);
  color: black;
}
.paginate-buttons:hover {
  background-color: #d8d8d8;
}
.active-page {
  background-color: #3498db;
  border: 1px solid #3498db;
  color: white;
}
.active-page:hover {
  background-color: #2988c8;
}
.orderList {
  border-radius: 20px;
  /* padding: 3rem; */

  font-size: 12px;
  color: black;
}
.order-table {
  width: 100%;
  border-collapse: collapse;
  /* border: 1px solid #ccc; */
}

.table-header {
  background-color: white;
  text-align: left;
  padding: 10px;
  font-weight: bold;
  height: 70px;
}

.table-row {
  height: 60px;
}

.table-row.even-row {
  background-color: #f2f2f2;
}

.table-row.odd-row {
  background-color: #fff;
}

.table-data {
  padding: 10px;
}

.status-button {
  padding: 5px 10px;
  background: linear-gradient(
    202deg,
    rgba(255, 145, 3, 0.24) 0%,
    rgba(246, 1, 0, 0.24) 100%
  );

  border: none;
  color: #333;
  font-weight: bold;
}

.status-button2 {
  padding: 5px 10px;
  background: linear-gradient(
    202deg,
    rgba(74, 224, 117, 0.428) 0%,
    rgba(0, 246, 78, 0.24) 100%
  );

  border: none;
  color: #333;
  font-weight: bold;
}

.action-link {
  text-decoration: none;
  color: #f60100;
}

/* Solid border */
.row > * {
  padding-left: 0;
  padding-right: 0;
}

/* .divider {
  display: flex;
  align-items: center;
}

.divider2 {
  display: flex;
  align-items: center;
}
.divider::after {
  flex: 1;
  content: "";
  padding: 3px;
  background-color: #dedede;
}

.divider2::after {
  flex: 1;
  content: "";
  padding: 3px;
  background-color: #e7811b;
} */
</style>
