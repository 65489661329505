import { formatPrice } from "@/util";
import { sendPushNotification, store } from "@/views/store/store";
import dayjs from "dayjs";
import { set, ref as dbRef, getDatabase, get, query, orderByChild, equalTo, update } from "firebase/database";
import { useToast } from "vue-toast-notification";
import { v4 } from 'uuid'


const toast = useToast({ position: "top-right" });

export class WalletTransaction {

    constructor(user) {
        this.balance = user?.wallet?.balance || 0;
        this.user = user;
        this.path = `/users/${user.uid}/wallet`
        this.history = user?.wallet?.history || [];
        this.walletDetails = user?.wallet
    }

    async getWalletDetails() {
        try {
            const snap = await get(dbRef(getDatabase(), this.path))
            // console.log(snap.val())
            const details = snap.val()

            this.balance = details?.balance
            this.history = details?.history
            return this
        } catch (error) {
            throw error
        }
    }

    async initialWalletTransactions(type, reason = 'not stated', amount) {
        // const prev_wallet_transaction = (await get(dbRef(getDatabase(), 'wallet_transactions'))).val() || []
        const transaction_id = v4()
        console.log({ transaction_id }, 'started')

        try {
            await update(dbRef(getDatabase(), `wallet_transactions/${transaction_id}`),
                {
                    date: dayjs().format('MMM DD, YYYY. hh:mm A'),
                    reason,
                    customer: {
                        uid: this.user.uid,
                        firstName: this.user.firstName,
                        lastName: this.user.lastName,
                        email: this.user.email,
                        phone: this.user.phoneNumber,
                    },
                    amount,
                    type,
                    status: 'Pending',
                    transaction_id,
                    admin: {
                        first_name: store.state.user.first_name,
                        last_name: store.state.user.last_name,
                        email: store.state.user.email,
                        phone: store.state.user.phone,
                        uid: store.state.user.uid,
                    }

                }
            )
            console.log({ transaction_id }, 'transaction sent')
            return transaction_id
        } catch (error) {
            throw error
        }
    }

    async updateWalletTransactions(transaction_id, status) {
        // const quer = query(dbRef(getDatabase(), 'wallet_transactions'), orderByChild('transaction_id'), equalTo(transaction_id))
        // const transaction = await (await get(quer)).val()
        // console.log(transaction)
        if (transaction_id) await update(dbRef(getDatabase(), `wallet_transactions/${transaction_id}`), { status })

        // try {
        //     await set(dbRef(getDatabase(), 'wallet_transactions'),
        //         {
        //             [transaction_id]: {
        //                 date: dayjs().format('MMM DD, YYYY. hh:mm A'),
        //                 reason,
        //                 customer: {
        //                     uid: this.user.uid,
        //                     firstName: this.user.firstName,
        //                     lastName: this.user.lastName,
        //                     email: this.user.email,
        //                     phone: this.user.phoneNumber,
        //                 },
        //                 amount,
        //                 type,
        //                 status: 'Pending',
        //                 transaction_id,
        //                 admin: {
        //                     first_name: store.state.user.first_name,
        //                     last_name: store.state.user.last_name,
        //                     email: store.state.user.email,
        //                     phone: store.state.user.phone,
        //                     uid: store.state.user.uid,
        //                 }
        //             }
        //         }
        //     )
        //     return transaction_id
        // } catch (error) {
        //     throw error
        // }
    }


    async getWalletTransaction() {
        try {
            const prev_wallet_transaction = (await get(dbRef(getDatabase(), 'wallet_transactions'))).val() || []
            return prev_wallet_transaction || []
        } catch (error) {

        }
    }

    async withdrawFromWallet(amount, reason, onSuccess, onWalletInsufficient, onCharge = () => null) {
        try {
            // console.log(first)
            if (amount <= this.balance) {
                const transaction_id = await this.initialWalletTransactions('deduction', reason, amount)
                console.log({ transaction_id }, 'transaction_id received')
                console.log('balance => ', this.balance, 'amount => ', amount, "--------------")
                await set(dbRef(getDatabase(), this.path), {
                    ...this.walletDetails,
                    balance: this.balance - amount,
                    history: [...this.history, {
                        amount: amount,
                        created: JSON.stringify(new Date().toISOString()),
                        type: 'wallet-deduction'
                    }]
                })
                onCharge()
                await this.updateWalletTransactions(transaction_id, 'Successful')
                console.log({ transaction_id }, 'transaction_id updated')
                onSuccess()
            } else {
                toast.info("Cannot deduct more than current wallet balance");
                onWalletInsufficient()
                // await this.updateWalletTransactions(transaction_id, 'failed')
            }
        } catch (error) {
            console.log(error)
        }
    }

    async depositToWallet(amount, reason, onSuccess, onCharge = () => null) {
        const transaction_id = await this.initialWalletTransactions('top-up', reason, amount)
        console.log({ transaction_id }, 'transaction_id received')
        try {
            await set(dbRef(getDatabase(), this.path), {
                ...this.walletDetails,
                balance: this.balance + amount,
                history: [...this.history, {
                    amount: amount,
                    created: JSON.stringify(new Date().toISOString()),
                    type: 'wallet-top-up'
                }]
            })
            onCharge()

            await this.updateWalletTransactions(transaction_id, 'Successful')
            console.log({ transaction_id }, 'transaction_id updated')

            onSuccess()
            console.log('---')
            if (this.user?.pushToken) {
                console.log('tsending noti')
                await sendPushNotification({
                    token: this.user?.pushToken,
                    title: "Wallet Top up",
                    body: `Payment Successful - wallet successfully topped up with ${formatPrice(amount)} `, orderId: ""
                })
                console.log('sent noti')
            }


        } catch (error) {
            console.log(error)

        }
    }
}