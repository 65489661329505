<template>
  <layout :hasTopNav="false" class="bg-[#EFEFEF] w-full">
    <v-dialog v-model="filtermodal">
      <div class="bg-white rounded w-[500px] p-2 mx-auto">
        Filter by
        <div>
          wallet : <input type="number" class="num-" /> -
          <input type="number" class="num-" />
        </div>
        <div>
          Meal point : <input type="number" class="num-" /> -
          <input type="number" class="num-" />
        </div>
        <div>
          Order : <input type="number" class="num-" /> -
          <input type="number" class="num-" />
        </div>
      </div>
    </v-dialog>
    <div class="w-full customer-container h-[100]">
      <top-nav :onSearch="(e) => search(e)"> </top-nav>

      <Text :size="24" :weight="700">Customers</Text>

      <div
        class="rounded-[14px] border shadow my-4 bg-white gap-4 justify-between flex flex-wrap items-center lg:px-5 md:px-1 py-3"
      >
        <div>{{ customers.length }} customers</div>
        <!-- <div> {{ customers.length }} customers</div>
       <div> {{ customers.length }} customers</div> -->

        <div>
          <select
            class="ue-select border border-black outline-none rounded py-1 px-2 !w-[200px]"
            v-model="sort_val"
            placeholder="sort by"
          >
            <option value="">Sort by</option>
            <option v-for="sort in sorts" :key="sort" :value="sort">
              {{ sort }}
            </option>
            <!-- <template #option="option">
              <span>{{ option.label }}</span>
            </template> -->
          </select>
          <!-- <ue-button @click="filtermodal = true"> Filter</ue-button> -->

          <!-- <div>
            Filter by wallet :
            <input type="number" class="num-" v-model="filter.wallet.min" /> -
            <input type="number" class="num-" v-model="filter.wallet.max" />
          </div> -->
        </div>
      </div>
      <section
        class="flex items-center gap-5 mb-2 bg-white rounded p-2 w-[fit-content] shadow"
      >
        <div class="flex gap-4 items-center">
          <Text :size="17">
            Wallet Top up: {{ formatPrice(allTopUp || 0) }}
          </Text>
          <time-drop-down :getDate="(e) => (timeFrame = e)" />
          <span class="cursor-pointer" @click="getCustomersThatToppedup"
            >view</span
          >
        </div>
      </section>
      <section class="w-full responsize-table">
        <table class="w-full">
          <thead>
            <tr>
              <th class="table-header"></th>
              <th class="table-header">Name</th>
              <th class="table-header">Orders</th>
              <th class="table-header">Amount spent</th>
              <th class="table-header">Wallet Balance</th>
              <th class="table-header">MealBot Point</th>
              <th class="table-header">App version</th>
              <th class="table-header">Tag</th>
              <th class="table-header">PD</th>
              <th class="table-header">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(customer, index) in paginatedcustomers"
              :key="customer.uid"
              class="table-row"
              :class="index % 2 === 0 ? 'even-row' : 'odd-row'"
            >
              <td class="table-data">
                {{ index + 1 + customersPerPage * (currentPage - 1) }}
              </td>
              <td class="table-data">
                {{ customer?.firstName + " " + customer?.lastName }}
              </td>
              <td class="table-data">
                {{ customer?.number_of_orders }}
              </td>
              <td class="table-data">
                {{ formatPrice(customer?.amount_spent) }}
              </td>
              <td class="table-data">
                {{ formatPrice(customer?.wallet?.balance) }}
              </td>
              <td class="table-data" v-if="customer?.mealPoint">
                {{ customer?.mealPoint?.point }}
              </td>

              <td class="table-data" v-else>0</td>

              <td class="table-data">
                {{ customer?.appVersion || "N/A" }}
              </td>
              <td class="table-data">
                <CustomerTag :uid="customer?.uid" />
              </td>

              <td class="table-data">
                {{ customer?.pendingTransaction?.length || 0 }}
              </td>

              <td class="table-data">
                <router-link
                  :to="`/operator/customers/${customer?.uid}`"
                  class="action-link !underline"
                  >View</router-link
                >
              </td>
            </tr>
          </tbody>
        </table>

        <div
          class="flex flex-col gap-2 bg-white shadow rounded w-full p-3 my-3 bg-w"
        >
          <div class="flex gap-3">
            <Text :size="17">Upload Termi excel</Text>
            <input type="file" name="" id="" @change="upload" />
            <input type="number" v-model="phoneIndex" />

          </div>

          <div class="flex flex-wrap gap-5">
            <div
              v-for="(detail, index) in customer_store.state.failReg"
              :key="detail"
              class="w-[150px] flex items-center gap-2"
            >
              ({{ index + 1 }}) {{ detail }}
              <div>{{ checkNumber(detail) ? " 🔵" : " 🔴" }}</div>
            </div>
          </div>
        </div>
        <Pagination
          class="py-3"
          :currentPage="currentPage"
          :totalPages="totalPages"
          @page-change="goToPage"
        />
      </section>
    </div>
  </layout>
</template>

<script>
import layout from "@/partials/Layout/layout.vue";
import TopNav from "@/components/TopNav.vue";
import Text from "@/components/Text.vue";
import { computed, onMounted, ref, watch, watchEffect } from "vue";
import {
  getDatabase,
  get,
  ref as dbRef,
  orderByChild,
} from "firebase/database";
import Pagination from "@/components/Pagination.vue";
import { store } from "@/views/store/store";
import { customer_store } from "@/views/store/customers";
import { formatDate, formatPrice, getTransactionType } from "@/util";
import UeButton from "@/components/UeButton.vue";
import firebase from "firebase/database";
import CustomerTag from "@/components/CustomerTag.vue";
import TimeDropDown from "../Dashboard/TimeDropDown.vue";
import dayjs from "dayjs";
import { csvPhoneNoProcessor } from "./csvPhoneNoProcessor";

// import firebase from "firebase"

export default {
  components: {
    layout,
    TopNav,
    Text,
    Pagination,
    UeButton,
    CustomerTag,
    TimeDropDown,
    Text,
  },

  setup() {
    const customers = ref([]);
    const searchValue = ref("");
    const filtermodal = ref(false);
    const sort_val = ref("");
    const allTopUp = ref(0);
    const timeFrame = ref();
    const failedRegistrationsFromTermi = ref([]);
    const customersThatToppedup = ref([]);
    const phoneIndex =  ref(1)
    const sorts = ref([
      "Wallet balance (Low to high)",
      "Wallet balance (High to low)",
      "Mealpoint (Low to high)",
      "Mealpoint (High to low)",
      "Orders (Low to high)",
      "Orders (High to low)",
      "Date created (Low to high)",
      "Date created (High to low)",
      "Pending Transaction (Low to high)",
      "Pending Transaction (High to low)",
    ]);

    onMounted(() => {
      // store.dispatch("getUsers");
      customer_store.dispatch("getCustomers");
      customer_store.commit("saveFailRegs", []);
    });

    const filter = ref({
      wallet: { min: 0, max: 100000 },
    });

    const checkNumber = (number) => {
      let isCorrect = false;
      const n = String(number || "");
      const first3 = n.slice(0, 3);
      const rest = n.slice(3, n.length);
      const forthNum = n.slice(3, 4);
      // console.log({ first3, rest, forthNum, number });
      if (first3 == "234" && rest.length == 10 && forthNum != 0) {
        isCorrect = true;
      }
      return isCorrect;
    };
    const search = (e) => {
      searchValue.value = e;
    };

    const giveDateToCustomer = (customer) => {
      if (customer?.created) return customer;
      if (!customer?.created)
        return {
          ...customer,
          created: "2023-11-17T12:00:24.841+01:00",
        };
    };
    const filterByWAallet = (customer, min, max) => {
      if (min && (!max || max <= min)) return customer?.wallet?.balance >= min;
      if (min && max)
        return (
          customer?.wallet?.balance >= min && customer?.wallet?.balance <= max
        );
      if (!min) return customer;
    };

    const calculateWalletLeft = () => {
      let customersThatToppedup_ = [];
      const customersWithWallet = customer_store.state.customers?.filter(
        (customer) => customer?.wallet
      );
      // .map((customer) => customer?.wallet);
      allTopUp.value = 0;

      const ddd = (date) => dayjs(date?.replaceAll('"', ""));
      customersWithWallet.forEach((customer) => {
        const history =
          customer?.wallet?.history?.filter(
            (item) =>
              ddd(item?.created) > dayjs(timeFrame.value?.start) &&
              ddd(item?.created) < dayjs(timeFrame.value?.end)
          ) || [];

        const start = history

          ?.map((item) => item?.type)
          .indexOf("wallet-top-up");

        if (history?.length && start > -1) {
          // console.log({start,customer});
          customersThatToppedup_.push(customer);
        }
        // console.log(history);
        let customerBalance = 0;
        // console.log(start);

        if (start >= 0) {
          for (let index = start; index < history.length; index++) {
            const element = history[index];
            if (getTransactionType(element?.type) == "credit") {
              customerBalance = customerBalance + element.amount || 0;
            } else {
              customerBalance = customerBalance - element.amount || 0;
            }
          }
        }
        if (customerBalance > 0) allTopUp.value += customerBalance;
      });
      return customersThatToppedup_;
    };

    watchEffect(() => {
      timeFrame.value;

      calculateWalletLeft();
    });

    const getCustomersThatToppedup = () => {
      customers.value = calculateWalletLeft();
    };

    const sortBy = (a, b, type) => {
      switch (type) {
        case "Wallet balance (High to low)":
          return (b?.wallet?.balance || 0) - (a?.wallet?.balance || 0);
        case "Wallet balance (Low to high)":
          return (a?.wallet?.balance || 0) - (b?.wallet?.balance || 0);
        case "Mealpoint (High to low)":
          return (b?.mealPoint?.point || 0) - (a?.mealPoint?.point || 0);
        case "Mealpoint (Low to high)":
          return (a?.mealPoint?.point || 0) - (b?.mealPoint?.point || 0);
        case "Date created (High to low)":
          return (
            new Date(b.created?.replaceAll('"', "")) -
            new Date(a.created?.replaceAll('"', ""))
          );
        case "Date created (Low to high)":
          return (
            new Date(a.created?.replaceAll('"', "")) -
            new Date(b.created?.replaceAll('"', ""))
          );
        case "Orders (High to low)":
          return (b.number_of_orders || 0) - (a.number_of_orders || 0);
        case "Orders (Low to high)":
          return (a.number_of_orders || 0) - (b.number_of_orders || 0);
        case "Pending Transaction (Low to high)":
          return (
            (a.pendingTransaction?.length || 0) -
            (b.pendingTransaction?.length || 0)
          );
        case "Pending Transaction (High to low)":
          return (
            (b.pendingTransaction?.length || 0) -
            (a.pendingTransaction?.length || 0)
          );
        default:
          return (
            new Date(b.created?.replaceAll('"', "")) -
            new Date(a.created?.replaceAll('"', ""))
          );
      }
    };

    // const res = dbRef(getDatabase(),"users").ref.
    const getCustomers = async () => {
      if (customer_store.state.customers.length) {
        let val = searchValue.value.toLowerCase();
        customers.value = customer_store.state.customers
          .filter(
            (x) =>
              `${x.firstName} ${x.lastName}`?.toLowerCase().includes(val) ||
              `${x.lastName} ${x.firstName}`?.toLowerCase().includes(val) ||
              x.phoneNumber?.toLowerCase().includes(val) ||
              x.email?.toLowerCase().includes(val)
          )
          .filter((customer) =>
            filterByWAallet(
              customer,
              filter.value.wallet.min,
              filter.value.wallet.max
            )
          )
          .toSorted((a, b) => sortBy(a, b, sort_val.value));
      }
    };
    watchEffect(() => {
      searchValue.value;
      filter.value;
      sort_val.value;
      // const users_ = customer_store.state.customers;
      // console.log({users_})

      getCustomers();
    });

    const upload = (e) => {
      csvPhoneNoProcessor(e.target.files[0], customer_store.state.customers,phoneIndex.value);
    };

    // watchEffect(()=>{
    //  totalPages.value
    //  customers.value
    //  console.log(totalPages.value,customers.value.length)
    // })

    // Pagination
    // const customersPerPage = 10;
    // const currentPage = ref(1);
    // const totalPages = computed(() =>
    //   Math.ceil(customers.value.length / customersPerPage)
    //   );

    // const goToPage = (page) => {
    //   if (page >= 1 && page <= totalPages.value) {
    //     currentPage.value = page;
    //   }
    // };

    // const paginatedcustomers = computed(() => {
    //   const startIndex = (currentPage.value - 1) * customersPerPage;
    //   const endIndex = startIndex + customersPerPage;
    //   return customers.value.slice(startIndex, endIndex);
    // });

    const customersPerPage = 15;
    const currentPage = ref(1);
    const totalPages = computed(() =>
      Math.ceil(customers.value.length / customersPerPage)
    );

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };
    watchEffect(() => {
      searchValue.value;
      sort_val.value;
      currentPage.value = 1;
    });

    const paginatedcustomers = computed(() => {
      const startIndex = (currentPage.value - 1) * customersPerPage;
      const endIndex = startIndex + customersPerPage;
      // console.log(startIndex, endIndex);
      return customers.value.slice(startIndex, endIndex);
    });

    return {
      paginatedcustomers,
      customers,
      currentPage,
      totalPages,
      goToPage,
      customers,
      searchValue,
      search,
      getTransactionType,
      formatPrice,
      formatDate,
      customersPerPage,
      filter,
      sorts,
      sort_val,
      filtermodal,
      allTopUp,
      timeFrame,
      csvPhoneNoProcessor,
      failedRegistrationsFromTermi,
      customer_store,
      getCustomersThatToppedup,
      upload,
      checkNumber,phoneIndex
    };
  },
};
</script>

<style lang="scss" scoped>
.customer-container {
  padding: 30px;
  @media screen and (max-width: 992px) {
    padding: 5px;
  }
}

.num- {
  width: 60px;
  border: 1px solid grey;
  outline: none;
  padding: 1px 3px;
}
.responsize-table {
  @media screen and (max-width: 992px) {
    overflow-x: scroll;
  }
}
</style>