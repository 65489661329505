<template>
  <div class="w-full h-full top_selling_item !relative">
    <nav>
      <div class="left">
        <Text :weight="600" :size="18">Top delivery locations</Text>
      </div>
      <div class="rightttt">
        <time-drop-down :getDate="(e) => (timeFrame = e)" />
      </div>
    </nav>
    <main>
      <div
        class="card"
        v-for="(top_location, i) in top_locations"
        :key="top_location.name"
      >
        <div class="w-full flex gap-[19px]">
          <div class="number h-full flex items-center">#{{ i + 1 }}</div>
          <div class="center flex py-2 justify-between h-full w-full">
            <Text :size="16" :weight="600" class="top capitalize">
              {{ top_location.name }}
            </Text>
            <!-- <div class="bottom w-auto flex justify-between"> -->
            <Text :weight="600" :size="16"> {{ top_location.count }}x</Text>
            <!-- </div> -->
          </div>
        </div>
      </div>
      <div
        class="flex w-full justify-center itemd-center"
        v-if="!top_locations.length"
      >
        <Text :weight="700" :size="24"> No orders yets</Text>
      </div>
    </main>
  </div>
</template>

<script>
import Text from "@/components/Text.vue";
// import TimeDropDownVue from "./TimeDropDown.vue";
import { onMounted, watch, watchEffect } from "vue";
import { ref } from "vue";
import { getOrdersByTimeFrame } from "@/util";
import TimeDropDown from "../../Operator/Dashboard/TimeDropDown.vue";
import { get, getDatabase, ref as dbRef } from "firebase/database";
export default {
  components: { Text, TimeDropDown },
  setup() {
    const timeFrame = ref({});
    const top_locations = ref([]);
    const delivery_locations = ref([
      "yaba",
      "mushin",
      "lagos island",
      "victoria island",
      "magodo",
      "banana island",
    ]);

    const fetchData = async () => {
      const res = await get(dbRef(getDatabase(), "delivery_address"));
      if (res?.val())
        delivery_locations.value = res
          ?.val()
          ?.map((location) => location?.address);
    };
    onMounted(() => {
      fetchData();
    });
    watchEffect(async () => {
      const { start, end } = timeFrame.value;
      const ordersInTimeFrame = await getOrdersByTimeFrame(start, end);
      let location_count = [];

      if (ordersInTimeFrame.length) {
        const locations = ordersInTimeFrame?.filter(order => order?.locationType == 'Delivery').map(
          (order) =>
            order?.delivery_address?.address ||
            order?.customer?.deliveryAddress?.houseAddress
        );
        // console.log(locations);
        //counting locations
        delivery_locations.value.map((delivery_location) => {
          // console.log(delivery_location);
          let count = locations.filter(
            (location) =>
              location?.toLowerCase() == delivery_location?.toLowerCase() ||
              location?.toLowerCase().includes(delivery_location)
          ).length;
          location_count.push({
            name: delivery_location,
            count,
          });
        });

        // const unique_locations = Array.from(new Set(locations));
        // // console.log(unique_locations, delivery_locations);

        // unique_locations.map((unique_location) => {
        //   let count = locations.filter(
        //     (location) => location == unique_location && location
        //   ).length;
        //   location_count.push({
        //     name: unique_location,
        //     count,
        //   });
        // });
      } else {
        location_count = [];
      }

      // console.log(location_count);
      top_locations.value = location_count
        .sort((a, b) => b.count - a.count)
        // .splice(0, 5);
    });

    return {
      timeFrame,
      top_locations,
    };
  },
};
</script>

<style lang="scss" >
.top_selling_item {
  font-family: Work Sans;
  height: 100%;
  padding: 33px 35px;
  width: 100%;
  display: flex;
  gap: 24px;
  justify-content: space-between;
  flex-direction: column;

  nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // position: absolute;top: 10px;width: 100%;
    .left {
      // top: 30px;
      // position: absolute;
      // left: 10px;
    }
    left: 10px;
    .rightttt {
      // top: 30px;
      // position: absolute;
      // // width: 75px;
      // right: 10px;
    }
  }
  main {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    padding-right: 4px;
    margin-top: 30px;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      width: 4px;
    }
    .card {
      display: flex;
      justify-content: space-between;
      //   align-items: center;
      width: 100%;
      flex-direction: row;
      height: 1;
      border: none;
      img {
        width: 64px;
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        height: 65px;
        // align-self: flex-end;
        display: block;
      }
      .left {
        height: 100%;
        display: flex;
        gap: 13px;
        align-items: center;
        width: fit-content;
      }
    }
  }
}
</style>