<template>
  <v-dialog v-model="modal">
    <div class="mx-auto bg-white rounded py-2 px-3 w-[500px]">
      <MealModal
        v-if="modal?.type == 'DELETEACCOUNT'"
        :title="`Delete this account ?`"
        :content="`Are you sure you want to delete this account from the app`"
        :rightClick="() => deleteAccount()"
        firstBtn="No, Cancel"
        secondBtn="YES, Confirm"
        :closeBtn="true"
        :tickBtn="false"
        :oncancel="() => (modal = null)"
      />

      <div v-if="modal?.type == 'ADDBANNER'" class="flex flex-col">
        <Text :size="20" class="text-center mb-4">Add new account</Text>

        <div class="flex flex-col gap-2">
          <label
            for="account_input"
            class="block border rounded p-2 cursor-pointer mt-3"
          >
            + image
          </label>
          <input
            type="file"
            name=""
            id="account_input"
            class="hidden"
            @input="fileInput"
          />
          <div>
            <label for="">Bank name</label>
            <input class="ue-input-field" v-model="formValues.bank" />
          </div>
          <div>
            <label for="">Account name</label>
            <input class="ue-input-field" v-model="formValues.name" />
          </div>
          <div>
            <label for="">Account number</label>
            <input class="ue-input-field" v-model="formValues.number" />
          </div>
        </div>

        <div v-if="preview">
          <img :src="preview" class="w-[400px] h-[200px]" />
        </div>
        <span v-if="loadingMessage"> {{ loadingMessage }}</span>
        <UeButton
          class="w-[fit-content] mx-auto mt-3"
          @click="addAccount"
          :disabled="loadingState"
        >
          {{ loadingState ? "..." : "Add" }}
        </UeButton>
      </div>
    </div>
  </v-dialog>

  <div class="bg-white p-2 mb-3 w-100 rounded">
    <div class="flex justify-between mb-3">
      <Text :size="20" weight="600"> Update App Dedicated Accounts</Text>
      <div class="flex gap-2">
        <UeButton @click="() => (modal = { type: 'ADDBANNER', data: null })">
          Add account
        </UeButton>
        <UeButton @click="() => savePosition()"> Save position </UeButton>
      </div>
    </div>
    <div
      class="flex gap-3 items-center relative"
      style="overflow: auto !important; min-width: 100% !important"
    >
      <draggable v-model="accounts" class="min-w-[100%] gap-3 flex">
        <template #item="{ element: account }">
          <div class="rounded border flex min-w-[300px]">
            <img :src="account?.logo" class="w-[60px] h-[60px] rounded-b" />
            <div
              class="flex rounded w-[100%] gap-2 items-center justify-between p-2"
            >
              <div class="w-full">
                <Text> {{ account?.name }}</Text>
                <Text>{{ account?.bank }}</Text>
                <Text weight="600"> {{ account?.number }}</Text>
              </div>
              <div class="flex items-center">
                <span
                  @click="
                    () => {
                      modal = { type: 'DELETEACCOUNT', data: account };
                    }
                  "
                  class="text-dark bg-white font-large cursor-pointer rounded-circle material-symbols-outlined"
                >
                  cancel
                </span>
              </div>
            </div>
          </div>
        </template>
      </draggable>
    </div>
  </div>
</template>
  
  <script setup>
import Text from "@/components/Text.vue";
import UeButton from "@/components/UeButton.vue";
import { get, ref as dbRef, getDatabase, set } from "firebase/database";
import { ref, watchEffect } from "vue";
import MealModal from "@/components/MealModal.vue";
import { useToast } from "vue-toast-notification";
import { projectStorage } from "@/firebase/config";
import draggable from "vuedraggable";
import {
  uploadBytes,
  ref as uploadRef,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

const accounts = ref([]);
const formValues = ref({
  name: "",
  bank: "",
  number: "",
  logo: "",
});
const modal = ref(null);
// const newBanner = ref({ name: "", type: "", img: "" });
const restaurants = ref();
const preview = ref(null);
const seletedImg = ref(null);
const loadingState = ref(false);
const loadingMessage = ref("");
watchEffect(async () => {
  const res = await get(dbRef(getDatabase(), "restaurants"));
  if (res.val()) restaurants.value = Object.keys(res.val());
});
watchEffect(async () => {
  const res = await get(dbRef(getDatabase(), "bank_account"));
  accounts.value = res?.val();
});

const fileInput = (e) => {
  const file = e.target.files[0];
  preview.value = URL.createObjectURL(file);
  seletedImg.value = file;
};
const toast = useToast({ position: "top-right" });
const addAccount = async () => {
  loadingState.value = true;
//   if (!formValues.value?.name) {
//     const proceed = window.confirm("Proceed without linking");
//     if (!proceed) return;
//   }

  if (!seletedImg.value) {
    toast.info("Can not proceed without img");
    return;
  }
  loadingMessage.value = "please wait while image is being uploaded";
  const storagePath = `bank_account_logos/${new Date().getTime()}`;
  const storageRef = uploadRef(projectStorage, storagePath);
  try {
    await uploadBytes(storageRef, seletedImg.value);
    const downloadURL = await getDownloadURL(storageRef);
    loadingMessage.value = "Adding banner to app...";
    formValues.value.logo = downloadURL;
    formValues.value.storagePath = storagePath;
    formValues.value.disable = true;
    const newIndex = accounts.value?.length || 0;
    console.log(formValues.value, newIndex);
    await set(
      dbRef(getDatabase(), `/bank_account/${newIndex}`),
      formValues.value
    );
    loadingMessage.value = "Success";
    setTimeout(() => {
      loadingState.value = false;
      modal.value = null;
      loadingMessage.value = "";
      accounts.value.push(formValues.value);
    }, 2000);
  } catch (error) {
    console.log(error);
  }
};

const savePosition = async () => {
//   console.log(accounts.value);
//   try {
//     await set(dbRef(getDatabase(), `scroll`), accounts.value);
//     toast.success("Position updated successful");
//   } catch (error) {}
};

const deleteAccount = async () => {
  const account = modal.value.data;
  const editedBanner = accounts.value?.filter(
    (singeBanner) => singeBanner?.logo != account?.logo
  );
  if (editedBanner.length) {
    try {
      const imageRef = uploadRef(projectStorage, account?.storagePath);
      console.log(imageRef,editedBanner)
      await deleteObject(imageRef);
      await set(dbRef(getDatabase(), `bank_account`), editedBanner);
      accounts.value = editedBanner;
      modal.value = null;
    } catch (error) {}
  } else {
    toast.info("App must have a least one account");
  }
};

// watchEffect(() => {
//   loadingMessage.value;
// if(loadingMessage.value){}
//     toast.info(loadingMessage.value);
// });
</script>
  
  <style lang="scss" scoped>
</style>