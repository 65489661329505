<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
    <path d="M33.5433 26.1186C33.5725 27.2124 33.2808 28.2478 32.7558 29.1374C32.4642 29.6624 32.0704 30.1436 31.6329 30.5374C30.6267 31.4707 29.2996 32.0395 27.8267 32.0832C25.6975 32.127 23.8163 31.0332 22.7808 29.3561C22.2267 28.4957 21.8913 27.4603 21.8767 26.3665C21.8329 24.529 22.6496 22.8665 23.9621 21.7728C24.9538 20.9561 26.2079 20.4457 27.5788 20.4165C28.3449 20.3991 29.1069 20.5328 29.8213 20.81C30.5357 21.0871 31.1885 21.5023 31.7424 22.0318C32.2963 22.5614 32.7405 23.1948 33.0495 23.8961C33.3585 24.5973 33.5263 25.3525 33.5433 26.1186Z" stroke="auto" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M25.4315 26.2934L26.9044 27.6934L29.9523 24.7475M4.62109 10.8496L17.4982 18.3017L30.2878 10.8934M17.4982 31.5142V18.2871" stroke="auto" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M31.5135 13.3732V21.6274C31.5135 21.7003 31.5135 21.7587 31.499 21.8316C30.4781 20.942 29.1656 20.417 27.7073 20.417C26.3365 20.417 25.0677 20.8982 24.0615 21.7003C23.3769 22.2443 22.8245 22.9363 22.4456 23.7243C22.0668 24.5124 21.8713 25.376 21.874 26.2503C21.874 27.3441 22.1802 28.3795 22.7198 29.2545C22.851 29.4878 23.0115 29.7066 23.1865 29.9107L20.5177 31.3837C18.8552 32.317 16.1427 32.317 14.4802 31.3837L6.69271 27.067C4.92813 26.0899 3.48438 23.6399 3.48438 21.6274V13.3732C3.48438 11.3607 4.92813 8.91074 6.69271 7.93366L14.4802 3.61699C16.1427 2.68366 18.8552 2.68366 20.5177 3.61699L28.3052 7.93366C30.0698 8.91074 31.5135 11.3607 31.5135 13.3732Z" stroke="auto" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>