<template>
  <div class="py-3">
    <v-dialog v-model="editOrder.state.addItemModal" persistent>
      <div
        class="bg-white responsive-modal mx-auto rounded p-2 h-[auto] !overflow-y"
        style="overflow-y: scroll"
      >
        <div class="w-100 flex justify-between mb-2">
          <Text :size="16" weight="600"> Select restaurant</Text>
          <button
            class="self-end"
            @click="
              () => {
                editOrder.commit('openAddItemModal', false);

                editOrder.commit('setRestaurant', []);
              }
            "
          >
            close
          </button>
        </div>

        <div class="w-100" v-if="!editOrder.state.restaurant[0]">
          <div
            class="my-2 flex border rounded p-1 cursor-pointer hv-btn"
            v-for="restaurant in Object.entries(restaurants || {}).filter(
              (restaurant) => !restaurant[1]?.hidden && restaurant[1]?.food
            )"
            :key="restaurant?.[0]"
            @click="() => editOrder.commit('setRestaurant', restaurant)"
          >
            <Text> {{ restaurant[0] }}</Text>
          </div>
        </div>

        <div class="w-100" v-if="editOrder.state.restaurant[0]">
          <button
            class="self-end"
            @click="
              () => {
                editOrder.commit('openAddExtraModal', false);
                editOrder.commit('setRestaurant', []);
              }
            "
          >
            back
          </button>

          <!-- {{ editOrder.state.restaurant[1].food  }} -->

          <div
            class="my-2 flex flex-col border rounded p-1 hover:!bg-light"
            v-for="x in Object.entries(
              editOrder.state.restaurant[1].food || {}
            )"
            :key="x?.[0]"
          >
            <Text :weight="600"> {{ x[0] }}</Text>
            <div class="w-100" v-for="item in x[1]" :key="item?.name">
              <div
                class="my-1 flex justify-between border rounded p-1 hover:!bg-light"
                :class="
                  item?.prices?.length || item?.types?.length
                    ? ''
                    : 'cursor-pointer hv-btn'
                "
                @click="() => openItemModal(item, x[0], null, null)"
              >
                <Text class="w-[30%]">
                  {{ item?.name }}
                  <span v-if="item?.prices?.length">
                    <br />
                    (Select a price)
                  </span>
                  <span v-if="item?.types?.length">
                    <br />
                    (Select a price)
                  </span>
                  <!-- <span v-if="item?.extra_portion">
                  <br />
                 {{ item?.extra_portion }}
                </span> -->
                </Text>

                <div class="flex gap-2 w-[30%] justify-end">
                  <!-- <Text>1</Text> -->

                  <!-- <div class=" flex flex-col"> -->
                  <Text v-if="item?.amount" class="">
                    +&#x20A6;{{ item?.amount }}</Text
                  >

                  <!-- </div> -->
                  <!-- 
                <Text v-if="item?.extra_portion">
                  +{{ item?.extra_portion?.name }} @
                  {{ item?.extra_portion?.amount }}</Text
                > -->

                  <!-- <div v-if="item?.extra_portion" class="flex flex-col gap-1">
                  <div
                    class="cursor-pointer border rounded p-1 hv-btn"
                    @click="
                      () =>
                        openItemModal(item, x[0], price, quantity[item?.name])
                    "
                  >
                    {{ item?.extra_portion?.name }} for &#x20A6;{{
                      item?.extra_portion?.amount
                    }}
                  </div>
                </div> -->
                  <div v-if="item?.prices?.length" class="flex flex-col gap-1">
                    <div
                      v-for="price in item?.prices"
                      :key="price?.item"
                      class="cursor-pointer border rounded p-1 hv-btn"
                      @click="() => openItemModal(item, x[0], price, null)"
                    >
                      {{ price?.item }} for &#x20A6;{{ price?.price }}
                    </div>
                  </div>

                  <div v-if="item?.types?.length" class="flex flex-col gap-1">
                    <div
                      v-for="price in item?.types"
                      :key="price?.item"
                      class="cursor-pointer border rounded p-1 hv-btn"
                      @click="() => openItemModal(item, x[0], price, null)"
                    >
                      {{ price?.item }} for &#x20A6;{{ price?.price }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="my-1 flex justify-between border rounded p-1 hover:!bg-light cursor-pointer hv-btn"
                v-if="item?.extra_portion"
                @click="
                  () => openItemModal(item, x[0], null, item?.extra_portion)
                "
              >
                {{ item?.extra_portion.name }} @
                &#x20A6;{{ item?.extra_portion.amount }}
              </div>
            </div>
          </div>

          <div class="flex justify-center">
            <button class="border rounded px-4 py-1">Add</button>
          </div>
        </div>
      </div>
    </v-dialog>

    <add-extra :item="editOrder.state.item" :order="editOrder.state.order" />
  </div>
</template>
  
  <script setup>
import { editOrder } from "@/views/store/editOrder";
import { get, getDatabase, ref as dbRef } from "firebase/database";
import { ref, watchEffect } from "vue";
import CheckBox from "./CheckBox.vue";
import Text from "./Text.vue";
import AddExtra from "./AddExtra.vue";
const props = defineProps({
  order: Object,
  item: Object,
});
const restaurants = ref([]);
const quantity = ref({});
//   const addItemModal = ref(false);

watchEffect(async () => {
  const res = await get(dbRef(getDatabase(), `/restaurants/`));

  restaurants.value = res?.val();
});

const openItemModal = (item, foodType, selectedPrice, extra_portion) => {
  console.log(item);
  if (item?.amount || selectedPrice?.price || extra_portion?.amount) {
    editOrder.commit("createNewItem", {
      item,
      foodType,
      selectedPrice,
      extra_portion,
    });
    editOrder.commit("openAddItemModal", false);
    editOrder.commit("openAddExtraModal", true);
  }
};
</script>
  
  <style lang="scss" scoped>
.hv-btn {
  &:hover {
    background: #efefef;
  }
}

.responsive-modal {
  // w-[50%] sm:w-[95%]
  width: 50%;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
}
</style>