<template>
  <head> </head>
  <div class="flex">
    <div class="prevbtn" @click="() => prev()">
      <span class="material-symbols-outlined text-[#FF8A65]"> keyboard_arrow_left </span>
      <!-- <ArrowLeft2 size="20" color="#FF8A65" /> -->
    </div>
    <div class="nextbtn" @click="() => next()">
      <!-- <ArrowRight2 size="20" color="#FF8A65" /> -->
      <span class="material-symbols-outlined text-[#FF8A65]"> keyboard_arrow_right </span>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { ref, watchEffect } from "vue";
export default {
  props: {
    orders: {
      type: Array,
      default: [],
    },
    paramKey:{
      type:String,
      default:'id'
    }
    ,
    onPrev:{
      type:Function,
      default:()=>null
    },
    onNext:{
      type:Function,
      default:()=>null
    }
  },
  // components: { ArrowLeft2, ArrowRight2 },
  setup(props) {
    const route = useRoute();
    const router = useRouter();

    const list = ref(props.orders.map((x) => x.id));

    const id = route.params[props.paramKey];
    const currentPath = ref(route.path.split("/"));
    watchEffect(() => {
      props.orders;
      list.value = props.orders.map((x) => x.id);
      // console.log(list.value)
    });
    const prev = () => {

      currentPath.value[3] = list.value[list.value.indexOf(id) - 1];
      // console.log(currentPath.value[3], id)
      router.push(currentPath.value.join("/"));
    };
    const next = () => {

      currentPath.value[3] = list.value[list.value.indexOf(id) + 1];
      // console.log(currentPath.value[3], id)
      router.push(currentPath.value.join("/"));
    };
    return { prev, next , route};
  },
};
</script>

<style lang="scss" scoped>
.prevbtn,
.nextbtn {
  cursor: pointer;
  z-index: 1000;
}
.nextbtn {
  right: 10px;
}

.prevbtn {
  left: 18%;
}
</style>