<template>
  <layout :hasSearch="false" class="bg-[#EFEFEFEF]">
    <div class="w-full px-5">
      <Text :weight="700" :size="24">Settings</Text>

      <div class="flex justify-between mt-5 w-full gap-4 items-start">
        <div
          class="w-[20%] flex gap-4 flex-col h-[250px] items-start justify-between"
        >
          <div class="flex flex-col gap-[30px] w-full pt-4 ">
            <router-link
              v-for="(page,i) in settings_pages"
              :key="page.key"
              replace
              :to="`${route.path}?page=${page.key}`"
            >
              <Text
                :size="18"
                :color="route.query.page === page.key ? '#F60100' : 'black'"
                :weight="400"
                class="flex items-center gap-2"
              >
                <span class="material-symbols-outlined !text-[inherit]">
                  {{ page.icon }}
                </span>
                {{ page.name }}</Text
              >
              
            <hr class="mt-4" v-if="i != settings_pages.length-1"/></router-link
            >
          </div>

          <ue-button class="rounded px-4 py-2 ml-3 mb-2" @click="() => logout()"
            >Logout</ue-button
          >
        </div>

        <div
          class="w-[80%] bg-white !h-[auto] flex flex-col justify-center rounded-[24px] shadow py-[40px] px-5"
        >
          <profile v-if="route.query.page === 'profile' || !route.query.page" />
          <!-- <Notifications v-if="route.query.page === 'notifications'" /> -->
          <!-- <Sessions v-if="route.query.page === 'session'" /> -->
          <Security v-if="route.query.page === 'security'" />
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/partials/Layout/layout.vue";
import Text from "@/components/Text.vue";
import { useRoute, useRouter } from "vue-router";
import Profile from "./Profile.vue";
import Notifications from "./Notifications.vue";
import Security from "./Security.vue";
import Sessions from "./Sessions.vue";
import { store } from "@/views/store/store";
import { onMounted } from "vue";
import UeButton from "@/components/UeButton.vue";
export default {
  components: {
    Layout,
    Text,
    Profile,
    Notifications,
    Security,
    Sessions,
    UeButton,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const settings_pages = [
      { name: "Profile", key: "profile", icon: "person" },
      // { name: "Notifiations", key: "notifiations", icon: "notifiations" },
      { name: "Security", key: "security", icon: "security" },
      // { name: "Session & login history", key: "session", icon: "session" },
    ];
    const logout = () => {
      store.commit("logout");
      router.push('/admin')
    };
    onMounted(() => {
      console.log(route.query?.page);
      if (!route.query?.page) {
        router.push(`${route.path}?page=profile`);
      }
    });
    return {
      route,
      logout,
      settings_pages,
    };
  },
};
</script>
        

<style lang="scss" scoped>
</style>