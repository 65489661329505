<template>
  <auth-body>
    <form @submit.prevent="handleSubmit" class="ui form flex flex-col">
      <Text :size="24" :weight="700" class="loginHeader text-center w-full"
        >Sign In</Text
      >
      <div class="flex flex-col gap-[20px]">
        <div>
          <label class="ue-input-label" for="">Email address</label>
          <input
            type="email"
            placeholder="Enter your email address"
            required
            v-model="email"
            class="ue-input-field"
          />
        </div>
        <div class="">
          <label class="ue-input-label" for="">Password</label>
          <div class="ue-input-field flex bg-white items-center">
            <input
              :type="show ? 'text' : 'password'"
              placeholder="Enter your password"
              required
              v-model="password"
              class="!h-[100%] w-[80%] !border-none"
            />
            <div
              @click="() => (show = !show)"
              id="toggle"
              class="cursor-pointer w-[15%] bg-white h-100 flex items-center"
            >
              <span class="material-symbols-outlined">
                {{ show ? "visibility_off" : "visibility" }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- <div v-if="error" class="error">{{ error }}</div> -->
      <Text class="w-full text-right" :weight="600">
        <!-- Already have an account? or -->
        <router-link
          id="forgot-password"
          to="/admin/reset-password"
          class="authSpan flex gap-2 justify-end"
          >Forgot <Text :color="'red'">Password?</Text></router-link
        >
      </Text>

      <button
        type="submit"
        v-if="!isPending"
        class="ui btn w-full fluid mpcblue rounded ue-btn-gradient"
      >
        SIGN IN
      </button>
      <button
        v-if="isPending"
        id="loading-btn"
        disabled
        class="ui button fluid mpcblue"
      >
        Loading
      </button>
    </form>
  </auth-body>
</template>

<script>
import { ref } from "@vue/reactivity";
import { signInWithEmailAndPassword } from "firebase/auth";
import { projectAuth, projectFunctions } from "@/firebase/config";
import Text from "@/components/Text.vue";
import { icons } from "@/assets/icons";
import { get, ref as dbRef, getDatabase, set, update } from "firebase/database";
import { useToast } from "vue-toast-notification";
import { store } from "../store/store";
import { permission } from "@/permission";
import AuthBody from "./AuthBody.vue";
import { httpsCallable } from "firebase/functions";
import { onMounted } from "vue";
// import jwt from "jsonwebtoken";

export default {
  setup() {
    const email = ref("");
    const password = ref("");
    const toast = useToast({ position: "top-right" });
    const isPending = ref(false);
    const show = ref(false);

    // const addUser = httpsCallable(projectFunctions, "addUser");
    // onMounted(() => {
    //   console.log("first");
    //   try {
    //     addUser({
    //       uid: "7vSTyTKimffBXXkBpOPIP0oqpF23",
    //       email: "halima@urbaneatsapp.com",
    //       password: "Password123",
    //     });
    //   } catch (error) {
    //     console.log({error})
    //   }
    // });

    const handleSubmit = async () => {
      isPending.value = true;
      try {
        const { user } = await signInWithEmailAndPassword(
          projectAuth,
          email.value,
          password.value
        );
        const res = await get(dbRef(getDatabase(), "admin_users"));
        const admin_users = Object.values(res.val());
        const admin_user = admin_users.find(
          (adminUser) => adminUser.uid === user.uid
        );
        if (admin_user) {
          // console.log(admin_user)
          store.commit("login", admin_user);
          // console.log("jjj");

          const permisssss = admin_user?.permissions
            ? Object.values(admin_user?.permissions)
            : [];
          const permit = permission(permisssss);

          // var token = jwt.sign({ foo: "bar" }, "shhhhh");
          const updates = { last_login: Date.now(), token: user.refreshToken };
          await update(
            dbRef(getDatabase(), `admin_users/${user.uid}`),
            updates
          );
          toast.success(`Welcome back ${admin_user.first_name} `);
          // console.log(user.refreshToken);
          window.location.assign(permit.first_page.path);
        } else {
          toast.error("User is not an admin");
        }
      } catch (error) {
        console.log(error);
        if (error.message.includes("Firebase: Error")) {
          // console.log(error.message.replace("auth/", "").replace("-", " "));
          toast.error(
            error.message
              .replace("Firebase: Error", "")
              .replace("auth/", "")
              .replace("-", " ")
              .replace("(", "")
              .replace(")", "")
          );
        }
      } finally {
        isPending.value = false;
      }
    };
    return { email, password, handleSubmit, icons, isPending, show };
  },
  components: { Text, AuthBody },
};
</script>

<style>
.form {
  width: 400px;
}
.back {
  position: fixed;
  display: flex;
  /* top: 40%; */
  align-items: center;
  height: 100vh;
  transform: translate(-50, -50);
}
</style>