export const getMealPointPrice = (order) => {
    const total = order?.total_price
    const delivery_fee = order?.delivery || 1000
    const service_fee = order?.service || 0
    const isAllowed = total >= (delivery_fee + service_fee)
    // console.log(order?.locationType)

    if (order?.locationType == 'Delivery') {
        console.log({ total, delivery_fee, service_fee, price: total - (delivery_fee + service_fee) })
        if (total >= (delivery_fee + service_fee)) {
            return total - (delivery_fee + service_fee)
        }
        return 0
    } else {
        return total
    }

    
}