<template>
  <div class="bg-white p-2 mb-3 w-100 rounded">
    <div class="flex justify-between mb-3">
      <Text :size="20" weight="600"> Update App Disclaimer Text</Text>
      <div class="flex gap-2 items-center">
        <UeButton @click="() => (edit = false)"> Cancel </UeButton>
        <UeButton @click="editText"> {{ edit ? "Save" : "Edit" }} </UeButton>
      </div>
    </div>
    <div
      class="gap-1 items-center relative"
      style="overflow: auto !important; min-width: 100% !important"
    >
      <Text :size="14" weight="600" v-if="!edit"> {{ disclaimer?.text ||  'No disclaimer' }}</Text>
      <textarea class="w-[100%] border p-2" v-model="disclaimer.text" v-else>
      </textarea>
      <v-switch
        v-model="disclaimer.show"
        :label="`${
          disclaimer.show
            ? 'Disclaimer text will be displayed'
            : 'Disclaimer text will be hidden'
        }`"
        hide-details
        inset
        :disabled="!edit"
        color="blue"
      ></v-switch>
    </div>
  </div>
</template>
  
  <script setup>
import Text from "@/components/Text.vue";
import UeButton from "@/components/UeButton.vue";
import { get, ref as dbRef, getDatabase, set } from "firebase/database";
import { ref, watchEffect } from "vue";

const disclaimer = ref({
  text: "",
  show: false,
});
const edit = ref(false);

const editText = async () => {
  if (edit.value) {
    console.log(disclaimer.value);
    await set(
      dbRef(getDatabase(), `/disclaimer_dedicated_account`),
      disclaimer.value
    );
  }

  edit.value = !edit.value;
};

watchEffect(async () => {
  //   disclaimer.value;
  edit.value;
  const res = (
    await get(dbRef(getDatabase(), `/disclaimer_dedicated_account`))
  ).val();
  if (res) disclaimer.value = res;
});
</script>
  
  <style lang="scss" scoped>
</style>