<template>
  <form class="flex flex-col gap-3" @submit="saveNames">
    <Text :size="20" :weight="600">Personal Details</Text>

    <div class="flex w-full gap-4">
      <div class="w-full">
        <label for=""><Text>FIRST NAME</Text></label>
        <input
          type="text"
          required
          v-model="names.first_name"
          class="w-full border outline-none rounded-[8px]"
        />
      </div>
      <div class="w-full">
        <label for=""><Text>LAST NAME</Text></label>
        <input
          type="text"
          required
          v-model="names.last_name"
          class="w-full border outline-none rounded-[8px]"
        />
      </div>
    </div>
    <!-- <div class="w-full">
      <label for=""><Text>EMAIL</Text></label>
      <input
        type="text"
        required
        v-model="email"
        class="w-full border outline-none rounded-[8px]"
      />
    </div> -->
    <div class="w-full">
      <label for=""><Text>Phone</Text></label>
      <input
        type="text"
        required
        v-model="names.phone"
        class="w-full border outline-none rounded-[8px]"
      />
    </div>

    <div class="w-full flex justify-center gap-2">
      <ue-button class="py-2 px-4"> Save Changes</ue-button>
    </div>
  </form>
  <ue-button v-if="!is_customer" class="py-2 px-4" @click="become_customer">
    Become a customer
  </ue-button>
</template>

<script>
import Text from "@/components/Text.vue";
import { store } from "@/views/store/store";
import { computed, ref, watchEffect } from "vue";
import { set, ref as dbRef, getDatabase, get } from "firebase/database";
import { useToast } from "vue-toast-notification";
import UeButton from "@/components/UeButton.vue";
export default {
  components: { Text, UeButton },
  setup() {
    const user = store.state.user;
    const is_customer = ref(null);
    const toast = useToast({ position: "top-right" });
    watchEffect(async () => {
      user?.uid;
      try {
        const find_in_customer = (
          await get(dbRef(getDatabase(), `users/${user.uid}`))
        ).val();
        is_customer.value = Boolean(find_in_customer);
      } catch (error) {
        is_customer.value = false;
      }
      // console.log(find_in_customer);
    });
    const names = ref({
      first_name: user?.first_name,
      last_name: user?.last_name,
      phone: user?.phone,
    });
    const email = ref(user.email);

    const become_customer = async () => {
      try {
        await set(dbRef(getDatabase(), `users/${user.uid}`), {
          firstName: user.first_name,
          lastName: user.last_name,
          phone: user.phoneNumber,
          email: user.email,
          uid: user.uid,
        });
        toast.success("You are now a customer. Go ahead and login");
      } catch (error) {
        console.log(error);
        toast.error("Error: ensure you have all your details");
      }
      // console.log(is_customer.value);
    };

    const saveNames = async (e) => {
      e.preventDefault();
      const payload = {
        ...user,
        ...names.value,
      };
      try {
        await set(dbRef(getDatabase(), `admin_users/${user.uid}`), payload);
        toast.success("Successful");
        store.commit("login", payload);
      } catch (error) {
        console.log(error);
      }
    };
    return {
      email,
      names,
      saveNames,
      become_customer,
      is_customer,
    };
  },
};
</script>

<style lang="scss" scoped>
.input {
  border-radius: 12px;
}
</style>