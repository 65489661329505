import { user_location } from "./views/store/location";

export const getLocation = () => {
    let coords = {}
    if (navigator.geolocation) {
        // console.log('ddd')
        navigator.geolocation.getCurrentPosition(
            (data) => {
                coords = data.coords
                user_location.commit('updateLocation', data.coords)
                // console.log(data.coords)
            }
        );
    }else{
        // console.log('d')
    }
    return coords
}