<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
  >
    <path
      d="M3.67484 10.3691H31.3248M12.4248 3.07741V10.1649M22.5748 3.07741V9.50866M32.0832 13.1253V21.8753C32.0832 22.1962 32.0832 22.517 32.054 22.8232C31.4416 22.0684 30.6679 21.4605 29.7896 21.0441C28.9114 20.6277 27.951 20.4134 26.979 20.417C25.4332 20.417 24.004 20.9566 22.8811 21.8607C22.1088 22.471 21.4856 23.2491 21.0586 24.136C20.6316 25.0228 20.412 25.9952 20.4165 26.9795C20.4188 27.9519 20.6372 28.9117 21.0559 29.7894C21.4745 30.6671 22.083 31.4408 22.8373 32.0545C22.5311 32.0837 22.2103 32.0837 21.8748 32.0837H13.1248C5.83317 32.0837 2.9165 29.167 2.9165 21.8753V13.1253C2.9165 5.83366 5.83317 2.91699 13.1248 2.91699H21.8748C29.1665 2.91699 32.0832 5.83366 32.0832 13.1253Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M33.5415 26.9795C33.5395 27.9136 33.3382 28.8366 32.9509 29.6866C32.5636 30.5366 31.9993 31.2942 31.2957 31.9087C30.1436 32.9295 28.6415 33.542 26.979 33.542C25.4186 33.542 23.9748 32.9878 22.8519 32.0545H22.8373C22.083 31.4408 21.4745 30.6671 21.0559 29.7894C20.6372 28.9117 20.4188 27.9519 20.4165 26.9795C20.4165 24.9087 21.3644 23.0566 22.8811 21.8607C24.0418 20.925 25.4881 20.4155 26.979 20.417C29.0353 20.417 30.8582 21.3503 32.054 22.8232C32.9873 23.9462 33.5415 25.4045 33.5415 26.9795Z"
      stroke="white"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.4272 26.9791L26.046 28.5979L29.546 25.3604"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>