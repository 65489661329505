<template>
    <Text class="text-center text-[red] !mb-[20px] title-text" :size="props.size || 40 " :weight="600"> <slot /></Text>
  </template>
    
    <script setup>
  import Text from "@/components/Text.vue";
  const props = defineProps({
    size: Number
  });
  </script>
    

    <style lang="scss" scoped>
    .title-text{
      @media screen and (max-width: 992px) {
        font-size: 28px !important;
      }
    }
  </style>