export const getAllOccurancesOfItem = (meals = [], meal) => {

    // console.log(meal)
    let occured_in = []
    const multi_keywords = ['single ', 'double ', 'extra ']
    const extra_keys = ['extra', 'types', 'customize']
    const check = (item) => {
        const remove_multi_keyword = (name = '') => {
            let extra_name = name
            multi_keywords.forEach((keyword) => {
                extra_name = extra_name.replace(keyword, '')
            })
            return extra_name
        }
        const item_name = remove_multi_keyword(item?.name?.toLowerCase())
        const meal_name = remove_multi_keyword(meal?.name?.toLowerCase())
        // if (item_name === meal_name) console.log(({ item_name, meal_name, item }))
        return item_name === meal_name
    }

    meals.forEach((meal_item, index) => {
        if (check(meal_item)) {
            // console.log("meal",meal_item)
            const stoplist_path = (`/restaurants/${meal_item.restaurant_name}/food/${meal_item.food_category}/${meal_item.index_in_firebase}/available`);
            const amount_path = (`/restaurants/${meal_item.restaurant_name}/food/${meal_item.food_category}/${meal_item.index_in_firebase}/amount`);
            const self = meal_item
            occured_in.push({ meal_item, self, stoplist_path, amount_path })
        }

        extra_keys.forEach((extra_key) => {
            if (meal_item[extra_key]) {
                const occurance = meal_item[extra_key]?.find(check) || null
                const innerIndex = meal_item[extra_key]?.findIndex(check)
                if (occurance) {
                    // console.log(extra_key,meal_item[extra_key])
                    const stoplist_path = (`/restaurants/${meal_item.restaurant_name}/food/${meal_item.food_category}/${meal_item.index_in_firebase}/${extra_key}/${innerIndex}/available`)
                    const amount_path = (`/restaurants/${meal_item.restaurant_name}/food/${meal_item.food_category}/${meal_item.index_in_firebase}/${extra_key}/${innerIndex}/amount`)
                    const self = occurance
                    occured_in.push({ meal_item, extra_key, innerIndex, stoplist_path, self, amount_path })
                }
            }
        })

        if (meal_item?.options) {
            const opts = Object.entries(meal_item.options || {})
            opts.forEach(option => {
                option[1].forEach((item,innerIndex) =>{

                    const occurance = item
                    // const innerIndex = option[1].findIndex(check)
                    if (check(item)) {
                        console.log('options',option[1])
                        const stoplist_path = (`/restaurants/${meal_item.restaurant_name}/food/${meal_item.food_category}/${meal_item.index_in_firebase}/options/${option[0]}/${innerIndex}/available`)
                        const amount_path = (`/restaurants/${meal_item.restaurant_name}/food/${meal_item.food_category}/${meal_item.index_in_firebase}/options/${option[0]}/${innerIndex}/amount`)
                        const self = occurance
                        occured_in.push({ meal_item, extra_key: `options ${option[0]}`, innerIndex, stoplist_path, self, amount_path })
                    }
                })
               

            });
        }
    })


    if (occured_in.length > 1) return ({ occured_in, meal })

}