
<template>
  <head>
    <link
      rel="stylesheet"
      href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
    />
  </head>

  <layout :hasTopNav="false">
    <div class="px-[40px] bg-light w-full h-full" v-if="!selectId">
      <TopNav :onSearch="search" />
      <div class="d-flex justify-content-between orderClass p-3 w-full">
        <div class="w-full">
          <div class="flex w-full items-center justify-between">
            <h1>
              <Text :size="27" :weight="700">Manual Confirmation</Text>
              <!-- <span class="capitalize">{{ selectId }}</span> -->
            </h1>

            <!-- <status-button
                :buttons="[
                  { message: 'Active Orders', key: 'active' },
                  { message: 'Unconfirmed Orders', key: 'unconfirmed' },
                  { message: 'Cancelled Orders', key: 'cancelled' },
                  { message: 'Completed Orders', key: 'completed' },
                ]"
                :on-change="
                  (data) => {
                    status = data.key;
                    message = data.message;
                  }
                "
              /> -->
          </div>
         
        </div>
      </div>
      <section class="orderList !p-0 !bg-white">
        <div>
          <table class="order-table">
            <thead>
              <tr>
                <th class="table-header"></th>
                <th class="table-header">Date</th>
                <th class="table-header">Order ID</th>
                <th class="table-header">Type</th>
                <th class="table-header">Amount</th>
                <th class="table-header">Confirmed By</th>
                
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(order, index) in paginatedOrders"
                :key="order.id"
                class="table-row"
                :class="index % 2 === 0 ? 'even-row' : 'odd-row'"
              >
                <td class="table-data">
                  {{ index + 1 + 15 * (currentPage - 1) }}
                </td>
                <td class="table-data">
                  {{ formatDate(order.status?.Confirmed?.time) }}
                </td>
                <td class="table-data"><OrderID :order="order"/></td>
                <td class="table-data">{{ order?.confirmation_type }}</td>
                <td class="table-data">
                  <!-- {{ formatPrice(order?.total_price) }} -->
                  {{ formatPrice(order?.amount_due || order?.total_price) }}
                </td>
                <td class="table-data">
                  {{ getAdminWhoConfirmed(order) }}
                </td>
                <!-- <td class="table-data">
                  {{
                    `${order?.customer?.firstName} ${order?.customer?.lastName}`
                  }}
                </td>
                <td class="table-data">{{ order.locationType }}</td>
                <td
                  v-if="order.locationType?.toLowerCase() === 'delivery'"
                  class="table-data"
                >
                  {{ order.courier.name ? order.courier.name : "Unassigned" }}
                </td>
                <td v-else class="table-data">N/A</td> -->
                <!-- <td class="table-data">
                  <Status
                    :items="order.items"
                    :status="order.status"
                    :order="order"
                  />
                </td> -->

                <!-- <td class="table-data">{{ diffByStatus(order) }}</td> -->
                <!-- <td class="table-data"><Refund :order="order" :refundAmount="order.total_price"  />{{ order?.totalAmount }}</td> -->

                <!-- <td class="table-data">
                  <router-link
                    :to="`/operator/orders/${order?.id}`"
                    class="action-link !underline"
                    >View</router-link
                  >
                </td> -->
                <!-- <td class="table-data cursor-pointer">
                  <receipt :order="order">
                    <span class="underline">{{
                      order?.printed ? "Printed" : "Print"
                    }}</span>
                  </receipt>
                </td> -->
              </tr>
            </tbody>
          </table>
          <Pagination
            class="py-3"
            :currentPage="currentPage"
            :totalPages="totalPages"
            @page-change="goToPage"
          />
        </div>
        <!-- <div>
            <unconfirmed-orders v-if="status == 'unconfirmed'" />
            <cancelled-orders v-if="status == 'cancelled'" />
          </div> -->
      </section>
    </div>

    <!-- <OrderModal v-else-if="selectedOrder?.id" :selectedOrder="selectedOrder" /> -->
    <div v-else>loading...</div>
  </layout>
</template>
          
        <script>
import { ref, computed, watchEffect } from "vue";
import Pagination from "@/components/Pagination.vue";
// import OrderModal from "./OrderModal.vue";
import TopNav from "@/components/TopNav.vue";
import Modal from "@/components/Modal.vue";
import { getData } from "@/firebase/config";
import { useRoute, useRouter } from "vue-router";
import InputField from "@/components/InputField.vue";
import Text from "@/components/Text.vue";
import StatusButton from "@/components/StatusButton.vue";
import {
  sum,
  formatDate,
  getUeOrderId,
  diffByStatus,
  formatPrice,
} from "@/util";
import Status from "@/components/Status.vue";
import Layout from "@/partials/Layout/layout.vue";
import { store } from "@/views/store/store";
import TimeLeftDropdown from "@/components/TimeLeftDropdown.vue";
import Receipt from "@/components/Receipt.vue";
import Refund from "../Cancelled/Refund.vue";
import UnconfirmedOrders from "@/partials/UnconfirmedOrders.vue";
import CancelledOrders from "@/partials/CancelledOrders.vue";
import { searchOrder } from "@/util/filterOrder";
import OrderID from "@/components/OrderID.vue";
// import {  } from '@/util';
export default {
  components: {
    TopNav,
    Pagination,
    // OrderModal,
    Modal,
    InputField,
    Text,
    Status,
    Layout,
    TimeLeftDropdown,
    Receipt,
    Refund,
    StatusButton,
    UnconfirmedOrders,
    CancelledOrders,OrderID
  },
  setup() {
    const orders = ref(store.state.orders);
    const status = ref("active");
    const message = ref("Active Orders");
    const kitchen = getData("restaurants");
    const selectedOrder = ref({});
    const route = useRoute();
    const router = useRouter();
    const selectId = ref(route.params.id);
    //   const selectedKitchen = ref("");
    const searchValue = ref("");
    const selectedTime = ref("");
    //   const selectKitchen = (e) => {
    //     selectedKitchen.value = e?.target?.value;
    //   };

    const search = (e) => {
      searchValue.value = e;
    };

  
    const getManuallyConfirmedOrders = (orders = []) => {
      return orders?.filter((order) =>
        Object.values(order?.timeline || [])?.find(
          (timeline) => timeline.message?.includes("confirmed payment") || timeline.message?.includes("switched order") 
        )
      );
    };

    const getAdminWhoConfirmed = (order) => {
      return Object.values(order?.timeline ||  {})?.find(
        (timeline) => timeline.message?.includes("confirmed payment") || timeline.message?.includes("switched order") 
      )?.name;
    };
    watchEffect(async() => {
    
      if (searchValue.value) {
        orders.value =await searchOrder(
          getManuallyConfirmedOrders(store.state.orders),
          searchValue.value
        );
      } else {
        orders.value = getManuallyConfirmedOrders(store.state.orders);
      }

      if (selectedTime.value === "htl") {
        orders.value.sort((a, b) => {
          return b.countdown.operator_timeleft - a.countdown.operator_timeleft;
        });
      } else if (selectedTime.value === "lth") {
        orders.value.sort((a, b) => {
          return a.countdown.operator_timeleft - b.countdown.operator_timeleft;
        });
      }
    });

    // Pagination
    const ordersPerPage = 15;
    const currentPage = ref(1);
    const totalPages = computed(() =>
      Math.ceil(orders.value.length / ordersPerPage)
    );

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };

    watchEffect(() => {
      searchValue.value;
      currentPage.value = 1;
    });

    const paginatedOrders = computed(() => {
      const startIndex = (currentPage.value - 1) * ordersPerPage;
      const endIndex = startIndex + ordersPerPage;
      return orders.value.slice(startIndex, endIndex);
    });

    return {
      ordersPerPage,
      currentPage,
      totalPages,
      goToPage,
      paginatedOrders,
      // select,
      // status,
      message,
      formatPrice,
      sum,
      selectedOrder,
      kitchen,
      selectId,
      // selectKitchen,
      router,
      search,
      formatDate,
      getUeOrderId,
      diffByStatus,
      selectedTime,
      getAdminWhoConfirmed,
    };
  },
};
</script>
          
          <style scoped>
</style>