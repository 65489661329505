import { sum } from "@/util";

export const runExpiry = (history) => {
    // console.log('kkkkkkkk', history,)

    let fireHistory = history
    const adds = history?.filter((history) => history?.pointType === "add");
    const subtracts = history?.filter(
        (history) => history?.pointType === "subtract"
    );
    const calc = (arr) => sum(arr?.map((a) => a?.point));
    const diff = calc(adds) - calc(subtracts)
    console.log(diff)
    let last_mealpoint_unused = null
    if (diff) {
        let pts = 0
        
        for (let i = adds.length - 1; i >= 0; i--) {
            const item = adds[i]
            pts += item.point

            if (pts >= diff) {
                const indexInHistory = history.findIndex(x => x?.pointType == 'add' && x.orderId == item.orderId)
                console.log('stop', item, indexInHistory)
                last_mealpoint_unused = indexInHistory
                break
            }

        }
        const used_meal_points = history.slice(0, last_mealpoint_unused).filter(x => x.pointType == 'add')
        const unused_meal_points = history.slice(last_mealpoint_unused).filter(x => x.pointType == 'add')
        const partial = adds[used_meal_points.length] || {}
        const partialPoint =  sum(unused_meal_points.map(y => y.point)) - diff 
        used_meal_points.forEach((history) => {
            //update as used

        })
        
        console.log(diff,pts)
        console.log(used_meal_points.map(y => y.point))
        console.log({ used_meal_points: sum(used_meal_points.map(y => y.point)) })
        console.log({ unused_meal_points: sum(unused_meal_points.map(y => y.point)) })
        console.log({ partialPoint, partial  })
        console.log({unused_meal_points})


        unused_meal_points.filter(x => x.orderId === partial.orderId).forEach((history) => {
            //check if should be expired

        })

        if(partialPoint){
            // update partial 
           const partial_update = {...partial , usedPoint : partial.point - partialPoint, unUsedPoint : partialPoint}
           console.log({partial_update})
        }
    }

    // }
    subtracts.forEach((element, i) => {
        // console.log(element)
        // const 

        // if (element.pointType === 'subtract') {
        //     const substractPoint = element.point
        //     const addHistoryBeforeSubtract = fireHistory.slice(0, i).filter(history => history.pointType === 'add')

        //     const pointBeforeSubtract = sum(addHistoryBeforeSubtract.map(history => history.point))
        //     // console.log(pointBeforeSubtract)

        //     addHistoryBeforeSubtract.slice(0, addHistoryBeforeSubtract.length - 1).forEach(history => {
        //         const index = fireHistory.findIndex(t => t.orderId === history.orderId && t.pointType === 'add')
        //         fireHistory[index] = {
        //             ...fireHistory[index],
        //             leftPoint: 0
        //         }
        //     })

        //     addHistoryBeforeSubtract[addHistoryBeforeSubtract.length - 1] = {
        //         ...addHistoryBeforeSubtract[addHistoryBeforeSubtract.length - 1],
        //         leftPoint: substractPoint - pointBeforeSubtract
        //     }


        // }
    });
    console.log(fireHistory)
}