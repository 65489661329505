<template>
  <head>
    <link
      rel="stylesheet"
      href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
    />
  </head>

  <div class="top-nav py-3 px-1">
    <div class="flex">
      <span
        class="material-symbols-outlined cursor-pointer"
        v-if="arrowback"
        id="arrowback"
        @click="() => goBack()"
      >
        keyboard_backspace
      </span>
      <form
        action=""
        @submit.prevent="() => null"
        :class="`searchDef ${!searchBar ? 'opacity-0' : 'opacity-1'}`"
      >
        <button class="search__buttonDef focus--box-shadow" type="submit">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M17.8394 16.4605L14.1641 12.7852C15.0489 11.6072 15.5266 10.1733 15.525 8.7C15.525 4.93672 12.4633 1.875 8.7 1.875C4.93672 1.875 1.875 4.93672 1.875 8.7C1.875 12.4633 4.93672 15.525 8.7 15.525C10.1733 15.5266 11.6072 15.0489 12.7852 14.1641L16.4605 17.8394C16.6466 18.0058 16.8892 18.0945 17.1387 18.0876C17.3881 18.0806 17.6255 17.9784 17.8019 17.8019C17.9784 17.6255 18.0806 17.3881 18.0876 17.1387C18.0945 16.8892 18.0058 16.6466 17.8394 16.4605ZM3.825 8.7C3.825 7.73582 4.11091 6.79328 4.64658 5.99159C5.18226 5.18991 5.94363 4.56506 6.83442 4.19609C7.72521 3.82711 8.70541 3.73057 9.65106 3.91867C10.5967 4.10677 11.4654 4.57107 12.1471 5.25285C12.8289 5.93463 13.2932 6.80328 13.4813 7.74893C13.6694 8.69459 13.5729 9.67479 13.2039 10.5656C12.8349 11.4564 12.2101 12.2177 11.4084 12.7534C10.6067 13.2891 9.66418 13.575 8.7 13.575C7.40754 13.5734 6.16847 13.0593 5.25457 12.1454C4.34066 11.2315 3.82655 9.99245 3.825 8.7Z"
              fill="#828282"
            />
          </svg>
        </button>
        <input
          class="search__inputDef focus--box-shadow"
          type="search"
          placeholder="Search..."
          @input="(e) => search(e)"
        />
      </form>
    </div>

    <div class="flex gap-[20px] items-center">
      <PrevNext v-if="props.PrevNextIcon" :orders="props.PrevNextList" :paramKey="props.PrevNextParamKey" />
      <button class="circle-icon pr-1" @click="refresh()">
        <img src="https://www.svgrepo.com/show/142031/reload.svg" alt="" />
      </button>
    </div>
  </div>
</template>
        
      <script>
import { onMounted, ref, watchEffect } from "vue";
import getUser from "@/composables/getUser";
import { useRoute, useRouter } from "vue-router";
import PrevNext from "./PrevNext.vue";
import { store } from "@/views/store/store";
import {debounce} from '@/util/debounce.js'
// import store from '@/views/store';

export default {
  props: {
    onRefresh: Function,
    searchBar: {
      default: true,
      type: Boolean,
    },
    onSearch: {
      default: () => null,
      type: Function,
    },
    arrowback: {
      default: false,
      type: Boolean,
    },
    arrowbackpath: {
      type: String,
      default: "",
    },
    PrevNextIcon: {
      type: Boolean,
      default: false,
    },
    PrevNextList: {
      type: Array,
      default: [],
    },
    PrevNextParamKey:{
      type: String,
      default: 'id',
    }
  },
  components: { PrevNext },
  setup(props) {
    //to get user's display name for icon
    const { user } = getUser();
    const user1 = ref(user);
    const displayName = "Gbemi";
    const router = useRouter();
    const route = useRoute();

    const searchValue = ref("");
    const refresh = () => {
      router.go();
    };
    onMounted(() => {
      store.commit("search", "");
    });
    const search = debounce((e) => {
      searchValue.value = e.target.value.toLowerCase();
      store.commit("search", e.target.value.toLowerCase());
    },2000);
    const goBack = () => {
      const strpath = route.path;
      if (props.arrowbackpath === "back") {
        router.go(-1);
      } else {
        router.push(
          props.arrowbackpath
            ? props.arrowbackpath
            : strpath.substring(0, strpath.lastIndexOf("/"))
        );
      }
    };
    watchEffect(() => {
      props.onSearch(searchValue.value);
    });
    return {
      user,
      user1,
      displayName,
      refresh,
      route,
      router,
      props,
      search,
      goBack,
    };
  },
};
</script>
        
        <style scoped lang="scss">
.top-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  align-items: center;
  @media screen and (max-width:992px) {
    // display: none;
    width: 90%;
    z-index: 110;
    position: relative;
  }
}

.top-nav-left {
  display: flex;
  justify-content: space-between;
  justify-self: end;
}
.top-nav-left img {
  width: 20px;
  height: 20px;

  margin: 5px 10px;
}
.searchDef {
  padding: 12px;
  display: flex;
  align-items: center;
  margin: 0 10px;
  background-color: #fff;
  border-radius: 20px;
  height: 30px;
  width: 400px;
  @media screen and (max-width:992px) {
    // display: none;
    width: 90%;
  }
}

.search__buttonDef {
  background-color: #fff;
  border: none;
  border-radius: 20px;
  padding: 5px;
  cursor: pointer;
}

.search__iconDef {
  border-radius: 20px;
  width: 20px;
  height: 20px;
}

.search__inputDef {
  flex: 1;
  border: none;
  padding: 5px;
  outline: none;
  border-radius: 20px;

}

.user-image {
  margin-left: 10px;
}

.circle-icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #ffffff;
  color: #ffffff;
  border: none;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.toggle {
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 12.5px;
  position: relative;
  margin-top: 4px;
}
#toggle-switch {
  display: none;
}

.toggle-circle {
  width: 20px;
  height: 20px;
  background-color: orange;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
}

#toggle-switch:checked + label .toggle-circle {
  background-color: orange;
  transform: translateX(20px);
}
</style>