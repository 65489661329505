<template>
  <div>
    <Text :size="18" :weight="600">Rewards</Text>
    <!-- <hr /> -->
    <div class="py-3 flex-col gap-3 flex">
      <Text :size="14" :weight="600">
        Total MealBot points earned :
        {{ totalmealPointsEarned || 0 }}
      </Text>
      <Text :size="14" :weight="600">
        Total MealBot points redeemed :
        {{ totalMealBotPointRedeemed || 0 }}
      </Text>
      <Text :size="14" :weight="600">
        Total Mealbot point expired :
        {{ totalMealBotPointExpired || 0 }}
      </Text>
    </div>

    <div class="responsize-table w-100">
      <table class="meal-point-history-table">
        <thead>
          <tr class="w-full justify-between border-b">
            <th>Date</th>
            <th>Meal redeemed</th>
            <th>Points</th>
          </tr>
        </thead>
        <tbody class="w-100">
          <tr
            class="w-full border my-2"
            v-for="item in paginatedmealPointsRedeemed"
            :key="item.created"
          >
            <td>
              {{ dayjs(item.created.replaceAll('"', "")).format("MMM DD.") }}
            </td>
            <td>{{ item.meal?.join(",") || "--" }}</td>
            <td>{{ item.point }}</td>
          </tr>
        </tbody>
      </table>
      <Pagination
        class="py-3"
        :currentPage="currentPage"
        :totalPages="totalPages"
        @page-change="goToPage"
      />
    </div>
  </div>
</template>

<script setup>
import Pagination from "@/components/Pagination.vue";
import { sum } from "@/util";
import dayjs from "dayjs";
import { runExpiry } from "./runExpiry";
import { computed, ref, watchEffect } from "vue";

const props = defineProps({
  customer: Object,
});
const customer = ref({});
const totalMealBotPointRedeemed = ref(0);
const mealPointsRedeemed = ref([]);
const totalmealPointsEarned = ref(0);
const totalMealBotPointExpired = ref(0);

watchEffect(() => {
  customer.value = props.customer;
});

const getCustomers = async () => {
  // ------------
  if (customer.value?.mealPoint?.history) {
    const Allhistory = customer.value?.mealPoint?.history;
    runExpiry(Allhistory);
    // const adds = Allhistory?.filter((history) => history?.pointType === "add");
    // const subtracts = Allhistory?.filter(
    //   (history) => history?.pointType === "subtract"
    // );
    // const calc = (arr) => sum(arr?.map((a) => a?.point));
    // console.log(
    //   calc(adds.slice(0, adds.length - 1)),
    //   calc(subtracts.slice(0, subtracts.length - 1)),
    //   "dddd",
    //   calc(adds.slice(0, adds.length - 1)) -
    //     calc(subtracts.slice(0, subtracts.length - 1))
    // );

    const getMealBotBalanceByHistory = (history, orderId) => {
      let balance = 0;

      // console.log(history?.map((history) => history?.orderId)?.sort((a,b)=>a-b));
      const indexByOrderId = history
        ?.map((history) => history?.orderId)
        ?.indexOf(orderId);
      const history_to_calc = history?.slice(0, indexByOrderId + 1);
      // console.log({ history_to_calc });
      history_to_calc.forEach((history) => {
        if (history?.pointType === "add" && !history?.expired)
          balance = balance + history.point;
        if (history?.pointType === "subtract")
          balance = balance - history.point;
      });
      return balance;
    };

    // console.log(
    //   subtracts?.map((sub) => ({
    //     point: getMealBotBalanceByHistory(Allhistory, sub?.orderId),
    //     id: sub?.orderId,
    //   }))
    // );

    mealPointsRedeemed.value = customer.value?.mealPoint?.history
      ?.filter((x) => x.pointType === "subtract" && x.point && !x?.expired)
      .toReversed();

    let AllMealPointRedeemedList = mealPointsRedeemed.value.map((x) =>
      parseInt(x.point)
    );

    if (AllMealPointRedeemedList.length) {
      totalMealBotPointRedeemed.value = AllMealPointRedeemedList.reduce(
        (a, b) => a + b
      );
    }

    let allPoints = customer.value?.mealPoint?.history
      ?.filter((point) => point?.pointType == "add")
      ?.map((x) => parseInt(x.point));
    if (allPoints.length)
      totalmealPointsEarned.value = allPoints.reduce((a, b) => a + b);

    const expiredPoints = Math.floor(
      sum(
        customer.value?.mealPoint?.history
          ?.filter((point) => point?.expired && point?.pointType === "add")
          ?.map((point) => parseFloat(point?.point))
      )
    );
    if (expiredPoints) {
      totalMealBotPointExpired.value = expiredPoints;
      // console.log(customer.value?.mealPoint?.history);
    }
  }
  // ------------
};
watchEffect(async () => {
  getCustomers();
});

// Pagination
const meaPointPerPage = 10;
const currentPage = ref(1);
const totalPages = computed(() =>
  Math.ceil(mealPointsRedeemed.value.length / meaPointPerPage)
);

const goToPage = (page) => {
  if (page >= 1 && page <= totalPages.value) {
    currentPage.value = page;
  }
};

const paginatedmealPointsRedeemed = computed(() => {
  const startIndex = (currentPage.value - 1) * meaPointPerPage;
  const endIndex = startIndex + meaPointPerPage;
  return mealPointsRedeemed.value.slice(startIndex, endIndex);
});
</script>

<style lang="scss" scoped>
.meal-point-history-table {
  border-collapse: collapse;
  border-spacing: 0 15px;
  width: 100%;

  tr {
    row-gap: 12px;
    &:nth-child(even) {
      background: #efefef;
    }
  }
  th,
  td {
    padding: 5px;
  }
}
</style>