<template>
  <v-dialog v-model="custom_modal">
    <div class="bg-white rounded mx-auto w-[fit-content] p-3">
      <div class="flex flex-wrap gap-1 mb-2">
        <div
          @click="
            () => {
              getTime(time.value);
              custom_modal = false;
            }
          "
          class="px-4 cursor-pointer border rounded py-2"
          :class="selectedTime === time.value ? `!bg-[grey]` : ''"
          v-for="time in times"
          :key="time.value"
        >
          {{ time.label }}
        </div>
      </div>

      <div>
     
        <date-range-picker
          ref="picker"
          :opens="'right'"
          :dateRange="{
            startDate: start,
            endDate: end,
          }"
          :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:mm:ss' }"
          :minDate="new Date('2023-10-01')"
          :maxDate="new Date()"
          :singleDatePicker="false"
          :timePicker="timePicker"
          :timePicker24Hour="timePicker24Hour"
          :showWeekNumbers="false"
          :showDropdowns="false"
          :autoApply="false"
          v-model="picker"
          @update="() => null"
          @toggle="() => null"
          @start-selection="
            (e) => {
              selectedTime = 'custom';
              start = e;
            }
          "
          @finish-selection="
            (e) => {
              end = e;
              selectCustomRange(e);
            }
          "
          :linkedCalendars="true"
          :dateFormat="dateFormat"
        >
          <template v-slot:input="picker">
            {{ dayjs(picker?.startDate).format("MMM DD YYYY") }} -
            {{ dayjs(picker?.endDate).format("MMM DD YYYY") }}
          </template>
        </date-range-picker>
      </div>
    </div>
  </v-dialog>
  <div
    class="border px-2 py-2 rounded cursor-pointer"
    @click="() => (custom_modal = true)"
  >
    <span class="capitalize"> {{ selectedTime?.replace("-", " ") }}</span>
    <br />
    <span v-if="selectedTime == 'custom'">
      {{ dayjs(start).format("DD MMM") }} -
      {{ dayjs(end).format("DD MMM") }}
    </span>
  </div>
</template>


<script>
import { ref } from "vue";
import InputField from "@/components/InputField.vue";
import dayjs from "dayjs";
import { onMounted } from "vue";
import { store } from "@/views/store/store";
import VueDatepickerUi from "vue-datepicker-ui";
import weekday from "dayjs/plugin/weekday";
import "vue-datepicker-ui/lib/vuedatepickerui.css";

import DateRangePicker from "vue3-daterange-picker";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  components: { InputField, VueDatepickerUi, DateRangePicker },
  props: {
    getDate: Function,
  },
  setup(props) {
    dayjs.extend(weekday);
    const times = ref([
      { label: "Today", value: "today" },
      { label: "Yesterday", value: "yesterday" },
      { label: "This week", value: "this-week" },
      { label: "This month", value: "this-month" },
      { label: "Last month", value: "last-month" },
      { label: "This year", value: "this-year" },
      { label: "Lifetime", value: "life-time" },
      // { label: "Custom", value: "custom" },
    ]);

    let frameDefault = [
      "12:00 AM",
      "01:00 AM",
      "02:00 AM",
      "03:00 AM",
      "04:00 AM",
      "05:00 AM",
      "06:00 AM",
      "07:00 AM",
      "08:00 AM",
      "09:00 AM",
      "10:00 AM",
      "11:00 AM",
      "12:00 PM",
      "01:00 PM",
      "02:00 PM",
      "03:00 PM",
      "04:00 PM",
      "05:00 PM",
      "06:00 PM",
      "07:00 PM",
      "08:00 PM",
      "09:00 PM",
      "10:00 PM",
      "11:00 PM",
    ];
    const selectedTime = ref("today");
    const today = new Date();
    const custom_modal = ref(false);

    const getMilli = (date) => new Date(date).getTime();

    const start = ref(new Date(`${today.toDateString()} 12:00 AM`).getTime());
    const end = ref(new Date(`${today.toDateString()} 11:59 PM`).getTime());
    const frame = ref([]);
    const format = ref("hh:00 A");
    const picker = ref({
      startDate: new Date("2023-10-20"),
      endDate: new Date(),
    });

    function getDates(startDate, stopDate, unit, format) {
      let dateArray = new Array();
      let currentDate = startDate;
      while (currentDate <= stopDate) {
        dateArray.push(new Date(currentDate));
        currentDate = dayjs(currentDate).add(1, unit);
      }
      return dateArray?.map((date) => dayjs(date).format(format));
    }

    const selectCustomRange = (e) => {
      if (selectedTime.value === "custom") {
        start.value = new Date(
          `${start.value?.toDateString()} 12:00 AM`
        ).getTime();

        end.value = new Date(`${end.value?.toDateString()} 11:59 PM`).getTime();

        const diff = Math.abs(dayjs(start.value).diff(end.value, "days"));

        if (diff == 0) {
          frame.value = getDates(start.value, end.value, "hour", "hh:00 A");
          format.value = "hh:00 A";
        }

        if (diff >= 1 && diff <= 45) {
          frame.value = [];
          frame.value = getDates(start.value, end.value, "day", "DD MMM YYYY");
          format.value = "DD MMM YYYY";
        }
        if (diff >= 46 && diff <= 90) {
          frame.value = [];
          frame.value = getDates(start.value, end.value, "month", "MMM YYYY");
          format.value = "MMM YYYY";
        }
        // if (diff >= 1) {
        //   frame.value = [];
        //   for (let index = 1; index <= diff; index++) {
        //     let day = `${index}-${dayjs().format("MMM")}-${dayjs().format(
        //       "YYYY"
        //     )}`;
        //     frame.value.push(day);
        //   }
        // }

        getTime("custom");
      }
    };

    const getTime = (e) => {
      // console.log(e)
      const time = e;
      selectedTime.value = e;

      if (time === "today") {
        start.value = new Date(`${today.toDateString()} 12:00 AM`).getTime();
        end.value = new Date(`${today.toDateString()} 11:59 PM`).getTime();
        // frame.value = frameDefault;
        // format.value = "hh:00 A";
        frame.value = getDates(start.value, end.value, "hour", "hh:00 A");
        format.value = "hh:00 A";
      } else if (time === "yesterday") {
        let todayMillisecs = getMilli(`${today.toDateString()} 12:00 AM`);
        const yesterdaystart = getMilli(
          `${new Date(todayMillisecs - 86400000).toDateString()} 12:01 AM`
        );
        const yesterdayend = getMilli(
          `${new Date(todayMillisecs - 86400000).toDateString()} 11:59 PM`
        );
        start.value = yesterdaystart;
        end.value = yesterdayend;
        // frame.value = frameDefault;
        // format.value = "hh:00 A";
        frame.value = getDates(start.value, end.value, "hour", "hh:00 A");
        format.value = "hh:00 A";
      } else if (time === "this-week") {
        // const monday = getMilli(today) - ((|| -6) - 1) * 86400000;
        const daynum = today.getDay();
        const subtract = daynum ? daynum - 1 : 6;
        const monday = dayjs().subtract(subtract, "days");

        const sunday = monday.add(6, "days"); //monday plus 7 days
        start.value = new Date(
          `${new Date(monday).toDateString()} 12:00 AM`
        ).getTime();
        end.value = new Date(
          `${new Date(sunday).toDateString()} 11:59 PM`
        ).getTime();
        // console.log(dayjs(start.value).format('DD-MMM-YYYY'), dayjs(end.value).format('DD-MMM-YYYY'));
        frame.value = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
        frame.value = getDates(start.value, end.value, "day", "ddd");
        format.value = "ddd";

        // console.log(daynum, "dddd", monday.format("DD MMM"));
      } else if (time === "this-month") {
        frame.value = [];
        const dayInMonth = dayjs().daysInMonth();
        for (let index = 1; index <= dayInMonth; index++) {
          let day = `${index}-${dayjs().format("MMM")}-${dayjs().format(
            "YYYY"
          )}`;
          frame.value.push(day);
        }
        start.value = new Date(dayjs().startOf("month")).getTime();
        end.value = new Date(dayjs().endOf("month")).getTime();
        // console.log({ frame });

        format.value = "D-MMM-YYYY";
      } else if (time === "last-month") {
        frame.value = [];
        const last_month = dayjs(new Date().toDateString()).subtract(
          1,
          "month"
        );
        const dayInMonth = dayjs(last_month).daysInMonth();
        // console.log(dayInMonth)
        for (let index = 1; index <= dayInMonth; index++) {
          let day = `${index}-${dayjs(last_month).format("MMM")}-${dayjs(
            last_month
          ).format("YYYY")}`;
          frame.value.push(day);
        }
        // console.log(last_month.startOf("month"));
        start.value = new Date(last_month.startOf("month")).getTime();
        end.value = new Date(last_month.endOf("month")).getTime();
        format.value = "D-MMM-YYYY";
      } else if (time === "this-year") {
        frame.value = [];
        const monthsInYear = 12;
        const mS = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "June",
          "July",
          "Aug",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ];
        // console.log(monthsInYear)
        for (let index = 1; index <= monthsInYear; index++) {
          let day = `${mS[index - 1]} ${today.getFullYear()}`;
          frame.value.push(day);
        }
        start.value = new Date(`01 ${frame.value[0]}`).getTime(); //YEAR BEGIN
        end.value = new Date(`31 ${frame.value[11]}`).getTime(); // YEAR END

        format.value = "MMM YYYY";
        // console.log({start,end})
      } else if (time === "life-time") {
        frame.value = [];
        const orders = store.state.orders.toReversed();
        const orders_len = orders?.length;
        const unique = new Set();
        for (let index = 0; index <= orders_len; index++) {
          let day = dayjs(orders[index]?.date?.replaceAll('"', "")).format(
            "MMM YYYY"
          );
          unique.add(day);
        }
        frame.value = Array.from(unique);
        start.value = new Date(
          dayjs(orders[0]?.date?.replaceAll('"', ""))
        ).getTime(); // YEAR BEGIN
        end.value = new Date(
          dayjs(orders[orders_len - 1]?.date?.replaceAll('"', ""))
        ).getTime(); // YEAR END

        format.value = "MMM YYYY";
      } else if (time === "custom") {
        // console.log('csddd')
      }
      //  else {
      //   start.value = new Date(`${today.toDateString()} 12:00 AM`).getTime();
      //   end.value = new Date(`${today.toDateString()} 11:59 PM`).getTime();
      //   frame.value = frameDefault;
      // }

      props.getDate({
        time,
        start: start.value,
        end: end.value,
        frame: frame.value,
        format: format.value,
      });

      // custom_modal.value = false;
    };

    onMounted(() => {
      getTime("today");
    });

    return {
      times,
      getTime,
      selectedTime,
      custom_modal,
      // customDateRange,
      start,
      end,
      dayjs,
      // disabledEndDate,
      picker,
      // disabledStartDate,
      selectCustomRange,
    };
  },
};
</script>

<style lang="scss" scoped>
.comp {
  display: flex;
  color: #f60100;
  //   width: 100px;
  //   border-radius: 10px;
  & > div {
    border: none !important;
    display: none;
  }
  gap: 12px;
  background: linear-gradient(
    202deg,
    rgba(255, 145, 3, 0.21) 0%,
    rgba(246, 1, 0, 0.21) 100%
  );
}
</style>