<template>
  <br />
  <Text :weight="600" :size="18">Delivery time ranges</Text>
  <div class="flex gap-3 flex-wrap">
    <div
      class="border p-3 w-[300px] rounded mb-2"
      v-for="item in delivery_range_types"
      :key="item.id"
    >
      <Text :weight="600" class="capitalize"> {{ item.name }}</Text>
      <hr />
      <div class="mt-3">
        Delivery time
        <input
          class="rounded border"
          v-model="item.delivery_time"
          type="text"
        />
      </div>
      <div class="mt-3">
        Estimated time
        <input
          class="rounded border"
          v-model="item.estimated_time"
          type="text"
        />
      </div>
      <div class="mt-3">
        Estimated checkout time
        <input
          class="rounded border"
          v-model="item.estimated_checkout_time"
          type="text"
        />
      </div>
      <div class="mt-3">
        Minimum spend
        <input
          class="rounded border"
          v-model="item.minimum_spend"
          type="text"
        />
      </div>
      <div class="mt-3">
        Minimum spend statement
        <input
          class="rounded border"
          v-model="item.minimum_spend_statement"
          type="text"
        />
      </div>
    </div>
  </div>
  <ue-button class="w-[200px] p-2" @click="saveChanges">Save</ue-button
  ><br /><br />
</template>

<script setup>
import Text from "@/components/Text.vue";
import UeButton from "@/components/UeButton.vue";
import { get, getDatabase, ref as dbRef, set } from "firebase/database";
import { onMounted, ref } from "vue";
import { useToast } from "vue-toast-notification";

const delivery_range_types = ref([]);
const isLoading = ref(false);

const fetchData = async () => {
  const res = await get(dbRef(getDatabase(), "delivery_range_types"));
  if (res?.val()) delivery_range_types.value = res?.val();
};

onMounted(() => {
  fetchData();
  //   console.log(delivery_locations.value);
});
const toast = useToast({position:'top-right'})

const saveChanges = async () => {
  console.log(delivery_range_types.value);
  isLoading.value = true;
  try {
    await set(
      dbRef(getDatabase(), "delivery_range_types"),
      delivery_range_types.value
    );
    toast.success("Success")
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
};

// [
//   {
//     name: "regular",
//     estimated_checkout_time: "45-65",
//     estimated_time: "30-50",
//     delivery_time: 50,
//     minimum_spend:0,
//     minimum_spend_statement:"",
//     unit: "mins",
//   },
//   {
//     name: "extended",
//     estimated_checkout_time: "60-90",
//     estimated_time: "60-90",
//     delivery_time: 60,
//     minimum_spend:0,
//     minimum_spend_statement:"",
//     unit: "mins",
//   },
//   {
//     name: "peak-regular",
//     estimated_checkout_time: "75-85",
//     estimated_time: "40-80",
//     delivery_time: 60,
//     minimum_spend:0,
//     minimum_spend_statement:"",
//     unit: "mins",
//   },
//   {
//     name: "peak-extended",
//     estimated_checkout_time: "90-120",
//     estimated_time: "90-120",
//     delivery_time: 90,
//     minimum_spend:4000,
//     minimum_spend_statement:"",
//     unit: "mins",
//   },
// ];
</script>

<style lang="scss" scoped>
</style>