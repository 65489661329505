
<template>
  <head>
    <link
      rel="stylesheet"
      href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
    />
  </head>

  <router-view :key="route.params.id">
    <Layout :hasTopNav="false">
      <div class="bg-light h-[100%] ue-container">
        <Modal>
          <div v-if="modal == 'cancel-item'">
            <MealModal
              title="Cancel Meal Order?"
              :content="'Are you certain that you want to cancel this meal order?'"
              :rightClick="() => setCanledced()"
              firstBtn="No, Cancel"
              secondBtn="YES, Confirm"
              :index="cancelid"
              :closeBtn="true"
              :tickBtn="false"
              :id="selectedOrder.id"
            />
          </div>
        </Modal>

        <top-nav
          :searchBar="false"
          :arrowback="true"
          :PrevNextIcon="true"
          :PrevNextList="prevnextlist"
        />
        <div class="d-flex justify-content-between">
          <h2>Order ID {{ route.params.id }}</h2>
        </div>

        <div
          class="flex items-center justify-between p-5 my-4 h-[100px] bg-white rounded-[16px]"
        >
          <div>
            <Text :weight="600" :size="15" :color="'rgba(0, 0, 0, 0.74)'">{{
              selectedOrder?.customer?.firstName +
              " " +
              selectedOrder?.customer?.lastName
            }}</Text>
            <Text :weight="600" :size="12" :color="'#F60100'">{{
              selectedOrder?.customer?.phone
            }}</Text>
          </div>
          <div
            class="flex gap-2"
            v-if="selectedOrder?.locationType === 'Delivery'"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 12C12.55 12 13.021 11.804 13.413 11.412C13.805 11.02 14.0007 10.5493 14 10C14 9.45 13.804 8.979 13.412 8.587C13.02 8.195 12.5493 7.99933 12 8C11.45 8 10.979 8.196 10.587 8.588C10.195 8.98 9.99933 9.45067 10 10C10 10.55 10.196 11.021 10.588 11.413C10.98 11.805 11.4507 12.0007 12 12ZM12 19.35C14.0333 17.4833 15.5417 15.7873 16.525 14.262C17.5083 12.7367 18 11.3827 18 10.2C18 8.38333 17.4207 6.896 16.262 5.738C15.1033 4.58 13.6827 4.00067 12 4C10.3167 4 8.89567 4.57933 7.737 5.738C6.57833 6.89667 5.99933 8.384 6 10.2C6 11.3833 6.49167 12.7377 7.475 14.263C8.45833 15.7883 9.96667 17.484 12 19.35ZM12 22C9.31667 19.7167 7.31267 17.596 5.988 15.638C4.66333 13.68 4.00067 11.8673 4 10.2C4 7.7 4.80433 5.70833 6.413 4.225C8.02167 2.74167 9.884 2 12 2C14.1167 2 15.9793 2.74167 17.588 4.225C19.1967 5.70833 20.0007 7.7 20 10.2C20 11.8667 19.3373 13.6793 18.012 15.638C16.6867 17.5967 14.6827 19.7173 12 22Z"
                fill="#F60100"
              />
            </svg>
            <Text
              :weight="600"
              :size="15"
              :color="'rgba(0, 0, 0, 0.74)'"
              class="w-[250px]"
            >
              {{
                selectedOrder?.customer?.deliveryAddress?.houseNo ||
                "" +
                  " " +
                  selectedOrder?.customer?.deliveryAddress?.houseAddress ||
                ""
              }}
            </Text>
          </div>
          <div>
            <Text :weight="600" :size="15" :color="'rgba(0, 0, 0, 0.74)'">
              {{ formatDate(selectedOrder?.date) }}
            </Text>
          </div>
          <div>
            <Text :weight="600" :size="15" :color="'rgba(0, 0, 0, 0.74)'"
              >₦ {{ selectedOrder?.total_price?.toLocaleString() }}</Text
            >
          </div>
          <div>
            <Text :weight="600" :size="15" :color="'#009A49'">
              {{
                selectedOrder?.status?.Delivered?.state ||
                selectedOrder?.status?.Pickup?.state
                  ? "Time spent : "
                  : "Time left : "
              }}
              {{ diffByStatus(selectedOrder) }}
            </Text>
          </div>
        </div>

        <table class="w-100">
          <thead>
            <tr>
              <!-- <th></th> -->
              <th>Name</th>
              <th>Quantity</th>
              <th>Prepared</th>
              <th>Cancelled</th>
              <th>Unit Amount</th>
              <th>Cancelled Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in items" :key="i">
              <!-- <td class="table-data">{{ i }}</td> -->
              <td class="table-data py-3">
                <p>{{ item.name }}</p>
                <Text
                  :weight="400"
                  v-for="addon in item?.addons"
                  :key="addon.name"
                >
                  {{ addon.name }} {{ formatPrice(addon.amount) }}

                  <!-- {{ addon.cancelled }} -->

                  <item-meal-canceled :addon="addon" :item="item" />
                </Text>
                <Text
                  :weight="400"
                  v-for="side in item?.sides"
                  :key="side.name"
                >
                  {{ side.name }} {{ formatPrice(side.amount) }}

                  <!-- {{ side?.cancelled }} -->

                  <item-meal-canceled :addon="side" :item="item" />
                </Text>
              </td>
              <td class="table-data py-3">{{ item.quantity }}</td>
              <td class="table-data py-3">{{ item.prepared }}</td>
              <td class="table-data py-3">{{ item.canceled }}</td>
              <td class="table-data py-3">{{ formatPrice(item.amount) }}</td>
              <td class="table-data py-3">
                {{ formatPrice(cancelledAmount(item)) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Layout>
  </router-view>
</template>
       <script scoped>
import { ref, computed, watchEffect, h, render } from "vue";
import Pagination from "@/components/Pagination.vue";
import TopNav from "@/components/TopNav.vue";
import Modal from "@/components/Modal.vue";
import { store } from "@/views/store/store";
import MealModal from "@/views/main/Kitchen/Meals/MealModal.vue";
import { useRoute, useRouter } from "vue-router";
import QuantityCounter from "@/components/QuantityCounter.vue";
import Countdown from "@/partials/countdown.vue";
import ProgressionBar from "@/components/ProgressionBar.vue";
import Text from "@/components/Text.vue";
import ItemMeal from "@/components/ItemMeal.vue";
import { diffByStatus, formatDate, formatPrice, sum } from "@/util";
import InputField from "@/components/InputField.vue";
import { ref as dbRef, getDatabase, get } from "firebase/database";
import ItemsTable from "@/partials/ItemsTable.vue";
import CourierDeets from "@/partials/CourierDeets.vue";
import Layout from "@/partials/Layout/layout.vue";
import Timeline from "@/components/Timeline.vue";
import Receipt from "@/components/Receipt.vue";
import UeTable from "@/components/UeTable.vue";
import ItemMealCanceled from "@/components/ItemMealCanceled.vue";

import { cancelledAmount } from "./cancelcalculation";
export default {
  props: {
    selectedOrder: Object,
    modal: Boolean,
  },
  components: {
    TopNav,
    Pagination,
    Modal,
    MealModal,
    QuantityCounter,
    Countdown,
    ProgressionBar,
    Text,
    ItemMeal,
    InputField,
    ItemsTable,
    CourierDeets,
    Layout,
    Timeline,
    Receipt,
    UeTable,
    Text,ItemMealCanceled
  },
  setup(props) {
    const selectedOrder = ref({});
    const selected = ref("active");
    const message = ref("Active Orders");
    const status = ref({});
    const items = ref([]);
    const modal = ref(store.state.modal.data);
    const router = useRouter();
    const prevnextlist = ref([]);
    const { id } = useRoute().params;
    const route = useRoute();

    watchEffect(() => {
      modal.value = store.state.modal.data;
    });
    const select = (type) => {
      selected.value = type;
      message.value = type === "active" ? "Active Orders" : "Completed Orders";
    };
    const _ooo = h("div", { class: "bar", innerHTML: "hello" });

    const fetchData = async (id) => {
      try {
        const snapshot = await get(dbRef(getDatabase(), "orders/" + id));

        if (snapshot.val()) {
          selectedOrder.value = snapshot.val();
          console.log(snapshot.val());
          items.value = selectedOrder.value.items;
        }
      } catch (error) {
        console.log(error);
      }
    };
    // const show =(data)=>h('div')
    router.afterEach((to, from) => {
      if (to.params.id) fetchData(to.params.id);
    });
    watchEffect(() => {
      fetchData(id);
    });

    watchEffect(() => {
      //create list of id's for the prevnext list operator

      //determine if its active or completed
      prevnextlist.value = store.state.orders.filter((x) => {
        // let type = Boolean(
        //   selectedOrder.value?.status?.Delivered?.state ||
        //     selectedOrder.value?.status?.Pickup?.state
        // );
        // let res = x?.status?.Delivered?.state || x?.status?.Pickup?.state;
        return x?.status?.Cancelled?.state
      });

      console.log({prevnextlist})
    });

    // Pagination
    const ordersPerPage = 5;
    const currentPage = ref(1);
    const totalPages = computed(() =>
      Math.ceil(orders.value.length / ordersPerPage)
    );

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };

    const paginatedOrders = computed(() => {
      const startIndex = (currentPage.value - 1) * ordersPerPage;
      const endIndex = startIndex + ordersPerPage;
      return orders.value.slice(startIndex, endIndex);
    });

    return {
      ordersPerPage,
      currentPage,
      totalPages,
      goToPage,
      paginatedOrders,
      select,
      selected,
      message,
      props,
      prevnextlist,
      items,
      modal,
      status,
      sum,
      router,
      route,
      selectedOrder,
      formatDate,

      diffByStatus,
      formatPrice,
      h,
      _ooo,
      render,
      cancelledAmount,
    };
  },
};
</script>
     
     <style lang="scss">
table {
  tr {
    &:nth-child(even) {
      background-color: #efefef;
    }
  }
}
</style>
  