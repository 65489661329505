import { getDatabase, set, ref as dbRef } from "firebase/database";

export const setCancelRefundStatus = async (order, amount, type,onSuccess = () => null) => {
    const is_all_cancelled = order?.items?.every(
        (item) => item.quantity == item.canceled
    );


    try {
        if (is_all_cancelled) {
            const path = `/orders/${order.id}/status/Cancelled`;
            await set(dbRef(getDatabase(), path), {
                state: true,
                time: JSON.stringify(new Date().toISOString())
            });
        } else {
            const path = `/orders/${order.id}/total_price`;
            const refund_completed = `/orders/${order.id}/refund_completed`;
            await set(dbRef(getDatabase(), path), order.total_price - amount);
            await set(dbRef(getDatabase(), refund_completed), type);
        }
        // await set(dbRef(getDatabase(), path), true);
        // console.log(order.items)
        // if (!order?.items?.filter(item => item.canceled == item.quantity).length) {
        //     window.confirm('This will cancel the whole order and refund the whole order price as no item was prepared')
        // }
        // toast.success("Refund complete");
        // toast.success("");
        // openModal.value = false;
        onSuccess()
    } catch (error) {
        console.log(error);
    }
}