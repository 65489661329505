<template>
  <Text :size="props.size || 30" :weight="600" class="header-blog">
    <slot
  /></Text>
</template>
  
  <script setup>
import Text from "@/components/Text.vue";
const props = defineProps({
  size: Number,
});
</script>
  
  <style lang="scss" scoped>
@media screen and (max-width: 992px) {
  .header-blog {
   @media screen and (max-width: 992px) {
    font-size: 20px !important;
   }
  }
}
</style>