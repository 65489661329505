<template>
  <div
    @click="() => (openSaleModal = true)"
    class="cursor-pointer hover:underline"
  >
   View sales report
     <!-- <br>
    No. of times ordered : {{ selectedMealOrder?.length }} <br />
        Quantity ordered : {{ qunatityOrdered }} -->
  </div>
  <v-dialog v-model="openSaleModal">
    <div class="mx-auto w-[600px] rounded bg-white">
      <div class="flex border p-2 !w-[100%] justify-between items-center">
        <div>
          {{ props.meal?.name }}
        </div>
        <time-drop-down :getDate="(data) => (timeFrame = data)" />
      </div>
      <div class="h-[400px] p-2">
        No. of times ordered : {{ selectedMealOrder?.length }} <br />
        Quantity ordered : {{ qunatityOrdered }}
        <div class="h-[350px]">
          <Line
            class=""
            :data="data"
            :options="options"
            :key="timeFrame?.start"
          />
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script setup>
import { getOrdersByTimeFrame, sum } from "@/util";
import { reactive, ref, watchEffect } from "vue";
import TimeDropDown from "../../Operator/Dashboard/TimeDropDown.vue";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "vue-chartjs";
import dayjs from "dayjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);
const props = defineProps({
  meal: Object,
});

const openSaleModal = ref(false);
const timeFrame = ref({
  frame:[],
  format:''
});
const data = ref({
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: "Sales report",
      fill: true,
      backgroundColor: (ctx) => {
        const canvas = ctx.chart.ctx;
        const gradient = canvas.createLinearGradient(255, 145, 3, 300);
        return gradient;
      },
      tension: 0.25,
      borderColor: "red",
      // data: [40, 39, 10, 40, 39, 80,656],
      data: [],
      fill: true,
    },
  ],
});
const selectedMealOrder = ref([]);
const qunatityOrdered = ref(0);
const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
};
watchEffect(async () => {
  // console.log(timeFrame.value,'lll')
  timeFrame.value;
  let ordersInTimeFrame = await getOrdersByTimeFrame(
    timeFrame.value?.start,
    timeFrame.value?.end
  );
  //   console.log(props.meal);
  if (ordersInTimeFrame.length) {
    const items = ordersInTimeFrame?.flatMap((order) =>
      order?.items?.map((item) => ({ ...item, date: order?.date }))
    );
    selectedMealOrder.value = items.filter(
      (item) =>
        item?.resturant === props.meal?.restaurant_name &&
        item?.name === props.meal?.name
    );
    // console.log(selectedMealOrder.value, timeFrame.value);
    const { frame, format } = timeFrame.value;
    let list = [];
    qunatityOrdered.value = 0
    frame.forEach((element, i) => {
      const orderInFrame = selectedMealOrder.value?.filter(
        (mealOrder) =>
          dayjs(mealOrder.date.replaceAll('"', "")).format(format) === element
      );
      const quantitiesSum = sum(
        orderInFrame.map(
          (item) => item.quantity - (item.cancelled || item.canceled || 0)
        )
      );
      list[i] = quantitiesSum;
      qunatityOrdered.value = qunatityOrdered.value + quantitiesSum;
    });

    data.value = {
      // labels: [ 'January', 'February', 'March' ],
      // datasets: [ { data: [40, 20, 12] } ]
      labels: frame,
      datasets: [
        {
          label: "Sales report",
          fill: true,
          backgroundColor: (ctx) => {
            const canvas = ctx.chart.ctx;
            const gradient = canvas.createLinearGradient(255, 145, 3, 300);
            return gradient;
          },
          tension: 0.25,
          borderColor: "red",
          data: list,
          fill: true,
        },
      ],
    };

    console.log(data.value);
  }
});

// console.log(props.meal, "dd");
</script>

<style lang="scss" scoped>
</style>