<template>
    <div class="pagination">
      <button class="btn btn-dark" @click="goToPage(currentPage - 1)" :disabled="currentPage === 1">Previous</button>
      <span class="page-number">{{ currentPage }} / {{ totalPages }}</span>
      <button class="btn btn-dark" @click="goToPage(currentPage + 1)" :disabled="currentPage === totalPages">Next</button>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      currentPage: {
        type: Number,
        required: true,
      },
      totalPages: {
        type: Number,
        required: true,
      },
    },
    methods: {
      goToPage(page) {
        if (page >= 1 && page <= this.totalPages) {
          this.$emit('page-change', page);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  button {
    margin: 0 5px;
    background-color: #343a40;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background: linear-gradient(180deg, #ff9103 0%, #f60100 100%);
  }
  
  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  span.page-number {
    margin: 0 10px;
    font-weight: bold;
  }
  button.btn {
  background: linear-gradient(180deg, #ff9103 0%, #f60100 100%);
  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
}
  </style>
  