import { set, ref as dbRef, getDatabase, } from "firebase/database"

const saveInitialOrderTotal = async (order) => {
    // if(!order) return;
    // console.log(order)
    if (order?.id) {
        try {
            const total_price = order?.total_price
            const amount_due = order?.amount_due
            if (!order?.initialOrderTotal) await set(dbRef(getDatabase(), `orders/${order?.id}/initialOrderTotal`), total_price)
            if (!order?.initialOrderAmountDue) await set(dbRef(getDatabase(), `orders/${order?.id}/initialOrderAmountDue`), amount_due)
            return total_price
        } catch (error) {
            console.log('Initial order total error: ', error)
        }
    } else {
        console.log('Initial order total error: ', 'No order provided')
    }
}
export { saveInitialOrderTotal }