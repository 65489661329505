<template>
  <v-dialog v-model="openEditModal" width="auto">
    <add-rider
      :type="'edit'"
      :rider="rider"
      :onClose="() => (openEditModal = false)"
    />
  </v-dialog>
  <v-dialog v-model="openDeleteModal" width="auto">
    <div class="bg-white p-4 rounded-[12px] w-[400px]">
      <MealModal
        :title="rider.deleted ? 'Restore rider' : 'Delete rider?'"
        :content="
          rider.deleted
            ? 'Are you sure you want to restore rider?'
            : 'Are you sure you want to delete rider?'
        "
        :firstBtn="'No, Cancel'"
        :secondBtn="'YES, Confirm'"
        :closeBtn="true"
        :tickBtn="false"
        :rightClick="confirmDeleteRider"
        :oncancel="() => (openDeleteModal = false)"
      />
    </div>
  </v-dialog>

  <v-dialog v-model="openPermernenntDeleteModal" width="auto">
    <div class="bg-white p-4 rounded-[12px] w-[400px]">
      <MealModal
        :title="'Permernent Delete rider?'"
        :content="'Are you sure you want to delete rider?'"
        :firstBtn="'No, Cancel'"
        :secondBtn="'YES, Confirm'"
        :closeBtn="true"
        :tickBtn="false"
        :rightClick="permernentDeleteRider"
        :oncancel="() => (openPermernenntDeleteModal = false)"
      />
    </div>
  </v-dialog>
  <v-dialog v-model="shiftModal" width="auto">
    <div class="bg-white p-4 rounded-[12px] w-[400px]">
      <MealModal
        :title="`${riderStatus ? 'End' : 'Start'} rider's shift`"
        :content="`Are you sure you want to ${
          riderStatus ? 'end' : 'start'
        } rider's riders shift?`"
        :firstBtn="'No, Cancel'"
        :secondBtn="'YES, Confirm'"
        :closeBtn="false"
        :tickBtn="true"
        :rightClick="updateShift"
        :oncancel="() => (shiftModal = false)"
      />
    </div>
  </v-dialog>

  <div class="rider">
    <TopNav :searchBar="false" :arrowback="true" class="!hidden" />
    <div class="header boxbody relative z-[1]">
      <div class="imgeDITS">
        <Label class="block w-[100%] h-[100%]" for="editimg">
          <img
            :src="img"
            alt=""
            v-if="rider?.image_url && !preview"
            class="object-fit"
          />
          <img :src="preview" alt="" v-if="preview" class="object-fit" />
          <Text
            :weight="600"
            :size="16"
            v-if="!rider?.image_url && !preview"
            class=""
            >No photo</Text
          >
          <input
            type="file"
            class="hidden"
            id="editimg"
            accept="image/png, image/gif, image/jpeg"
            @input="editImg"
          />
        </Label>
        <div
          class="rounded p-3 my-1 flex justify-between w-full"
          v-if="preview"
        >
          <div
            @click="() => (preview = '')"
            class="rounded cursor-pointer py-1 px-3 my-1 border w-[fit-content]"
          >
            Cancel
          </div>
          <div
            @click="saveNewPicture"
            class="rounded cursor-pointer py-1 px-3 my-1 border w-[fit-content]"
          >
            Save
          </div>
        </div>
      </div>

      <div class="flex flex-col justify-evenly == w-[60%] gap-3">
        <div class="flex items-center w-full !h-[50px] !gap-4">
          <div class="editor">
            <Text :weight="400" :size="16">First name </Text>
            <Text :weight="600" :size="20">{{ rider.first_name }} </Text>
          </div>
          <div class="editor">
            <Text :weight="400" :size="16">Last name </Text>
            <Text :weight="600" :size="20">{{ rider.last_name }}</Text>
          </div>
        </div>
        <div class="flex items-center w-full !h-[50px] !gap-4">
          <div class="editor">
            <Text :weight="400" :size="16">Phone </Text>
            <Text :weight="600" :size="20">{{ rider.phone }}</Text>
          </div>
          <div class="editor">
            <Text :weight="400" :size="16">Email</Text>
            <Text :weight="600" :size="20">{{ rider.email }}</Text>
          </div>
        </div>

        <div class="flex items-center w-full !h-[50px] !gap-4">
          <div class="editor">
            <Text :weight="400" :size="16">Plate number :</Text>
            <Text :weight="600" :size="20">{{ rider.plate_number }}</Text>
          </div>
          <div class="editor">
            <Text :weight="400" :size="16">Status :</Text>
            <Text :weight="600" :size="20">{{ rider.status }}</Text>
          </div>
        </div>

        <div class="flex items-center w-full !h-[50px] !gap-4">
          <div class="editor">
            <Text :weight="400" :size="16">Completed trips </Text>
            <Text :weight="600" :size="20">{{
              rider?.trip_history
                ? Object.values(rider?.trip_history)?.length
                : 0
            }}</Text>
          </div>
          <div class="editor">
            <Text :weight="400" :size="16">Joined at : {{ " " }}</Text>
            <Text :weight="600" :size="16">
              {{
                dayjs(rider.joined_at).format("DD of MMM, YYYY. hh:mm A.")
              }}</Text
            >
          </div>
        </div>
      </div>
      <div
        @click="openAddRider()"
        class="absolute top-5 cursor-pointer hover:bg-light !h-[40px] right-5 border w-[100px] flex items-center justify-center rounded"
      >
        <Text :color="'black'" :weight="600">Edit</Text>
      </div>

      <div
        @click="() => (shiftModal = true)"
        class="absolute top-[200px] cursor-pointer hover:bg-light !h-[40px] right-5 w-[100px] border flex items-center justify-center rounded"
      >
        {{ `  ${riderStatus ? "End" : "Start"} shift` }}
      </div>
    </div>

    <Map :defaultRider="rider" v-if="rider.status === 'online'" />
    <Commission />
    <review-card
      :key="rider.id"
      :name="rider.first_name + ' ' + rider.last_name"
      :phone="rider.phone"
      :reviews="rider.reviews"
      :onClick="() => router.push(`/logistics/rider-review/${rider.id}?from=`)"
    />

    <div class="boxbody p-4">
      <Text :weight="600" :size="24">
        Current orders ({{ current_orders.length }})</Text
      >
    </div>
    <div class="boxbody p-4">
      <Text :weight="600" :size="24">
        Completed trips ({{
          rider?.trip_history ? Object.values(rider?.trip_history)?.length : 0
        }})
      </Text>
    </div>
    <div class="boxbody p-4">
      <Text :weight="600" :size="24">
        Rejected trips ({{
          rider?.rejected_orders
            ? Object.values(rider?.rejected_orders)?.length
            : 0
        }})</Text
      >
    </div>
    <div class="flex w-100">
      <div
        class="boxbody bg-danger w-full text-center text-white p-3 m-2 cursor-pointer"
        @click="() => deleteRider()"
      >
        <Text :color="'white'" :weight="700">
          {{ rider.deleted ? "Restore rider" : "Delete rider " }}
        </Text>
      </div>
      <div
        v-if="rider.deleted"
        class="boxbody bg-danger w-full text-center text-white p-3 m-2 cursor-pointer"
        @click="() => (openPermernenntDeleteModal = true)"
      >
        <Text :color="'white'" :weight="700"> Permernent Delete</Text>
      </div>
    </div>
  </div>
</template>

<script setup>
import Text from "@/components/Text.vue";
import TopNav from "@/components/TopNav.vue";
import {
  get,
  getDatabase,
  onValue,
  ref as dbRef,
  set,
  remove,
} from "firebase/database";
import { useRoute, useRouter } from "vue-router";
import { watchEffect } from "vue";
import { ref } from "vue";
import dayjs from "dayjs";
import { store } from "@/views/store/store";
import AddRider from "./AddRider.vue";
import Modal from "@/components/Modal.vue";
import MealModal from "@/components/MealModal.vue";
import { projectStorage } from "@/firebase/config";
import { ref as uploadRef, uploadBytes } from "firebase/storage";
import { useToast } from "vue-toast-notification";
import ReviewCard from "@/components/ReviewCard.vue";
import Map from "../Orders/Map.vue";
import Commission from "./Commission.vue";
import { getAuth } from "firebase/auth";
import { computed } from "vue";

const id = useRoute().params.id;
const disabled = ref(true);
const rider = ref({});
const values = ref();
const modalTYPE = ref(store.state.modal.data?.type);
const openDeleteModal = ref(false);
const img = ref("");
const preview = ref("");
const openEditModal = ref(false);
const newImg = ref({});
const toast = useToast({ position: "top-right" });
const router = useRouter();
const shiftModal = ref(false);
const riderTableOrders = ref(0);
const current_orders = ref([]);
const openPermernenntDeleteModal = ref(false);
const completed_orders = ref([]);

watchEffect(() => {
  current_orders.value = store.state.orders.filter((order) => {
    return (
      order.courier?.id == id && order.accepted && !order.status.Delivered.state
    );
  });
});

const openAddRider = () => {
  openEditModal.value = true;
};
const deleteRider = () => {
  openDeleteModal.value = !openDeleteModal.value;
};
const permernentDeleteRider = async () => {
  try {
    const path = `courier/${rider.value.id}`;
    await remove(dbRef(getDatabase(), path));
    rider.value = {};
    router.push(`/logistics/riders/`);
    toast.success("User deleted");
  } catch (error) {
    console.log(error);
  }
};
const editImg = (e) => {
  // console.log(URL.createObjectURL(e.target.files[0]))
  preview.value = URL.createObjectURL(e.target.files[0]);
  newImg.value = e.target.files[0];
};
const riderStatus = computed(() => {
  return rider.value.status === "online";
});

const updateShift = async (param) => {
  await set(
    dbRef(getDatabase(), "courier/" + rider.value.id + "/status"),
    riderStatus.value ? "offline" : "online"
  );
  rider.value.status = riderStatus.value ? "online" : "offline";
  shiftModal.value = false;
};

const saveNewPicture = async () => {
  const imgUrl = Math.floor(Math.random() * 100000) + newImg.value.name;
  try {
    const storageRef = uploadRef(projectStorage, `riders/${imgUrl}`);
    await uploadBytes(storageRef, newImg.value);
    set(
      dbRef(getDatabase(), "courier/" + rider.value.id + "/image_url"),
      imgUrl
    );
    preview.value = "";
    rider.value.image_url = imgUrl;
    toast.success("Image edited successfully ");
  } catch (error) {
    toast.error("An error occured");
  }
};
const getData = async () => {
  const data = await get(dbRef(getDatabase(), "courier/" + id));
  if (data.exists()) {
    const image_name = data.val().image_url.replace("/riders/", "");
    img.value = `https://firebasestorage.googleapis.com/v0/b/urban-eats-cloud.appspot.com/o/riders%2F${image_name}?alt=media&token=b3cad814-e1ca-459b-9fde-6117d82eb2f2`;
    rider.value = data.val();
    riderTableOrders.value =
      (rider.value.accepted_orders
        ? Object.values(rider.value.accepted_orders).length
        : 0) +
      (rider.value.rejected_orders
        ? Object.values(rider.value.rejected_orders).length
        : 0);
    values.value = rider.value;
  }
};
watchEffect(() => {
  getData();
});
onValue(dbRef(getDatabase(), "courier/" + id), () => {
  getData();
});
watchEffect(() => (modalTYPE.value = store.state.modal.data?.type));
const confirmDeleteRider = async () => {
  await set(
    dbRef(getDatabase(), "courier/" + id + "/deleted/"),
    !rider.value.deleted
  );
  openDeleteModal.value = false;
  rider.value.deleted = !rider.value.deleted;
};
</script>

<style lang="scss" scoped>
.rider {
  background: #efefef;
  width: 100%;
  display: flex;
  gap: 12px;
  flex-direction: column;
  padding: 0 30px;
  .header {
    // height: 500px;
    display: flex;
    width: 100%;
    justify-content: space-around;
    padding: 20px 0;
    .editor {
      //   display: flex;
      //   height: 50px;
      width: 100%;
      align-items: center;
      white-space: nowrap;
      input {
        border: none;
        outline: none;
        min-width: 50px;
        font-size: 21px;
        font-weight: 600;
      }
    }
    .imgeDITS {
      width: 20%;
      label {
        width: 100%;
        text-align: center;
        cursor: pointer;
        img {
          // aspect-ratio: 1;
          object-fit: cover;
          &:hover {
            opacity: 0.2;
            cursor: pointer;
          }
        }
        //   background: green;
      }
    }
    div {
      //   background: pink;
      height: 100%;
      //   padding: 30px;
    }
  }
  .boxbody {
    border-radius: 12px;
    background-color: white;
  }
}
</style>