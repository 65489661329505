<template>
  <div class="bg-light py-[10px] items-table">
    <Modal>
      <div v-if="modal == 'cancel-item'">
        <MealModal
          title="Cancel Meal Order?"
          :content="'Are you certain that you want to cancel this meal order?'"
          :rightClick="() => setCanledced()"
          firstBtn="No, Cancel"
          secondBtn="YES, Confirm"
          :index="cancelid"
          :closeBtn="true"
          :tickBtn="false"
          :id="selectedOrder.id"
        />
      </div>
      <div v-else-if="modal == 'complete-meal'">
        <MealModal
          :title="'All Meals Completed?'"
          :content="'Are you sure all meals has been prepared?'"
          :firstBtn="'No, Cancel'"
          :secondBtn="'YES, Confirm'"
          :closeBtn="false"
          :tickBtn="true"
          :rightClick="completeAll"
        />
      </div>
    </Modal>
    <v-dialog v-model="itemRowToCancel.name" persistent>
      <div
        class="bg-white rounded mx-auto p-3 w-[400px] justofy-center flex flex-col gap-2"
      >
        <Text :weight="600" :size="18" class="text-center">
          {{ itemRowToCancel?.name }} {{}}
        </Text>

        <Text :weight="400" :size="12" class="text-center">
          This will cancel all items, addons, extra etc in
          {{ itemRowToCancel?.name }}
        </Text>

        <quantity-counter
          class="w-[fit-content] mx-auto"
          :onchange="(e) => (allItemRowToCancelCount = e)"
          :count="itemRowToCancel?.cancelled || itemRowToCancel?.canceled || 0"
          :disabled="
            !canStillUpdate & itemRowToCancel?.refund_completed ||
            !allItemsCancelledEqually(allItemRowToCancelIndex)
          "
          :max="itemRowToCancel.quantity"
        />

        <!-- </div> -->
        <div class="flex justify-evenly w-100 mt-3">
          <div
            class="border px-2 rounded w-25 text-center cursor-pointer"
            @click="() => (itemRowToCancel = {})"
          >
            Cancel
          </div>
          <div
            class="border px-2 rounded w-25 text-center cursor-pointer"
            @click="
              () =>
                setCanledced(
                  allItemRowToCancelCount,
                  allItemRowToCancelIndex,
                  itemRowToCancel
                )
            "
          >
            {{ itemRowToCancelLoading ? "Loading..." : "Save" }}
          </div>
        </div>
      </div>
    </v-dialog>

    <!-- Selected Order -->
    <section class="orderList">
      <div v-if="!selectedOrder?.status?.Assembled?.state">
        <button
          @click="() => editOrder.commit('selectOrder', selectedOrder)"
          class="px-3 py-2 rounded float-right font-bold border mb-1"
        >
          Edit
        </button>
      </div>
      <div v-if="selectedOrder?.promoUsed" class="float-right flex items-center mr-4">
        <Text color="red" size="15">
          Promocode used: {{ formatPrice(selectedOrder?.promoUsed?.amount) }} -  {{ selectedOrder?.promoUsed?.code }}
        </Text>
      </div>
      <Text
        :size="14"
        class="text-danger"
        weight="600"
        v-if="mealPointBugOverPrice"
      >
        {{ mealPointBugOverPrice }}
      </Text>
      <Text
        :size="14"
        class="text-danger"
        weight="600"
        v-if="selectedOrder.items_cancelling"
      >
        Attempt to "{{ selectedOrder.items_cancelling?.message }}" is pending
        <ue-button
          :disabled="rectifyFailedCancelledItemLoading"
          @click="
            async () => {
              rectifyFailedCancelledItemLoading = true;
              await rectifyFailedCancelledItem(selectedOrder);
              rectifyFailedCancelledItemLoading = false;
            }
          "
        >
          {{ rectifyFailedCancelledItemLoading ? "Loading... " : "Rectify" }}
        </ue-button>
      </Text>
      <div class="contaa">
        <table class="order-table rounded">
          <thead class="">
            <tr class="xsw-full">
              <th class="table-header">Items</th>
              <th class="table-header">Qty</th>
              <th class="table-header">Kitchen</th>

              <th
                v-if="
                  props.type === 'operator' ||
                  (props.type === 'kitchen') & !props.noaction
                "
                class="table-header"
              >
                Prepared
              </th>
              <th
                v-if="
                  (props.type === 'operator' || props.type === 'kitchen') &
                  !props.noaction
                "
                class="table-header"
              >
                Cancel
              </th>
              <th
                v-if="
                  (props.type === 'operator' || props.type === 'assembler') &
                  !props.noaction
                "
                class="table-header"
              >
                Assembled
              </th>
              <th class="table-header">Price</th>
            </tr>
          </thead>
          <tbody class="">
            <tr
              class="table-row relative flex !h-[100px] items-center"
              :class="index % 2 === 0 ? 'even-row' : 'odd-row'"
              v-for="(order, index) in items"
              :key="index"
            >
              <td class="table-data w-[40%]">
                <item-meal
                  :category="order.category"
                  :img="order.img"
                  :mealname="order.name"
                  :order="selectedOrder"
                  :itemIdex="index"
                  :item="order"
                  :addons="combineExtras(order)"
                />
                <convert-to-double-portion
                  :item="order"
                  :order="selectedOrder"
                  :index="index"
                />

                <div v-if="order?.selectedPrice?.item">
                  {{ order?.selectedPrice?.item }} @
                  {{ formatPrice(order?.selectedPrice?.price) }}
                </div>
              </td>
              <td class="table-data w-[auto]">{{ order.quantity }}</td>
              <td class="table-data w-[auto]">{{ order.resturant }}</td>
              <td
                class="table-data w-[auto]"
                v-if="
                  (props.type === 'operator' || props.type === 'kitchen') &
                  !props.noaction
                "
              >
                <quantity-counter
                  :onchange="
                    (e, type) => setPrepared(e, index, order, selectedOrder)
                  "
                  :count="order.prepared"
                  :max="
                    order.quantity - (order.cancelled || order?.canceled || 0)
                  "
                  :disabled="!canStillUpdate"
                ></quantity-counter>
              </td>
              <td
                class="table-data w-[auto]"
                v-if="
                  (props.type === 'operator' || props.type === 'kitchen') &
                  !props.noaction
                "
              >
                <v-tooltip
                  v-if="store.state.cancelledLoading !== selectedOrder?.id"
                  location="top center"
                  origin="top start"
                  no-click-animation
                >
                  <template v-slot:activator="{ props }">
                    <span
                      v-bind="!allItemsCancelledEqually(index) ? props : ''"
                      class="cursor-pointer"
                    >
                      <item-meal-canceled
                        :item="order"
                        :addon="order"
                        @click="() => initiateCancel(order, index)"
                      />
                    </span>
                  </template>

                  <div
                    class="flex flex-col gap-0"
                    v-if="!allItemsCancelledEqually(index)"
                  >
                    {{
                      !allItemsCancelledEqually(index)
                        ? "All items have to be cancelled equally"
                        : ""
                    }}
                  </div>
                </v-tooltip>
                <span v-else>loading...</span>
              </td>
              <td
                class="table-data w-[auto]"
                v-if="props.type === 'operator' || props.type === 'assembler'"
              >
                <div class="items-center flex">
                  <input-field
                    :disabled="
                      sum(items.map((x) => x.prepared)) !==
                        sum(items.map((x) => x.quantity)) -
                          sum(
                            items.map((x) => x?.cancelled || x?.canceled || 0)
                          ) || !canStillUpdate
                    "
                    :checked="order.assembled"
                    @input="(e) => setAssembled(e, order, index)"
                    :type="'roundcheck'"
                    :id="index"
                    class=""
                  />
                  {{
                    order.prepared !==
                    order.quantity - (order.cancelled || order?.canceled || 0)
                      ? "preparing "
                      : ""
                  }}
                </div>
              </td>
              <td class="table-data w-[10%]">
                {{ formatPrice(getItemTotalPrice(order)) }}
                <span v-if="order.point"
                  >MBP({{ order.point * order.quantity }})</span
                >
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <button
            @click="editOrder.commit('openAddItemModal', true)"
            v-if="editOrder.state.order?.id == selectedOrder?.id"
            class="btn float-right"
          >
            +
          </button>
        </div>

        <div
          class="w-100 flex justify-center mt-3 items-center gap-3"
          v-if="!props.noaction"
        >
          <div v-if="props.type === 'operator' || props.type === 'kitchen'">
            <button
              class="button active"
              style="margin: 1rem"
              @click="openCompleteConfirmation()"
              v-if="!isPreparedOrAssembled?.prepared"
            >
              Complete Meal Preparation
            </button>
            <div
              v-else
              style="background-color: rgba(0, 154, 73, 0.24)"
              class="px-[34px] py-[7px] bg-[rgba(0, 154, 73, 0.24)] rounded !font-[700] text-[#009A49]"
            >
              Meal Prepartation Completed
            </div>
          </div>
          <div v-if="props.type === 'operator' || props.type === 'assembler'">
            <button
              v-if="!isPreparedOrAssembled?.assembled"
              class="button active"
              style="margin: 1rem"
              @click="completeAllAssemble()"
            >
              Complete Meal Assembly
            </button>
            <div
              v-else
              style="background-color: rgba(0, 154, 73, 0.24)"
              class="px-[34px] py-[7px] bg-[rgba(0, 154, 73, 0.24)] rounded !font-[700] text-[#009A49]"
            >
              Meal Assembly Completed
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Cancelled Order -->
    <section
      class="orderList"
      v-if="sum(items.map((x) => x.cancelled || x?.canceled || 0)) > 0"
    >
      <div v-if="props.type === 'operator' || props.type === 'kitchen'">
        <Text :weight="800" :size="24">Cancelled Items</Text>
        <div>
          <table class="order-table rounded">
            <thead>
              <tr>
                <th class="table-header"></th>
                <th class="table-header"></th>
                <th class="table-header">Qty</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(order, index) in items.filter((x) => x.cancelled !== 0)"
                :key="order.name"
                class="table-row"
                :class="index % 2 === 0 ? 'even-row' : 'odd-row'"
              >
                <td class="table-data">{{ index + 1 }}</td>
                <td class="table-data">{{ order.name }}</td>
                <td class="table-data">
                  {{ order.cancelled || order?.canceled || 0 }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <add-item />
  </div>
</template>
       <script setup>
import { ref, computed, watchEffect } from "vue";
import AddItem from "@/components/AddItem.vue";
import Modal from "@/components/Modal.vue";
import { store } from "@/views/store/store";
import MealModal from "@/views/main/Kitchen/Meals/MealModal.vue";
import { useRoute, useRouter } from "vue-router";
import QuantityCounter from "@/components/QuantityCounter.vue";
import Text from "@/components/Text.vue";
import ItemMeal from "@/components/ItemMeal.vue";
import ItemMealCanceled from "@/components/ItemMealCanceled.vue";
import {
  // formatDate,
  formatPrice,
  getExtras,
  getItemTotalPrice,
  sum,
} from "@/util";
import InputField from "@/components/InputField.vue";
import ConvertToDoublePortion from "./ConvertToDoublePortion.vue";
import {
  onValue,
  ref as dbRef,
  getDatabase,
  get,
  remove,
} from "firebase/database";
import { useToast } from "vue-toast-notification";
import { extra_permissions_keys } from "@/views/main/Operator/Accounts/extra_permissions";
import { editOrder } from "@/views/store/editOrder";
import { WalletTransaction } from "@/views/main/Operator/Customers/updateWallet";
import { rectifyFailedCancelledItem } from "@/util/rectifyAction";
import UeButton from "@/components/UeButton.vue";

const props = defineProps({
  selectedOrder: Object,
  modal: Boolean,
  type: String,
  noaction: Boolean,
});
const selectedOrder = ref({});
const status = ref({});
const items = ref([]);
const modal = ref(store.state.modal.data);
// const router = useRouter();
const cancelid = ref();
const { id } = useRoute().params;
const orderIdPath = ref();
const changes = ref([]);
const $toast = useToast({ position: "top-right" });
const canStillUpdate = ref(false);
const itemRowToCancel = ref({});
const allItemRowToCancelCount = ref(0);
const allItemRowToCancelIndex = ref(-1);
const itemRowToCancelLoading = ref(false);
const mealPointBugOverPrice = ref(false);
const canCompleteMealPreparation = ref(false);
const rectifyFailedCancelledItemLoading = ref(false);
watchEffect(() => {
  canCompleteMealPreparation.value =
    store.state.user?.extra_permissions?.includes(
      extra_permissions_keys.complete_meal_preparation
    );
});

watchEffect(() => {
  modal.value = store.state.modal.data;
});
watchEffect(() => {
  orderIdPath.value = store.state.orders.map((x) => x.id).indexOf(id);
});

watchEffect(() => {
  onValue(dbRef(getDatabase(), "orders/" + id), (snapshot) => {
    selectedOrder.value = snapshot.val();
    items.value = selectedOrder.value.items;
    status.value = selectedOrder.value.status;
    canStillUpdate.value = !(
      selectedOrder.value.status.Assembled.state ||
      selectedOrder.value.status?.Dispatched?.state ||
      selectedOrder.value.status?.Pickup?.state
    );
  });
});

const giveTypeAndIndex = (arr = [], type) => {
  return arr?.length
    ? arr.map((item, index) => ({ ...item, type, index }))
    : [];
};

const combineExtras = (item) => {
  // console.log(item);
  const addons = item?.addons || [];
  const sides = item?.sides || [];
  const customize = item?.customize || [];
  // console.log({ addons, sides, customize });
  return giveTypeAndIndex(addons, "addons")
    .concat(giveTypeAndIndex(sides, "sides"))
    .concat(giveTypeAndIndex(customize, "customize"));
};

const setPrepared = (prepare, itemIndex, order) => {
  console.log("jjj");
  // $toast.success()
  if (canStillUpdate.value) {
    store.dispatch("prepareItem", {
      prepare,
      id: selectedOrder.value.id,
      itemIndex,
      order,
      selectedOrder: selectedOrder.value,
    });
  } else {
    $toast.info("This items can no longer be updated");
  }
};
const initiateCancel = (item, index) => {
  if (
    !store.state?.user?.extra_permissions?.includes(
      extra_permissions_keys.cancel_single_item
    )
  ) {
    $toast.info("You don't have permission to perform this action! ");
    return;
  }
  if (selectedOrder.value?.items_cancelling) {
    $toast.info("Please rectify previous action! ");
    return;
  }
  itemRowToCancel.value = item;
  allItemRowToCancelIndex.value = index;
  allItemRowToCancelCount.value = item?.cancelled || item?.canceled || 0;
};

const setCanledced = async (cancelcount, index, order) => {
  // console.log({ cancelcount, index, order });
  if (itemRowToCancelLoading.value) return;
  const extras = getExtras(selectedOrder.value, index);
  if (canStillUpdate.value) {
    if (!selectedOrder.value.refund_completed) {
      // if (
      //   !extras.filter((extra) => extra?.cancelled || extra?.canceled).length
      // ) {
      changes.value[index].cancelled = cancelcount;
      // changes.value[index].canceled = cancelcount;
      changes.value[index].prepared = order.quantity - cancelcount;
      extras.forEach((extra) => {
        const qty =
          changes.value[index][extra?.extrasKey][extra?.indexInExtra]?.qty;
        changes.value[index][extra?.extrasKey][extra?.indexInExtra].cancelled =
          cancelcount * (qty ?? changes.value[index].quantity);
      });
      itemRowToCancelLoading.value = true;
      store
        .dispatch("cancelItemAndExtra", {
          orderId: selectedOrder.value?.id,
          changes: changes.value,
          count: cancelcount,
          order: selectedOrder.value,
          index,
        })
        .then(() => {
          itemRowToCancel.value = {};
        })
        .finally(() => (itemRowToCancelLoading.value = false));
    } else {
      $toast.info("A refund has been made for this order");
    }
  } else {
    $toast.info("This items can no longer be updated");
  }
};

const allItemsCancelledEqually = (index) => {
  const extras = getExtras(selectedOrder.value, index);
  const mainItem = selectedOrder.value?.items[index];
  const allow = extras.every(
    (extra) =>
      (extra?.cancelled || extra?.canceled || 0) /
        (extra?.qty ?? mainItem?.quantity) ==
      (mainItem?.cancelled || mainItem?.canceled || 0)
  );
  // console.log(mainItem, extras, allow);
  return allow;
};

const setAssembled = (e, order, i) => {
  if (canStillUpdate.value) {
    store.dispatch("assembleMeal", {
      assembled: e.target.checked,
      itemIndex: i,
      orderId: selectedOrder.value.id,
      order,
      selectedOrder: selectedOrder.value,
    });
  } else {
    $toast.info("This items can no longer be updated");
  }
};

const completeAllAssemble = () => {
  canStillUpdate
    ? store.dispatch("assembleAllMeals", {
        orderId: selectedOrder.value.id,
      })
    : $toast.info("This items can no longer be updated");
};
const openCompleteConfirmation = () => {
  canStillUpdate
    ? store.commit("openModal", "complete-meal")
    : $toast.info("This items can no longer be updated");
};

const completeAll = () => {
  store.dispatch("completeMealPreparation", {
    orderId: selectedOrder.value.id,
  });
  store.commit("closeModal");
};

watchEffect(() => {
  changes.value = items.value;
});

watchEffect(async () => {
  //CONFIRM MBP

  // const itemWithMBP = items.value?.filter((item) => item.point);
  const pointsTotal = sum(items.value?.map((item) => parseInt(item.point)));
  const itemsWithMBPgreaterThan1 = items.value?.filter(
    (item) => item.point && item.quantity >= 1
  );
  // console.log(itemsWithMBPgreaterThan1,'jbhv')
  if (itemsWithMBPgreaterThan1?.length) {
    const MBP_History = (
      await get(
        dbRef(
          getDatabase(),
          `users/${selectedOrder.value.customer?.id}/mealPoint/history`
        )
      )
    ).val();
    const adds = MBP_History?.filter(
      (history) => history?.pointType === "add" && !history?.expired
    );
    const subtracts = MBP_History?.filter(
      (history) => history?.pointType === "subtract" && !history?.expired
    );
    const calc = (arr) => sum(arr?.map((a) => a?.point));
    const MBP_EarnedBeforeOrder = calc(adds);
    const MBP_RedeemedBeforeOrder = calc(
      subtracts?.filter((mbp) => mbp?.orderId !== selectedOrder.value.id)
    );
    const balanceBeforeOrderWasPlaced =
      MBP_EarnedBeforeOrder - MBP_RedeemedBeforeOrder;
    console.log({ balanceBeforeOrderWasPlaced, pointsTotal });

    if (pointsTotal > balanceBeforeOrderWasPlaced) {
      mealPointBugOverPrice.value = `This customer didn't have enough meal point balance to purchase meal(s) paid by meal point! `;
    }
  }
});

const isPreparedOrAssembled = computed(() => {
  const items = selectedOrder.value?.items || [];
  const status = selectedOrder.value?.status || {};
  const qtyToPrepare = sum(
    items?.map(
      (item) => item.quantity - (item?.cancelled || item?.canceled || 0)
    ) || []
  );
  const qtyToAssemble = items?.length;
  const preparedByItems =
    sum(items?.map((item) => item.prepared) || []) == qtyToPrepare;
  const assembledByItems =
    items?.filter((item) => item.assembled).length == qtyToAssemble;
  const preparedByStatus = status?.Prepared?.state;
  const assembledByStatus = status?.Assembled?.state;
  return {
    prepared: preparedByItems && preparedByStatus,
    assembled: assembledByItems && assembledByStatus,
  };
});
</script>
     
   