<template>
  <div
    class="w-full p-3 bg-white rounded-[12px] my-4"
    id="message-list"
    v-if="wallet_transactions?.length"
  >
    <div
      class="flex flex-col gap-4 w-full"
      v-for="(transaction, i) in paginatedwallet_transactions"
      :key="transaction.date"
    >
      <div class="rounded-pill my-2 px-4 flex gap-3" :id="`transaction${i}`">
        <!-- <div
          class="flex items-center justify-center rounded-[100%] bg-info h-[24px] w-[24px] p-1 text-white"
        >
      
        </div> -->
        <Text :size="16" :weight="600">
          {{
            transaction.admin?.first_name + " " + transaction.admin?.last_name
          }}</Text
        >
        <Text :weight="500" :size="16">
          {{ transaction.transaction }} {{ "  " }}
          {{ transaction?.type === "top-up" ? "topped up" : "deducted" }}
          {{ "  " }} {{ formatPrice(transaction?.amount) }} for
          {{ transaction?.reason }} on {{ transaction?.date }}
        </Text>
      </div>
    </div>
    <Pagination
      class="py-3"
      :currentPage="currentPage"
      :totalPages="totalPages"
      @page-change="goToPage"
    />
  </div>
</template>

<script setup>
import Pagination from "@/components/Pagination.vue";
import Text from "@/components/Text.vue";
import { formatPrice } from "@/util";
import dayjs from "dayjs";
import { get, ref as dbRef, getDatabase, onValue } from "firebase/database";
import { computed } from "vue";
import { ref, watchEffect } from "vue";

const props = defineProps({
  customer: Object,
});
const customer = ref({});

const wallet_transactions = ref([]);

watchEffect(async () => {
  customer.value = props.customer;
  onValue(dbRef(getDatabase(), "wallet_transactions"), (snap) => {
    const res = snap?.val();
    wallet_transactions.value = Object.values(res || {})
      ?.filter(
        (transaction) => transaction?.customer?.uid ===  customer.value?.uid
      )
      ?.toSorted((a,b)=>dayjs(b.date) - dayjs(a.date));
  });

  //   const res = (await get()).val() || [];
});

// Pagination
const ordersPerPage = 5;
const currentPage = ref(1);
const totalPages = computed(() =>
  Math.ceil(wallet_transactions.value.length / ordersPerPage)
);

const goToPage = (page) => {
  if (page >= 1 && page <= totalPages.value) {
    currentPage.value = page;
  }
};

const paginatedwallet_transactions = computed(() => {
  const startIndex = (currentPage.value - 1) * ordersPerPage;
  const endIndex = startIndex + ordersPerPage;
  return wallet_transactions.value.slice(startIndex, endIndex);
});
</script>

<style lang="scss" scoped>
</style>
