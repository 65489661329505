<template>
  <div :key="current" class="flex flex-wrap gap-1 respon"  >
    <!-- <div class="!justify-self-end border w-[fit-content] float-right rounded px-1 mb-1 bg-light cursor-pointer"  @click="() => (togg = false)">X</div> -->
    <button
      v-for="button in buttons"
      :key="button.key"
      class="button"
      :class="{ active: current === button.key }"
      @click="
        () => {
          props.onChange(button);
          current = button.key;
          togg = false
        }
      "
    >
      {{ button.message }}
      <div
        :class="{ active: current === button.key }"
        class="rounded-full bracket !w-[20px] h-[20px] flex items-center justify-center"
        v-if="button.tab_bracket"
      >
        {{ button.tab_bracket }}
      </div>
    </button>
  </div>
  <!-- {{ showStatusButton }} {{ togg }} -->
  <!-- <div
    class="order-status-res btn border"
    v-if="!togg"
    @click="() => (togg = !togg)"
  >
    <span class="material-symbols-outlined"> filter_list </span>
  </div> -->
</template>

<script setup>
import { computed } from "vue";
import { onMounted, ref, watchEffect } from "vue";

const props = defineProps({
  buttons: {
    type: Array,
    default: { message: "", key: "", click: () => null, tab_bracket: "" },
  },
  current: String,
  onChange: Function,
});

const current = ref();
const buttons = ref(props.buttons);

onMounted(() => {
  current.value = props.buttons[0].key;
});
watchEffect(() => {
  // console.log(current.value,'-----------', props.current)
  current.value = props.current;
});
watchEffect(() => {
  //   current.value = props.current;
  buttons.value = props.buttons;
});

const togg = ref(false);
const windowWidth = ref(window.innerWidth);

const handleResize = () => {
  windowWidth.value = window.innerWidth;
  togg.value = true;
  if (window.innerWidth < 991) togg.value = false;
};

onMounted(() => {
  window.addEventListener("resize", handleResize);
});

const showStatusButton = computed(() => {
  if (windowWidth.value < 991 && !togg.value) return true;
  return togg.value;
});
</script>

<style lang="scss" scoped>
.button {
  padding: 10px 30px;
  border-radius: 5px;
  border: 1px #f60100 solid;
  background-color: #fff;
  color: #f60100;
  display: flex;
  gap: 4px;
  align-items: center;
  margin-left: 20px;
  @media screen and (max-width:992px) {
    margin-left: 0px;
    padding: 6px 15px;
    text-align: center;
  }
}
.button.active {
  background: linear-gradient(180deg, #ff9103 0%, #f60100 100%);
  color: white;
  border: none;
}
.bracket.active {
  background: white !important;
  color: #f60100 !important;
}
.bracket {
  background: linear-gradient(180deg, #ff9103 0%, #f60100 100%) !important;
  color: white;
}
.respon {
  @media screen and (max-width: 992px) {
    position: absolute;
    top: 60px;
    right: 20px;
    z-index: 1000;
    align-items: stretch;

    justify-content: center;
    border-radius: 8px;
    z-index: 1000;box-shadow: 1px 2px 4px grey;
    padding: 5px;
    // shadow bg-grey rounded py-2

    background-color: white;
    flex-direction: column;
  }
}
.order-status-res {
  @media screen and (max-width: 992px) {
    position: absolute;
    top: 60px;
    right: 20px;
    flex-direction: column;

    cursor: pointer;
    align-items: center;
  }
}
</style>