<template>
  <!-- <img :src="icons.blog.chowcity.image1" alt="" /> -->
  <div class="img">
    <!-- <slot /> -->
    <img :src="img_path" alt="" />

    <!-- <Skeleton  height="400px" width="400px"/> -->
<!-- <Skeleton count={5} /> -->
  </div>
</template>

<script setup>
// import { Skeleton } from 'vue-loading-skeleton';
import "vue-loading-skeleton/dist/style.css"
const props = defineProps({
  src: String,
});
// const path = require('../../../../assets/images/blog/' + props.src)
// https://firebasestorage.googleapis.com/v0/b/urban-eats-cloud.appspot.com/o/blog%2Fimportantlinks%2FFoodDeliveryInIkoyi1.jpg?alt=media&token=dc769840-617b-47a9-b2c6-b725ab855f4d&_gl=1*361uks*_ga*OTQxODg2OTQyLjE2OTY2MDA5OTM.*_ga_CW55HF8NVT*MTY5OTQxMTQzOS4zNjAuMS4xNjk5NDEyMDg5LjQ4LjAuMA..
const parts = props.src?.split("/");
const folder = parts[0];
const image = parts[1];

const img_path = `https://firebasestorage.googleapis.com/v0/b/urban-eats-cloud.appspot.com/o/blog%2F${folder}%2F${image}?alt=media&token=dc769840-617b-47a9-b2c6-b725ab855f4d&_gl=1*361uks*_ga*OTQxODg2OTQyLjE2OTY2MDA5OTM.*_ga_CW55HF8NVT*MTY5OTQxMTQzOS4zNjAuMS4xNjk5NDEyMDg5LjQ4LjAuMA..`;
const loadUserData = async () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(img_path);
    }, 4000);
  });
};


</script>

<style lang="scss" scoped>
.img {
  width: 100%;
  // border: 1px solid red;
  display: flex;
  justify-content: center;
  img {
    // height: 300px;
    // display: none;
    margin: auto;
    width: 50% !important;
    position: relative;
    margin: 20px 0;
    @media screen and (max-width:500px) {
      // display: none;
      width: 100% !important;
      // border: red 4px solid;

    }
  }
  //   display: none;
}
</style>