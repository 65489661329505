<template>
  <div class="relative">
    <!-- SELECT INPUT FIELD -->
    <div
      v-if="inputType === 'select'"
      class="f!lex gap-[24px] items-center selectcon"
    >
      <label :for="id" v-if="!value">{{ label }}</label>
      <select name="" class="pr-5" :value="value" :id="id" ref="inputField">
        <option value="">{{ placeholder }}</option>
        <option
          :selected="defaultValue === value"
          :value="value"
          v-for="{ label, value } in options"
          :key="value"
        >
          {{ label }} {{ defaultValue }}
        </option>
      </select>
      <i class="fas fa-angle-down"></i>
    </div>

    <!-- RADIO INPUT FIELD -->
    <div v-else-if="inputType === 'roundcheck'" class="radio-btn">
      <label :for="id">
        <div :class="checked ? 'checked' : 'unchecked'">
          <!-- <span class="material-symbols-outlined " > check_circle </span> -->
          <tick-circle v-if="checked" class="checked" />
          <!-- <img :src="tickCircle" v-if="checked" class="checked" /> -->
          <!-- <div v-html="tickCircle"></div> -->
        </div>
      </label>
      <input
        type="checkbox"
        class="hidden"
        :checked="checked"
        :id="id"
        :name="id"
        :disabled="disabled"
        @input="$emit('checkUnCheck', $event.target.checked)"
        @change="checkUnCheck"
        :value="value"
      />
    </div>

    <!-- OTHER INPUT FIELDS -->
    <div v-else>
      <!-- {{ inputType }} -->
      <label :for="id" class="block text-sm font-bold text-dark">{{
        label
      }}</label>
      <input
        :type="inputType"
        :name="name"
        :id="id"
        :class="inputClass"
        :disabled="disabled"
        v-model="value"
        :required="required"
        ref="inputField"
      />
      <button
        v-if="type === 'password'"
        class="absolute top-0 right-0 px-3 py-2"
        @click="togglePassword"
      >
        <span
          class="text-blue cursor-pointer text-sm font-medium underline"
          v-if="!showPassword"
          >Show</span
        >
        <span
          class="text-blue cursor-pointer text-sm font-medium underline"
          v-else
          >Hide</span
        >
      </button>
    </div>
  </div>
</template>
  
<script>
import TickCircle from '@/assets/vuesvg/TickCircle.vue';
// import tickCircle  from "@/assets/saxsvg/All/outline/tick-circle.svg";
export default {
  components: { TickCircle },
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    type: {
      type: String,
      default: "text",
    },
    inputClass: {
      type: String,
      default: "w-full px-4 py-3 border text-sm border-gray-300 rounded",
    },
    options: {
      type: Array,
    },
    placeholder: {
      type: String,
    },
    checked: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    defaultValue: {
      type: String,
    },
    name: {
      type: String,
    },
    required:{
      type: Boolean,
      
    }
  },
  data(props) {
    return {
      showPassword: false,
      value: props.defaultValue,
      checked: props.checked,
      disabled: props.disabled,
      name: props.name,
      defaultValue: props.defaultValue,
      type:props.type

    };
  },
  computed: {
    inputType() {
      return this.showPassword ? "text" : this.type;
    },
  },

  watch: {
    checked: function (newValue, oldValue) {
      this.checked = newValue;
    },
    required: function (newValue, oldValue) {
      this.required = newValue;
    },
    disabled: function (newValue, oldValue) {
      this.disabled = newValue;
    },
    defaultValue: function (newValue, oldValue) {
      this.defaultValue = newValue;
    },
    value: function (newValue, oldValue) {
      console.log(newValue);
      this.value = newValue;
    },
  },
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
      this.$refs.inputField.type = this.showPassword ? "text" : this.type;
    },
    input(e) {
      this.name = e.target.name;
      this.value = e.target.value;
    },
    checkUnCheck(e) {
      this.checked = e.target.checked;
    },
  },
};
</script>
<style lang="scss" scoped>
.selectcon {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px;
  border-radius: 12px;
  position: relative;
  border: none;
  height: 35px;
  flex-shrink: 0;
  border-radius: 4.403px;
  border: 1px solid #000;
  label {
    position: absolute;
    width: 100%;
    white-space: nowrap;
  }
  select {
    // display: none;
    position: relative;
    z-index: 100;
    outline: none;
    border: none;
    width: 100%;
    // background: green;
    option {
      // display: none÷;
      background-color: white;
      width: 100%;
    }
  }
  i {
    position: relative;
    right: 0;
  }
  &:hover {
    // border: 1px solid #e2e2e2;
  }
}

.radio-btn {
  .unchecked {
    width: 25px;
    height: 25px;
    // stroke-width: 2px;
    stroke: #000;
    border: 1px black solid;
    border-radius: 100%;
    cursor: pointer;
    padding: 0;
  }
  .checked {
    flex-shrink: 0;
    fill: #009a49;
    color: white;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
}
</style>
