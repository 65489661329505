<template>
  <head>
    <link
      rel="stylesheet"
      href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
    />
  </head>
  <router-view :key="route.path">
    <Layout :hasTopNav="false">
      <div class="order-container ue-container flex-col">
        <Modal>
          <div v-if="modal == 'cancel-item'">
            <MealModal
              title="Cancel Meal Order?"
              :content="'Are you certain that you want to cancel this meal order?'"
              :rightClick="proceedCancel"
              firstBtn="No, Cancel"
              secondBtn="YES, Confirm"
              :index="cancelid"
              :closeBtn="true"
              :tickBtn="false"
              :id="selectedOrder.id"
            />
          </div>
          <div v-else-if="modal == 'complete-meal'">
            <MealModal
              :title="'Order Assembled?'"
              :content="'Are you certain that this meals has been prepared completely?'"
              :firstBtn="'No, Cancel'"
              :secondBtn="'YES, Confirm'"
              :closeBtn="false"
              :tickBtn="true"
              :rightClick="completeAll"
            />
          </div>
        </Modal>

        <top-nav
          :searchBar="false"
          :arrowback="true"
          :PrevNextIcon="true"
          :PrevNextList="prevnextlist"
        />
        <div class="d-flex justify-content-between items-center">
          <h2>Order ID {{ route.params.id }}</h2>
          <!-- <Receipt :order="selectedOrder">
            <span class="underline cursor-pointer">{{
              selectedOrder?.printed ? "Printed" : "Print"
            }}</span>
          </Receipt> -->

          <CustomerTag :uid="selectedOrder?.customer?.id" />
        </div>
        <ProgressionBar :order="selectedOrder" />
        <br>
       <div>
        <OrderDetails :order="selectedOrder" />
       </div>

        <div
          class="flex items-center justify-center bg-white py-5 rounded-[16px]"
        >
          <count-down
            :title="
              selectedOrder.status?.Assembled?.state
                ? 'Time spent for preparation and assembly'
                : 'Time left for preparation and assembly'
            "
            :minunte="22.5"
            :seconds="'00'"
            :order="selectedOrder"
          />
        </div>

        <items-table :type="'assembler'" />

        <courier-deets :selectedOrder="selectedOrder" />

        <Timeline :selectedOrder="selectedOrder" />
      </div>
    </Layout>
  </router-view>
</template>
          
        <script>
import { ref, computed, watchEffect } from "vue";
import Pagination from "@/components/Pagination.vue";
import TopNav from "@/components/TopNav.vue";
import Modal from "@/components/Modal.vue";
import CountDown from "@/partials/countdown.vue";
import { store } from "@/views/store/store";
import MealModal from "@/components/MealModal.vue";
import { useRoute, useRouter } from "vue-router";
import ProgressionBar from "@/components/ProgressionBar.vue";
import Text from "@/components/Text.vue";
import ItemMeal from "@/components/ItemMeal.vue";
import InputField from "@/components/InputField.vue";
import { getUeOrderId, sum } from "@/util";
import { getDatabase, onValue, ref as dbRef } from "firebase/database";
import ItemsTable from "@/partials/ItemsTable.vue";
import CourierDeets from "@/partials/CourierDeets.vue";
import Layout from "@/partials/Layout/layout.vue";
import Timeline from "@/components/Timeline.vue";
import Receipt from "@/components/Receipt.vue";

import OrderDetails from "@/components/OrderDetails.vue";
import CustomerTag from "@/components/CustomerTag.vue";

export default {
  props: {
    selectedOrder: Object,
    modal: Boolean,
  },
  components: {
    TopNav,
    Pagination,
    Modal,
    ProgressionBar,
    MealModal,
    CountDown,
    Text,
    ItemMeal,
    InputField,
    ItemsTable,
    CourierDeets,
    Layout,
    Timeline,
    Receipt, OrderDetails,
    CustomerTag
},
  setup(props) {
    const selected = ref("active");
    const message = ref("Active Orders");
    const status = ref({});
    const items = ref([]);
    const selectedOrder = ref({});
    const modal = ref(store.state.modal.data);
    const route = useRoute();
    const router = useRouter();
    const { id } = useRoute().params;
    const prevnextlist = ref([]);
    const type = ref(route.params.type);

    watchEffect(() => {
      modal.value = store.state.modal.data;
      type.value = route.params.type;
    });
    const select = (type) => {
      selected.value = type;
      message.value = type === "active" ? "Active Orders" : "Completed Orders";
    };

    router.afterEach((to, from) => {
      console.log(to.params.id);
      onValue(dbRef(getDatabase(), "orders/" + to.params.id), (snapshot) => {
        if (snapshot.val()) {
          selectedOrder.value = snapshot.val();
          items.value = selectedOrder.value.items;
          status.value = selectedOrder.value.status;
        }
      });
    });

    watchEffect(() => {
      onValue(dbRef(getDatabase(), "orders/" + route.params.id), (snapshot) => {
        selectedOrder.value = snapshot.val();
        items.value = selectedOrder.value.items;
        status.value = selectedOrder.value.status;
      });
    });

    watchEffect(() => {
      //create list of id's for the prevnext list assembler

      //determine if its active or completed
      prevnextlist.value = store.state.orders.filter((x) => {
        let type = Boolean(selectedOrder.value?.status?.Prepared?.state);
        let res = Boolean(x?.status?.Prepared?.state);
        let type2 = Boolean(selectedOrder.value?.status?.Assembled?.state);
        let res2 = Boolean(x?.status?.Assembled?.state);
        if (type2 && type) {
          // completed
          return type2 == res2;
        } else if (type /* prepared */ && !type2 /* but no assembled */) {
          // active
          return type == res && res2 == false;
        } else if (!type /* notprepared */ && !type2 /* but no assembled */) {
          // active
          return res == false && res2 == false;
        }
      });
    });

    // const setAssembled = (e, order, i) => {
    //   store.dispatch("assembleMeal", {
    //     assembled: e.target.checked,
    //     itemIndex: i,
    //     orderId: props.selectedOrder.id,
    //   });
    // };

    // const openCompleteConfirmation = () => {
    //   store.commit("openModal", "complete-meal");
    // };

    // const completeAll = () => {
    //   store.dispatch('assembleAllMeals',{orderId:props.selectedOrder.id})

    // };

    // Pagination
    const ordersPerPage = 5;
    const currentPage = ref(1);
    const totalPages = computed(() =>
      Math.ceil(orders.value.length / ordersPerPage)
    );

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };

    const paginatedOrders = computed(() => {
      const startIndex = (currentPage.value - 1) * ordersPerPage;
      const endIndex = startIndex + ordersPerPage;
      return orders.value.slice(startIndex, endIndex);
    });

    return {
      ordersPerPage,
      currentPage,
      totalPages,
      goToPage,
      paginatedOrders,
      select,
      selected,
      message,
      route,
      // props,
      // setAssembled,
      items,
      // openCompleteConfirmation,
      // completeAll,
      modal,
      status,
      sum,
      router,
      getUeOrderId,
      selectedOrder,
      prevnextlist,
    };
  },
};
</script>
          
          <style scoped>
.order-container {
  position: relative;
  background-color: #f5f5f5;
  /* overflow-y: auto; */
  display: flex;
  flex-direction: column;
}

/* active and completed button */
.button {
  padding: 10px 30px;
  border-radius: 5px;
  border: 1px #f60100 solid;
  background-color: #fff;
  color: #f60100;
  margin-left: 20px;
  justify-self: center;
}
.button.active {
  background: linear-gradient(180deg, #ff9103 0%, #f60100 100%);
  color: white;
  border: none;
}

.pagination-container {
  display: flex;
  column-gap: 10px;
}
.paginate-buttons {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(217, 217, 217);
  color: black;
}
.paginate-buttons:hover {
  background-color: #d8d8d8;
}
.active-page {
  background-color: #3498db;
  border: 1px solid #3498db;
  color: white;
}
.active-page:hover {
  background-color: #2988c8;
}
.orderList {
  border-radius: 20px;
  /* padding: 3rem; */

  font-size: 12px;
  color: black;
}
.order-table {
  width: 100%;
  border-collapse: collapse;
  /* border: 1px solid #ccc; */
}

.table-header {
  background-color: white;
  text-align: left;
  padding: 10px;
  font-weight: bold;
  height: 70px;
}

.table-row {
  height: 60px;
}

.table-row.even-row {
  background-color: #f2f2f2;
}

.table-row.odd-row {
  background-color: #fff;
}

.table-data {
  padding: 10px;
}

.status-button {
  padding: 5px 10px;
  background: linear-gradient(
    202deg,
    rgba(255, 145, 3, 0.24) 0%,
    rgba(246, 1, 0, 0.24) 100%
  );

  border: none;
  color: #333;
  font-weight: bold;
}

.status-button2 {
  padding: 5px 10px;
  background: linear-gradient(
    202deg,
    rgba(74, 224, 117, 0.428) 0%,
    rgba(0, 246, 78, 0.24) 100%
  );

  border: none;
  color: #333;
  font-weight: bold;
}

.action-link {
  text-decoration: none;
  color: #f60100;
}

/* Solid border */
.row > * {
  padding-left: 0;
  padding-right: 0;
}
</style>