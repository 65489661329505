<template>
  <div>
    <!-- <v-dialog v-model="loadingModal"> </v-dialog> -->
    <v-dialog v-model="openModal" persistent>
      <div
        class="bg-white rounded w-[fit-content] py-2 px-4 mx-auto"
        v-if="!loadingModal"
      >
        <MealModal
          :title="`Refund to customers ${props.refund_destination} ?`"
          :content="`Are you sure you want to refund ${formatPrice(
            props.refundAmount
          )} to the customer\'s ${props.refund_destination}`"
          :rightClick="() => setRefund()"
          firstBtn="No, Cancel"
          secondBtn="YES, Confirm"
          :index="order.id"
          :closeBtn="true"
          :tickBtn="false"
          :oncancel="() => (openModal = false)"
          :id="order.id"
        />
      </div>
      <img
        v-else
        :src="icons.loading"
        alt=""
        class="mx-auto w-[100px] h-[100px]"
      />
    </v-dialog>

    <div @click="() => (openModal = true && !props.disabled)"><slot /></div>
  </div>
</template>

<script>
import InputField from "@/components/InputField.vue";
import MealBody from "../../Assembler/Meals/MealBody.vue";
import MealModal from "@/components/MealModal.vue";
import { computed, ref, watchEffect } from "vue";
import { set, ref as dbRef, getDatabase, get, update } from "firebase/database";
import { useToast } from "vue-toast-notification";
import { formatPrice, sum } from "@/util";
import { icons } from "@/assets/icons";
import dayjs from "dayjs";
import { extra_permissions_keys } from "../Accounts/extra_permissions";
import { store } from "@/views/store/store";
export default {
  components: { InputField, MealBody, MealModal },
  props: {
    order: Object,
    refundAmount: Number,
    refundType: String,
    shouldCancelOrder: Boolean,
    onSuccess: Function,
    disabled: Boolean,
    onClick: Function,
    refund_destination: {
      type: String,
      default: "wallet",
    },
  },
  setup(props) {
    const openModal = ref(false);
    const order = ref(props.order);
    const toast = useToast({ position: "top-right" });
    const loadingModal = ref(false);

    watchEffect(() => {
      order.value = props.order;
    });

    const walletRefund = async () => {
      try {
      // console.log(props.refundAmount, props.order?.customer?.id);
      const customer_id = props.order?.customer?.id;
      const refund_amount = props.refundAmount;

      // console.log(customer_id, refund_amount);
      const wallet_details_path = `users/${customer_id}/wallet/`;
      // const wallet_history_path = `users/${customer_id}/wallet/`;
      const prev_wallet_details =
        (await get(dbRef(getDatabase(), wallet_details_path))).val() || {};
      const prev_wallet_balance = prev_wallet_details?.balance || 0;
      const prev_wallet_history = prev_wallet_details?.history || [];
      const payload = {
        ...prev_wallet_details,
        balance: prev_wallet_balance + refund_amount,
        history: [
          ...prev_wallet_history,
          {
            amount: refund_amount,
            created: JSON.stringify(new Date().toISOString()),
            type: "wallet-reversal",
            order: order.value.id,
          },
        ],
      };
      // const path = `orders/${order.value.id}/refund_completed`;
    
        await set(dbRef(getDatabase(), wallet_details_path), payload);

        props.onSuccess(prev_wallet_balance + refund_amount);

        toast.success("Wallet refund successful");
        loadingModal.value = false;
        openModal.value = false;
      } catch (error) {
        console.log(error);
      }
    };

    const mealpointRefund = async () => {
      const orderMealPoints =
        order.value?.items?.map((item) => parseInt(item?.point)) || [];
      const point = sum(orderMealPoints);
      console.log(point);
      const mealpointHistoryRef = dbRef(
        getDatabase(),
        `users/${order.value?.customer?.id}/mealPoint/history`
      );
      const mealpointSumRef = dbRef(
        getDatabase(),
        `users/${order.value?.customer?.id}/mealPoint/point`
      );
      const refund = {
        created: JSON.stringify(dayjs().format("YYYY-MM-DDTHH:mm:ss.SSSZ")),
        orderId: order.value?.id,
        point,
        pointType: "add",
      };
      const prevMealPointHistory = (await get(mealpointHistoryRef)).val() || [];
      const prevMealPointSum = (await get(mealpointSumRef)).val() || 0;
      console.log({ prevMealPointHistory, prevMealPointSum, point, refund });
      if (point) {
        await set(mealpointHistoryRef, [...prevMealPointHistory, refund]);
        await set(mealpointSumRef, parseFloat(prevMealPointSum) + point);
        toast.success("Mealpoint refund successful");
      }
    };

    const setRefund = async () => {
      loadingModal.value = true;
      if (props.refund_destination === "wallet") {
        await mealpointRefund();
        await walletRefund();
        // alert('hbhv')
      } else {
        // alert('ggg')
        await mealpointRefund();
        props.onSuccess();
        loadingModal.value = false;
      }
    };

 
    // const hidden = computed(() => {
    //   // const extra_permissions = extra_permissions_keys.cancel_order_after_dispatch;
    //   const cancel_order_after_dispatch =
    //     store.state.user?.extra_permissions?.includes(
    //       extra_permissions_keys.cancel_order_after_dispatch
    //     );

    //   console.log({ cancel_order_after_dispatch });

    //   if (order.value?.locationType == "Delivery") {
    //     return !order.value.status?.Dispatched?.state;
    //   }
    //   if (order.value?.locationType != "Delivery") {
    //     return !order.value.status?.Pickup?.state;
    //   }
    // });
    return {
      openModal,
      order,
      setRefund,
      props,
      formatPrice,
      loadingModal,
      icons,
      // hidden,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>