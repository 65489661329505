<template>
  <div class="flex gap-[100px] cursor-pointer mb-3">
    <Text
      @click="() => switchTo('change-password')"
      :key="action"
      :color="action === 'change-password' ? 'red' : 'black'"
      >Change Password
    </Text>

    <Text
      @click="() => switchTo('change-email')"
      :key="action"
      :color="action === 'change-email' ? 'red' : 'black'"
      >Change Email</Text
    >
  </div>
  <form
    v-if="action === 'change-password'"
    class="flex flex-col gap-3"
    @submit="send"
  >
    <Text :size="20" :weight="600">Change Password</Text>

    <div class="w-full">
      <label for=""><Text :weight="600">EMAIL</Text></label>
      <input
        type="text"
        required
        v-model="email"
        disabled
        class="ue-input-field"
      />
    </div>

    <div class="w-full flex justify-center mt-5">
      <ue-button class="py-2 px-4"> Send password reset link</ue-button>
    </div>
  </form>

  <form
    v-if="action === 'change-email'"
    class="flex flex-col gap-3"
    @submit="modify"
  >
    <Text :size="20" :weight="600">Change Email</Text>

    <div class="w-full">
      <label for=""><Text :weight="600">CURRENT EMAIL</Text></label>
      <input
        type="text"
        required
        v-model="email"
        disabled
        class="w-full border outline-none rounded-[8px]"
      />
    </div>
    <div class="w-full">
      <label for=""><Text :weight="600">PASSWORD</Text></label>
      <input
        type="text"
        required
        v-model="password"
        class="w-full border outline-none rounded-[8px]"
      />
    </div>

    <div class="w-full">
      <label for=""><Text>NEW EMAIL</Text></label>
      <input
        type="email"
        required
        v-model="new_email"
        class="w-full border outline-none rounded-[8px]"
      />
    </div>

    <div class="w-full flex justify-center">
      <ue-button :disabled="loading" class="py-2 px-4" > Save Changes</ue-button>
    </div>
  </form>
</template>
  
  <script>
import Text from "@/components/Text.vue";
import { store } from "@/views/store/store";
import { onMounted, ref, watchEffect } from "vue";
import { set, ref as dbRef, getDatabase } from "firebase/database";
import { useToast } from "vue-toast-notification";
import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updateEmail,
  updatePassword,
} from "firebase/auth";
import { projectAuth } from "@/firebase/config";
import UeButton from "@/components/UeButton.vue";
import { useRoute, useRouter } from "vue-router";
export default {
  components: { Text, UeButton },
  setup() {
    const user = store.state.user;
    const router = useRouter();
    const email = ref(user.email);
    const new_email = ref("");
    const route = useRoute();
    const toast = useToast({ position: "top-right" });
    const password = ref("");
    const action = ref(route.query.action);
    const loading = ref(false);
    watchEffect(() => {
      action.value = route.query.action;
    });

    onMounted(() => {
      router.push(`${route.path}?page=security&action=change-password`);
    });
    const switchTo = (name) =>
      router.push(`${route.path}?page=security&action=${name}`);

    const send = async (e) => {
      loading.value = true;
      e.preventDefault();
      try {
        await sendPasswordResetEmail(projectAuth, user.email);
        toast.success("Reset passwork link has been sent to your email");
        loading.value = false;
      } catch (error) {
        console.log(error);
        loading.value = false;
      }
    };

    const modify = async (e) => {
      loading.value = true;
      e.preventDefault();
      try {
        const { user: currentUser } = await signInWithEmailAndPassword(
          projectAuth,
          user.email,
          password.value
        );

        await updateEmail(currentUser, new_email.value);
        await set(
          dbRef(getDatabase(), `admin/${user.uid}/email`),
          new_email.value
        );
        toast.success("Email updated successfully");
      } catch (error) {
        console.log(error);
        toast.error(
          error.message
            .replace("Firebase: Error", "")
            .replace("auth/", "")
            .replace("-", " ")
            .replace("(", "")
            .replace(")", "")
        );
      } finally {
        loading.value = false;
      }
    };
    return {
      email,
      new_email,
      send,
      action,
      modify,
      switchTo,
      loading,
      password,
    };
  },
};
</script>
  
  <style lang="scss" scoped>
.input {
  border-radius: 12px;
}
</style>