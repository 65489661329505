import { store } from "@/views/store/store";
import { get, query, ref as dbRef, getDatabase, orderByValue, equalTo, orderByChild, endAt, startAt, limitToLast, limitToFirst, orderByKey,sta } from "firebase/database";

export const filter_order = (order) => {


    const canncelOrders = []
    // const is_delivery = order?.location
    const operatorActiveOrders = store.state.orders.filter((x) => {
        if (order.locationType === 'Delivery') {
            return !x?.status?.Delivered?.state
        } else {
            return !x?.status?.Pickup?.state
        }
    });
    const operatorCompletedOrders = store.state.orders.filter((x) => {
        if (order.locationType === 'Delivery') {
            return x?.status?.Delivered?.state
        } else {
            return x?.status?.Pickup?.state
        }
    });


    return {
        operatorActiveOrders, operatorCompletedOrders
    }
}

export const searchOrder = async (orders, value) => {
    // console.log(value)
    const query_search_value = value?.toString().toUpperCase()
    // console.log(query_search_value?.length >= 3)
    if (value) {
        const offline_result = orders.filter(
            (order) =>
                order.id?.toLowerCase().includes(value.toLowerCase()) ||
                `${order.customer?.firstName} ${order.customer?.lastName}`
                    ?.toLowerCase()
                    ?.includes(value.toLowerCase())
                || order?.items?.map(item => item?.name?.toLowerCase())?.join(' ').includes(value.toLowerCase())
        );
        // if (offline_result?.length) return offline_result
        let online_result = []
        if (query_search_value?.length >= 3) {
            try {
                const orderId = query_search_value?.toString()
                console.log(orderId)
                const res = await get(query(dbRef(getDatabase(), 'orders/'), orderByKey(), equalTo(orderId), limitToFirst(10)))
                // const endsAt = await get(query(dbRef(getDatabase(), 'orders/'), orderByKey(), endAt(orderId), limitToFirst(5)))

                let result = []
                // console.log("UE")
                // console.log(Object.values(endsAt.val() || {}))
                
                if (res.val()) online_result = Object.values(res.val())
                result
            } catch (error) {
                console.log(error)

            }
        }
        return online_result.concat(offline_result)
    }
    return orders

}
