<template>
  <layout :hasSearch="false" class="prep-body" :hasTopNav="false">
    <v-dialog v-model="openModal">
      <!-- <div
        v-if="openModal == 'pending_to_current'"
        class="rounded w-[fit-content] mx-auto p-5 bg-white"
      >
        <MealModal
          :title="'Move to current inventory?'"
          :content="'Are you sure you want to move pending inventory to current?'"
          :firstBtn="'No, Cancel'"
          :secondBtn="'YES, Confirm'"
          :closeBtn="false"
          :tickBtn="true"
          :rightClick="() => move_to_current()"
          :oncancel="() => (openModal = false)"
        />
      </div> -->

      <update-meal-inventory
        :recipe_id="openModal"
        :onClose="() => (openModal = false)"
      />
    </v-dialog>
    <div class="w-full p-5 flex-col gap-4">
      <header class="flex justify-between">
        <Text :size="24" :weight="600"> Prep </Text>
        <!-- <router-link to="/inventory/ingredients/add">
          <button class="ue-btn-gradient rounded px-3 py-2">
            Add ingredient
          </button>
        </router-link> -->
      </header>
      <div class="w-full flex mt-5 items-center justify-between">
        <div class="search flex items-center gap-3">
          <span class="material-symbols-outlined"> search </span>
          <input type="search" placeholder="Search" v-model="search" />
        </div>
        <ue-select
          class="w-[150px]"
          :options="[...statuses]"
          v-model="status"
          placeholder="All"
          label="label"
        >
          <template #option="option">
            <span>{{ option.label }}</span>
          </template>
        </ue-select>
      </div>

      <table class="gap-[25px] w-full pt-[100px]">
        <thead class="">
          <tr class="w-full !my-[20px] px-[24px]">
            <th>
              <Text :size="17" :weight="600" class="data_item">Item</Text>
            </th>
            <th>
              <Text :size="17" :weight="600" class="data_item">Type</Text>
            </th>
            <th>
              <Text :size="17" :weight="600" class="data_item"
                >Current Inventory</Text
              >
            </th>
            <th>
              <Text :size="17" :weight="600" class="data_item"
                >Pending Inventory</Text
              >
            </th>
            <th>
              <Text :size="17" :weight="600" class="data_item">Prepare</Text>
            </th>
            <th>
              <Text :size="17" :weight="600" class="data_item">Action</Text>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="rounded-[16px] h-[100px] my-[20px] border items-center bg-white justify-between w-full px-[24px]"
            v-for="prep in paginatedList"
            :key="prep.id"
          >
            <td>
              <Text :size="17" :weight="500" class="data_item">{{
                prep?.name
              }}</Text>
            </td>
            <td>
              <Text :size="17" :weight="500" class="data_item">{{
                prep?.type
              }}</Text>
            </td>
            <td>
              <Text :size="17" :weight="500" class="data_item">{{
                prep?.current_inventory
              }}</Text>
            </td>
            <td>
              <Text
                v-if="prep?.type != 'Ready to serve'"
                :size="17"
                :weight="500"
                class="data_item wordspacing"
              >
                <move-inventory
                  :recipe="prep"
                  :on-complete="(value) => (rerender = value)"
                />
                <!-- <input-field
                  :type="'roundcheck'"
                  class="mr-3 cursor-pointer"
                  @click="
                    () => {
                      pending_to_current_prep = prep;
                      openModal = 'pending_to_current';
                    }
                  "
                  :checked="prep?.pending_inventory == 0"
                />
                <span style="font-size: 17px">
                  {{ prep?.pending_inventory }} pending
                </span> -->
              </Text>
            </td>
            <td>
              <ue-button
                @click="() => (openModal = prep.id)"
                v-if="prep?.type != 'Ready to serve'"
                >Prepare</ue-button
              >
            </td>
            <td>
              <Text
                @click="() => router.push(`/inventory/prep/${prep.id}`)"
                :size="17"
                :weight="500"
                class="flex-1 underline cursor-pointer data_item"
                :color="'#F60100'"
                >View</Text
              >
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination
        class="py-3"
        :currentPage="currentPage"
        :totalPages="totalPages"
        @page-change="goToPage"
      />
    </div>
  </layout>
</template>

<script>
import layout from "@/partials/Layout/layout.vue";
import Text from "@/components/Text.vue";
import { computed, ref, watchEffect } from "vue";
// import AddIngredient from "./AddIngredient.vue";
import { get, getDatabase, ref as dbRef, set } from "firebase/database";
import { getImg } from "@/util";
// import { getIngredientStatus } from "./getIngredientStatus";
import { useRouter } from "vue-router";
import Pagination from "@/components/Pagination.vue";
import UpdateMealInventory from "./UpdateMealInventory.vue";
import InputField from "@/components/InputField.vue";
import MealModal from "@/components/MealModal.vue";
import UeButton from "@/components/UeButton.vue";
import MoveInventory from "./MoveInventory.vue";

export default {
  components: {
    layout,
    Text,
    Pagination,
    UpdateMealInventory,
    InputField,
    MealModal,
    UeButton,
    MoveInventory,
  },
  setup() {
    const statuses = ["Available", "Low stock", "Out of stock"];
    const status = ref("");
    const openModal = ref(false);
    const search = ref("");
    const router = useRouter();
    const preplist = ref([]);
    const rerender = ref(0);

    watchEffect(async () => {
      search.value;
      rerender.value;
      status.value;
      const val = search.value.toLowerCase();
      const ingredient_data = await get(
        dbRef(getDatabase(), "inventory/ingredients")
      );
      const recipe_data = await get(dbRef(getDatabase(), "inventory/recipes"));

      if (ingredient_data.val() && recipe_data.val()) {
        let only_ready_to_serve_ingredients = Object.values(
          ingredient_data.val()
        ).filter((ingredient) => ingredient.type.id == "ready_to_serve");

        let recipe_list = Object.values(recipe_data.val());

        function PrepItem(id, name, type, c_i, p_i, prepare) {
          return {
            id,
            name,
            type,
            current_inventory: c_i || 0,
            pending_inventory: p_i,
            prepare,
          };
        }
        const prep_inge_list = only_ready_to_serve_ingredients.map(
          (ingredient) =>
            PrepItem(
              ingredient?.id,
              ingredient?.name,
              ingredient?.type?.name,
              ingredient?.current_inventory,
              null,
              false
            )
        );
        const prep_recipe_list = recipe_list.map((recipe) =>
          PrepItem(
            recipe?.id,
            recipe?.meal?.name,
            recipe.meal?.type?.name,
            recipe?.current_inventory,
            recipe?.pending_inventory || 0,
            true
          )
        );

        preplist.value = [...prep_inge_list, ...prep_recipe_list].filter(
          (value) =>
            value.name.toLowerCase().includes(val) ||
            value.type.toLowerCase().includes(val)
        );
      }
    });

    // const move_to_current = async () => {
    //   // console.log(pending_to_current_prep.value);
    //   const path = `inventory/recipes/${pending_to_current_prep.value.id}`;
    //   const move =
    //     pending_to_current_prep.value.pending_inventory +
    //       pending_to_current_prep.value.current_inventory || 0;
    //   try {
    //     await set(dbRef(getDatabase(), `${path}/pending_inventory/`), 0);
    //     await set(dbRef(getDatabase(), `${path}/current_inventory/`), move);
    //     openModal.value = false;
    //   } catch (error) {
    //     console.log("Error :", error);
    //   }
    // };

    const perPage = 15;
    const currentPage = ref(1);
    const totalPages = computed(() =>
      Math.ceil(preplist.value?.length / perPage)
    );
    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };
    const paginatedList = computed(() => {
      const startIndex = (currentPage.value - 1) * perPage;
      const endIndex = startIndex + perPage;
      return preplist.value?.slice(startIndex, endIndex);
    });
    return {
      statuses,
      status,
      preplist,
      search,
      getImg,
      router,
      goToPage,
      currentPage,
      paginatedList,
      totalPages,
      openModal,
      // move_to_current,
      rerender,
    };
  },
};
</script>

<style lang="scss" scoped>
.prep-body {
  img {
    width: 64px;
    height: 64px;
  }
  width: 100%;
  .search {
    border-radius: 30px;
    background: #fff;
    width: 80%;
    height: 39px;
    padding: 0 20px;
    display: flex;
    flex-shrink: 0;
    input {
      outline: none;
      height: 100%;
      width: 100%;
    }
  }
  table {
    border-collapse: separate;
    border-spacing: 0 1.4em;

    td:first-child {
      border-left-style: solid;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      padding-left: 20px;
    }
    td:last-child {
      border-right-style: solid;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      padding-right: 20px;
    }
    th:first-child {
      padding-left: 20px;
    }
    th:last-child {
      padding-right: 20px;
    }
  }

  .data_item {
    display: flex;
    // padding: 0 12px;
    // justify-content: center;
  }
  .selecttttt {
    border: 1px black solid;
    border-radius: 4px;
  }
  select::-ms-expand {
    display: block !important;
  }
}
</style>