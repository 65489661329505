<template>
  <div>
    <table class="order-table">
      <thead>
        <tr>
          <th class="table-header" v-for="{ key, name } in columns" :key="key">
            {{ name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item,index) in paginatedList"
          :key="item.id"
          class="table-row"
        >
          <td
            class="table-data"
            :key="key"
            v-for="({ key, cellClass, render }) in columns"
            :class="cellClass"
          >
            {{ render ? render(item, index) : item[key] }}
        
          </td>
        </tr>
      </tbody>
    </table>
    <pagination
      v-if="hasPagination"
      class="py-3"
      :currentPage="currentPage"
      :totalPages="totalPages"
      @page-change="goToPage"
    />
  </div>
</template>

<script>
import { computed, ref, watchEffect } from "vue";
import Pagination from "./Pagination.vue";
export default {
  props: {
    columns: Array,
    list: Array,
    perPage: Number,
    pagination: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const columns = ref([]);
    const list = ref([]);
    watchEffect(() => {
      columns.value = props.columns;
      list.value = props.list;
      // console.log(list.value);
    });
    const perPage = props.perPage || 15;
    const currentPage = ref(1);
    const totalPages = computed(() => Math.ceil(list.value?.length / perPage));
    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };
    const paginatedList = computed(() => {
      const startIndex = (currentPage.value - 1) * perPage;
      const endIndex = startIndex + perPage;
      return list.value?.slice(startIndex, endIndex);
    });
    return {
      columns,
      list,
      goToPage,
      currentPage,
      paginatedList,
      totalPages,
      hasPagination: props.pagination,
    };
  },
  components: { Pagination },
};
</script>

<style lang="scss" scoped>
tr {
  background: #f2f2f2 !important;
  // display: none;
}
</style>