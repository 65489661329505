
import { store, store as vuexStore } from '@/views/store/store'
import { getDatabase, onValue, ref as dbRef, get, query, limitToLast, orderByChild, startAt, endAt, equalTo, orderByKey, set, remove } from "firebase/database";
import { getLocation } from './getLocation';
import dayjs from 'dayjs';
import { useToast } from 'vue-toast-notification';
import { removeUserPendingTransaction } from './views/main/Operator/Orders/removeUserPendingTransaction';


const toast = useToast({ position: 'top-right' })
const deleteUndefinedOrders = async () => {
    try {
        const undefinedRef = dbRef(getDatabase(), "orders/undefined")
        const hasUndefined = (await get(undefinedRef)).exists()
        // console.log({hasUndefined})
        if (hasUndefined) {
            await remove(undefinedRef);
            toast.success("Undefined order found and removed");
        }
    } catch (error) {
        console.log(error);
    }
};


let i = 0
export function runAllDefault() {


    // fetch orders data into store
    const querProcessing = query(dbRef(getDatabase(), "orders/"), orderByChild('date'), limitToLast(100))
    onValue(querProcessing, async (snapshot) => {
        if (snapshot.val()) {
            // console.log(i++)
            if (Object.values(snapshot.val())) {
                let removeBug = Object.values(snapshot.val())
                    .reverse()
                    .filter(order => order?.items?.length)
                    .sort((a, b) => dayjs(b?.date?.replaceAll('"', '')) - dayjs(a?.date?.replaceAll('"', '')))
                vuexStore.commit("updateOrders", removeBug);
                vuexStore.commit("updateCancelledOrders", removeBug);
                vuexStore.commit("updateUnconfirmedOrders", removeBug);
            }
            const keys = Object.keys(snapshot.val());
            vuexStore.commit("undefinedOrders", keys?.filter(key => key === 'undefined'));
        }
    });

    const querScheduled = query(dbRef(getDatabase(), "orders/"), orderByChild('isSchedule'), equalTo(true), limitToLast(10))
    onValue(querScheduled, async (snapshot) => {
        if (Object.values(snapshot.val() || {})) {
            let removeBug = Object.values(snapshot.val()).reverse().filter(order => order?.items?.length)
            vuexStore.commit("updateScheduledOrders", removeBug);
            return removeBug
        }
    });
    // getScheduledOrders()
    onValue(dbRef(getDatabase(), "courier/"), (snapshot) => {
        if (Object.values(snapshot.val() || {})) {
            vuexStore.commit("updateRiders", Object.values(snapshot.val()).filter(rider => rider?.email).reverse());
        }
    });


    const deleteUnclearedPendingTransactions = async () => {
        const queryUsersByPendingTransaction = query(dbRef(getDatabase(), "users/"), orderByChild('pendingTransaction/0/id'), startAt("!"), endAt("~"))
        const snapshot = await get(queryUsersByPendingTransaction)
        const usersWithPendingTransaction = Object.values(snapshot.val() || {})
        if (usersWithPendingTransaction.length) {
            for (let userIndex = 0; userIndex < usersWithPendingTransaction.length; userIndex++) {
                const user = usersWithPendingTransaction[userIndex]
                // console.log(user)
                const pendingTransactions = user?.pendingTransaction || []
                for (let pdIndex = 0; pdIndex < pendingTransactions.length; pdIndex++) {
                    const pendingTransaction = pendingTransactions[pdIndex]
                    const order_id = pendingTransaction?.id
                    const isDelivered = await (await get(dbRef(getDatabase(), `orders/${order_id}/status/Delivered/state`))).val()
                    const isPickedup = await (await get(dbRef(getDatabase(), `orders/${order_id}/status/Pickup/state`))).val()
                    const isCancelled = await (await get(dbRef(getDatabase(), `orders/${order_id}/status/Cancelled/state`))).val()
                    if (isPickedup || isCancelled || isDelivered) {
                        console.log(user?.uid)
                       await removeUserPendingTransaction({customer_uid:user?.uid,order_id})
                        console.log({ order_id, isDelivered, isCancelled, isPickedup, user, pendingTransaction })
                    }

                }

            }
        }
    }
    deleteUnclearedPendingTransactions()
    // console.log('first')
    // get(queryUsersByPendingTransaction)

    //     .then((snapshot) => {
    //         console.log(snapshot.val())


    //     }).catch(err => console.log(err))



    if (window.location.pathname.includes('meal-review') && store.state?.user?.permissions?.includes("Operator Meal Review")) {
        const getMealReviews = async () => {
            const quer = query(
                dbRef(getDatabase(), "orders/"),
                orderByChild("foodReview"),
                startAt("!"),
                endAt("~"),
            );
            const res = (await get(quer)).val();
            store.commit('update_orders_with_meal_reviews', Object.values(res || {}))
        }
        getMealReviews()
    }

    //authenticate admin
    if (vuexStore.state.user.email) {
        onValue(dbRef(getDatabase(), `admin_users/${vuexStore.state.user.uid}`), (snapshot) => {
            if (snapshot.val()) {
                vuexStore.commit("login", snapshot.val());
                const token = (snapshot.val()?.token)
                if (!token) {
                    store.commit('logout')
                    window.location.assign('/admin')
                }
                getLocation()
                // console.log(locate)
            }
            if (!snapshot.exists()) {
                store.commit('logout')
                window.location.assign('/admin')
            }
        });
    }
    deleteUndefinedOrders()
}


// export { getScheduledOrders }
