<template>
  <layout :hasSearch="false" class="recipes-body" :hasTopNav="false">
    <div class="w-full p-5 flex-col gap-4">
      <header class="flex justify-between">
        <Text :size="24" :weight="600"> Recipes </Text>
        <router-link to="/inventory/recipes/add">
          <ue-button :variant="'md'">Add Recipe</ue-button>
        </router-link>
      </header>
      <div class="w-full flex mt-5 items-center justify-between">
        <div class="search flex items-center gap-3">
          <span class="material-symbols-outlined"> search </span>
          <input type="search" placeholder="Search" v-model="search" />
        </div>
      </div>

      <section class="flex flex-col gap-[25px] pt-[100px]">
        <div
          class="flex rounded-[16px] h-[100px] items-center bg-white justify-between w-full px-[0px]"
          v-for="recipe in paginatedList"
          :key="recipe.id"
        >
          <div class="flex items-center gap-5 px-[40px]">
            <Text :size="17" :weight="500" class="data_item">
              <div v-if="recipe?.meal?.image?.includes('.')">

    
                <img
                  v-if="recipe.meal.type.id == 'custom'"
                  class="rounded"
                  :src="
                    getImgFromFirebase(
                      'customMeals',
                      recipe?.meal?.image?.replace('customMeals/', '')
                    )
                  "
                />
                <img v-else class="rounded" :src="recipe?.meal?.image" />
              </div>
              <span v-else>No image</span>
            </Text>

            <Text :size="17" :weight="500" class="data_item">{{
              recipe.meal?.name
            }}</Text>
            <!-- <Text :size="17" :weight="500" class="data_item">{{
              recipe?.isCustom ? 'Custom':'Meal'
            }}</Text> -->
          </div>
          <ue-button
            :variant="'md'"
            @click="() => router.push(`/inventory/recipes/${recipe.id}`)"
            class="mr-5"
            >Edit Recipe</ue-button
          >
        </div>
      </section>
      <Pagination
        class="py-3"
        :currentPage="currentPage"
        :totalPages="totalPages"
        @page-change="goToPage"
      />
    </div>
  </layout>
</template>
  
  <script>
import layout from "@/partials/Layout/layout.vue";
import Text from "@/components/Text.vue";
import { computed, ref, watchEffect } from "vue";
import { get, getDatabase, ref as dbRef } from "firebase/database";
import { getImgFromFirebase } from "@/util";
import { useRouter } from "vue-router";
import Pagination from "@/components/Pagination.vue";
import UeButton from "@/components/UeButton.vue";

export default {
  components: { layout, Text, Pagination, UeButton },
  setup() {
    const openModal = ref(false);
    const search = ref("");
    const router = useRouter();

    const recipes = ref([]);
    watchEffect(async () => {
      search.value;
      const val = search.value.toLowerCase();
      const data = await get(dbRef(getDatabase(), "inventory/recipes"));

      if (data.val()) {
        let list = Object.values(data.val());
        // console.log(list);
        // .filter(
        //   (value) =>
        //     value.meal?.name.toLowerCase().includes(val) ||
        //     value.items
        //       .map((item) => item?.name)
        //       .join(" ")
        //       .toLowerCase()
        //       .includes(val)
        // );
        recipes.value = list;
      }
    });

    const perPage = 15;
    const currentPage = ref(1);
    const totalPages = computed(() =>
      Math.ceil(recipes.value?.length / perPage)
    );
    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };
    const paginatedList = computed(() => {
      const startIndex = (currentPage.value - 1) * perPage;
      const endIndex = startIndex + perPage;
      return recipes.value?.slice(startIndex, endIndex);
    });
    return {
      openModal,
      recipes,
      search,
      getImgFromFirebase,
      router,
      goToPage,
      currentPage,
      paginatedList,
      totalPages,
    };
  },
};
</script>
  
  <style lang="scss" scoped>
.recipes-body {
  img {
    width: 64px;
    height: 64px;
  }
  width: 100%;
  .search {
    border-radius: 30px;
    background: #fff;
    width: 80%;
    height: 39px;
    padding: 0 20px;
    display: flex;
    flex-shrink: 0;
    input {
      outline: none;
      height: 100%;
      width: 100%;
    }
  }
  .data_item {
    display: flex;
    justify-content: center;
  }
  .selecttttt {
    border: 1px black solid;
    border-radius: 4px;
  }
  select::-ms-expand {
    display: block !important;
  }
  th {
    font-size: 17px;
    font-weight: 600 !important;
  }
}
</style>