
<template>
  <Bar :data="data" :options="options" />
</template>

<script  >
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { Bar } from "vue-chartjs";
import { ref, watchEffect } from "vue";

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default {
  name: "App",
  components: {
    Bar,
  },
  props: {
    labels: Array,
    data: Array,
  },
  setup(props) {
    const data = ref({
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      datasets: [],
    });
    watchEffect(() => {
      data.value = {
        ...data.value,
        labels: props.labels,
        datasets: [
          {
            label: "Mins",
            data: props.data,
            barThickness: 6,
            backgroundColor: "red",
            borderRadius: 5,
          },
        ],
      };
    });
    return { options, data };
  },
};
</script>
