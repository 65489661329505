import { createTimeLine, sendPushNotification, store } from "@/views/store/store";
import dayjs from "dayjs";
import { getDatabase, ref as dbRef, get, set, update } from "firebase/database";
import { useToast } from "vue-toast-notification";

const toast = useToast({ position: 'top-right' })

export const moveScheduledOrderToActive = async (order) => {


    try {

        set(dbRef(getDatabase(), `orders/${order?.id}/status/Confirmed`), {
            state: true,
            time: JSON.stringify(new Date().toISOString()),
        });

        update(dbRef(getDatabase(), `orders/${order?.id}`), {
            isSchedule: false,
            orderReminderTime: JSON.stringify(
                dayjs(Date.now())
                    .add(40, "minutes")
                    .format("YYYY-MM-DDTHH:mm:ss.SSSZ")
            ),
            placed_date: order?.date,
            date: JSON.stringify(dayjs().format("YYYY-MM-DDTHH:mm:ss.SSSZ"))
        })

        const token = (
            await get(
                dbRef(getDatabase(), `users/${order?.customer?.id}/pushToken`)
            )
        ).val();

        await createTimeLine({
            orderId: order?.id,
            user_type: 'admin',
            uid: store.state.user.uid,
            name: store.state.user.first_name + " " + store.state.user.last_name,
            message: `moved this order from scheduled to active`,
        });
        if (token) {
            sendPushNotification({
                token,
                title: ``,
                body: `Your scheduled order ${order?.id} is being prepared`,
                orderId: order?.id,
            });
        }
        // toast.success('Order moved to a')
    } catch (error) {
        console.log(error)
    }

}

