<template>
  <head>
    <link
      rel="stylesheet"
      href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
    />
  </head>
  <ConfirmAction />
  <router-view :key="route.path">
    <Layout :hasTopNav="false">
  
      <v-dialog v-model="restoreModal">
        <div
          class="bg-light py-2 mx-auto rounded w-[400px] items-table text-center"
        >
          <!-- <MealModal
            title="Restore cancelled order?"
            :content="'Are you certain that you want to restore this meal order?'"
            :rightClick="() => restoreCancelledOrder(selectedOrder)"
            firstBtn="No, Cancel"
            secondBtn="YES, Confirm"
            :index="cancelid"
            :closeBtn="false"
            :oncancel="() => (restoreModal = false)"
            tickBtn
            :id="selectedOrder.id"
          /> -->
          <Text :size="20"> Restore cancelled order? </Text>
          <Text :size="14">
            Are you certain that you want to restore this order?
          </Text>

          <div class="flex w-100 justify-center gap-3 mt-4">
            <button
              v-if="!selectedOrder?.status?.AwaitingPayment?.state"
              :disabled="restoreLoading"
              class="cursor-pointer border rounded px-2 btn"
              :weight="400"
              :size="20"
              @click="() => restoreOrder('wallet')"
            >
              {{ restoreLoading ? "Loading..." : "Restore & debit wallet" }}
            </button>
            <button
              v-if="!selectedOrder?.status?.AwaitingPayment?.state"
              :disabled="restoreLoading"
              class="cursor-pointer border rounded px-2 btn"
              :weight="400"
              :size="20"
              @click="() => restoreOrder('bank alert')"
            >
              {{
                restoreLoading ? "loading..." : "     Restore via bank alert"
              }}
            </button>

            <button
              v-if="selectedOrder?.status?.AwaitingPayment?.state"
              :disabled="restoreLoading"
              class="cursor-pointer border rounded px-2 btn"
              :weight="400"
              :size="20"
              @click="() => restoreOrder('')"
            >
              {{ restoreLoading ? "loading..." : "     Restore" }}
            </button>
          </div>
        </div>
      </v-dialog>
      <div class="bg-light px-[40px]">
        <top-nav
          :searchBar="false"
          :arrowback="true"
          :arrowbackpath="`/${route.path.split('/')[1]}/orders`"
          :PrevNextIcon="true"
          :PrevNextList="prevnextlist"
        />
        <div class="d-flex justify-content-between">
          <h2>Order ID {{ route.params.id }}</h2>
          <!-- <td class="table-data cursor-pointer"> -->
          <!-- <Receipt :order="selectedOrder">
              <span class="underline cursor-pointer">{{
                selectedOrder?.printed ? "Printed" : "Print"
              }}</span>
            </Receipt> -->
          <!-- </td> -->
        </div>

        <!-- For the process flow -->
        <!-- <progression-bar :order="selectedOrder" /> -->
        <br />
        <br />
        <order-details :order="selectedOrder" />

        <!-- timer -->

        <items-table :type="'kitchen'" :key="selectedOrder.id" noaction />

        <div
          class="flex justify-center"
          v-if="
            vueStore?.state?.user?.extra_permissions?.includes(
              extra_permissions_keys.restore_cancelled_order
            )
          "
        >
          <button
            @click="() => (restoreModal = true)"
            class="!w-[75%] bg-danger text-white btn !mx-auto"
          >
            Restore
          </button>
        </div>
        <Timeline :selectedOrder="selectedOrder" />
      </div>
    </Layout>
  </router-view>
</template>
     <script scoped>
import { ref, computed, watchEffect } from "vue";
import Pagination from "@/components/Pagination.vue";
import TopNav from "@/components/TopNav.vue";
import Modal from "@/components/Modal.vue";
import { store as vueStore } from "@/views/store/store";
import MealModal from "@/views/main/Kitchen/Meals/MealModal.vue";
import { useRoute, useRouter } from "vue-router";
import QuantityCounter from "@/components/QuantityCounter.vue";
//   import styles from "./styles.module.scss";
import Countdown from "@/partials/countdown.vue";
import ProgressionBar from "@/components/ProgressionBar.vue";
import Text from "@/components/Text.vue";
import ItemMeal from "@/components/ItemMeal.vue";
import { getUeOrderId, sum } from "@/util";
import { store } from "@/views/store/store";
import { ref as dbRef, getDatabase, onValue } from "firebase/database";
import ItemsTable from "@/partials/ItemsTable.vue";
import PrevNext from "@/components/PrevNext.vue";
import { onMounted } from "vue";
import Layout from "@/partials/Layout/layout.vue";
import Timeline from "@/components/Timeline.vue";
// import Receipt from "@/components/Receipt.vue";
import OrderDetails from "@/components/OrderDetails.vue";
import ConfirmAction from "@/components/ConfirmAction.vue";

import { restoreCancelledOrder } from "@/views/main/Operator/Orders/cancelOrder";
import { extra_permissions_keys } from "@/views/main/Operator/Accounts/extra_permissions";

export default {
  props: {
    selectedOrder: Object,
    modal: Boolean,
    orders: Array,
  },
  components: {
    TopNav,
    Pagination,
    Modal,
    MealModal,
    QuantityCounter,
    Countdown,
    ProgressionBar,
    Text,
    ItemMeal,
    ItemsTable,
    PrevNext,
    Layout,
    Timeline,
    OrderDetails,ConfirmAction

    // Receipt
  },
  setup(props) {
    const selected = ref("active");
    const message = ref("Active Orders");
    const status = ref({});
    const items = ref([]);
    const selectedOrder = ref({});
    const modal = ref(vueStore.state.modal.data);
    const router = useRouter();
    const { id } = useRoute().params;
    const prevnextlist = ref([]);
    const route = useRoute();
    const restoreModal = ref(false);
    const restoreLoading = ref(false);

    router.afterEach((to, from) => {
      console.log(to.params.id);
      onValue(dbRef(getDatabase(), "orders/" + to.params.id), (snapshot) => {
        if (snapshot.val()) {
          selectedOrder.value = snapshot.val();
          items.value = selectedOrder.value.items;
          status.value = selectedOrder.value.status;
        }
      });
    });
    watchEffect(() => {
      onValue(dbRef(getDatabase(), "orders/" + route.params.id), (snapshot) => {
        if (snapshot.val()) {
          // console.log(snapshot.val());
          selectedOrder.value = snapshot.val();
          items.value = selectedOrder.value.items;
          status.value = selectedOrder.value.status;
        }
      });
    });

    watchEffect(() => {
      modal.value = vueStore.state.modal.data;
    });
    const select = (type) => {
      selected.value = type;
      message.value = type === "active" ? "Active Orders" : "Completed Orders";
    };

    const restoreOrder = (type) => {
      // alert(type)
      restoreLoading.value = true;
      restoreModal.value = false;
      restoreCancelledOrder(selectedOrder.value, type, () => {
        restoreModal.value = false;
        // restoreLoading.value = false;
        
        restoreLoading.value = false;
        router.push('/operator/orders/')
      });
    };
    watchEffect(() => {
      //create list of id's for the prevnext list kitchen

      //determine if its active or completed
      prevnextlist.value = store.state.cancelledOrders
      // .filter((x) => {
      //   // let type = Boolean(selectedOrder.value?.status?.Prepared?.state);
      //   let res = Boolean(x?.status?.Cancelled?.state);
      //   return res 
      // });

      // console.log({prevnextlist:prevnextlist.value})
    });

    // Pagination
    const ordersPerPage = 5;
    const currentPage = ref(1);
    const totalPages = computed(() =>
      Math.ceil(orders.value.length / ordersPerPage)
    );

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };

    const paginatedOrders = computed(() => {
      const startIndex = (currentPage.value - 1) * ordersPerPage;
      const endIndex = startIndex + ordersPerPage;
      return orders.value.slice(startIndex, endIndex);
    });

    return {
      ordersPerPage,
      currentPage,
      totalPages,
      goToPage,
      paginatedOrders,
      select,
      selected,
      message,
      props,
      route,
      restoreLoading,
      restoreCancelledOrder,
      selectedOrder,
      items,
      modal,
      status,
      sum,
      router,
      getUeOrderId,
      id,
      prevnextlist,
      restoreModal,
      restoreOrder,
      vueStore,
      extra_permissions_keys,
    };
  },
};
</script>
   
   