<template>
  <div class="!bg-[white] mx-auto rounded border w-[70%] p-3">
    <div class="flex justify-between my-2 items-center">
      <Text :size="20">
        {{ explore }}
      </Text>
      <span
        @click="
          () => {
            if (!loading) props.onClose();
          }
        "
        class="text-dark bg-white cursor-pointer rounded-circle block material-symbols-outlined"
      >
        cancel
      </span>
    </div>
    <div class="flex items-center justify-between">
      <div class="flex gap-3 items-center">
        <ue-select
          class="min-w-[300px] my-3"
          :options="meallist"
          label="display_name"
          required
          multiple
          v-model="newItems"
          placeholder="Add new meals"
        >
          <template #option="option">
            <div class="flex" :key="option.display_name">
              <img
                :src="option.image"
                alt=""
                class="h-[20px] w-[40px] pr-3"
                style="object-fit: cover"
              />
              {{ option.display_name }}
            </div>
          </template>
        </ue-select>
        <UeButton
          class="h-[30px]"
          @click="addNewItems"
          :disabled="!newItems.length || loading"
        >
          {{ loading ? "Loading" : "Add" }}
        </UeButton>
      </div>

      <UeButton @click="savePositions" :disabled="loading">
        {{ loading ? "Loading" : " Save position" }}
      </UeButton>
    </div>

    <div class="flex items-center gap-2">
      <Text>Filter by restaurant</Text>

      <ue-select
        class="min-w-[300px] my-3"
        :options="restaurants"
        label="name"
        required
        v-model="restaurant"
        placeholder="Select restaurant"
      >
        <template #option="option">
          <div class="flex" :key="option?.name">
            {{ option?.name }}
          </div>
        </template>
      </ue-select>
    </div>
    Move items around to rearrange

    <div class="flex flex-wrap max-h-[650px] overflow-y-scroll gap-3">
      <draggable
        v-model="mealsInExplore"
        class="flex flex-wrap gap-y-4 gap-x-3"
      >
        <template #item="{ element: meal }">
          <div class="w-[200px] pt-2 rounded gap-3 border flex-wrap">
            <div class="flex justify-between p-2 h-[30px] items-center">
              <Text :size="12" weight="600" class="m-1">
                {{ meal?.name }}
                <span style="font-size: 10px !important">
                  ({{ meal?.restaurant_name }})
                </span>
              </Text>
              <span
                @click="() => removeCategory(meal)"
                class="text-dark bg-white cursor-pointer rounded-circle material-symbols-outlined"
              >
                cancel
              </span>
            </div>
            <img
              :src="meal?.image"
              alt=""
              class="w-[200px] h-[200px]"
              style="object-fit: cover"
            />
          </div>
        </template>
      </draggable>
      <!-- <div
        class="w-[200px] bg-light flex items-center justify-center cursor-pointer rounded mb-3 border h-[225px]"
      >
      </div> -->
    </div>
  </div>
</template>

<script setup>
import Text from "@/components/Text.vue";
import UeButton from "@/components/UeButton.vue";
import { meals } from "@/views/store/meals";
import { onMounted, ref, watchEffect } from "vue";
import { get, ref as dbRef, getDatabase, set, remove } from "firebase/database";
import { useToast } from "vue-toast-notification";
import draggable from "vuedraggable";
import { httpsCallable } from "firebase/functions";
import { projectFunctions } from "@/firebase/config";

const props = defineProps({
  explore: String,
  onClose: Function,
});

const explore = ref(null);
const mealsInExplore = ref([]);
const meallist = ref([]);
const newItems = ref([]);
const toast = useToast({ position: "top-right" });
const loading = ref(false);
const restaurants = ref([]);
const restaurant = ref("");

onMounted(async () => {
  meals.dispatch("updateMeals");

  restaurants.value = Object.keys(
    (await get(dbRef(getDatabase(), `restaurants`))).val() || {}
  );
  // console.log(restaurants.value);
});

watchEffect(() => {});

const addDisplayAndID = (meal) => ({
  ...meal,
  display_name: `${meal?.name} - ${meal?.restaurant_name}`,
  id: `${meal?.name}-${meal?.restaurant_name}-${meal?.id}`, //for uniqueness
});

const sortByExplorePosition = (a, b) => {
  // console.log(a?.explore_positions, a?.name, a?.restaurant_name);
  const a_explore_positions = Object.keys(a?.explore_positions || {});
  const b_explore_positions = Object.keys(b?.explore_positions || {});
  const explore_positions = a_explore_positions.concat(...b_explore_positions);

  const hasPosition =
    explore_positions.filter((position) => position.includes(explore.value))
      .length === 2;

  if (hasPosition) {
    return (
      a?.explore_positions[explore.value] - b?.explore_positions[explore.value]
    );
  }

  return 0;
};

watchEffect(() => {
  explore.value = props.explore;

  mealsInExplore.value = explore.value
    ? meals.state.value
        .map(addDisplayAndID)
        .filter(
          (meal) =>
            meal?.category?.includes(explore.value) ||
            meal?.category == explore.value
        )
        .filter((meal) => {
          if (restaurant.value)
            return meal.restaurant_name === restaurant.value;
          return true;
        })
        .toSorted(sortByExplorePosition)
    : [];
  meallist.value = meals.state.value
    .map(addDisplayAndID)
    .filter(
      (meal) =>
        !meal?.category?.includes(explore.value) &&
        meal?.category != explore.value
    );
});

const multipleUpdates = httpsCallable(projectFunctions, "multipleUpdates");
async function addNewItems() {
  //   console.log(newItems.value);
  loading.value = true;
  const updates = newItems.value.map((newItem, index) => {
    const prevCategory = newItem.category || [];
    const isPrevString = typeof prevCategory == "string";
    let value = isPrevString
      ? [prevCategory, explore.value]
      : [...prevCategory, explore.value];
    const category_path = `/restaurants/${newItem.restaurant_name}/food/${newItem.food_category}/${newItem.index_in_firebase}/category`;
    const newExplorePositionPath = `/restaurants/${newItem.restaurant_name}/food/${newItem.food_category}/${newItem.index_in_firebase}/explore_positions/${explore.value}`;
    const newPositionValue = mealsInExplore.value.length + index;

    // await set(dbRef(getDatabase(), category_path), value);
    return { path: category_path, value };
  });

  try {
    // await Promise.all(promisies);

    await multipleUpdates({ updates });

    toast.success("Items added successfully");
    meals.dispatch("updateMeals");
    await savePositions();
    loading.value = false;
    // props.onClose();
  } catch (error) {
    console.log(error);
  }
}

async function removeCategory(item) {
  loading.value = true;
  const category_path = `/restaurants/${item.restaurant_name}/food/${item.food_category}/${item.index_in_firebase}/category`;
  const explore_position_path = `/restaurants/${item.restaurant_name}/food/${item.food_category}/${item.index_in_firebase}/explore_positions/${explore.value}`;
  const prevCategory = item.category || [];
  const isString = typeof prevCategory == "string";
  let value = isString ? "" : prevCategory.filter((x) => x != explore.value);
  //   console.log({ prevCategory, value });
  //   console.log(item)
  try {
    await set(dbRef(getDatabase(), category_path), value);
    await remove(dbRef(getDatabase(), explore_position_path));

    mealsInExplore.value = mealsInExplore.value.filter(
      (meal) => meal.display_name !== item.display_name
    );
    await savePositions();
    toast.success(
      `${item.name} (${item.restaurant_name}) removed in this explore category!`
    );
    loading.value = false;
  } catch (error) {
    console.log(error);
    loading.value = false;
  }
}

const savePositions = async () => {
  loading.value = true;
  try {
    const updates = mealsInExplore?.value?.map((meal, i) => {
      const prevPositions = meal?.explore_positions || {};
      const value = { ...prevPositions, [explore.value]: i };
      const explore_position_path = `/restaurants/${meal?.restaurant_name}/food/${meal?.food_category}/${meal?.index_in_firebase}/explore_positions`;
      console.log({ explore_position_path, ...value }, meal?.name);
      // await set(dbRef(getDatabase(), explore_position_path), value);
      return { path: explore_position_path, value };
    });
    await multipleUpdates({ updates });
    toast.success("Items position updated successfully");
    meals.dispatch("updateMeals");
    loading.value = false;
  } catch (error) {
    console.log(error);
    loading.value = false;
  }
};
</script>

<style lang="scss" scoped>
</style>