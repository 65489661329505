<template>
  <layout :hasTopNav="false">
    <div class="add-vendor-body">
      <top-nav :searchBar="false" :arrowback="true" />
      <Text :size="24" :weight="700">{{ id ? "Edit" : "Add" }} vendor</Text>
      <form action="" class="mt-5 flex flex-col gap-4" @submit="submit">
        <div class="">
          <label>Company Name</label>
          <input
            class="ue-input-field"
            placeholder="Company Name"
            v-model="formValues.vendor_name"
          />
        </div>
        <div class="">
          <label>Contact Name</label>
          <input
            class="ue-input-field"
            placeholder="Contact Name"
            v-model="formValues.contact_name"
          />
        </div>
        <div class="">
          <label>Contact Phone</label>
          <input
            class="ue-input-field"
            placeholder="Contact Phone"
            v-model="formValues.contact_phone"
          />
        </div>
        <div class="flex-1">
          <label>Address</label>
          <input
            class="ue-input-field"
            placeholder="Address"
            v-model="formValues.address"
          />
        </div>
        <div class="flex-1">
          <label>Ingredients</label>

          <!-- <ue-select
            class="ue-input-field ue-select"
            :options="ingredients"
            label="name"
            multiple
            v-model="formValues.ingredient"
            placeholder="Select Ingredient"
          >
            <template #option="option">
              <span>{{ option.name }}</span>
            </template>
          </ue-select> -->
          <div class="flex flex-wrap gap-4">
            <!-- <Text v-for="ingredient in ingredients" :key="ingredient.name">
              <input
                type="checkbox"
                class="cursor-pointer mr-2"
                :checked="ingredientExist(ingredient)"
                @input="(e) => addIngredient(ingredient, e.target.checked)"
                :id="ingredient.name"
              />
              <label :for="ingredient.name"> {{ ingredient.name }}</label>
            </Text> -->

            <ue-select
              class="ue-input-field drop"
              :options="ingredients"
              label="name"
              multiple
              v-model="formValues.ingredients"
            >
              <template #option="option">
                <span :key="option.name">
                  {{ option.name }}
                </span>
              </template>
            </ue-select>
          </div>
        </div>

        <ue-button :variant="'lg'" :disabled="loading" class="mx-auto mt-4">
          {{ loading ? "Loading" : id ? "Edit vendor" : "Add vendor" }}
        </ue-button>
        <!-- <button
          v-if="id"
          class="rounded-pill w-[70%] ue-btn-gradient mt-5 mx-auto py-3 edit-btn"
          @click="edit"
          :disabled="loading"
        >
          {{ loading ? "Loading" : "Edit vendor" }}
        </button> -->
      </form>
    </div>
  </layout>
</template>
  
  <script>
import layout from "@/partials/Layout/layout.vue";
import Text from "@/components/Text.vue";
import TopNav from "@/components/TopNav.vue";
import { set, ref as dbRef, getDatabase, get } from "firebase/database";
import { computed, ref, watchEffect } from "vue";
import { useToast } from "vue-toast-notification";
import { useRoute, useRouter } from "vue-router";
import UeButton from "@/components/UeButton.vue";
export default {
  components: { layout, Text, TopNav, UeButton },

  setup() {
    const route = useRoute();
    const loading = ref(false);
    const router = useRouter();
    const toast = useToast({ position: "top-right" });
    const ingredients = ref();

    const formValues = ref({
      vendor_name: "",
      contact_name: "",
      contact_phone: "",
      address: "",
      ingredients: [],
    });

    watchEffect(() => {
      const getvendor = async () => {
        const data = await get(
          dbRef(getDatabase(), `/inventory/vendors/${route.params.id}`)
        );
        if (data.val()) formValues.value = data.val();
        if (!data.val().ingredients) formValues.value.ingredients = [];
      };

      const getIngredients = async () => {
        const data = await get(dbRef(getDatabase(), `/inventory/ingredients`));
        if (data.val()) ingredients.value = Object.values(data.val());
      };
      getIngredients();
      if (route.params.id) getvendor();
    });

    const addIngredient = (ingredient, checked) => {
      let temp = formValues.value.ingredients;
      if (checked) temp.push(ingredient);
      if (!checked) temp = temp.filter((temp) => temp.id !== ingredient.id);
      formValues.value.ingredients = temp;
    };

    const ingredientExist = (ingredient) => {
      if (ingredient) {
        return formValues.value?.ingredients
          ?.map((ingredient) => ingredient.id)
          .includes(ingredient.id);
      }
      return false;
    };

    const submit = async (e) => {
      const create = async () => {
        const id = formValues.value.vendor_name
          .toLowerCase()
          .replaceAll(" ", "_");
        const path = `/inventory/vendors/${id}`;
        const isExist = await get(dbRef(getDatabase(), path));
        // console.log(isExist.val());
        if (isExist.val() != null) {
          toast.error("This vendor name is taken");
        } else {
          await set(dbRef(getDatabase(), path), {
            ...formValues.value,
            id,
            created_at: Date.now(),
          });
          toast.success("vendor added successfully");
          router.push("/inventory/vendors");
        }
      };

      const edit = async (e) => {
        const path = `/inventory/vendors/${route.params.id}`;
        await set(dbRef(getDatabase(), path), {
          ...formValues.value,
          id: route.params.id,
        });
        toast.success("Vendor edited successfully");
      };

      e.preventDefault();
      loading.value = true;
      const requiredFields = ["contact_phone", "vendor_name"];
      const isReuiredFilled = Object.entries(formValues.value)
        .filter((entry) => requiredFields.includes(entry[0]))
        .every((entry) => entry[1].length || entry[1]);
      try {
        if (!isReuiredFilled) {
          toast.info("Please fill vendor name and phone ");
        } else {
          route.params.id ? edit() : create();
        }
      } catch (error) {
        console.log(error);
      } finally {
        loading.value = false;
      }
    };

    return {
      submit,
      formValues,
      loading,
      id: route.params.id,
      ingredients,
      addIngredient,
      ingredientExist,
    };
  },
};
</script>
  
  <style lang="scss" >
.add-vendor-body {
  width: 100%;
  padding: 0px 40px;
  margin: auto;
  height: 100%;
  label {
    margin-bottom: 12px;
    color: #000;
    font-family: Work Sans;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 96% */
    text-transform: capitalize;
  }
  input,
  .vs__search,
  .vs__selected {
    color: rgba(0, 0, 0, 1);
    font-family: Work Sans;
    font-size: 17px !important;
    &::placeholder {
      color: rgba(0, 0, 0, 0.6);
      font-family: Work Sans;
      font-size: 17px !important;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .save-btn,
  .edit-btn {
    font-size: 24px;
  }
  select {
    option {
      width: 100%;
    }
  }
  select::-ms-expand {
    display: block;
  }
}
</style>