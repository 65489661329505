{/* <script> */ }
export function initMap(el, origin, destination, locations, onComplete = () => null) {
    let res = {}
    var directionsService = new google.maps.DirectionsService;
    // Optionally create a map
    var directionsDisplay = new google.maps.DirectionsRenderer;
    console.log(el, 'hhh')
    var map = new google.maps.Map(el, {
        zoom: 7,
        center: { lat: 41.85, lng: -87.65 }
    });
    directionsDisplay.setMap(map);

    directionsService.route({
        origin,
        destination,
        travelMode: 'DRIVING',
        waypoints: locations
    }, function (response, status) {
        if (status === 'OK') {
            // Pass data to the map
            directionsDisplay.setDirections(response);

            // See the data in the console
            // console.log(response);
            res = response
            onComplete(response)
        } else {

            window.alert('Directions request failed due to ' + status);
        }
    });

    return res
}
// </script>