<template>
  <div class="w-full h-full top_selling_item !relative bg-white rounded">
    <nav>
      <div class="left">
        <Text :weight="600" :size="18"> Meals Summary</Text>
      </div>
      <div class="rightttt flex gap-3">
        <ue-button @click="download">Download</ue-button>
        <ue-button @click="downloadDailyMealSummary">{{
          loadingDaily ? "loading..." : "Download Daily"
        }}</ue-button>
        <time-drop-down :getDate="(e) => (timeFrame = e)" />
      </div>
    </nav>
    <main id="MealSummary" class="">
      <div
        v-if="!meals.length"
        class="h-[100%] flex items-center justify-center"
      >
        <Text :weight="700" :size="24"> No orders yets</Text>
      </div>
      <Text :size="16" :weight="600" class="top">
        Meal Summary for {{ dayjs(timeFrame.start).format("MMM DD, YYYY") }} -
        {{ dayjs(timeFrame.end).format("MMM DD, YYYY") }}
      </Text>
      <div
        class="flex"
        v-for="(meal, i) in meals?.filter((meal) => meal?.quantity)"
        :key="meal?.name"
      >
        <!-- <div class="number h-full flex items-center hideInPdf" > {{ i + 1 }}</div> -->

        <Text :size="16" :weight="600" class="top w-[50px]" :key="i">
          {{ meal?.quantity || 0 }}
        </Text>
        <Text :size="16" :weight="600" class="top">
          {{ meal?.name }}
        </Text>
      </div>
    </main>
  </div>
</template>
  
  <script>
import Text from "@/components/Text.vue";
import TimeDropDownVue from "./TimeDropDown.vue";
import { watch, watchEffect } from "vue";
import { ref } from "vue";
import { getDates, getOrdersByTimeFrame, getItemQty } from "@/util";
import UeButton from "@/components/UeButton.vue";
import html2pdf from "html2pdf.js";
import dayjs from "dayjs";
import { json2csv } from "json-2-csv";
import { export2csv } from "./exportcsv";
export default {
  components: { Text, TimeDropDown: TimeDropDownVue, UeButton },
  setup() {
    const timeFrame = ref({});
    const meals = ref([]);
    const hideInPdf = ref(false);
    const loadingDaily = ref(false);
    const fetchMealSummary = async (start, end) => {
      const ordersInTimeFrame = await getOrdersByTimeFrame(start, end);
      let top_selling_meals = [];
      if (ordersInTimeFrame.length) {
        const itemsInTimeFrame = ordersInTimeFrame.map((x) => x?.items).flat(1);
        const meals = new Set();
        const getSpecial = (order) => {
          if (order) {
            if (order?.extraPortion) return order?.extraPortion?.name;
            if (order?.selectedPrice)
              return order?.name + " " + order?.selectedPrice?.item;
            return order?.name;
          }
        };
        itemsInTimeFrame.map((x) => meals.add(getSpecial(x))); //unique meals
        Array.from(meals).map((meal, i) => {
          let gather_meals = itemsInTimeFrame.filter(
            (x) => getSpecial(x) === meal
          );
          if (gather_meals.length) {
            const quantity = gather_meals
              .map((x) => x?.quantity - (x?.cancelled || x?.canceled || 0) || 0)
              .reduce((a, b) => a + b);
            if (quantity) {
              top_selling_meals[i] = {
                name:
                  getSpecial(gather_meals[0]) +
                  (gather_meals[0]?.extraPortion?.name
                    ? `(${2 * quantity}  ${gather_meals[0]?.name})`
                    : ""),
                extraPortion: gather_meals[0]?.extraPortion || {},
                selectedPrice: gather_meals[0]?.selectedPrice || {},
                quantity: quantity || 0,
                number_of_orders: gather_meals.length,
              };
            }
          }
        });

        let addons = {};
        let sides = {};
        let customize = {};

        const getCountOfExtra = (extra, set = new Set(), obj = {}, item) => {
          // console.log(item);
          if (extra?.length) {
            extra.map((x) => {
              const added = set.has(x?.name);
              const extraQuantity =
                getItemQty({ subItem: x, mainItem: item }) -
                (x?.cancelled || x?.canceled || 0);
              // getItemQty({ subItem: x, mainItem: item?.quantity }) -

              if (extraQuantity) {
                if (!added) {
                  set.add(x?.name);
                  obj[x?.name] = 1 * extraQuantity;
                }
                if (added) {
                  obj[x?.name] = obj[x?.name] + extraQuantity;
                }
              }
            });
          }
        };
        const addonsSet = new Set();
        const sidesSet = new Set();
        const customizeSet = new Set();
        itemsInTimeFrame?.map((item) => {
          getCountOfExtra(item?.addons, addonsSet, addons, item);
          getCountOfExtra(item?.sides, sidesSet, sides, item);
          getCountOfExtra(item?.customize, customizeSet, customize, item);
        });
        const toObj = (extraObj) => {
          return Object.entries(extraObj).map((x) => ({
            name: x[0],
            quantity: x[1],
          }));
        };
        const addExtraToList = (extraList) => {
          toObj(extraList).map((extra) => {
            const isAlrieadyAmeal = top_selling_meals
              .map((x) => x?.name)
              .indexOf(extra?.name);
            if (isAlrieadyAmeal > 0) {
              top_selling_meals[isAlrieadyAmeal].quantity =
                top_selling_meals[isAlrieadyAmeal].quantity +
                extra?.quantity -
                (extra?.cancelled || extra?.canceled || 0);
            } else {
              top_selling_meals.push(extra);
            }
          });
        };

        addExtraToList(addons);
        addExtraToList(sides);
        addExtraToList(customize);
      } else {
        top_selling_meals = [];
      }
      // meals.value =
      return {
        meals: top_selling_meals.sort((a, b) => b.quantity - a.quantity),
        orderTotal: ordersInTimeFrame.length,
      };
    };
    watchEffect(async () => {
      const { start, end } = timeFrame.value;
      const res = await fetchMealSummary(start, end);
      meals.value = res.meals;
    });

    const downloadDailyMealSummary = async () => {
      loadingDaily.value = true;
      const dates = getDates(
        timeFrame.value.start,
        timeFrame.value.end,
        "days",
        "YYYY-MM-DD"
      );
      console.log(dates);
      let stats = [];
      for (let i = 0; i <= dates.length - 1; i++) {
        const date = dates[i];
        // const day = i.toString().padStart(2, "0");
        // const start = `2024-04-${day}`;
        // const end = `2024-04-${day}`;

        const _start = dayjs(date).set("hours", 0).set("minutes", 0);

        const _end = dayjs(date).set("hours", 23).set("minutes", 59);

        console.log(date);
        const data = await fetchMealSummary(_start, _end);

        const meals = data.meals
          ?.map((item) => `${item?.name} ${item?.quantity}`)
          .join(" | ");

        stats.push({
          date: date,
          "Total orders": data.orderTotal,
          "Items sold": meals,
        });
      }
      const csv = json2csv(stats);
      export2csv(csv, "export.csv", "text/csv;charset=utf-8;");
      loadingDaily.value = false;
    };
    const download = async () => {
      const hides = document.getElementsByClassName("hideInPdf");
      hides.forEach((element) => {
        element.style.opacity = 0;
      });
      const element = document.getElementById("MealSummary");
      var opt = {
        margin: [1.5, 2, 1.5, 2], //top, left, buttom, right,
        filename: `${"MealSummary"}.pdf`,
        image: { type: "jpeg", quality: 75 },
        html2canvas: { dpi: 192, scale: 2, letterRendering: true, scrollY: 0 },
        // html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
      };
      try {
        await html2pdf()
          .from(element)
          .set(opt)
          .toPdf()
          .output("dataurlnewwindow");
        hides.forEach((element) => {
          element.style.opacity = 1;
        });
        // store.dispatch("setOrderPrint", { order: order.value });
      } catch (error) {
        console.log(error);
      }
    };

    // watchEffect(()=>{
    //   top_selling_meals.sort((a, b) => b.number_of_orders - a.number_of_orders)
    // })
    return {
      timeFrame,
      meals,
      download,
      hideInPdf,
      dayjs,
      downloadDailyMealSummary,
      loadingDaily,
    };
  },
};
</script>
  
  <style lang="scss" >
.top_selling_item {
  font-family: Work Sans;
  height: 100%;
  padding: 33px 35px;
  width: 100%;
  display: flex;
  gap: 24px;
  justify-content: space-between;
  flex-direction: column;

  nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // position: absolute;top: 10px;width: 100%;
    .left {
      // top: 30px;
      // position: absolute;
      // left: 10px;
    }
    left: 10px;
    .rightttt {
      // top: 30px;
      // position: absolute;
      // // width: 75px;
      // right: 10px;
    }
  }
  main {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    padding-right: 4px;
    margin-top: 30px;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      width: 4px;
    }
    .card {
      display: flex;
      justify-content: space-between;
      //   align-items: center;
      width: 100%;
      flex-direction: row;
      height: 1;
      border: none;
      img {
        width: 64px;
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        height: 65px;
        // align-self: flex-end;
        display: block;
      }
      .left {
        height: 100%;
        display: flex;
        gap: 13px;
        align-items: center;
        width: fit-content;
      }
    }
  }
}
</style>