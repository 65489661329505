
import { useToast } from 'vue-toast-notification';
import createPersistedState from "vuex-persistedstate";
import { createStore } from "vuex"
import { getDatabase, set, ref as dbRef, update, get, query, orderByChild, equalTo } from 'firebase/database';
import { formatPrice, getItemTotalPrice, getOrderID, sum, writeOrderTotalInTimeline } from '@/util';
import { createTimeLine, store } from './store';
import { WalletTransaction } from '../main/Operator/Customers/updateWallet';

// import { useRouter } from "vue-router";




// const router = useRouter()
const toast = useToast({ position: 'top-right' })


export const editOrder = createStore({
    state() {
        return {
            order: {},
            item: {},
            newItem: {},
            updates: {},
            loading: false,
            itemIndex: null,
            addItemModal: false,
            restaurant: {},
            addExtraModal: false,
            quantity: 1,
            message: '',
            proceedWithInSufficientWallet: {
                state: false,
                wallet_amount: 0,
                bank_alert_amount: 0
            }
        }
    },
    mutations: {
        selectOrder: (state, payload) => {
            if (state.order?.id == payload?.id) {
                state.order = {}
                state.item = {}
            } else {
                state.order = payload
                // console.log(JSON.stringify(state.order?.items[0]))
                state.item = {}

            }
        },
        setItem: (state, payload) => {
            console.log('selected item => ', payload)
            state.item = payload?.item
            state.itemIndex = payload?.itemIndex
        },
        openAddExtraModal: (state, payload) => {
            state.addExtraModal = payload
            if (!payload) state.proceedWithInSufficientWallet = {}

            // state.item = payload
        },
        openAddItemModal: (state, payload) => {

            state.addItemModal = payload
            // state.newItem = {}
        },
        setRestaurant: (state, payload) => {

            state.restaurant = payload
        },
        selectItem: (state, payload) => {
            // console.log('-----',)
            const { type, newItem, food } = payload
            let selected = state.updates[type] || []
            const value = {
                amount: newItem?.amount?.replaceAll(',', ''),
                available: newItem?.available,
                food,
                name: newItem?.name,
                qty: 1
            }
            if (selected?.find(x => x?.name == newItem?.name)) {
                selected = selected?.filter(x => x?.name != newItem?.name)
            } else {
                selected.push(value)
            }
            console.log({ selected })
            state.updates[type] = selected
        },
        setQuantity: (state, payload) => {
            state.quantity = payload
        },
        setExtraQuantity: (state, payload) => {
            const { type, subItem, qty } = payload
            const index =   state.updates[type]?.findIndex(x => x?.name === subItem?.name)
            if(index >=0 ){
                state.updates[type][index].qty = qty
            }
            console.log(state.updates,index)
            // state.quantity = payload
        },
        createNewItem: (state, payload) => {
            const { item, foodType, selectedPrice, extra_portion } = payload
            let amount = item?.amount?.replaceAll(',', '')
            if (selectedPrice?.price) amount = selectedPrice?.price?.replaceAll(',', '')
            if (extra_portion?.amount) amount = extra_portion?.amount?.replaceAll(',', '')

            const data = {
                // "addons": [],
                amount,
                "assembled": false,
                "canceled": 0,
                "category": "",
                // "customize": [],
                "extraPortion": extra_portion || "",
                foodType,
                "id": "0004",
                "img": item?.image,
                "name": item?.name,
                "point": 0,
                "prepared": 0,
                "quantity": 1,
                "realAmount": 0,
                "resturant": state.restaurant[0],
                "selectedPrice": selectedPrice || "",
                // "sides": [],
                "type": "meal"
            }
            state.item = data

        },
        editMessage: (state = payload) => {
            state.message = `${state.message}  ${payload}`
        },

        saveAddExtras: async (state, payload) => {
            const isNewItem = Boolean(state.restaurant[0])
            // const indexFound = state.order?.items?.findIndex(item => item?.name == state.item?.name && item?.resturant == state.item?.resturant)
            console.log(state.item, '-----', state.updates)
            let index = state.itemIndex
            const op_path = `/restaurants/${state.item?.resturant}/food/${state.item?.foodType}`
            // console.log(op_path)
            // const quer = query(, orderByChild('name'), equalTo(state.item?.name))
            const mealsincate = (await get(dbRef(getDatabase(), op_path))).val() || []
            const meal = mealsincate?.find(meal => meal?.name === state.item.name)
            const mealOpitons = Object.keys(meal?.options || {})?.map(item => item?.toLowerCase())
            // console.log(mealOpitons)
            const keys = ['addons', 'sides', 'customize']
            let price = 0

            if (isNewItem) {
                state.item.quantity = state.quantity;
                price = parseInt(state.item?.amount?.replaceAll(',', '')) * state.item.quantity
                console.log('price', price)
                index = state.order?.items?.length

            }
            let hasExtras = false
            console.log(state.updates, '0000')
            keys.forEach(element => {
                if (state.updates[element]) {
                    const zzz = state.updates[element]
                    const qty = parseInt(state.item?.quantity - (state.item?.cancelled || state.item?.canceled || 0)) 
                    price += sum(zzz.map(item => parseInt(item?.amount * qty * item?.qty)))
                    console.log(price, zzz)
                    state.message += `(${zzz.map(item => item?.name).join(', ')}) `
                    hasExtras = true
                }
            })

            if (isNewItem) {
                if (hasExtras) state.message = `${state.item.name} with ${state.message}`
                if (!hasExtras) state.message = `${state.item.name}`
            } else {
                state.message = `${state.message} in ${state.item.name}`
            }

            const runEdit = async (wallet_and_bank_message) => {
                console.log(state.message)
                let stop = true

                keys.forEach(element => {
                    if (state.updates[element]) stop = false
                    if (state.updates[element]) state.item[element] = [...Object.values(state.item[element] || {}), ...Object.values(state.updates[element])]
                });
           
                const prev_order_total = (await get(dbRef(getDatabase(), `orders/${state.order?.id}/total_price`))).val()
                const new_order_total = parseInt(prev_order_total) + price
                // console.log(state?.order?.id, state.item, index)
                let timeout = setTimeout(() => {
                    state.loading = false
                }, 5000)

                set(dbRef(getDatabase(), `orders/${state?.order?.id}/items/${index}`), state.item)
                    .then(() => {
                        set(dbRef(getDatabase(), `orders/${state?.order?.id}/total_price`), new_order_total)
                        set(dbRef(getDatabase(), `orders/${state?.order?.id}/confirmation_type`), payload)
                        set(dbRef(getDatabase(), `orders/${state?.order?.id}/amount_due`), (state.order?.amount_due || 0) + price)
                        set(dbRef(getDatabase(), `orders/${state?.order?.id}/new_items_total`), {
                            method: payload,
                            price: (state.order?.new_items_total?.price || 0) + price
                        })
                        createTimeLine({
                            orderId: state.order?.id,
                            user_type: 'admin',
                            uid: store.state.user.uid,
                            name: store.state.user.first_name + " " + store.state.user.last_name,
                            message: `confirmed payment (${formatPrice(price)}) for ${state.message} via ${wallet_and_bank_message || payload}, order total ${writeOrderTotalInTimeline(new_order_total)}`,
                        });
                        toast.success("Order updated successfully")
                        clearTimeout(timeout)
                        state.loading = false
                        state.addExtraModal = false
                        state.restaurant = {}
                        state.item = {}
                        state.order = {}
                        state.updates = {}
                        state.quantity = 1
                        state.index = null
                        state.message = ''

                    }).catch((error) => {
                        console.log(error)
                        toast.error("An error occured")
                    })

            }

            state.loading = true
            // console.log({price})
            if (price) {
                if (payload == 'wallet balance') {
                    const user = (await get(dbRef(getDatabase(), `users/${state.order?.customer?.id}/`))).val()
                    const walletUpdate = new WalletTransaction(user)
                    await walletUpdate.withdrawFromWallet(
                        price,
                        `${state?.message}. Order ${getOrderID(state.order)}`,
                        () => runEdit(),
                        () => {

                            state.loading = false
                            state.proceedWithInSufficientWallet = {
                                state: walletUpdate.balance ? true : false, //incase wallet is 0
                                wallet_amount: walletUpdate.balance,
                                bank_alert_amount: price - walletUpdate.balance,
                                total: price
                            }
                        }
                    )
                }
                else if (payload == "wallet balance and bank alert") {
                    const user = (await get(dbRef(getDatabase(), `users/${state.order?.customer?.id}/`))).val()
                    const walletUpdate = new WalletTransaction(user)
                    const wallet_price = walletUpdate.balance || 0
                    const bank_alert_price = price - wallet_price
                    if (wallet_price) {
                        const extra_message = ` wallet (${formatPrice(wallet_price)}) and bank alert (${formatPrice(bank_alert_price)}) `
                        await walletUpdate.withdrawFromWallet(
                            wallet_price,
                            `${state?.message} (partially). Order ${getOrderID(state.order)}`,
                            () => runEdit(extra_message),
                        )
                        // runEdit(extra_message)
                    } else {
                        toast.error('Please use bank alert, wallet balance is 0')
                        state.loading = false
                    }
                } else {
                    runEdit()
                }
            } else {
                toast.info('Select atleast one item'); state.loading = false; return;
            }
            // state.updates = {}
        }
    },
    actions: {
    },
    // plugins: [createPersistedState()]
})