<template>
  <head>
    <link
      rel="stylesheet"
      href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
    />
  </head>

  <layout :hasTopNav="false">
    <v-dialog v-model="ask_add_type" width="auto" class="rounded">
      <div
        class="bg-white rounded-[12px] flex-col h-[auto] py-3 w-[300px] flex items-center justify-center"
      >
        <Text :size="18" :weight="600" class="mb-3">Add Staff</Text>
        <div class="flex w-full justify-around my-3">
          <button
            class="border ue-btn-outline rounded-[8px] px-3 py-2"
            @click="router.push('/operator/users-and-permissions/add-staff')"
          >
            Add new staff
          </button>
          <button
            class="ue-btn-gradient rounded-[8px] px-3 py-2"
            @click="
              router.push('/operator/users-and-permissions/existing-user')
            "
          >
            Add existing user
          </button>
        </div>
      </div>
    </v-dialog>
    <v-dialog v-model="ask_logout_all">
      <div class="bg-white p-3 w-[350px] mx-auto rounded">
        <MealModal
          title="Log staff out ?"
          :content="'Are you certain you want to force logout all staff?'"
          :rightClick="() => logoutAllUsers()"
          firstBtn="No, Cancel"
          secondBtn="YES, Confirm"
          :oncancel="() => (ask_logout_all = false)"
          :closeBtn="true"
          :tickBtn="false"
        />
      </div>
    </v-dialog>
    <div class="px-[40px] bg-light w-full h-full">
      <TopNav :onSearch="(e) => (searchValue = e)" />
      <div class="d-flex justify-content-between orderClass p-3 w-full">
        <div class="w-full">
          <div class="flex w-full items-center justify-between">
            <h1>
              <Text :size="27" :weight="700"> Users and permissions </Text>
            </h1>
            <!-- -->
            <div class="flex gap-2">
              <button
                class="button ue-btn-gradient text-white"
                @click="() => (ask_add_type = true)"
              >
                Add staff</button
              ><button
                class="button ue-btn-gradient text-white"
                @click="() => (ask_logout_all = true)"
              >
                Logout all staff
              </button>
            </div>
          </div>
        </div>
      </div>
      <section class="orderList !p-0">
        <div
          v-for="admin_user in admin_users"
          :key="admin_user.uid"
          class="rounded-[12px] my-4 bg-white items-center justify-between flex px-3 py-4 w-full"
        >
          <Text :size="20" :weight="600" class="w-[25%]">
            {{ admin_user.first_name }} {{ admin_user.last_name }}
          </Text>
          <Text :size="20" :weight="400" class="w-[30%]">
            {{
              admin_user.permission_list
                ?.filter((list) => list.permitted)
                ?.map((list) => list.page)
                ?.join(",   ") || "No permissions"
            }}
          </Text>
          <Text :size="14" :weight="400" class="w-[20%]">
            Last login <br />
            {{
              admin_user?.last_login
                ? dayjs(admin_user?.last_login).format("YYYY MMM DD. hh:mmA")
                : ""
            }}
          </Text>
          <button class="px-3 py-1 rounded text-white bg-[#FF9103] w-[10%]">
            <router-link
              :to="`/operator/users-and-permissions/${admin_user.uid}`"
            >
              <Text :color="'white'" :size="14"> Edit staff </Text>
            </router-link>
          </button>
        </div>
      </section>
    </div>
  </layout>
</template>
            
          <script>
import TopNav from "@/components/TopNav.vue";
import InputField from "@/components/InputField.vue";
import Text from "@/components/Text.vue";
import Layout from "@/partials/Layout/layout.vue";
import { useRoute, useRouter } from "vue-router";
import AddStaff from "./AddStaff.vue";
import { ref } from "vue";
import { watchEffect } from "vue";
import { get, getDatabase, ref as dbRef, remove } from "firebase/database";
import UeButton from "@/components/UeButton.vue";
import { dashboards } from "@/dashboards";
import dayjs from "dayjs";
import MealModal from "@/components/MealModal.vue";
import { store } from "@/views/store/store";

export default {
  components: {
    TopNav,
    InputField,
    Text,
    Layout,
    AddStaff,
    UeButton,
    MealModal,
  },
  setup() {
    const router = useRouter();
    const admin_users = ref([[]]);
    const searchValue = ref("");
    const ask_add_type = ref(false);
    const ask_logout_all = ref(false);
    const dashboard_list = ["Operator", "Logistics", "Kitchen", "Assembler"];

    watchEffect(() => {
      searchValue.value;
      const getUsers = async () => {
        const res = await get(dbRef(getDatabase(), "admin_users"));
        let users = Object.values(res.val() || {})?.filter(user => user?.email);

        //get permissions list
        users = users.map((user) => {
          // console.log(user?.first_name,user?.permissions)
          const user_permissions = Object.values(user?.permissions || {});
          let permission_list = [];
          if (user_permissions?.length) {
            permission_list = dashboard_list.map((item) => ({
              page: item,
              permitted: user_permissions?.filter((permission) =>
                permission?.includes(item)
              )?.length,
            }));
          }

          return {
            ...user,
            permission_list,
          };
        });

        const search = (user) => {
          // console.log(searchValue.value)
          const se = searchValue.value.toLowerCase();
          return searchValue.value
            ? user.first_name.toLowerCase().includes(se) ||
                user.last_name.toLowerCase().includes(se)
            : users;
        };

        admin_users.value = users.filter(search);
      };
      getUsers();
    });

    const logoutAllUsers = async () => {
      try {
        const promises = admin_users.value.map(async (user) => {
          // console.log(user.token, user.uid, store.state.user?.uid);
          if (user.token && user.uid !== store.state.user?.uid) {
            return await remove(
              dbRef(getDatabase(), `admin_users/${user.uid}/token`)
            );
          }
          return null;
        });

        await Promise.all(promises);
      } catch (error) {
        console.log(error);
      }
    };

    // dayjs().$event
    return {
      router,
      admin_users,
      searchValue,
      ask_add_type,
      dashboards,
      dayjs,
      logoutAllUsers,
      ask_logout_all,
    };
  },
};
</script>
            
            <style scoped>
</style>