<template>
  <div @click="() => (openSwitchModal = true)">
    <slot />
  </div>
  <ConfirmAction />
  <v-dialog v-model="openSwitchModal" style="z-index: 20">
    <div class="bg-white w-[400px] rounded border mx-auto p-2 switch-form">
      <Text :size="17" weight="600">Switch Order {{ order?.id }}</Text>

      <vue-google-autocomplete
        :country="['ng']"
        id="map"
        classname="outline-none my-4 !w-[100%] rounded"
        placeholder="Enter new location "
        v-on:placechanged="(e) => (formValues.deliveryLocation = e)"
      >
      </vue-google-autocomplete>
      <input
        type="number"
        class="border !border-[#f60100] outline-none px-2 py-[8px] w-100 rounded"
        placeholder="Enter delivery fee"
        v-model="formValues.delivery_fee"
      />
      <div class="flex justify-evenly">
        <div class="mt-4 flex gap-1">
          <CheckBox
            :checked="charge_type == 'manual confirmation'"
            @click="charge_type = 'manual confirmation'"
          />
          Manual confirmation
        </div>
        <div class="mt-4 flex gap-1">
          <CheckBox
            :checked="charge_type == 'wallet'"
            @click="charge_type = 'wallet'"
          />
          Debit wallet
        </div>
      </div>
      <div class="flex mt-3 justify-center">
        <ue-button class="mt-3" :disabled="loading" @click="convert">{{
          loading ? "Loading..." : "Switch to delivery"
        }}</ue-button>
      </div>
    </div>
  </v-dialog>
</template>

<script setup>
import Text from "@/components/Text.vue";

import VueGoogleAutocomplete from "vue-google-autocomplete";
import { ref, watchEffect } from "vue";
import UeButton from "@/components/UeButton.vue";
import CheckBox from "@/components/CheckBox.vue";
import { set, ref as dbRef, getDatabase, get } from "firebase/database";
import { createTimeLine, store } from "@/views/store/store";
import { WalletTransaction } from "../Customers/updateWallet";
import { formatPrice } from "@/util";
import { useToast } from "vue-toast-notification";

import { confirmAction } from "@/views/store/confirmAction";
import ConfirmAction from "@/components/ConfirmAction.vue";

const props = defineProps({
  order: Object,
});
const order = ref(props.order);
const charge_type = ref("manual confirmation");
const formValues = ref({
  deliveryLocation: {},
  delivery_fee: null,
});

watchEffect(() => {
  order.value = props.order;
});

const openSwitchModal = ref(false);
const toast = useToast({ position: "top-right" });
const loading = ref(false);

const convert = async () => {
  const charge = formValues.value.delivery_fee;
  if (!charge || !formValues.value.deliveryLocation?.latitude) {
    console.log(!charge, !formValues.value.deliveryLocation?.latitude);
    toast.info("Please fill in all fields!");
    return;
  }

  loading.value = true;
  let newOrder = order.value;

  const { Pickup, ...status } = order.value.status;
  //
  newOrder.locationType = "Delivery";
  newOrder.delivery = formValues.value.delivery_fee;
  newOrder.status = {
    ...status,
    Dispatched: {
      state: false,
      time: "",
    },
    Arrived: {
      state: false,
      time: "",
    },
    Delivered: {
      state: false,
      time: "",
    },
  };
  const {
    street_number,
    route,
    latitude,
    longitude,
    administrative_area_level_2,
  } = formValues.value.deliveryLocation;
  const address = `${street_number || ""} ${route || ""} ${
    administrative_area_level_2 || ""
  }`;
  newOrder.customer.deliveryAddress = {
    address,
    houseAddress: address,
    latitude,
    longitude,
    houseNo: "",
    phoneNumber: order.value.customer.phone,
  };
  newOrder.preparation_time = "45mins";
  newOrder.time_left = "40-45";

  const updatePrice = () => {
    newOrder.total_price =
      order.value.total_price + formValues.value.delivery_fee;
    newOrder.amount_due =
      order.value.amount_due + formValues.value.delivery_fee;
  };

  let runTimeline = false;
  // console.log(newOrder);
  try {
    if (charge_type.value == "manual confirmation") {
      const proceedWithBankAlert = async () => {
        updatePrice();
        newOrder.confirmation_type = "Bank Alert";
        await set(dbRef(getDatabase(), `orders/${order.value.id}`), newOrder);
        runTimeline = true;
      };
      confirmAction.commit("toggleModal", {
        onProceed: () => proceedWithBankAlert(),
        confirmText: "BANK ALERT",
      });
    }
    if (charge_type.value == "wallet") {
      const proceedWithWallet = async () => {
        const user_id = order.value.customer.id;
        const user = (
          await get(dbRef(getDatabase(), `users/${user_id}`))
        ).val();

        const wallet_act = new WalletTransaction(user);
        const balance = user?.wallet?.balance || 0;

        if (balance < charge) {
          order.value = props.order;
          toast.error("User does not have enough balance");
          runTimeline = false;
        } else {
          updatePrice();
          newOrder.confirmation_type = "Wallet";
          await set(dbRef(getDatabase(), `orders/${order.value.id}`), newOrder);
          await wallet_act.withdrawFromWallet(charge, "", () => null);
          runTimeline = true;
        }
      };
      confirmAction.commit("toggleModal", {
        onProceed: () => proceedWithWallet(),
        confirmText: "WALLET",
      });
    }

    if (runTimeline) {
      await createTimeLine({
        orderId: order.value.id,
        user_type: "admin",
        uid: store.state.user.uid,
        name: store.state.user.first_name + " " + store.state.user.last_name,
        message: `switched order to delivery via ${
          charge_type.value
        }, delivery fee (${formatPrice(charge)})`,
      });
    }

    loading.value = false;
    openSwitchModal.value = false;
  } catch (error) {
    console.log(error);
    loading.value = false;
    toast.error("An error occured");
  }
};
</script>

<style lang="scss" scoped>
.switch-form {
  .pac-container {
    z-index: 999999 !important;
    position: absolute !important;
  }
  input {
    border: 1px #f60100 solid;
    &::-webkit-input-placeholder {
      font-size: 14px !important;
    }
  }
}
</style>