export const getMultiplePrices = (meals = [], meal, newPrice, priceIndex) => {

    const check = item => (item?.name?.toLowerCase() === meal?.name?.toLowerCase())
    let meal_multiple_prices = []

    meals.forEach((meal_item, index) => {

        if (check(meal_item) && meal_item?.prices?.length) {
            console.log(meal_item?.restaurant_name,meal_item)
            // const stoplist_path = (`/restaurants/${meal_item.restaurant_name}/food/${meal_item.food_category}/${meal_item.index_in_firebase}/available`);
            const prices_list = meal_item?.prices || []

            if (prices_list.length) {
                const unit_amount_path = (`/restaurants/${meal_item.restaurant_name}/food/${meal_item.food_category}/${meal_item.index_in_firebase}/amount`);
                const prices_base_path = (`/restaurants/${meal_item.restaurant_name}/food/${meal_item.food_category}/${meal_item.index_in_firebase}/prices`);
                // const self = meal_item
                prices_list.forEach((price_list, i) => {
                    const quantity = parseInt(price_list?.item?.replace(/\D/g, '^')?.split("^")?.filter(num => num).pop())
                    const unit_amount = parseInt(newPrice?.replaceAll(',', ''))
                    // console.log({ price, quantity, unit: price / quantity, newPrice, priceIndex })
                    // const unit_amount = price / quantity
                    meal_multiple_prices.push({ meal_item, self: price_list, item_price_path: `${prices_base_path}/${i}/price`, unit_amount_path, unit_amount, quantity, item_price_value: unit_amount * quantity })
                })
            }


        }
    })

    return meal_multiple_prices



}