<template>
  <head>
    <link
      rel="stylesheet"
      href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
    />
  </head>

  <layout :hasTopNav="false">
    <div class="px-[40px] bg-light w-full h-full">
      <TopNav />
      <div class="d-flex justify-content-between orderClass p-3 w-full">
        <div class="w-full">
          <div class="flex w-full items-center justify-between">
            <h1>
              <Text :size="27" :weight="700"> {{ message }}</Text>
              <!-- <span class="capitalize">{{ selectId }}</span> -->
            </h1>
          </div>
        </div>
      </div>
      <section class="orderList !p-0 !bg-white">
        <div>
          <table class="order-table">
            <thead>
              <tr>
                <th class="table-header"></th>
                <th class="table-header">Order ID</th>
                <th class="table-header">Date</th>
                <th class="table-header">Customer</th>
                <th class="table-header">Amount</th>
                <th class="table-header">Reason</th>
                <th class="table-header">Admin</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(summary, index) in paginatedSummary?.toReversed()"
                :key="summary.id"
                class="table-row"
                :class="index % 2 === 0 ? 'even-row' : 'odd-row'"
              >
                <td class="table-data">
                  {{ index + 1 + 15 * (currentPage - 1) }}
                </td>
                <td class="table-data"><OrderID :id="summary?.orderId" /></td>
                <td class="table-data">
                  {{ dayjs(summary.date).format("MMM DD. hh:mmA ") }}
                </td>
                <td class="table-data">
                  <customer-name
                    v-if="summary.customerId"
                    :customerId="summary.customerId"
                  />
                  <span v-else>{{ summary.customer }}</span>
                </td>

                <td class="table-data">
                  {{ formatPrice(summary?.amount) }}
                </td>

                <td class="table-data">{{ summary?.message }}</td>

                <td class="table-data">{{ summary?.staff }}</td>
              </tr>
            </tbody>
          </table>
          <Pagination
            class="py-3"
            :currentPage="currentPage"
            :totalPages="totalPages"
            @page-change="goToPage"
          />
        </div>
      </section>
    </div>

    <!-- <OrderModal v-else-if="selectedOrder?.id" :selectedOrder="selectedOrder" /> -->
  </layout>
</template>
          
        <script>
import { ref, computed, watchEffect } from "vue";
import Pagination from "@/components/Pagination.vue";
// import OrderModal from "./OrderModal.vue";
import TopNav from "@/components/TopNav.vue";
import Modal from "@/components/Modal.vue";
import { getData } from "@/firebase/config";
import { useRoute, useRouter } from "vue-router";
import InputField from "@/components/InputField.vue";
import Text from "@/components/Text.vue";
import {
  sum,
  formatDate,
  getUeOrderId,
  // diffByStatus,
  formatPrice,
} from "@/util";
import Status from "@/components/Status.vue";
import Layout from "@/partials/Layout/layout.vue";
import { store } from "@/views/store/store";
import TimeLeftDropdown from "@/components/TimeLeftDropdown.vue";
import Receipt from "@/components/Receipt.vue";
import Refund from "./Refund.vue";
import { order_canceled_amount } from "./cancelcalculation";
import { setCancelRefundStatus } from "./updateCancelItemRefundStatus";
import { set, ref as dbRef, getDatabase, get, push } from "firebase/database";
import dayjs from "dayjs";
import OrderID from "@/components/OrderID.vue";
import CustomerName from "@/components/CustomerName.vue";

export default {
  components: {
    TopNav,
    Pagination,
    // OrderModal,
    Modal,
    InputField,
    Text,
    Status,
    Layout,
    TimeLeftDropdown,
    Receipt,
    Refund,
    OrderID,
    CustomerName,
  },
  setup() {
    const orders = ref(store.state.orders);
    const status = ref("active");
    const message = ref("Cancelled Orders");
    const kitchen = getData("restaurants");
    const selectedOrder = ref({});
    const route = useRoute();
    const router = useRouter();
    const selectId = ref(route.params.id);
    // const selectedKitchen = ref("");
    const searchValue = ref("");
    const selectedTime = ref("");
    // const selectKitchen = (e) => {
    //   selectedKitchen.value = e?.target?.value;
    // };

    // const search = (e) => {
    //   searchValue.value = e;
    // };

    // watchEffect(() => {
    //   selectId.value = route.params.id;
    //   if (selectId.value) {
    //     selectedOrder.value = store.state.orders.find(
    //       (x) => x.id === selectId.value
    //     );
    //   }
    // });

    // const select = (type) => {
    //   status.value = type;
    //   message.value = type === "active" ? "Active Orders" : "Completed Orders";
    // };
    // const orderIsCancelledItem = (order) => {
    //   const items = order?.items;
    //   const checkItemIfItHasAddonOrSide = (item) => {
    //     let hasCancelledAddon, hasCancelledSide, hasCancelledCustomize;
    //     if (item?.addons)
    //       hasCancelledAddon = item?.addons?.some((addon) => addon?.cancelled);
    //     if (item?.sides)
    //       hasCancelledSide = item?.sides?.some((side) => side?.cancelled);
    //     if (item?.customize)
    //       hasCancelledCustomize = item?.customize?.some(
    //         (customize) => customize?.cancelled
    //       );
    //     if (hasCancelledAddon || hasCancelledSide || hasCancelledCustomize)
    //       // console.log(hasCancelledAddon, hasCancelledSide);
    //       return hasCancelledAddon || hasCancelledSide || hasCancelledCustomize;
    //   };
    //   if (items?.length)
    //     return items?.filter(
    //       (item) => item?.canceled > 0 || checkItemIfItHasAddonOrSide(item)
    //     ).length;
    // };

    // const calculateAmount = (order) => {
    //   const is_all_cancelled = order?.items?.every(
    //     (item) => item.quantity == item.canceled
    //   );
    //   if (is_all_cancelled) return order?.total_price;
    //   return order_canceled_amount(order);
    // };

    // const isPending = (order) => {
    //   // console.log(order?.locationType)
    //   if (order?.locationType === "Delivery") {
    //     return !order?.status?.Dispatched?.state && !order.refund_completed;
    //   } else {
    //     return !order?.status?.Pickup?.state && !order.refund_completed;
    //   }
    // };
    // const isRefundable = (order) => {
    //   if (order?.locationType === "Delivery") {
    //     return order.refund_completed || !order?.status?.Dispatched?.state;
    //   } else {
    //     return order.refund_completed || !order?.status?.Pickup?.state;
    //   }
    // };

    // watchEffect(() => {
    //   const onlyCancelledOrder =
    //     store.state.orders?.filter(orderIsCancelledItem);

    //   let statusFilter = onlyCancelledOrder.filter((x) => {
    //     return status.value === "active"
    //       ? !x?.refund_completed
    //       : x?.refund_completed;
    //   });

    //   if (searchValue.value) {
    //     orders.value = store.state.orders.filter(
    //       (x) =>
    //         x.id?.toLowerCase().includes(searchValue.value.toLowerCase()) ||
    //         `${x.customer?.firstName} ${x.customer?.lastName}`
    //           ?.toLowerCase()
    //           ?.includes(searchValue.value.toLowerCase())
    //     );
    //   } else {
    //     orders.value = statusFilter;
    //   }

    //   if (selectedTime.value === "htl") {
    //     orders.value.sort((a, b) => {
    //       return b.countdown.operator_timeleft - a.countdown.operator_timeleft;
    //     });
    //   } else if (selectedTime.value === "lth") {
    //     orders.value.sort((a, b) => {
    //       return a.countdown.operator_timeleft - b.countdown.operator_timeleft;
    //     });
    //   }
    // });

    const cancelledItemSummary = ref([]);
    watchEffect(async () => {
      cancelledItemSummary.value = Object.values(
        (await get(dbRef(getDatabase(), `cancelled_items_summary`))).val() || {}
      )
      .sort((a, b) =>a?.date- b?.date);
    });

    // Pagination
    const ordersPerPage = 15;
    const currentPage = ref(1);
    const totalPages = computed(() =>
      Math.ceil(cancelledItemSummary.value.length / ordersPerPage)
    );

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };

    const paginatedSummary = computed(() => {
      const startIndex = (currentPage.value - 1) * ordersPerPage;
      const endIndex = startIndex + ordersPerPage;
      return cancelledItemSummary.value.slice(startIndex, endIndex);
    });

    return {
      ordersPerPage,
      currentPage,
      totalPages,
      goToPage,
      paginatedSummary,
      // select,
      status,
      message,
      sum,
      selectedOrder,
      kitchen,
      selectId,
      // selectKitchen,
      router,
      // search,
      formatDate,
      getUeOrderId,
      // diffByStatus,
      selectedTime,
      // calculateAmount,
      formatPrice,
      setCancelRefundStatus,
      // isPending,
      // isRefundable,
      dayjs,
    };
  },
};
</script>
          
          <style scoped>
.button {
  padding: 10px 30px;
  border-radius: 5px;
  border: 1px #f60100 solid;
  background-color: #fff;
  color: #f60100;
  margin-left: 20px;
}
.button.active {
  background: linear-gradient(180deg, #ff9103 0%, #f60100 100%);
  color: white;
  border: none;
}

.pagination-container {
  display: flex;
  column-gap: 10px;
}
.paginate-buttons {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(217, 217, 217);
  color: black;
}
.paginate-buttons:hover {
  background-color: #d8d8d8;
}
.active-page {
  background-color: #3498db;
  border: 1px solid #3498db;
  color: white;
}
.active-page:hover {
  background-color: #2988c8;
}

.order-table {
  width: 100%;
  border-collapse: collapse;
  /* border: 1px solid #ccc; */
}

.table-row {
  height: 60px;
}

.table-row.even-row {
  background-color: #f2f2f2;
}

.table-row.odd-row {
  background-color: #fff;
}

.status-button {
  padding: 5px 10px;
  background: linear-gradient(
    202deg,
    rgba(255, 145, 3, 0.24) 0%,
    rgba(246, 1, 0, 0.24) 100%
  );

  border: none;
  color: #333;
  font-weight: bold;
}

.status-button2 {
  padding: 5px 10px;
  background: linear-gradient(
    202deg,
    rgba(74, 224, 117, 0.428) 0%,
    rgba(0, 246, 78, 0.24) 100%
  );

  border: none;
  color: #333;
  font-weight: bold;
}

.action-link {
  text-decoration: none;
  color: #f60100;
}
</style>