<template>
  <div v-if="table">
    <table class="order-table">
      <thead>
        <tr>
          <th class="table-header"></th>
          <th class="table-header">Order ID</th>
          <th class="table-header">Date</th>
          <th class="table-header">Customer Name</th>
          <th class="table-header">Service</th>
          <th class="table-header">Courier</th>
          <th class="table-header">Tag</th>
          <th class="table-header">Amount due</th>
          <!-- <th class="table-header text-center">Confirm Payment</th> -->

          <th class="table-header">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(order, index) in paginatedOrders"
          :key="order.id"
          class="table-row"
          :class="index % 2 === 0 ? 'even-row' : 'odd-row'"
        >
          <td class="table-data">
            {{ index + 1 + 15 * (currentPage - 1) }}
          </td>
          <td class="table-data"><OrderID :order="order" /></td>
          <td class="table-data">{{ formatDate(order.date) }}</td>
          <td class="table-data">
            <!-- {{ `${order?.customer?.firstName}  ${order?.customer?.lastName}` }} -->
            <CustomerName :customer="order?.customer" />
          </td>
          <td class="table-data">{{ order.locationType }}</td>
          <td
            v-if="order.locationType?.toLowerCase() === 'delivery'"
            class="table-data"
          >
            {{ order.courier.name ? order.courier.name : "Unassigned" }}
          </td>
          <td v-else class="table-data">
            <!-- @click="() => orderUpdateMealInvetory({order})" -->
            N/A
          </td>

          <td class="table-data">
            <customer-tag :uid="order?.customer?.id" :order="order" />
          </td>

          <td class="table-data">
            {{ formatPrice(  (order?.initialOrderAmountDue || order?.amount_due)) }}
          </td>

          <td class="table-data">
            <router-link
              :to="`/${path.split('/')[1]}/cancelled-order/${order?.id}`"
              class="action-link !underline"
              >View</router-link
            >
          </td>
        </tr>
      </tbody>
    </table>

    <!-- {{ store.state.searchValue }} -->
    <Pagination
      class="py-3"
      :currentPage="currentPage"
      :totalPages="totalPages"
      @page-change="goToPage"
    />
  </div>
  <SingleUnconfirmed v-else />
</template>

<script setup>
import Pagination from "@/components/Pagination.vue";
import { formatDate, getOrderID, formatPrice } from "@/util";
import { searchOrder } from "@/util/filterOrder";
import { store } from "@/views/store/store";
import { computed } from "vue";
import ConfirmPayment from "./ConfirmPayment.vue";
import { ref, watchEffect } from "vue";
import InputField from "@/components/InputField.vue";
import SingleUnconfirmed from "./SingleUnconfirmed.vue";
import { useRoute } from "vue-router";

import CustomerTag from "@/components/CustomerTag.vue";
import OrderID from "@/components/OrderID.vue";
import CustomerName from "@/components/CustomerName.vue";
// import Timeline from "@/components/Timeline.vue";

const orders = ref([]);
const table = ref(true);
const { path } = useRoute();

watchEffect(async () => {
  // orders.value =;

  orders.value = await searchOrder(
    store.state.cancelledOrders,
    store.state.searchValue
  );
});

// Pagination
const ordersPerPage = 15;
const currentPage = ref(1);
const totalPages = computed(() =>
  Math.ceil(orders.value.length / ordersPerPage)
);

const goToPage = (page) => {
  if (page >= 1 && page <= totalPages.value) {
    currentPage.value = page;
  }
};

const paginatedOrders = computed(() => {
  const startIndex = (currentPage.value - 1) * ordersPerPage;
  const endIndex = startIndex + ordersPerPage;
  return orders.value.slice(startIndex, endIndex);
});
</script>

<style lang="scss" scoped>
</style>