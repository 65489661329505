<template>
  <button
    :class="`${disabled ? ' !bg-[#f2f2f2]' : ''}  ${variant}`"
    :disabled="disabled"
  >
    <slot></slot>
  </button>
</template>
  
<script>
import { ref } from "vue";
import { watchEffect } from "vue";
export default {
  props: {
    buttonClass: {
      type: String,
      default:
        "w-[content] mt-8 py-4 px-4 bg-blue text-white text-sm rounded bg-blue hover:bg-gold hover:text-blue",
    },
    variant: String,
    disabled: Boolean,
  },

  setup(props) {
    const md = `px-3 py-2 rounded text-white whitespace-nowrap md`;
    const lg = ` w-50 !py-0 !h-[43px] rounded-[30px] lg`;
    const variant = ref("md");
    const disabled = ref(false);

    watchEffect(() => {
      disabled.value = props.disabled;
      switch (props.variant) {
        case "md":
          variant.value = md;
          break;
        case "lg":
          variant.value = lg;
          break;
        default:
          variant.value = md;
          break;
      }
    });

    return {
      disabled,
      variant,
    };
  },
};
</script>
<style lang="scss">
.lg {
  background-image: linear-gradient(
    to right,
    rgba(246, 1, 0, 1),
    rgba(246, 1, 0, 1),
    rgba(255, 145, 3, 1)
  );
  border: none;
  color: white;
  font-size: 24px;

  font-family: Work Sans;
  text-transform: capitalize;

  font-style: normal;
  font-weight: 700;
  cursor: pointer;
  white-space: nowrap;
}
.md {
  background: var(
    --Primary,
    linear-gradient(180deg, #ff9103 -36.96%, #f60100 100%)
  );
  white-space: nowrap;
  text-transform: capitalize;
  font-family: Work Sans;
  border: none;
  color: white;
  font-weight: 500;
  line-height: 16px;
  font-size: 17px;
}
</style>