<template>
  <!-- {{ center }} -->
  <!-- {{ props.order.arrived_location }} -->
  <GoogleMap
    api-key="AIzaSyAUR5lKBWKOCHVAe1c5iN4e5vo0OL7_GxY"
    style="width: 100%; height: 500px"
    :center="center"
    class="pb-4"
    :zoom="13"
  >
    <Polyline :options="flightPath" />
    <Marker :options="kitchenMarkOptions" class="!bg-success" />
    <Marker
      v-if="deliveryLocationMarkOptions"
      :options="deliveryLocationMarkOptions"
    />
    <Marker
      v-if="arrivedLocationMarkerOptions"
      :options="arrivedLocationMarkerOptions"
    />
    <Marker
      v-if="riderLocationMarkOptions"
      :options="riderLocationMarkOptions"
    />
  </GoogleMap>
  <a>
    <!-- {{ riderLocationMarkOptions }} -->
  </a>
</template>

<script>
import { riderMapCircleIcon } from "@/util";
import { getDatabase, onValue, ref as dbRef } from "firebase/database";
import { defineComponent, ref, watchEffect } from "vue";
import { useRoute } from "vue-router";
import { GoogleMap, Polyline, Marker } from "vue3-google-map";
import img from "@/assets/images/icons8-map-pin-48.png";

export default defineComponent({
  components: { GoogleMap, Polyline, Marker },
  props: {
    order: { type: Object, default: {} },
    defaultRider: { type: Object, default: {} },
  },
  setup(props) {
    const deliveryCoordinates = ref({});
    const riderCoordinates = ref({});
    const ueKitchen = ref({ lat: 6.45392, lng: 3.47739 });
    const deliveryLocationMarkOptions = ref({});
    const riderLocationMarkOptions = ref({});
    const { id } = useRoute().params;
    const flightPath = ref({});
    const center = ref({});
    const arrivedLocation = ref({});
    const arrivedLocationMarkerOptions = ref({});
    const kitchenMarkOptions = {
      position: { ...ueKitchen.value },
      label: "",
      title: "Urban Eats kitchen",
      icon: "https://img.icons8.com/color/48/map-pin.png",
    };

    center.value = { ...ueKitchen.value };
    // console.log(center.value)
    //
    watchEffect(() => {
      onValue(dbRef(getDatabase(), `orders/${props.order.id}`), (snap) => {
        if (snap.val()) {
          const { customer } = snap.val();
          deliveryCoordinates.value.lat = customer?.deliveryAddress?.latitude;
          deliveryCoordinates.value.lng = customer?.deliveryAddress?.longitude;
          arrivedLocation.value.lat = snap.val()?.arrived_location?.latitude;
          arrivedLocation.value.lng = snap.val()?.arrived_location?.longitude;
          // console.log(arrivedLocation.value)
          // arrivedLocation.value =
          // if (courier?.live_location) {
          //   riderCoordinates.value.lat = courier?.live_location?.latitude;
          //   riderCoordinates.value.lng = courier?.live_location?.longitude;
          // }
        }
      });

      onValue(
        dbRef(getDatabase(), `courier/${props.order?.courier?.id || id}`),
        (snap) => {
          if (snap.val()) {
            const courier = snap.val();
            riderCoordinates.value.lat = courier?.live_location?.latitude;
            riderCoordinates.value.lng = courier?.live_location?.longitude;

            if (riderCoordinates.value) {
              riderLocationMarkOptions.value = {
                position: { ...riderCoordinates.value },
                label: `${courier?.first_name} ${courier?.last_name}`
                  ?.split(" ")
                  .map((x) => x[0])
                  .join(""),
                title: `${courier?.first_name} ${courier?.last_name} | Rider`,
                icon: riderMapCircleIcon,
              };
            }
          }
        }
      );

      if (deliveryCoordinates.value) {
        deliveryLocationMarkOptions.value = {
          position: { ...deliveryCoordinates.value },
          label: "",
          title: `${props.order.customer?.firstName} ${props.order.customer?.lastName} | customer`,
          icon: img,
        };
      }
      if (arrivedLocation.value) {
        arrivedLocationMarkerOptions.value = {
          position: { ...arrivedLocation.value },
          label: "🔵",
          title: `Arrived Position`,
          icon: 'img',
        };
      }

      // riderLocationMarkOptions.value = {
      //   position: { lat: coor?.latitude, lng: coor?.longitude },
      //   label: riderName
      //     ?.split(" ")
      //     .map((x) => x[0])
      //     .join(""),
      //   title: `${riderName} | Rider`,
      //   icon: riderMapCircleIcon,
      // };
      // if (props.defaultRider) {

      // const riderName =
      //   props.defaultRider.first_name + " " + props.defaultRider.last_name;
      // console.log(riderName, "---00000");
      // const coor = props.defaultRider.live_location;
      // center.value = { lat: coor?.latitude, lng: coor?.longitude };
      // console.log(riderName);

      // }

      flightPath.value = {
        path: [deliveryCoordinates.value, ueKitchen.value],
        geodesic: true,
        strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 2,
      };

      // console.log(deliveryCoordinates.value, riderCoordinates.value);
    });

    return {
      center,
      flightPath,
      kitchenMarkOptions,
      deliveryLocationMarkOptions,
      riderLocationMarkOptions,
      props,arrivedLocationMarkerOptions
    };
  },
});
</script>