import dayjs from 'dayjs';
import { set, ref as dbRef, getDatabase, get } from 'firebase/database'
import { useToast } from 'vue-toast-notification';
export const mealpointTransaction = async (order, point, customer, type) => {
    // const orderMealPoints =
    //   order?.items?.map((item) => parseInt(item?.point)) || [];
    // const point = sum(orderMealPoints);
    const toast = useToast({position:'top-right'})
    const mealpointHistoryRef = dbRef(
        getDatabase(),
        `users/${order?.customer?.id}/mealPoint/history`
    );
    const mealpointSumRef = dbRef(
        getDatabase(),
        `users/${order?.customer?.id}/mealPoint/point`
    );
    const refund = {
        created: JSON.stringify(dayjs().format("YYYY-MM-DDTHH:mm:ss.SSSZ")),
        orderId: order?.id,
        point,
        pointType: type,
    };

    const prevMealPointHistory = (await get(mealpointHistoryRef)).val() || [];
    const prevMealPointSum = (await get(mealpointSumRef)).val() || 0;
    console.log({ prevMealPointHistory, prevMealPointSum, point, refund });
    if (point) {
        if (type) await set(mealpointHistoryRef, [...prevMealPointHistory, refund]);
        if (type === 'add') await set(mealpointSumRef, parseFloat(prevMealPointSum) + point);
        if (type === 'subtract') await set(mealpointSumRef, parseFloat(prevMealPointSum) - point);
        toast.success(`Mealpoint ${type}ed successfully`)
    }
};