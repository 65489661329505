

<template>
  <layout :hasTopNav="false" class="bg-[#EFEFEF] w-full">
    <div class="w-full px-5 h-[100]">
      <top-nav :arrowback="false" :searchBar="false"> </top-nav>

      <Text :weight="700" :size="27"> Wallet Transactions </Text>
      <div class="rounded-[12px] bg-white p-4 mt-4 w-full">
        <table class="order-table">
          <thead>
            <tr>
              <th class="table-header"></th>
              <!-- <th class="table-header">Order ID</th> -->
              <th class="table-header">Date</th>
              <th class="table-header">Customer</th>
              <th class="table-header">Admin</th>
              <th class="table-header">Type</th>
              <th class="table-header">Amount</th>
              <th class="table-header">Reason</th>
              <th class="table-header">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(transaction, index) in paginatedTransactions"
              :key="index"
              class="table-row"
              :class="index % 2 === 0 ? 'even-row' : 'odd-row'"
            >
              <td class="table-data">
                {{ index + 1 + transactionsPerPage * (currentPage - 1) }}
              </td>
              <td class="table-data">{{ formatDate(transaction?.date) }}</td>
              <td class="table-data">
                <customer-name :customer="transaction?.customer" />
                <!-- {{
                  `${transaction?.customer?.firstName} ${transaction?.customer?.lastName}`
                }} -->
              </td>

              <td class="table-data">
                {{
                  `${transaction?.admin?.first_name} ${transaction?.admin?.last_name}`
                }}
              </td>

              <td class="table-data">
                {{ transaction?.type }}
              </td>
              <td class="table-data">
                {{ formatPrice(transaction.amount) }}
              </td>
              <td class="table-data">
                {{ transaction.reason }}
              </td>
              <td class="table-data">
                {{ transaction.status  || '--' }}
              </td>
            </tr>
          </tbody>
        </table>

        <Pagination
          class="py-3"
          :currentPage="currentPage"
          :totalPages="totalPages"
          @page-change="goToPage"
        />
      </div>
    </div>
  </layout>
</template>
    
    <script setup>
import layout from "@/partials/Layout/layout.vue";
import TopNav from "@/components/TopNav.vue";
import Text from "@/components/Text.vue";
import { computed, ref, watchEffect } from "vue";
import { getDatabase, get, ref as dbRef, set } from "firebase/database";
import Pagination from "@/components/Pagination.vue";
import CustomerName from "@/components/CustomerName.vue";
import { formatDate, formatPrice } from "@/util";
import dayjs from "dayjs";
// import Pagination from "@/components/Pagination.vue";
// import Text from "@/components/Text.vue";

const wallet_transactions = ref([]);

watchEffect(async () => {
  const transactions = Object.values(
    (await get(dbRef(getDatabase(), "wallet_transactions"))).val() || {}
  );
  wallet_transactions.value = transactions?.toSorted((a,b)=>dayjs(b.date) - dayjs(a.date)); [];
  // console.log(wallet_transactions.value);
});
//     components: { layout, TopNav, Text, Pagination, Status, WalletTable },

//     setup() {
//       const customer = ref({});
//       const editing = ref("");
//       const route = useRoute().params;
//       const currInput = ref("");
//       const toast = useToast();
//       const orders = ref([]);
//       const totalAmountSpent = ref(0);
//       const totalMealBotPointRedeemed = ref(0);
//       const mealPointsRedeemed = ref([]);
//       const totalmealPointsEarned = ref(0);
//       const formValues = ref({ email: "", phoneNumber: "" });

//       return {
//         customer,

//       };
//     },
//   };

const transactionsPerPage = 15;
const currentPage = ref(1);
const totalPages = computed(() =>
  Math.ceil(wallet_transactions.value.length / transactionsPerPage)
);

const goToPage = (page) => {
  if (page >= 1 && page <= totalPages.value) {
    currentPage.value = page;
  }
};

const paginatedTransactions = computed(() => {
  const startIndex = (currentPage.value - 1) * transactionsPerPage;
  const endIndex = startIndex + transactionsPerPage;
  return wallet_transactions.value.slice(startIndex, endIndex);
});
</script>
    
    <style lang="scss" scoped>
input {
  padding: 6px;
  margin: 4px 0;
  width: 90%;
  border-radius: 4px;
}
.editing {
  border: 0.1px solid grey;
}
.edited {
  border: none;
  outline: none;
}
.meal-point-history-table {
  border-collapse: collapse;
  border-spacing: 0 15px;
  tr {
    row-gap: 12px;
    &:nth-child(even) {
      background: #efefef;
    }
  }
  th,
  td {
    padding: 5px;
  }
}
</style>