import { customer_store } from "@/views/store/customers";
import dayjs from "dayjs";

function CSVToArray(csvData, delimiter) {
    delimiter = (delimiter || ",");
    var pattern = new RegExp((
        "(\\" + delimiter + "|\\r?\\n|\\r|^)" +
        "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
        "([^\"\\" + delimiter + "\\r\\n]*))"), "gi");
    var data = [[]];
    var matches = null;
    while (matches = pattern.exec(csvData)) {
        var matchedDelimiter = matches[1];
        if (matchedDelimiter.length && (matchedDelimiter != delimiter)) {
            data.push([]);
        }
        if (matches[2]) {
            var matchedDelimiter = matches[2].replace(
                new RegExp("\"\"", "g"), "\"");
        } else {
            var matchedDelimiter = matches[3];
        }
        data[data.length - 1].push(matchedDelimiter);
    }
    return (data);
}
//var csv is the CSV file with headers
function csvJSON(csv) {

    var lines = csv.split("\n");

    var result = [];

    // NOTE: If your columns contain commas in their values, you'll need
    // to deal with those before doing the next step 
    // (you might convert them to &&& or something, then covert them back later)
    // jsfiddle showing the issue https://jsfiddle.net/
    var headers = lines[0].split(",");

    for (var i = 1; i < lines.length; i++) {

        var obj = {};
        var currentline = lines[i].split(",");

        for (var j = 0; j < headers.length; j++) {
            obj[headers[j]] = currentline[j];
        }

        result.push(obj);

    }

    //return result; //JavaScript object
    return JSON.stringify(result); //JSON
}
export const csvPhoneNoProcessor = (csv_file, customers, phoneIndex = 1) => {

    let failedReg = []
    const file = csv_file;
    const reader = new FileReader();
    reader.onload = e => {

        const res = e.target.result
        const list = CSVToArray(res)
        // console.log({ list })
        const new_list = (list.splice(1, list.length))//
        let numbers = new Set(new_list.map(item => (item[phoneIndex]?.replace("+", "")))) //gather phones uniquely



        // const endDate = new Date(new_list[0][0])
        const startDate = new Date(new_list[0][6]) // get date to extract customer from
        console.log({ startDate, new_list: new_list[0][6] }, dayjs(new_list[0][6]).format("MMM DD YYYY"))
        const ddd = (date) => new Date(date?.replaceAll('"', ''))
        const numbers_list = Array.from(numbers).filter(itemIsNotEmpty => itemIsNotEmpty).map(phoneNumber => phoneNumber?.replace('+', ''))
        const customersWithinDate = customers
        // ?.filter(customer => ddd(customer?.created) >= startDate)
        const customerPhones = customersWithinDate.map(customer => customer?.phoneNumber?.replace('+', ''))
        // console.log({ customerPhones, numbers_list })
        let difference = numbers_list.filter(termi_number => !customerPhones.includes(termi_number))
        // console.log({ difference })
        failedReg = Array.from(new Set(new_list.filter(iten => difference.includes(iten[phoneIndex]))))
        // console.log({failedReg})
        customer_store.commit('saveFailRegs', difference)
    };
    reader.readAsText(file);
}