<template>
  <head>
    <link
      rel="stylesheet"
      href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
    />
  </head>

  <div class="modal-container">
    <div class="modal-box">
      <div class="close-button" @click="close()">
        <i class="fas fa-times"></i>
      </div>

      <img
        v-if="props.closeBtn"
        class="sidebar-icon"
        src="https://firebasestorage.googleapis.com/v0/b/uber-eats-e496b.appspot.com/o/images%2Fclosecircle.png?alt=media&token=ce163790-87e5-4ce1-86cf-d30c8d1b1746"
        alt=""
      />

      <img
        v-if="props.tickBtn"
        class="sidebar-icon"
        src="https://firebasestorage.googleapis.com/v0/b/uber-eats-e496b.appspot.com/o/images%2Ftickcircle.png?alt=media&token=257c9f60-0cd7-465a-8abc-4b4294c0b863"
        alt=""
      />
      <h3>{{ title }}</h3>
      <p>{{ content }}</p>
      <div class="button-container">
        <button class="button white-button" @click="close()">
          {{ firstBtn }}
        </button>
        <button
          class="button orange-button"
          @click="rightClick"
          :disabled="props.loading"
        >
          {{ props.loading ? "Loading..." : secondBtn }}
        </button>
      </div>
    </div>
  </div>
</template>
            
          <script>
import Pagination from "@/components/Pagination.vue";

import TopNav from "@/components/TopNav.vue";
import { store } from "@/views/store/store";
import { ref } from "vue";

export default {
  components: {
    TopNav,
    Pagination,
  },
  props: {
    title: String,
    content: String,
    firstBtn: String,
    secondBtn: String,
    closeBtn: Boolean,
    tickBtn: Boolean,
    rightClick: Function,
    oncancel: Function,
    loading: Boolean,
  },
  setup(props) {
    const loading = ref(false);
    const close = () => {
      store.commit("closeModal");
      props.oncancel();
    };
    const rightClick = async () => {
      // loading.value = true;
      props.rightClick();
      // setTimeout(() => {
      //   loading.value = false;
      // }, 4000);
    };
    return {
      props,
      close,
      loading,
      rightClick,
    };
  },
};
</script>
<style scoped>
/* .modal-container {
  margin-left: 300px;
  height: 100vh;
  background-color: #f5f5f5;
  width: 90%;
} */
.modal-box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 24px 0;
}
/* active and completed button */
.button {
  padding: 10px 10px;
  border-radius: 20px;
  font-size: 11px;
}
.button-container {
  /* margin-right: 50px; */
}
.white-button {
  background-color: white;
  color: #f60100;
  border: 1px solid white;
  font-weight: 700;
  line-height: 16px;
  margin-right: 80px;
}

.orange-button {
  background: linear-gradient(202deg, #ff9103 0%, #f60100 100%);
  border: none;
  color: white;
  font-weight: 700;
  line-height: 16px;
}
.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
.modal-box h3 {
  font-size: 24px;
  margin-bottom: 20px;
}
.modal-box p {
  font-size: 12px;
  margin-bottom: 40px;
}
@media screen and (max-width: 700px) {
  .side-bar {
    display: none;
  }
}
</style>