<template>
  <head>
    <link
      rel="stylesheet"
      href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
    />
  </head>

  <layout :hasTopNav="false">
    <div class="px-[40px] bg-light w-full h-full" v-if="!selectId">
      <TopNav :onSearch="search" :PrevNextIcon="false" />
      <div class="d-flex justify-content-between orderClass p-3 w-full">
        <div class="w-full">
          <div class="flex w-full items-center justify-between">
            <h1>
              <Text :size="27" :weight="700"> {{ message }}</Text>
              <span class="capitalize">{{ selectId }}</span>
            </h1>
            <!-- <div>
              <button
                class="button"
                :class="{ active: status === 'active' }"
                @click="select('active')"
              >
                Active Orders
              </button>
              <button
                class="button"
                :class="{ active: status === 'completed' }"
                @click="select('completed')"
              >
                Completed Orders
              </button>
            </div> -->
            <status-button
              :buttons="[
                {
                  message: 'Active Orders',
                  key: 'active',
                  // tab_bracket: store.state?.order_tabs?.kitchen_active?.length,
                },
                { message: 'Unconfirmed Orders', key: 'unconfirmed' ,  
                // tab_bracket: store.state?.unconfirmedOrders.length
              },
                { message: 'Cancelled Orders', key: 'cancelled' },
                { message: 'Completed Orders', key: 'completed' },
              ]"
              :on-change="
                (data) => {
                  status = data.key;
                  message = data.message;
                }
              "
            />
          </div>
          <div class="flex mt-5 items-center gap-[0px] justify-end">
            <!-- <button class="kitchenBtn border"> -->
            <input-field
              :id="'sle'"
              :label="' '"
              :type="'select'"
              @input="selectKitchen"
              class="outline-none p-3"
              :placeholder="'All kitchens'"
              :options="kitchen.map((x) => ({ label: x.id, value: x.id }))"
            ></input-field>
            <time-left-dropdown
              :getTime="(time) => (selectedTime = time)"
              v-if="status !== 'completed'"
            />
          </div>
        </div>
      </div>
      <section class="orderList !p-0 !bg-white">
        <div v-if="status == 'active' || status == 'completed'">
          <table class="order-table">
            <thead>
              <tr>
                <th class="table-header"></th>
                <th class="table-header">Order ID</th>
                <th class="table-header">Date</th>
                <th class="table-header">Customer Name</th>
                <th class="table-header">Service</th>
                <th class="table-header">Courier</th>
                <th class="table-header">Status</th>
                <th class="table-header">
                  {{ status === "active" ? "Time Left" : "Time Spent" }}
                </th>
                <th class="table-header">Action</th>
                <th class="table-header">Print</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(order, index) in paginatedOrders"
                :key="order.id"
                class="table-row"
                :class="index % 2 === 0 ? 'even-row' : 'odd-row'"
              >
                <td class="table-data">
                  {{ index + 1 + 15 * (currentPage - 1) }}
                </td>
                <td class="table-data">{{ getOrderID(order) }}</td>
                <td class="table-data">{{ formatDate(order.date) }}</td>
                <td class="table-data">
                  {{
                    `${order?.customer?.firstName}  ${order?.customer?.lastName}`
                  }}
                </td>
                <td class="table-data">{{ order.locationType }}</td>
                <td
                  v-if="order.locationType?.toLowerCase() === 'delivery'"
                  class="table-data"
                >
                  {{ order.courier.name ? order.courier.name : "Unassigned" }}
                </td>
                <td v-else class="table-data">
                  <!-- @click="() => orderUpdateMealInvetory({order})" -->
                  N/A
                </td>

                <td class="table-data">
                  <status
                    :items="order.items"
                    :status="order.status"
                    :order="order"
                    :optimize="status == 'completed'"
                  />
                </td>

                <td class="table-data">
                  <!-- {{ getPreparationTime(order) }} -->

                  <time-left :order="order" :count-down="15" type="kitchen" />
                </td>
                <td class="table-data">
                  <router-link
                    :to="`/kitchen/orders/${order?.id}`"
                    class="action-link !underline"
                    >View</router-link
                  >
                </td>
                <td class="table-data cursor-pointer">
                  <Receipt :order="order">
                    <span class="underline">{{
                      order?.printed ? "Printed" : "Print"
                    }}</span>
                  </Receipt>
                </td>
              </tr>
            </tbody>
          </table>
          <Pagination
            class="py-3"
            :currentPage="currentPage"
            :totalPages="totalPages"
            @page-change="goToPage"
          />
        </div>
        <div>
          <unconfirmed-orders v-if="status == 'unconfirmed'" />
          <cancelled-orders v-if="status == 'cancelled'" />
        </div>
      </section>
    </div>

    <!-- <OrderModal v-else-if="selectedOrder?.id" :selectedOrder="selectedOrder" :orders="orders"/> -->
    <div v-else>loading...</div>
  </layout>
</template>
        
      <script>
import { ref, computed, watchEffect } from "vue";
import Pagination from "@/components/Pagination.vue";
// import OrderModal from "./SingleOrder.vue";
import TopNav from "@/components/TopNav.vue";
import Modal from "@/components/Modal.vue";
import { getData } from "@/firebase/config";
import { useRoute, useRouter } from "vue-router";
import InputField from "@/components/InputField.vue";
import Text from "@/components/Text.vue";
import {
  formatDate,
  getOrderID,
  getPreparationTime,
  getUeOrderId,
  sum,
} from "@/util";
import Status from "@/components/Status.vue";
import Layout from "@/partials/Layout/layout.vue";
import UnconfirmedOrders from "@/partials/UnconfirmedOrders.vue";
import CancelledOrders from "@/partials/CancelledOrders.vue";
import { orderUpdateMealInvetory, store } from "@/views/store/store.js";
import TimeLeftDropdown from "@/components/TimeLeftDropdown.vue";
// import StatusButton from "@/components/StatusButton.vue";
import StatusButton from "@/components/StatusButton.vue";
import Receipt from "@/components/Receipt.vue";
import { searchOrder } from "@/util/filterOrder";
import TimeLeft from "@/components/TimeLeft.vue";
// import CancelOrder from '../../Order/CancelOrder.vue';

export default {
  components: {
    TopNav,
    Pagination,
    // OrderModal,
    Modal,
    InputField,
    Text,
    Status,
    Layout,
    TimeLeftDropdown,
    Receipt,
    StatusButton,
    UnconfirmedOrders,
    CancelledOrders,
    TimeLeft,
  },
  setup() {
    const allOrders = ref([...store.state.orders]);
    const orders = ref(allOrders);
    const status = ref("active");
    const message = ref("Active Orders");
    const kitchen = getData("restaurants");
    const selectedOrder = ref({});
    const route = useRoute();
    const router = useRouter();
    const selectId = ref(route.params.id);
    const selectedKitchen = ref("");
    const searchValue = ref("");
    const selectedTime = ref("");

    const selectKitchen = (e) => {
      selectedKitchen.value = e?.target?.value;
    };

    const search = (e) => {
      searchValue.value = e;
    };

    watchEffect(() => {
      selectId.value = route.params.id;
      if (selectId.value) {
        selectedOrder.value = store.state.orders.find(
          (x) => x.id === selectId.value
        );
      }
    });
    watchEffect(() => {
      orders.value = store.state.orders.filter(
        (x) =>
          sum(x.items?.map((x) => x.prepared)) !==
          sum(x.items.map((x) => x.quantity)) -
            sum(x.items.map((x) => x.canceled))
      );
    });

    const select = (type) => {
      status.value = type;
      message.value = type === "active" ? "Active Orders" : "Completed Orders";
    };

    //filter by kitchen and status
    watchEffect(async () => {
      let statusFilter = store.state.orders.filter((x) => {
        return status.value === "active"
          ? sum(x.items?.map((x) => x.prepared)) !==
              sum(x.items.map((x) => x.quantity)) -
                sum(x.items.map((x) => (x.cancelled || x.canceled || 0)))
          : sum(x.items?.map((x) => x.prepared)) ===
              sum(x.items.map((x) => x.quantity)) -
                sum(x.items.map((x) => (x.cancelled || x.canceled || 0)));
      });

      console.log(statusFilter)
      if (searchValue.value) {
        // orders.value = store.state.orders.filter(
        //   (x) =>
        //     x.id?.toLowerCase().includes(searchValue.value) ||
        //     x.customer?.name
        //       ?.toLowerCase()
        //       ?.includes(searchValue.value.toLowerCase())
        // );
        // orders.value = searchOrder(store.state.orders, searchValue.value);

        const res = await searchOrder(store.state.orders, searchValue.value);
        orders.value = res;
      } else {
        orders.value = selectedKitchen.value
          ? statusFilter.filter(
              (x) =>
                x.items.filter((y) => y.resturant === selectedKitchen.value)
                  .length
            )
          : statusFilter;
      }

      if (selectedTime.value === "htl") {
        orders.value.sort((a, b) => {
          return (
            b.countdown.kitchen_assembler_timeleft -
            a.countdown.kitchen_assembler_timeleft
          );
        });
      } else if (selectedTime.value === "lth") {
        orders.value.sort((a, b) => {
          return (
            a.countdown.kitchen_assembler_timeleft -
            b.countdown.kitchen_assembler_timeleft
          );
        });
      }
    });
    // Pagination
    const ordersPerPage = 15;
    const currentPage = ref(1);
    const totalPages = computed(() =>
      Math.ceil(orders.value.length / ordersPerPage)
    );

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };

    watchEffect(() => {
      searchValue.value;
      status.value;
      currentPage.value = 1;
    });

    const paginatedOrders = computed(() => {
      const startIndex = (currentPage.value - 1) * ordersPerPage;
      const endIndex = startIndex + ordersPerPage;
      return orders.value.slice(startIndex, endIndex);
    });

    return {
      ordersPerPage,
      currentPage,
      totalPages,
      goToPage,
      paginatedOrders,
      select,
      status,
      message,
      sum,
      selectedOrder,
      kitchen,
      selectId,
      selectKitchen,
      router,
      formatDate,
      search,
      getUeOrderId,
      selectedTime,
      getPreparationTime,
      orders,
      orderUpdateMealInvetory,
      getOrderID,
      store,
    };
  },
};
</script>
        
        <style scoped>
/* active and completed button */
.button {
  padding: 10px 30px;
  border-radius: 5px;
  border: 1px #f60100 solid;
  background-color: #fff;
  color: #f60100;
  margin-left: 20px;
}
.button.active {
  background: linear-gradient(180deg, #ff9103 0%, #f60100 100%);
  color: white;
  border: none;
}

.pagination-container {
  display: flex;
  column-gap: 10px;
}
.paginate-buttons {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(217, 217, 217);
  color: black;
}
.paginate-buttons:hover {
  background-color: #d8d8d8;
}
.active-page {
  background-color: #3498db;
  border: 1px solid #3498db;
  color: white;
}
.active-page:hover {
  background-color: #2988c8;
}

.order-table {
  width: 100%;
  border-collapse: collapse;
  /* border: 1px solid #ccc; */
}

.table-row {
  height: 60px;
}

.table-row.even-row {
  background-color: #f2f2f2;
}

.table-row.odd-row {
  background-color: #fff;
}

.status-button {
  padding: 5px 10px;
  background: linear-gradient(
    202deg,
    rgba(255, 145, 3, 0.24) 0%,
    rgba(246, 1, 0, 0.24) 100%
  );

  border: none;
  color: #333;
  font-weight: bold;
}

.status-button2 {
  padding: 5px 10px;
  background: linear-gradient(
    202deg,
    rgba(74, 224, 117, 0.428) 0%,
    rgba(0, 246, 78, 0.24) 100%
  );

  border: none;
  color: #333;
  font-weight: bold;
}

.action-link {
  text-decoration: none;
  color: #f60100;
}
</style>