<template>
  <ConfirmAction />
  <v-dialog v-model="openModal">
    <div class="bg-white rounded p-2 w-[350px] mx-auto">
      <Text class="text-center" :size="14" weight="600">
        Convert {{ item?.name }} to Double portion meal
      </Text>
      <br />
      <Text> {{ item.name }} : {{ formatPrice(item.amount) }} </Text>
      <Text>
        {{ meal?.extra_portion?.name }} :
        {{ formatPrice(meal?.extra_portion?.amount) }}
      </Text>
      <Text> charge : {{ formatPrice(charge) }}</Text>
      <br />

      <div class="flex justify-center gap-2">
        <button
          class="border btn"
          @click="
            () => {
              confirmAction.commit('toggleModal', {
                onProceed: () => convertToDoublePortion(item, 'wallet'),
                confirmText: 'WALLET',
              });
            }
          "
        >
          {{ loading ? "loading..." : " Convert via wallet" }}
        </button>
        <button
          class="border btn"
          @click="
            () => {
              confirmAction.commit('toggleModal', {
                onProceed: () => convertToDoublePortion(item, 'bank alert'),
                confirmText: 'BANK ALERT',
              });
            }
          "
        >
          {{ loading ? "loading..." : " Convert via bank alert" }}
        </button>
      </div>
    </div>
  </v-dialog>
  <div
    class="cursor-pointer hover:underline"
    v-if="show"
    @click="() => (openModal = true)"
  >
    convert to double portion
  </div>
</template>

<script setup>
import {
  query,
  ref as dbRef,
  getDatabase,
  orderByChild,
  equalTo,
  get,
  set,
  update,
} from "firebase/database";
import { computed } from "vue";
import { ref, watchEffect } from "vue";
import Text from "@/components/Text.vue";
import { createTimeLine } from "@/views/store/store";
import { WalletTransaction } from "@/views/main/Operator/Customers/updateWallet";
import { store } from "../views/store/store";
import { formatPrice } from "@/util";
import { confirmAction } from "@/views/store/confirmAction";
import ConfirmAction from "@/components/ConfirmAction.vue";

const props = defineProps({
  item: Object,
  order: Object,
  index: Number,
});
const item = ref({});
const meal = ref({});
const loading = ref(null);
const openModal = ref(false);
watchEffect(async () => {
  item.value = props.item;
  // console.log(item.value);
  const path = `/restaurants/${item.value.resturant}/food/${item.value.foodType}`;
  const quer = query(
    dbRef(getDatabase(), path),
    orderByChild("name"),
    equalTo(item.value.name)
  );
  const data = await get(quer);
  meal.value = Object.values(data.val() || {})[0];
});

const toNum = (num) => parseInt(String(num)?.replaceAll(",", ""));

const charge = computed(() => {
  const unitCharge =
    toNum(meal.value?.extra_portion?.amount) - toNum(meal.value?.amount);
  const quantity =
    item.value.quantity - (item.value.cancelled || item.value?.canceled || 0);
  return unitCharge * quantity;
});

const convertToDoublePortion = async (selectedItem, type) => {
  loading.value = true;
  const timeline = {
    orderId: props.order?.id,
    user_type: "admin",
    uid: store.state.user.uid,
    name: store.state.user.first_name + " " + store.state.user.last_name,
    message: `converted ${item.value.name} to double portion via ${type}`,
  };

  const fn = async () => {
    const path = `orders/${props.order?.id}/items/${props.index}`;
    const updates = {
      extraPortion: meal.value.extra_portion,
      amount: toNum(item.value.amount) + charge.value,
    };
    try {
      await update(dbRef(getDatabase(), path), updates);
      createTimeLine(timeline);
      openModal.value = false;
      loading.value = false;
    } catch (error) {
      console.log(error);
      loading.value = false;
    }
  };

  if (type == "bank alert") fn();

  if (type == "wallet") {
    const user = (
      await get(dbRef(getDatabase(), `users/${props.order?.customer?.id}`))
    ).val();

    const wallet = new WalletTransaction(user);
    await wallet.withdrawFromWallet(
      charge.value,
      `converted ${item.value.name} to double portion in ${props.order.id}`,
      () => fn(),
      () => (loading.value = false)
    );
  }
};

const show = computed(() => {
  return (
    meal.value?.extra_portion &&
    toNum(meal.value?.extra_portion?.amount) - toNum(item.value?.amount) &&
    !props.order?.status?.Assembled?.state
  );
});
</script>

<style lang="scss" scoped>
</style>