<template>
  <head>
    <link
      rel="stylesheet"
      href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
    />
  </head>

  <layout :hasTopNav="false">
    <div class="px-[40px] bg-light w-full h-full" v-if="!selectId">
      <TopNav :onSearch="(e) => (search = e)" :PrevNextIcon="false" />
      <div class="d-flex justify-content-between orderClass p-3 w-full">
        <div class="w-full">
          <div class="flex w-full items-center justify-between">
            <h1>
              <Text :size="27" :weight="700"> Promo Codes</Text>
              <!-- <span class="capitalize">{{ selectId }}</span> -->
            </h1>
            <router-link to="/operator/promo-codes/add">
              <ue-button :variant="'md'"> Create Promo Code </ue-button>
            </router-link>
          </div>
        </div>
      </div>
      <section class="orderList !p-0 !bg-white">
        <div>
          <table class="order-table">
            <thead>
              <tr>
                <th class="table-header"></th>
                <th class="table-header">Title</th>
                <th class="table-header">Status</th>
                <!-- <th class="table-header">Value</th> -->
                <th class="table-header">Used</th>
                <th class="table-header">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(promo_code, index) in paginatedPromoCodes"
                :key="promo_code?.code"
                class="table-row"
                :class="index % 2 === 0 ? 'even-row' : 'odd-row'"
              >
                <td class="table-data">
                  {{ index + 1 + 15 * (currentPage - 1) }}
                </td>
                <td class="table-data">{{ promo_code?.code }}</td>
                <td class="table-data">{{ getStatus(promo_code) }}</td>
                <!-- <td class="table-data">{{ promo_code?.formattedPrice }}</td> -->
                <td class="table-data">
                  {{ promo_code?.users?.length || 0 }}
                </td>
                <td class="table-data action-link">
                  <router-link :to="`/operator/promo-codes/${promo_code?.id}`">
                    Edit</router-link
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <Pagination
            class="py-3"
            :currentPage="currentPage"
            :totalPages="totalPages"
            @page-change="goToPage"
          />
        </div>
      </section>
    </div>

    <!-- <OrderModal v-else-if="selectedOrder?.id" :selectedOrder="selectedOrder" :orders="orders"/> -->
    <div v-else>loading...</div>
  </layout>
</template>
            
          <script setup>
import { ref, computed, watchEffect } from "vue";
import Pagination from "@/components/Pagination.vue";
// import OrderModal from "./SingleOrder.vue";
// import {} from 'fi'
import TopNav from "@/components/TopNav.vue";
import Text from "@/components/Text.vue";
import Layout from "@/partials/Layout/layout.vue";
import { get, getDatabase, ref as dbRef } from "firebase/database";
import UeButton from "@/components/UeButton.vue";

const promo_codes = ref([]);
const search = ref("");

watchEffect(async () => {
  // console.log(search);
  search.value;
  const res = await get(dbRef(getDatabase(), "promo_codes"));
  promo_codes.value = Object.values(res.val())
    ?.reverse()
    ?.filter((card) => {
      const value = search.value?.toLowerCase();
      return (
        String(card?.code)?.toLocaleLowerCase()?.includes(value) ||
        String(card?.senderName)?.toLocaleLowerCase()?.includes(value) ||
        String(card?.price)?.toLocaleLowerCase()?.includes(value)
      );
    });
});
const getStatus = (code) => {
  // console.log(code?.end_date)

  if (code.ended) {
    return "INACTIVE";
  }
  if (code?.end_date) {
    const now = new Date().getTime();
    const end = new Date(code?.end_date + " " + code?.end_time).getTime();

    return now < end ? "ACTIVE" : "EXPIRED";
  }
  return "ACTIVE";
};
// Pagination
const promoCodePerPage = 15;
const currentPage = ref(1);
const totalPages = computed(() =>
  Math.ceil(promo_codes.value.length / promoCodePerPage)
);

const goToPage = (page) => {
  if (page >= 1 && page <= totalPages.value) {
    currentPage.value = page;
  }
};

const paginatedPromoCodes = computed(() => {
  const startIndex = (currentPage.value - 1) * promoCodePerPage;
  const endIndex = startIndex + promoCodePerPage;
  return promo_codes.value.slice(startIndex, endIndex);
});
</script>
  <style lang="scss">
.action-link {
  a {
    color: #f60100 !important;
    text-decoration: underline;
    cursor: pointer;
  }
  /* display: none; */
}
</style>