<template>
  <head>
    <link
      rel="stylesheet"
      href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
    />
  </head>
  <div class="container">
    <div class="header">
      <h3>Users</h3>
      <button @click="showAddUserModal = true">Add User</button>
    </div>
    <table class="user-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Role</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(user, index) in users" :key="index">
          <td>{{ user.name }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.role }}</td>
          <td>
            <i @click="showEditUserModal(user)" class="fas fa-pencil-alt"></i>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- Modal for adding users-->

    <div v-if="showAddUserModal" class="modall">
      <form class="user-form" @submit.prevent="addUser">
        <div class="form-header">
          <h2>Add User</h2>
          <i @click="showAddUserModal = false" class="fas fa-times"></i>
        </div>

        <div class="form-group">
          <label for="name">Name:</label>
          <input v-model="newUser.name" type="text" id="name" required />
        </div>

        <div class="form-group">
          <label for="email">Email:</label>
          <input v-model="newUser.email" type="email" id="email" required />
        </div>

        <div class="form-group">
          <label for="role">Role:</label>
          <input v-model="newUser.role" type="text" id="role" required />
        </div>

        <button type="submit" class="btn">Create User</button>
      </form>
    </div>
    <!-- Modal for editing users-->
    <div v-if="showEditUserModalFlag" class="modall">
      <form class="user-form" @submit.prevent="saveUserChanges">
        <div class="form-header">
          <h2>Edit User</h2>
          <i @click="showEditUserModalFlag = false" class="fas fa-times"></i>
        </div>

        <div class="form-group">
          <label for="editName">Name:</label>
          <input v-model="editUser.name" type="text" id="editName" required />
        </div>
        <div class="form-group">
          <label for="editEmail">Email:</label>
          <input
            v-model="editUser.email"
            type="email"
            id="editEmail"
            required
          />
        </div>

        <div class="form-group">
          <label for="editRole">Role:</label>
          <input v-model="editUser.role" type="text" id="editRole" required />
        </div>

        <button type="submit" class="btn">Update User</button>
      </form>
    </div>
  </div>
</template>
  
  <script> 
import { ref } from "vue";

export default {
  setup() {
    const users = ref([
      { name: "John Doe", email: "john@example.com", role: "Admin" },
      { name: "Jane Smith", email: "jane@example.com", role: "User" },
    ]);

    const newUser = ref({
      name: "",
      email: "",
      password: "",
      role: "",
    });

    const showAddUserModal = ref(false);

    const showEditUserModalFlag = ref(false);
    const editUser = ref({});

    const addUser = () => {
      users.value.push({ ...newUser.value });
      newUser.value = {
        name: "",
        email: "",
        password: "",
        role: "",
      };
      showAddUserModal.value = false;
    };

    const showEditUserModal = (user) => {
      editUser.value = { ...user };
      showEditUserModalFlag.value = true;
    };

    const saveUserChanges = () => {
      const index = users.value.findIndex(
        (user) => user.email === editUser.value.email
      );
      if (index !== -1) {
        users.value.splice(index, 1, { ...editUser.value });
        showEditUserModalFlag.value = false;
      }
    };

    return {
      users,
      newUser,
      showAddUserModal,
      showEditUserModalFlag,
      editUser,
      addUser,
      showEditUserModal,
      saveUserChanges,
    };
  },
};
</script>
  
  <style>
.container {

  padding: 20px;
  color: black;
  background-color: white;
margin: 30px 50px;
  border-radius: 8px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.header button {
  padding: 5px 10px;
  background: linear-gradient(
    202deg,
    rgba(255, 145, 3, 0.24) 0%,
    rgba(246, 1, 0, 0.24) 100%
  );

  border: none;
  color: #333;
  font-weight: bold;
}

.user-table {
  width: 100%;
  border-collapse: collapse;
}

.user-table th,
.user-table td {
  padding: 8px;
  border: 1px solid #ccc;
}

.modall {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.modall form {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
}

.user-form {
  width: 300px;
  margin: 0 auto;
}

.form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: #ff9103 1px solid;
}

button.ue-btn {
  background: linear-gradient(180deg, #ff9103 0%, #f60100 100%);
  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
}
</style>
  