<template>
  <table class="order-table rounded">
    <thead>
      <tr>
        <th class="table-header"></th>
        <th class="table-header">Order ID</th>
        <th class="table-header">Type</th>
        <th class="table-header">Meals ordered</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(order, index) in paginatedOrders"
        :key="order.id"
        class="table-row"
        :class="index % 2 === 0 ? 'even-row' : 'odd-row'"
      >
        <td class="table-data">
            {{ index + 1 + 15 * (currentPage - 1) }}
        </td>
        <td class="table-data">
          <OrderID :order="order" />
        </td>
        <td class="table-data ">
          {{ order?.type }}
        </td>
        <td class="table-data ">
          {{ order.items?.map((item) => item?.name)?.join(', ') }}
        </td>
      </tr>
    </tbody>
  </table>
  <Pagination
    class="py-3"
    :currentPage="currentPage"
    :totalPages="totalPages"
    @page-change="goToPage"
  />
</template>

<script setup>
import OrderID from "@/components/OrderID.vue";
import Pagination from "@/components/Pagination.vue";
import dayjs from "dayjs";
import { computed, ref, watchEffect } from "vue";
// import {
//   ref as dbRef,
//   endAt,
//   get,
//   getDatabase,
//   orderByChild,
//   query,
//   startAt,
//   limitToFirst,
//   equalTo,
// } from "firebase/database";

const props = defineProps({
  orders: {
    type: Array,
    default: [],
  },
  date: {
    type: String,
    default: dayjs().format("MMM-DD-YYYY"),
  },
  usersThisDay: {
    type: Array,
    default: [],
  },
  customer_first_order: {
    type: Object,
    default: {},
  },
});
const orders = ref([]);

watchEffect(() => {
  console.log(props.orders);
  orders.value = Object.values(props.orders || {});
});

// Pagination
const ordersPerPage = 15;
const currentPage = ref(1);
const totalPages = computed(() =>
  Math.ceil(orders.value.length / ordersPerPage)
);

const goToPage = (page) => {
  if (page >= 1 && page <= totalPages.value) {
    currentPage.value = page;
  }
};

const paginatedOrders = computed(() => {
  const startIndex = (currentPage.value - 1) * ordersPerPage;
  const endIndex = startIndex + ordersPerPage;
  return orders.value.slice(startIndex, endIndex);
});
</script>

<style lang="scss" scoped>
</style>