import { extrasKeys, sum } from "@/util";

export const cancelledAmount = (item) => {
    // console.log(item)
    const meal_cancel_price = item.canceled * parseInt(item.amount);
    const addons_cancel_price = item?.addons
        ?.filter((addon) => (addon.cancelled && typeof addon.cancelled === 'number'))
        ?.map((addon) => parseInt(addon.amount * addon.cancelled));
    const sides_cancel_price = item?.sides
        ?.filter((side) => (side.cancelled && typeof side.cancelled === 'number'))
        ?.map((side) => parseInt(side.amount * side.cancelled));
    const customize_cancel_price = item?.customize
        ?.filter((customize) => (customize.cancelled && typeof customize.cancelled === 'number'))
        ?.map((customize) => parseInt(customize.amount * customize.cancelled));
    const total_cancelled_amount =
        meal_cancel_price + sum(addons_cancel_price) + sum(customize_cancel_price) + sum(sides_cancel_price);

    return total_cancelled_amount
};

export const order_canceled_amount = (order) => {
    const items = order?.items
    return sum(items?.map(cancelledAmount))
}

