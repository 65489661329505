<template>
  <head>
    <link
      rel="stylesheet"
      href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
    />
  </head>
  <router-view :key="route.path">
    <Layout :hasTopNav="false">
      <v-dialog v-model="currentModal">
        <div
          v-if="currentModal"
          class="bg-white rounded w-[400px] py-1 px-4 mx-auto"
        >
          <MealModal
            v-if="currentModal == 'cancel'"
            :title="`Cancel Order`"
            :content="`Are you sure you want to cancel this scheduled order?`"
            :rightClick="() => setCancel(selectedOrder)"
            firstBtn="No, Cancel"
            secondBtn="YES, Confirm"
            :closeBtn="true"
            :tickBtn="false"
            :oncancel="() => (currentModal = null)"
          />
          <MealModal
            v-if="currentModal == 'movetoactive'"
            :title="`Move to Active`"
            :content="`Are you sure you want to move this order to active?`"
            :rightClick="() => moveToActive(selectedOrder)"
            firstBtn="No, Cancel"
            secondBtn="YES, Confirm"
            :closeBtn="false"
            :tickBtn="true"
            :oncancel="() => (currentModal = false)"
          />
          <MealModal
            v-if="currentModal == 'reschedule'"
            :title="`Reschedule order`"
            :content="`Are you sure you want to reschedule this order`"
            :rightClick="() => reSchedule(selectedOrder)"
            firstBtn="No, Cancel"
            secondBtn="YES, Confirm"
            :closeBtn="false"
            :tickBtn="true"
            :oncancel="() => (currentModal = false)"
          />
        </div>
      </v-dialog>

      <div class="bg-light px-[40px]">
        <TopNav
          :searchBar="false"
          :arrowback="true"
          :arrowbackpath="`/${route.path.split('/')[1]}/orders`"
          :PrevNextIcon="true"
          :PrevNextList="prevnextlist"
        />
        <div class="d-flex justify-content-between">
          <h2>Order ID {{ route.params.id }}</h2>
        </div>

        <order-details :order="selectedOrder" />

        <div
          class="bg-white rounded my-3 p-3 flex justify-between gap-2 items-center"
        >
          <!-- {{ extractScheduledOrderDate(selectedOrder?.scheduleDateTime) }} -->
          <div class="font-bold text-lg">
            {{
              dayjs(
                selectedOrder.scheduleDateTime?.date?.replaceAll('"', "")
              ).format("DD MMM")
            }}
            ({{ selectedOrder.scheduleDateTime?.time }})
          </div>

          <div class="pl-3 flex gap-2 items-center">
            <input
              type="date"
              v-model="formValues.date"
              class="outline-none border !border-1 !border-[rgba(60, 60, 60, .26)] p-2 rounded w-[210px]"
            />
            <!-- <input type="time" v-model="formValues.time" class="outline-none" /> -->
            <ue-select
              class="ue-select !w-[210px] rounded"
              v-model="formValues.time"
              :options="[...times]"
              placeholder="Select time"
            >
              <template #option="time">
                <span>{{ time.label }} </span>
              </template>
            </ue-select>
            <UeButton @click="() => (currentModal = 'reschedule')"
              >Edit</UeButton
            >
          </div>
        </div>

        <div class="flex gap-3 justify-center">
          <div
            @click="() => (!cancel_loading ? (currentModal = 'cancel') : null)"
            class="flex gap-2 bg-danger cursor-pointer py-3 w-[45%] justify-center items-center my-3 rounded text-white items-center"
          >
            {{ cancel_loading ? "Loading..." : " Cancel Order" }}
          </div>
          <div
            @click="() => (currentModal = 'movetoactive')"
            class="flex gap-2 bg-success cursor-pointer py-3 w-[45%] justify-center items-center my-3 rounded text-white items-center"
          >
            Move to Active
          </div>
        </div>

        <items-table :type="'kitchen'" :key="selectedOrder.id" noaction />
        <Timeline :selectedOrder="selectedOrder" />
      </div>
    </Layout>
  </router-view>
</template>
           <script setup>
import { ref, computed, watchEffect } from "vue";
import { createTimeLine, store as vueStore } from "@/views/store/store";
import { useRoute, useRouter } from "vue-router";
import { store } from "@/views/store/store";
import {
  ref as dbRef,
  get,
  getDatabase,
  onValue,
  set,
} from "firebase/database";

import { useToast } from "vue-toast-notification";

import ItemsTable from "./ItemsTable.vue";
import OrderDetails from "@/components/OrderDetails.vue";
import Layout from "./Layout/layout.vue";
import TopNav from "@/components/TopNav.vue";
import MealModal from "@/components/MealModal.vue";
import { extractScheduledOrderDate } from "@/util";
import dayjs from "dayjs";
import UeButton from "@/components/UeButton.vue";
import { moveScheduledOrderToActive } from "@/views/main/Operator/Orders/moveScheduledOrderToActive";
import Timeline from "@/components/Timeline.vue";
import { cancelOrder } from "@/views/main/Operator/Orders/cancelOrder";
import { walletRefund } from "@/views/main/Operator/Orders/refundWallet";
import { removeUserPendingTransaction } from "@/views/main/Operator/Orders/removeUserPendingTransaction";

const selected = ref("active");
const message = ref("Active Orders");
const status = ref({});
const items = ref([]);
const selectedOrder = ref({});
const modal = ref(vueStore.state.modal.data);
const router = useRouter();
const prevnextlist = ref([]);
const route = useRoute();
const cancelModal = ref(false);
const cancel_loading = ref(false);
const toast = useToast({ position: "top-right" });
const moveToActiveModal = ref(false);
const formValues = ref({ date: "", time: "" });
const currentModal = ref(null);

router.afterEach((to, from) => {
  // console.log(to.params.id);
  onValue(dbRef(getDatabase(), "orders/" + to.params.id), (snapshot) => {
    if (snapshot.val()) {
      selectedOrder.value = snapshot.val();

    }
  });
});

watchEffect(() => {
  onValue(dbRef(getDatabase(), "orders/" + route.params.id), (snapshot) => {
    if (snapshot.val()) {
      // console.log(snapshot.val());
      selectedOrder.value = snapshot.val();
      
      console.log(selectedOrder.value.date)
    }
  });
});

watchEffect(() => {
  //create list of id's for the prevnext list kitchen

  //determine if its active or completed
  prevnextlist.value = store.state.scheduledOrders.filter((x) => {
    let type = Boolean(selectedOrder.value?.status?.Prepared?.state);
    let res = Boolean(x?.status?.Prepared?.state);
    return res == type;
  });
});

const moveToActive = async () => {
  try {
    await moveScheduledOrderToActive(selectedOrder.value);
    toast.success("Order moved to active");
    currentModal.value = null;
    router.push(`/${route.path.split("/")[1]}/orders`);
  } catch (error) {
    console.log(error);
  }
};

const times = computed(() => {
  const start = 12,
    hours = 24;
  let arr = [];
  for (let index = 0; index < hours; index++) {
    const to12hours = (num) => {
      if (num >= 12) {
        if (num == 24) return (num - 12 || 12) + ":00 AM";
        return (num - 12 || 12) + ":00 PM";
      }
      return (num || 12) + ":00 AM";
    };
    arr.push(`${to12hours(index)} - ${to12hours(index + 1)}`);
  }
  const FROM = "8:00 AM - 9:00 AM";
  const TO = "11:00 PM - 12:00 AM";
  return arr.slice(arr.indexOf(FROM), arr.indexOf(TO) + 1);
});
const reSchedule = () => {
  // console.log(selectedOrder.value.scheduleDateTime);
  currentModal.value = null;

  const run = async () => {
    const date = JSON.stringify(dayjs(formValues.value.date).toISOString());
    const time = formValues.value.time;
    const scheduleDateTime = { date, time };

    // console.log(scheduleDateTime);
    const selectedDateTime = dayjs(extractScheduledOrderDate(scheduleDateTime));
    const prevSelectedDateTime = dayjs(
      extractScheduledOrderDate(selectedOrder.value.scheduleDateTime)
    );
    // console.log(selectedDateTime.format("MMM DD YYYY hh:mm A"));

    if (selectedDateTime < dayjs().add(10, "minutes")) {
      currentModal.value = null;
      toast.info("Can not reschedule order for a past day");
      return;
    }
    if (selectedDateTime > dayjs().add(2, "weeks")) {
      currentModal.value = null;
      toast.info("Can not reschedule order for a day more than 2 weeks");
      return;
    }

    // console.log({ date, time });
    try {
      await set(
        dbRef(
          getDatabase(),
          `orders/${selectedOrder.value?.id}/scheduleDateTime`
        ),
        scheduleDateTime
      );
      await createTimeLine({
        orderId: selectedOrder.value?.id,
        user_type: "admin",
        uid: store.state.user.uid,
        name: store.state.user.first_name + " " + store.state.user.last_name,
        message: `rescheduled this order from ${prevSelectedDateTime.format(
          "DD MMM, YYYY hh:mm A"
        )} to  ${selectedDateTime.format("DD MMM, YYYY hh:mm A")}`,
      });
      selectedOrder.value.selectedDateTime = selectedDateTime;
      toast.success("Order rescheduled");
      currentModal.value = null;
    } catch (error) {
      currentModal.value = null;
    }
    // console.log(scheduleDateTime);
  };
  console.log(currentModal.value);
  if (!formValues.value.date && !formValues.value.time) {
    toast.info("please select date and time");
    return;
  }

  run();
};
const setCancel = async (order) => {
  if (cancel_loading.value) {
    return;
  }
  try {
    currentModal.value = null;
    cancel_loading.value = true;
    await cancelOrder(order);
    // if (order?.walletUsed) {
    // toast.info("Part payment was made");
    await walletRefund({ order, refundAmount: order?.total_price });
    // toast.success("Part payment refunded");
    // }
    await removeUserPendingTransaction({
      customer_uid: selectedOrder.value.customer.id,
      order: selectedOrder.value,
    });
    cancel_loading.value = false;
    toast.success("Order cancelled successfully");

    router.push(`/${route.path.split("/")[1]}/orders`);
  } catch (error) {
    console.log({ error });
    cancel_loading.value = false;
  }
};
</script>
         
         