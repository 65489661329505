<template>
  <v-dialog> </v-dialog>
  <form
    class="gap-3 addRider h-[100%] p-5 flex flex-col"
    @submit="handleSubmit"
    v-if="!successFull"
  >
    <Text :size="24" :weight="600">
      {{
        props.type === "edit" ? "Edit rider details" : "Add a new rider"
      }}</Text
    >
    <label
      v-if="props.type !== 'edit'"
      for="file"
      class="h-[200px] w-[200px] border mx-auto rounded-full text-center cursor-pointer flex-col flex items-center justify-center"
    >
      <!-- <Camera :size="24"  color="black" type="linear" /> -->
      <span class="material-symbols-outlined" v-if="!preview">
        photo_camera
      </span>
      <Text :weight="600" v-if="!preview"> Add photo</Text>
      <img
        :src="preview"
        alt=""
        class="rounded-full h-[100%] object-cover"
        v-else
      />

      <input
        type="file"
        @input="fileInput"
        id="file"
        accept="image/png, image/gif, image/jpeg"
        class="hidden"
      />
    </label>
    <div class="flex w-full justify-between gap-4">
      <input-field
        class="input-font"
        :label="'First name'"
        :type="'text'"
        :name="'first_name'"
        @input="handleChange"
        :defaultValue="formValues.first_name"
        required
      />
      <input-field
        class="input-font"
        :label="'Last name'"
        name="last_name"
        @input="handleChange"
        :defaultValue="formValues.last_name"
        required
      />
    </div>
    <input-field
      class="input-font"
      :label="'Phone no.'"
      :name="'phone'"
      @input="handleChange"
      :value="formValues.phone"
      :defaultValue="formValues.phone"
      required
    />
    <!-- <input type="tel" name="" id=""> -->
    <input-field
      class="input-font"
      :label="'Email'"
      name="email"
      @input="handleChange"
      :defaultValue="formValues.email"
      required
    />
    <div class="flex w-full justify-between gap-4 items-center">
      <input-field
        class="input-font"
        :label="'Plate number'"
        name="plate_number"
        :defaultValue="formValues.plate_number"
        required
        @input="handleChange"
      />
      <div class="flex flex-col w-[50%]">
        <label for="" class="font-bold font-[8px]">Vehicle type</label>
        <select
          name="vehicle_type"
          id=""
          class="border py-3 !h-[100%] rounded w-[100%] px-2 outline-none font-[17px]"
          v-model="formValues.vehicle_type"
          required
          style="font-size: 17px"
        >
          <option value=""></option>
          <option value="Motor cycle">Motor cycle</option>
          <option value="Bicycle">Bicycle</option>
        </select>
      </div>
    </div>

    <div
      class="flex border rounded py-2"
      :class="props.type === 'edit' ? 'hidden' : ''"
    >
      <input
        disabled
        :value="formValues.password"
        class="border-none !w-[70%] outline-none"
        :label="'Temporary password'"
        :type="'text'"
        name="password"
        @input="handleChange"
      />
      <button
        type="button"
        class="border-none py-1 w-[max-content]"
        @click="generatePassword()"
      >
        Generate password
      </button>
    </div>

    <div class="w-full flex gap-3 justify-end">
      <v-btn
        variant="outlined"
        class="w-[100px] capitilize"
        @click="() => cancel()"
      >
        Cancel
      </v-btn>
      <Button
        variant="contained"
        class="px-3 w-[100px] rounded"
        :class="!loading ? 'bg-success' : 'bg-light'"
        type="submit"
        :disabled="loading"
      >
        {{ loading ? "Loading..." : "Save" }}
      </Button>
    </div>
  </form>
  <div
    v-else
    class="flex flex-col justify-between !h-[200px] bg-white p-4 rounded-[12px] m-auto"
  >
    <Text v-if="props.type !== 'edit'" :weight="600" :size="24"
      >New rider added successfully</Text
    >
    <Text v-else :weight="600" :size="24">
      Rider details edited successfully</Text
    >
    <Text :weight="400" :size="14" v-if="props.type !== 'edit'"
      >Successful! Rider login details has been sent to the rider via email and
      SMS</Text
    >
    <Text :weight="400" :size="14" v-if="props.type === 'edit'"></Text>
    <div class="w-full flex justify-end">
      <Button
        class="px-3 w-[fit-content] py-2 rounded bg-success"
        type="submit"
        @click="() => close()"
      >
        Okay
      </Button>
    </div>
  </div>
</template>

<script>
import InputField from "@/components/InputField.vue";
import { store } from "@/views/store/store";
import { ref as uploadRef, uploadBytes } from "firebase/storage";
import { ref, watchEffect } from "vue";
import { uuid } from "vue-uuid";
import { projectFunctions, projectStorage } from "@/firebase/config";
import { set, ref as dbRef, getDatabase, get } from "firebase/database";
import { useToast } from "vue-toast-notification";

import Text from "@/components/Text.vue";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { httpsCallable } from "firebase/functions";

export default {
  components: { InputField, Text, Text },
  props: {
    type: String,
    rider: Object,
    onClose: Function,
  },
  setup(props) {
    const formValues = ref({
      first_name: props?.rider?.first_name || undefined,
      last_name: props?.rider?.last_name || undefined,
      phone: props?.rider?.phone || undefined,
      email: props?.rider?.email || undefined,
      plate_number: props?.rider?.plate_number || undefined,
      vehicle_type: props?.rider?.vehicle_type || undefined,
    });

    const preview = ref(null);
    const toast = useToast({ position: "top-right" });
    const payload = ref({});
    const successFull = ref(false);
    const error = ref([]);
    const loading = ref(false);

    const close = () => {
      store.commit("closeModal");
      successFull.value = false;
      props.onClose();
    };
    const handleChange = (e) => {
      loading.value = false;
      formValues.value[e.target.name] = e.target.value;
      if (e.target.name === "phone")
        formValues.value[e.target.name] = e.target.value?.replace(/\D/g, "");
    };
    const fileInput = (e) => {
      formValues.value.image = e?.target?.files[0];
      preview.value = URL.createObjectURL(e?.target?.files[0]);
    };

    const generatePassword = () => {
      const uni = uuid.v4();
      formValues.value.password = uni.substring(
        uni.lastIndexOf("-") + 1,
        uni.length
      );
    };
    watchEffect(() => {
      generatePassword();
    });

    //onsubmit
    const handleSubmit = async (e) => {
      try {
        e.preventDefault();
        loading.value = true;
        error.value = [];
        //saving new rider
        const submit = async (t) => {
          const image_url =
            Math.floor(Math.random() * 100000) + formValues?.value?.image?.name;
          const id = uuid.v4().replaceAll("-", "");

          const auth = getAuth();
          payload.value = {
            accepted_orders: "",
            email: formValues.value.email,
            first_name: formValues.value.first_name,
            image_url: formValues.value.image?.name ? image_url : "",
            last_name: formValues.value.last_name,
            phone: formValues.value.phone,
            plate_number: formValues.value.plate_number,
            rejected_orders: "",
            joined_at: Date.now(),
            status: "offline",
            vehicle_type: formValues.value.vehicle_type,
            deleted: false,
          };

          console.log(payload.value);

          try {
            const { user } = await createUserWithEmailAndPassword(
              auth,
              formValues.value.email,
              formValues.value.password
            );

            const previousCouriers = await get(dbRef(getDatabase(), "courier/"));
            await set(dbRef(getDatabase(), "courier/"), {
              ...previousCouriers.val(),
              [user.uid]: { ...payload.value, id: user.uid },
            });
            if (formValues.value.image) {
              const storageRef = uploadRef(projectStorage, `riders/${image_url}`);
              await uploadBytes(storageRef, formValues.value.image);
            }

            const sendMailToRider = httpsCallable(
              projectFunctions,
              "sendMailToRider"
            );
            await sendMailToRider({
              email: payload.value.email,
              password: formValues.value.password,
              first_name: payload.value.first_name,
            });

            successFull.value = true;
            loading.value = false;
          } catch (error) {
            console.log(error);
            loading.value = false;
            if (new Error(error).message.includes("/email-already-in-use")) {
              toast.error("This email already taken by a customer");
            } else {
              toast.error(new Error(error).message, {
                position: "top-right",
              });
            }
          }
        };

        ///function to check for existing fields
        const prev_riders = (store.state.riders);

        const iSTaken = (key, value) => {
          if (prev_riders) {
            return Object.values(prev_riders).filter((x) => x[key] === value)
              .length;
          } else {
            return 0;
          }
        };

        //editing existing rider
        const editRider = async () => {
          try {
            await set(dbRef(getDatabase(), "courier/" + props.rider.id), {
              ...props.rider,
              ...formValues.value,
            });
            successFull.value = true;
          } catch (error) {
            console.log(error);
          }
          loading.value = false;
        };

        console.log(props);
        const { email, phone, plate_number } = formValues.value;
        if (props.type !== "edit") {
          //validation for existing fields for signup
          if (iSTaken("email", email))
            error.value.push("Email address  is taken");
          if (iSTaken("phone", phone))
            error.value.push("Phone number is taken");
          // if (iSTaken("plate_number", plate_number))
          //   error.value.push("Plate number is taken");
          if (error.value.length === 0) {
            // alert('fff')
            submit();
          } else {
            error.value.map((x) => toast.error(x));
            loading.value = false;
          }
        } else {
          //validation for existing fields but not previous value in database for signin
          delete formValues.value.password;
          if (email !== props.rider.email && iSTaken("email", email)) {
            error.value.push("Email address is taken");
          }
          if (phone !== props.rider.phone && iSTaken("phone", phone)) {
            error.value.push("Phone number is taken");
          }
          // if (
          //   plate_number !== props.rider.plate_number &&
          //   iSTaken("plate_number", plate_number)
          // ) {
          //   error.value.push("Plate number is taken");
          // }
          if (error.value.length === 0) {
            editRider();
          } else {
            error.value.map((x) => toast.error(x));
            loading.value = false;
          }
        }
      } catch (error) {
        console.log(error)
      }
    };
    const cancel = () => {
      store.commit("closeModal");
      props.onClose();
    };
    return {
      cancel,
      handleSubmit,
      formValues,
      fileInput,
      preview,
      handleChange,
      generatePassword,
      successFull,
      close,
      loading,
      props,
    };
  },
};
</script>

<style lang="scss" scoped>
.addRider {
  // display: none;
  background: white;
  margin: auto;
  width: min(500px, 100%) !important;

  .input-font {
    input {
      font-size: 47px !important;
      // display: none !important;
    }
  }
}
</style>