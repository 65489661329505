<template>
  <v-dialog v-model="modal">
    <v-card class="w-[450px] mx-auto p-4">
      <Text weight="600" size="20" class="text-center mb-3">
        Switch Delivery Ranges
      </Text>

      <div class="flex w-full gap-4 flex-col">
        <div class="flex justify-between items-center">
          <Text weight="500" size="16"> Regular to Peak Regular </Text>
          <ue-button
            class="w-fit h-fit"
            @click="() => switchRange('regular', 'peak-regular')"
            ><span class="material-symbols-outlined"> sync </span></ue-button
          >
        </div>
        <div class="flex justify-between items-center">
          <Text weight="500" size="16">Extented to Peak Extented </Text>
          <ue-button
            class="w-fit h-fit"
            @click="() => switchRange('extended', 'peak-extended')"
            ><span class="material-symbols-outlined"> sync </span></ue-button
          >
        </div>
        <div class="flex justify-between items-center">
          <Text weight="500" size="16">Peak Regular to Regular </Text>
          <ue-button
            class="w-fit h-fit"
            @click="() => switchRange('peak-regular', 'regular')"
            ><span class="material-symbols-outlined"> sync </span></ue-button
          >
        </div>
        <div class="flex justify-between items-center">
          <Text weight="500" size="16">Peak Extented to Extented </Text>
          <ue-button
            class="w-fit h-fit"
            @click="() => switchRange('peak-extended', 'extended')"
            ><span class="material-symbols-outlined"> sync </span></ue-button
          >
        </div>
      </div>
    </v-card>
  </v-dialog>
  <ue-button class="h-[30px] mt-3" @click="() => (modal = true)"
    >Switch ranges</ue-button
  >
</template>

<script setup>
import Text from "@/components/Text.vue";
import UeButton from "@/components/UeButton.vue";
import { multipleUpdates } from "@/views/store/store";

import { ref, watchEffect } from "vue";
import { useToast } from "vue-toast-notification";
const locations = ref([]);
const modal = ref(false);
const toast = useToast({ position: "top-right" });
const props = defineProps({
  locations: Array,
  onComplete: Function,
});
watchEffect(() => {
  locations.value = props.locations;
  console.log(locations.value, "kkk");
});
const changes = ref([]);
const switchRange = async (from, to) => {
  try {
    // console.log({ from, to });
    locations.value?.forEach((location, i) => {
      if (location.delivery_range === from)
        changes.value.push({
          path: `/delivery_address/${i}/delivery_range`,
          value: to,
        });
    });
    // console.log(changes.value);
    await multipleUpdates({ updates: changes.value });
    toast.success("Successfull");
    props.onComplete();
    modal.value = false
  } catch (error) {
    toast.error("An error occured");
  }
};

</script>

<style lang="scss" scoped>
</style>