<template>
  <Layout>
    <div class="flex flex-col gap-3 mx-5">
      <TopNav :onSearch="search" />
      <Text :size="27" :weight="700"> Stats</Text>
      <div class="rounded-[12px] flex flex-col gap-3 bg-white p-4 w-full">
        <table class="order-table">
          <thead>
            <tr>
              <th class="table-header">Date</th>
              <!-- <th class="table-header">Total Download (all)</th>
              <th class="table-header">Total Download (iOS)</th>
              <th class="table-header">Total Download (android)</th> -->
              <th class="table-header">New Customers (all)</th>
              <th class="table-header">New Customers (iOS)</th>
              <th class="table-header">New Customers (android)</th>
              <th class="table-header">New Customers | FirstOrder (all)</th>
              <th class="table-header">New Customers | FirstOrder (iOS)</th>
              <th class="table-header">New Customers | FirstOrder (android)</th>
              <th class="table-header">FirstOrder (all)</th>
              <th class="table-header">FirstOrder (iOS)</th>
              <th class="table-header">FirstOrder (android)</th>
              <th class="table-header">Action</th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(stat, index) in paginatedStats"
              :key="index"
              class="table-row"
              :class="index % 2 === 0 ? 'even-row' : 'odd-row'"
            >
              <td class="table-data">
                {{
                  stat?.date?.split("-")[0] + " " + stat?.date?.split("-")[1]
                }}
              </td>
              <!-- <td class="table-data">{{ 0 }}</td>
              <td class="table-data">{{ 0 }}</td>
              <td class="table-data">{{ 0 }}</td> -->
              <td class="table-data">{{ stat?.new_customers }}</td>
              <td class="table-data">{{ stat?.new_customers_ios_count }}</td>
              <td class="table-data">
                {{ stat?.new_customers_android_count }}
              </td>
              <td class="table-data">
                {{ collate(stat?.users_stat, "new_customer_first_order") }}
              </td>
              <td class="table-data">
                <!-- {{ stat?.new_customers_ios_count_first_order }} -->
                {{ collate(stat?.users_stat, "new_customer_ios_first_order") }}
              </td>
              <td class="table-data">
                <!-- {{ stat?.new_customers_android_count_first_order }} -->
                {{
                  collate(stat?.users_stat, "new_customer_android_first_order")
                }}
              </td>
              <td class="table-data">
                {{ collate(stat?.users_stat, "first_order") }}
              </td>
              <td class="table-data">
                <!-- {{ stat?.number_of_first_orders_ios }} -->
                {{ collate(stat?.users_stat, "first_order_ios") }}
              </td>
              <td class="table-data">
                <!-- {{ stat?.number_of_first_orders_android }} -->
                {{ collate(stat?.users_stat, "first_order_android") }}
              </td>
              <td class="table-data">
                <router-link
                  :to="`/operator/downloads/${stat?.date}`"
                  class="action-link !underline"
                >
                  View
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
        <Pagination
          class="py-3"
          :currentPage="currentPage"
          :totalPages="totalPages"
          @page-change="goToPage"
        />
      </div>
    </div>
  </Layout>
</template>
  
  <script setup>
import Text from "@/components/Text.vue";
import TopNav from "@/components/TopNav.vue";
import Layout from "@/partials/Layout/layout.vue";
import dayjs from "dayjs";
import { onMounted, ref, watchEffect } from "vue";

import Pagination from "@/components/Pagination.vue";
import { computed } from "vue";
import {
  getDatabase,
  ref as dbRef,
  get,
  set,
  query,
  orderByChild,
  startAt,
} from "firebase/database";

const props = defineProps({
  customer: Object,
});

const stats = ref([]);

onMounted(async () => {
  const ttt = JSON.stringify(
    dayjs(new Date().setHours(0, 0, 0, 0)).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
  );
  const quer = query(
    dbRef(getDatabase(), `users`),
    orderByChild("created"),
    startAt(ttt)
  );
  const customersToday = await get(quer);
  const new_customers = Object.values(customersToday.val() || {});
  const key = dayjs().format("MMM-DD-YYYY");
  const stats_query = dbRef(getDatabase(), `download_stats/${key}`);
  const current_stats = (await get(stats_query)).val();

  await set(stats_query, {
    ...current_stats,
    new_customers: new_customers?.length,
    new_customers_android_count:
      new_customers.filter((user) => user.appOS == "android")?.length || 0,
    new_customers_ios_count:
      new_customers.filter((user) => user.appOS == "ios")?.length || 0,
    date: key,
  });
});

watchEffect(async () => {

  const res = (await get(dbRef(getDatabase(), `download_stats`))).val();

  stats.value = Object.values(res || {}).toSorted(
    (a, b) => dayjs(b?.date) - dayjs(a?.date)
  );

});

const collate = (users_stat, key) =>
  Object.values(users_stat || {}).filter((stat) => stat[key]).length || 0;
const statsPerPage = 15;
const currentPage = ref(1);
const totalPages = computed(() => Math.ceil(stats.value.length / statsPerPage));

const goToPage = (page) => {
  if (page >= 1 && page <= totalPages.value) {
    currentPage.value = page;
  }
};

const paginatedStats = computed(() => {
  const startIndex = (currentPage.value - 1) * statsPerPage;
  const endIndex = startIndex + statsPerPage;
  return stats.value.slice(startIndex, endIndex);
});
</script>
  
  <style lang="scss" scoped>
.action-link {
  text-decoration: none;
  color: #f60100;
}
</style>