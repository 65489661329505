<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
  >
    <path
      d="M21.8763 2.91699V17.5003C21.8763 19.1045 20.5638 20.417 18.9596 20.417H2.91797V11.1128C3.98255 12.3816 5.61589 13.1691 7.42422 13.1253C8.89714 13.0962 10.2242 12.5274 11.2159 11.5795C11.668 11.2003 12.0471 10.7191 12.3388 10.1941C12.8638 9.30449 13.1555 8.25449 13.1263 7.16074C13.0826 5.45449 12.3242 3.95241 11.143 2.91699H21.8763Z"
      stroke="auto"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.0846 20.417V24.792C32.0846 27.2128 30.1305 29.167 27.7096 29.167H26.2513C26.2513 27.5628 24.9388 26.2503 23.3346 26.2503C21.7305 26.2503 20.418 27.5628 20.418 29.167H14.5846C14.5846 27.5628 13.2721 26.2503 11.668 26.2503C10.0638 26.2503 8.7513 27.5628 8.7513 29.167H7.29297C4.87214 29.167 2.91797 27.2128 2.91797 24.792V20.417H18.9596C20.5638 20.417 21.8763 19.1045 21.8763 17.5003V7.29199H24.5596C25.6096 7.29199 26.5721 7.86074 27.0971 8.76491L29.5909 13.1253H27.7096C26.9076 13.1253 26.2513 13.7816 26.2513 14.5837V18.9587C26.2513 19.7607 26.9076 20.417 27.7096 20.417H32.0846Z"
      stroke="auto"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.6667 32.0833C12.4402 32.0833 13.1821 31.776 13.7291 31.2291C14.276 30.6821 14.5833 29.9402 14.5833 29.1667C14.5833 28.3931 14.276 27.6513 13.7291 27.1043C13.1821 26.5573 12.4402 26.25 11.6667 26.25C10.8931 26.25 10.1513 26.5573 9.60427 27.1043C9.05729 27.6513 8.75 28.3931 8.75 29.1667C8.75 29.9402 9.05729 30.6821 9.60427 31.2291C10.1513 31.776 10.8931 32.0833 11.6667 32.0833ZM23.3333 32.0833C24.1069 32.0833 24.8487 31.776 25.3957 31.2291C25.9427 30.6821 26.25 29.9402 26.25 29.1667C26.25 28.3931 25.9427 27.6513 25.3957 27.1043C24.8487 26.5573 24.1069 26.25 23.3333 26.25C22.5598 26.25 21.8179 26.5573 21.2709 27.1043C20.724 27.6513 20.4167 28.3931 20.4167 29.1667C20.4167 29.9402 20.724 30.6821 21.2709 31.2291C21.8179 31.776 22.5598 32.0833 23.3333 32.0833ZM32.0833 17.5V20.4167H27.7083C26.9063 20.4167 26.25 19.7604 26.25 18.9583V14.5833C26.25 13.7813 26.9063 13.125 27.7083 13.125H29.5896L32.0833 17.5Z"
      stroke="auto"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.1255 7.16021C13.1546 8.25396 12.863 9.30396 12.338 10.1935C12.0463 10.7185 11.6671 11.1998 11.2151 11.579C10.2234 12.5269 8.8963 13.0956 7.42339 13.1248C5.61506 13.1685 3.98172 12.381 2.91714 11.1123C2.71297 10.8935 2.53797 10.6456 2.37756 10.3977C1.80881 9.53729 1.48797 8.51646 1.45881 7.42271C1.43718 6.54715 1.61349 5.67801 1.97457 4.88009C2.33566 4.08216 2.87221 3.37604 3.54422 2.81438C4.53589 1.99771 5.79005 1.48729 7.16089 1.45813C8.69214 1.42896 10.0921 1.98313 11.1421 2.91646C12.3234 3.95188 13.0817 5.45396 13.1255 7.16021Z"
      stroke="auto"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.01562 7.33587L6.48854 8.73587L9.53646 5.79004"
      stroke="auto"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>