<template>
  <head>
    <link
      rel="stylesheet"
      href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
    />
  </head>

  <table class="order-table">
    <thead>
      <tr>
        <th class="table-header"></th>
        <th class="table-header">Order ID</th>
        <th class="table-header">Date</th>
        <th class="table-header">Customer Name</th>
        <th class="table-header">Service</th>
        <th class="table-header">Scheduled date</th>
        <th class="table-header">Status</th>
        <th class="table-header">Time to</th>
        <th class="table-header">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(order, index) in paginatedOrders"
        :key="order.id"
        class="table-row"
        :class="index % 2 === 0 ? 'even-row' : 'odd-row'"
      >
        <td class="table-data flex gap-2">
          {{ index + 1 + 15 * (currentPage - 1) }}
        </td>

        <td class="table-data">
          <OrderID :order="order" />
        </td>
        <td class="table-data">
          {{ dayjs(order.date?.replaceAll('"', "")).format("MMM DD. hh:mmA ") }}
        </td>
        <td class="table-data">
          <!-- {{
                      `${order?.customer?.firstName} ${order?.customer?.lastName}`
                    }} -->
          <customer-name :customer="order?.customer" />
        </td>
        <td class="table-data">{{ order.locationType }}</td>
        <td class="table-data">
          {{
            dayjs(order.scheduleDateTime?.date?.replaceAll('"', "")).format(
              "DD MMM"
            )
          }}
          ({{ order.scheduleDateTime?.time }})
        </td>
        <td class="table-data">{{ is2hoursWithin(order) }}</td>
        <td class="table-data">
          {{
            is2hoursWithin(order) == "overdue"
              ? "0 min"
              : scheduledTimeLeft(order)
          }}
        </td>
        <td class="table-data">
          <router-link
            :to="`/operator/scheduled-order/${order?.id}`"
            class="action-link !underline"
            >View</router-link
          >
        </td>
      </tr>
    </tbody>
  </table>
  <div class="flex justify-center">
    <button
      :class="`mx-auto p-2 self-center m-2 ${
        loading_progress ? 'bg-light' : 'bg-[red]'
      } text-white`"
      :disabled="loading_progress"
      @click="movePendingOrdersWithin2hours"
    >
      Move orders scheduled 2 hours from now
    </button>
  </div>
  <Pagination
    class="py-3"
    :currentPage="currentPage"
    :totalPages="totalPages"
    @page-change="goToPage"
  />

  <!-- </layout> -->
</template>
          
        <script setup>
import { ref, computed, watchEffect, onMounted } from "vue";
import Pagination from "@/components/Pagination.vue";
// import OrderModal from "./OrderModal.vue";
import { useRoute } from "vue-router";
import CustomerName from "@/components/CustomerName.vue";

import { sendPushNotification, store } from "@/views/store/store";

import { searchOrder } from "@/util/filterOrder";
import dayjs from "dayjs";
import OrderID from "@/components/OrderID.vue";
import { set, ref as dbRef, getDatabase, get } from "firebase/database";
import { useToast } from "vue-toast-notification";
import { moveScheduledOrderToActive } from "@/views/main/Operator/Orders/moveScheduledOrderToActive";
import { getScheduledOrders } from "@/onAllScreenLoadActions";

const props = defineProps({
  onClearScheduledOrders: {
    type: Function,
    default: () => null,
  },
});

// onMounted(()=>{
//   getScheduledOrders()
// })

const orders = ref(store.state.scheduledOrders);
const status = ref("active");
const selectedOrder = ref({});
const route = useRoute();
const selectId = ref(route.params.id);

const searchValue = ref("");
const selectedTime = ref("");

const search = (e) => {
  searchValue.value = e;
};

// watchEffect(() => {
//   selectId.value = route.params.id;
//   if (selectId.value) {
//     selectedOrder.value = store.state.orders.find(
//       (x) => x.id === selectId.value
//     );
//   }
// });

// watchEffect(async () => {
//   let statusFilter = store.state.orders.filter((x) => {
//     if (x.status?.Delivered?.state) {
//       return status.value === "active"
//         ? !x?.status?.Delivered?.state
//         : x.status?.Delivered?.state || x?.status?.Pickup?.state;
//     } else {
//       return status.value === "active"
//         ? !x?.status?.Pickup?.state
//         : x.status?.Delivered?.state || x?.status?.Pickup?.state;
//     }
//   });

//   if (searchValue.value) {
//     const res = await searchOrder(store.state.orders, searchValue.value);
//     orders.value = res;
//   } else {
//     orders.value = statusFilter;
//   }
//   if (selectedTime.value === "htl") {
//     orders.value.sort((a, b) => {
//       return b.countdown.operator_timeleft - a.countdown.operator_timeleft;
//     });
//   } else if (selectedTime.value === "lth") {
//     orders.value.sort((a, b) => {
//       return a.countdown.operator_timeleft - b.countdown.operator_timeleft;
//     });
//   }
// });
const toast = useToast({ position: "top-right" });

function convertTo24Hour(time) {
  var hours = parseInt(time.substr(0, 2));
  if (time.indexOf("AM") != -1 && hours == 12) {
    time = time.replace("12", "0");
  }
  if (time.indexOf("PM") != -1 && hours < 12) {
    time = time.replace(hours, hours + 12);
  }
  return time.replace(/(AM|PM)/, "");
}

const extractDate = (scheduleDateTime) => {
  const time = String(scheduleDateTime?.time || " - ")
    .split("-")[0]
    //   .padStart(9, "0")
    ?.replaceAll(" ", "");
  const date = dayjs(scheduleDateTime?.date?.replaceAll('"', ""));
  const hours = convertTo24Hour(time).split(":")[0];

  return new Date(date).setHours(hours, 0, 0, 0);
};

const is2hoursWithin = (order) => {
  const timedate = extractDate(order?.scheduleDateTime);
  const scheduled_time = dayjs(timedate);
  const scheduled_time_2_hours_check = dayjs(timedate).subtract(2, "hours");
  const now = dayjs();
  const fmt = (djs) => djs.format("MMM DD hh:mm A");
  // console.log(fmt(scheduled_time_2_hours_check), fmt(scheduled_time), fmt(now));
  if (scheduled_time_2_hours_check < now && scheduled_time > now)
    return "ready";
  if (scheduled_time < now) return "overdue";
  if (scheduled_time_2_hours_check > now) return "not ready";
};

const scheduledTimeLeft = (order) => {
  // console.log(first);
  const scheduled_time = dayjs(extractDate(order?.scheduleDateTime));
  const units = ["weeks", "days", "hours", "minutes", "seconds"];
  let res = "";

  for (let index = 0; index < units.length; index++) {
    let unit = units[index];
    const differ = Math.abs(dayjs().diff(scheduled_time, unit));
    if (differ) {
      // if (differ == 1) delete unit[unit.length-1];
      res = `${differ} ${unit}`;
      break;
    }
  }

  return res;
};
const movePendingOrdersWithin2hours = async () => {
  const ordersToClear =
    orders.value.filter(
      (order) =>
        is2hoursWithin(order) == "ready" || is2hoursWithin(order) == "overdue"
    ) || [];
  if (!ordersToClear.length) {
    toast.info("No scheduled order is ready");
    return;
  }
  toast.info("Please wait while orders are moved");
  const promises = ordersToClear.map(async (element) => {
    await moveScheduledOrderToActive(element);
    // set(dbRef(getDatabase(), `orders/${element?.id}/isSchedule`), false);
    // const token = (
    //   await get(
    //     dbRef(getDatabase(), `users/${element?.customer?.id}/pushToken`)
    //   )
    // ).val();

    // if (token) {
    //   sendPushNotification({
    //     token,
    //     title: ``,
    //     body: `Your scheduled order ${element?.id} is being prepared`,
    //     orderId: element?.id,
    //   });
    // }
  });

  try {
    await Promise.all(promises);
    props.onClearScheduledOrders();
    toast.success("Successful");
  } catch (error) {
    console.log(error);
  }
};

// Pagination
const ordersPerPage = 15;
const currentPage = ref(1);
const totalPages = computed(() =>
  Math.ceil(orders.value.length / ordersPerPage)
);

const goToPage = (page) => {
  if (page >= 1 && page <= totalPages.value) {
    currentPage.value = page;
  }
};

const paginatedOrders = computed(() => {
  const startIndex = (currentPage.value - 1) * ordersPerPage;
  const endIndex = startIndex + ordersPerPage;
  return orders.value.slice(startIndex, endIndex);
});
watchEffect(() => {
  searchValue.value;
  status.value;
  currentPage.value = 1;
});
</script>
          
<style scoped>
.button {
  padding: 10px 30px;
  border-radius: 5px;
  border: 1px #f60100 solid;
  background-color: #fff;
  color: #f60100;
  margin-left: 20px;
}
.button.active {
  background: linear-gradient(180deg, #ff9103 0%, #f60100 100%);
  color: white;
  border: none;
}

.pagination-container {
  display: flex;
  column-gap: 10px;
}
.paginate-buttons {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(217, 217, 217);
  color: black;
}
.paginate-buttons:hover {
  background-color: #d8d8d8;
}
.active-page {
  background-color: #3498db;
  border: 1px solid #3498db;
  color: white;
}
.active-page:hover {
  background-color: #2988c8;
}

.order-table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 12px;
  /* border: 1px solid #ccc; */
}

.table-row {
  height: 60px;
}

.table-row.even-row {
  background-color: #f2f2f2;
}

.table-row.odd-row {
  background-color: #fff;
}

.status-button {
  padding: 5px 10px;
  background: linear-gradient(
    202deg,
    rgba(255, 145, 3, 0.24) 0%,
    rgba(246, 1, 0, 0.24) 100%
  );

  border: none;
  color: #333;
  font-weight: bold;
}

.status-button2 {
  padding: 5px 10px;
  background: linear-gradient(
    202deg,
    rgba(74, 224, 117, 0.428) 0%,
    rgba(0, 246, 78, 0.24) 100%
  );

  border: none;
  color: #333;
  font-weight: bold;
}

.action-link {
  text-decoration: none;
  color: #f60100;
}
</style>