<template>
  <head>
    <link
      rel="stylesheet"
      href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
    />
  </head>

  <layout :hasTopNav="false">
    <div class="px-[40px] bg-light w-full h-full" v-if="!selectId">
      <TopNav :onSearch="(e) => (search = e)" :PrevNextIcon="false" />
      <div class="d-flex justify-content-between orderClass p-3 w-full">
        <div class="w-full">
          <div class="flex w-full items-center justify-between">
            <h1>
              <Text :size="27" :weight="700"> Gift Cards</Text>
              <!-- <span class="capitalize">{{ selectId }}</span> -->
            </h1>
          </div>
        </div>
      </div>
      <section class="orderList !p-0 !bg-white">
        <div>
          <table class="order-table">
            <thead>
              <tr>
                <!-- <th class="table-header"></th> -->
                <th class="table-header">Code</th>
                <th class="table-header">Customer</th>
                <!-- <th class="table-header">Recipient name</th> -->
                <th class="table-header">Date</th>
                <th class="table-header">Value</th>
                <th class="table-header">Used</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(card, index) in paginatedCards"
                :key="card?.code"
                class="table-row"
                :class="index % 2 === 0 ? 'even-row' : 'odd-row'"
              >
                <!-- <td class="table-data">
                  {{ index + 1 + 15 * (currentPage - 1) }}
                </td> -->
                <td class="table-data">
                  *{{
                    card?.code?.substring(
                      card?.code?.length - 4,
                      card?.code?.length
                    )
                  }}
                </td>
                <!-- <td class="table-data">{{ card?.senderName }}</td> -->
                <td class="table-data">
                  {{ card?.used ? card?.receiver?.name : card?.senderName }}
                </td>
                <td class="table-data">{{ formatDate(card?.created) }}</td>
                <td class="table-data">{{ card?.formattedPrice }}</td>
                <td class="table-data">{{ card?.used ? "Yes" : "No" }}</td>
              </tr>
            </tbody>
          </table>
          <Pagination
            class="py-3"
            :currentPage="currentPage"
            :totalPages="totalPages"
            @page-change="goToPage"
          />
        </div>
      </section>
    </div>

    <!-- <OrderModal v-else-if="selectedOrder?.id" :selectedOrder="selectedOrder" :orders="orders"/> -->
    <div v-else>loading...</div>
  </layout>
</template>
          
        <script setup>
import { ref, computed, watchEffect } from "vue";
import Pagination from "@/components/Pagination.vue";
// import OrderModal from "./SingleOrder.vue";
// import {} from 'fi'
import TopNav from "@/components/TopNav.vue";
import Text from "@/components/Text.vue";
import Layout from "@/partials/Layout/layout.vue";
import { get, getDatabase, ref as dbRef } from "firebase/database";
import { formatDate } from "@/util";

const cards = ref([]);
const search = ref("");

watchEffect(async () => {
  // console.log(search);
  search.value;
  const res = await get(dbRef(getDatabase(), "gift_card"));
  const dateval = (date) => new Date(date?.replaceAll('"', "")).getTime();
  cards.value = Object.values(res.val())
    ?.sort((a, b) => dateval(b.created) - dateval(a.created))
    ?.filter((card) => {
      const value = search.value?.toLowerCase();
      return (
        String(card?.code)?.toLocaleLowerCase()?.includes(value) ||
        String(card?.senderName)?.toLocaleLowerCase()?.includes(value) ||
        String(card?.price)?.toLocaleLowerCase()?.includes(value)
      );
    });
});

// Pagination
const cardsPerPage = 15;
const currentPage = ref(1);
const totalPages = computed(() => Math.ceil(cards.value.length / cardsPerPage));

const goToPage = (page) => {
  if (page >= 1 && page <= totalPages.value) {
    currentPage.value = page;
  }
};

const paginatedCards = computed(() => {
  const startIndex = (currentPage.value - 1) * cardsPerPage;
  const endIndex = startIndex + cardsPerPage;
  return cards.value.slice(startIndex, endIndex);
});
</script>
