<template>
  <span
    v-if="canLink"
    class="cursor-pointer"
    style="color: inherit"
    @click="() => router.push(`/operator/customers/${uid}`)"
  >
    {{ name }}
  </span>
  <span style="color: inherit" v-else>
    {{ name }}
  </span>
</template>
  
  <script setup>
import { store } from "@/views/store/store";
import { get, getDatabase, ref as dbRef } from "firebase/database";
import { computed, ref, watchEffect } from "vue";
// import { ref } from "firebase/storage";
import { useRouter } from "vue-router";

const router = useRouter();
const canLink = computed(() => {
  const permit = Object.values(store.state.user?.permissions || {})?.find(
    (perr) => perr == "Operator Customer Action(view)"
  );
  // console.log({permit});
  return Boolean(permit);
});
const props = defineProps({
  customer: Object,
  id_key: {
    default: "uid",
    type: String,
  },
  customerId: String,
});

const customer = ref({});
watchEffect(() => {});

const name = ref(`${props.customer?.firstName} ${props.customer?.lastName}`);
const uid = ref(props.customer?.uid || props?.customer?.id);
watchEffect(async () => {
  customer.value = props.customer;

  if (!props.customer) {
    const res = await get(dbRef(getDatabase(), `users/${props.customerId}`));
    customer.value = res?.val();
  }
  name.value = `${customer.value?.firstName} ${customer.value?.lastName}`;
  uid.value = customer.value?.uid || customer.value?.id;
});
</script>
  
  <style lang="scss" scoped>
</style>