<template>
  <head>
    <link
      rel="stylesheet"
      href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
    />
  </head>

  <layout :hasTopNav="false">
    <div class="kitchen-meal">
      <TopNav :onSearch="search" />

      <GenericSoldOutRestock :occurances="occurances" />
      <!-- <SideBar /> -->
      <div class="d-flex justify-content-between orderClass">
        <h1>{{ stopList ? "Available Meals" : "Stop List" }}</h1>
        <div>
          <div>
            <button
              class="button"
              :class="stopList ? 'active' : ''"
              @click="selectStopList(true)"
            >
              Available Meals
            </button>
            <button
              class="button"
              :class="!stopList ? 'active' : ''"
              @click="selectStopList(false)"
            >
              Stop List
            </button>

            <button
              class="button"
              :class="!showHiddenMeals ? 'active' : ''"
              @click="() => (showHiddenMeals = false)"
            >
              Shown meals
            </button>
            <button
              class="button"
              :class="showHiddenMeals ? 'active' : ''"
              @click="() => (showHiddenMeals = true)"
            >
              Hidden meals
            </button>
          </div>
        </div>
      </div>

      <section>
        <div class="menu-div !relative">
          <a
            href="#"
            :class="cate === category ? 'active' : ''"
            v-for="cate in categories"
            :key="cate"
            @click="() => (category = cate)"
          >
            {{ cate }}</a
          >
          <a href="#" v-if="!categories.length">
            Select a kitchen to view categories
          </a>

          <select
            @input="(e) => (selectedKitchen = e.target.value)"
            class="outline-none px-3 py-2 mt-2 selecttttt flex-end !absolute w-[150px] right-5 top-3"
          >
            <option value="">All restaurants</option>
            <option v-for="option in options" :key="option" :value="option">
              {{ option }}
            </option>
          </select>
        </div>
      </section>

      <div class="flex-wrap !py-0 flex w-full">
        <div
          v-for="meal in paginatedMeals"
          class="meal-grid flex-wrap flex"
          :key="meal.restaurant_name + meal.name"
        >
          <div class="meal">
            <img class="meal-picture" :src="meal.image" :alt="meal.name" />
            <div class="meal-details flex flex-col">
              <div class="meal-info w-full items-center justify-between flex">
                <h4 class="meal-name !my-0">{{ meal.name }}</h4>
                <div class="meal-price text-right">
                  ₦{{ getMealPrice(meal) }}

                  <meal-details-modal
                    v-if="
                      store.state.user.extra_permissions.includes(
                        extra_permissions_keys.edit_meal_price
                      )
                    "
                    :meal="meal"
                    :is-reward="category == 'Rewards'"
                  />
                </div>
                <!-- <div class="meal-price text-right flex flex-col gap-1" v-else>
                  ₦{{ meal.price }}
                </div> -->
              </div>
              <div
                class="meal-shop w-full flex items-center justify-between text-right my-3"
              >
                <div class="meal-special-name">
                  {{ meal.restaurant_name }}
                  <!-- {{ `${ meal.restaurant_name}/food/${meal.food_category}/${index_in_firebase}` }} -->
                </div>

                <div
                  v-if="
                    store.state.user.extra_permissions.includes(
                      extra_permissions_keys.edit_meal_stock
                    )
                  "
                >
                  <a
                    v-if="!loading"
                    href="#"
                    class="meal-shop-link"
                    @click="() => addRemoveFromStopList(meal)"
                  >
                    <div v-if="meal.available">Add to Stop list</div>
                    <div v-else>Remove from Stop list</div>
                  </a>
                  <div v-if="meal.extra && !loading">
                    <a
                      class="text-grey cursor-pointer block underline text-[12px]"
                      v-for="(extra, index) in meal.extra"
                      :key="extra.name"
                      @click="
                        () => addRemoveFromStopList(extra, meal, index, 'extra')
                      "
                    >
                      {{
                        extra.available
                          ? `Add ${extra.name} to stoplist`
                          : `Remove ${extra.name} from stoplist`
                      }}
                    </a>
                  </div>
                  <div v-if="meal.types && !loading">
                    <a
                      class="text-grey cursor-pointer block underline text-[12px]"
                      v-for="(type, index) in meal.types"
                      :key="type.name"
                      @click="
                        () => addRemoveFromStopList(type, meal, index, 'types')
                      "
                    >
                      {{
                        type.available
                          ? `Add ${type.item} to stoplist`
                          : `Remove ${type.item} from stoplist`
                      }}
                    </a>
                  </div>
                  <!-- {{ meal.options }} -->

                  <div v-if="meal.customize && !loading">
                    <!-- <div
                      v-for="option in Object.entries(meal.options)"
                      :key="option[0]"
                    > -->
                    <!-- {{ option[0] }} -->
                    <a
                      class="text-grey cursor-pointer block underline text-[12px]"
                      v-for="(item, index) in meal.customize"
                      :key="item.name"
                      @click="
                        () =>
                          addRemoveFromStopList(item, meal, index, `customize`)
                      "
                    >
                      {{
                        item.available
                          ? `Add ${item.name} to stoplist`
                          : `Remove ${item.name} from stoplist`
                      }}
                    </a>
                    <!-- </div> -->
                  </div>
                  <div v-if="meal.options && !loading">
                    <div
                      v-for="option in Object.entries(meal.options)"
                      :key="option[0]"
                    >
                      {{ option[0] }}
                      <a
                        class="text-grey cursor-pointer block underline text-[12px]"
                        v-for="(item, index) in option[1]"
                        :key="item.name"
                        @click="
                          () =>
                            addRemoveFromStopList(
                              item,
                              meal,
                              index,
                              `options/${option[0]}`
                            )
                        "
                      >
                        {{
                          item.available
                            ? `Add ${item.name} to stoplist`
                            : `Remove ${item.name} from stoplist`
                        }}
                      </a>
                    </div>
                  </div>
                  <div><meal-sales-report :meal="meal" /></div>
                  <div v-if="loading">Loading...</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        :currentPage="currentPage"
        :totalPages="totalPages"
        @page-change="goToPage"
      /></div
  ></layout>
</template>
          
        <script setup>
import { ref, computed, watchEffect, reactive, watch } from "vue";
import Pagination from "@/components/Pagination.vue";
import TopNav from "@/components/TopNav.vue";
import MealDetailsModal from "./MealDetailsModal.vue";
import InputField from "@/components/InputField.vue";
import Layout from "@/partials/Layout/layout.vue";
import { getData } from "@/firebase/config";
import { meals } from "@/views/store/meals";
import {
  ref as dbRef,
  getDatabase,
  onValue,
  set,
  get,
} from "firebase/database";
import Text from "@/components/Text.vue";
import { store } from "@/views/store/store";
import { extra_permissions_keys } from "../../Operator/Accounts/extra_permissions";
import MealSalesReport from "./MealSalesReport.vue";
import { getAllOccurancesOfItem } from "./getAllOccurancesOfItem";
import GenericSoldOutRestock from "./GenericSoldOutRestock.vue";

const selected = ref("active");
const message = ref("Available Meals");
const categories = ref(["Restaurants", "Rewards"]);
const category = ref("Restaurants");
const searchValue = ref("");
const mealList = ref([]);
const stopList = ref(true);
const selectedKitchen = ref("");
const meal_list = ref([]);
const kitchen = getData("restaurants");
const options = ref();
const loading = ref(false);
const showHiddenMeals = ref(false);
const occurances = ref(null);

// console.log(kitchen);

watchEffect(() => {
  options.value = [...kitchen.value.map((kit) => kit.id)];
});

const getMealPrice = (meal) => {
  if (meal?.point && meal.amount) {
    return parseInt(meal.point).toLocaleString()
  }
  if (meal?.amount) return meal?.amount;
  if (meal?.prices)
    return (
      meal?.prices
        ?.slice(0, 2)
        .map((price) => price.price)
        .join(", ") + "..."
    );

};

const getMeals = async (snapshot) => {
  loading.value = true;
  let restaurant_meals_list = [],
    rewards_meal_list = [];
  // const snapshot = await get(dbRef(getDatabase(), "restaurants"));
  if (snapshot.val()) {
    const restaurants = Object.entries(snapshot.val());
    restaurants.map((restaurant) => {
      const restaurant_name = restaurant[0],
        restaurant_data = restaurant[1];

      if (restaurant_data?.food) {
        const food_in_restaurant = Object.entries(restaurant_data?.food);

        food_in_restaurant.map((category) => {
          const food_category = category[0],
            food_in_category = category[1];

          food_in_category.map((food_item, i) => {
            const item = {
              ...food_item,
              restaurant_name,
              food_category,
              index_in_firebase: i,
              is_extra: false,
              path: ``,
            };
            restaurant_meals_list.push(item);
          });
        });
      }

      if (restaurant_data?.reward) {
        const reward_meals_restaurant = Object.values(restaurant_data?.reward);

        reward_meals_restaurant.map((food_item, i) => {
          const item = {
            ...food_item,
            restaurant_name,
            food_category: null,
            index_in_firebase: i,
            is_extra: false,
          };
          rewards_meal_list.push(item);
        });
      }
    });

    if (category.value == "Restaurants") {
      mealList.value = restaurant_meals_list;
    } else {
      mealList.value = rewards_meal_list;
    }
    loading.value = false;
  }
};

watchEffect(() => {
  category.value;
  onValue(dbRef(getDatabase(), "restaurants"), (snapshot) => {
    if (snapshot.val()) getMeals(snapshot);
    meals.dispatch("updateMeals");
  });
});

const search = (e) => {
  searchValue.value = e.toLowerCase();
};

watchEffect(() => {
  meal_list.value = mealList.value.filter((meal) => {
    let kitchen = selectedKitchen.value
      ? meal.restaurant_name == selectedKitchen.value
      : true;
    return (
      meal.available == stopList.value &&
      Boolean(meal?.hidden) == showHiddenMeals.value &&
      meal.name.toLowerCase().includes(searchValue.value) &&
      kitchen
    );
  });
});

const selectStopList = (bool) => (stopList.value = bool);

const select = (type) => {
  selected.value = type;
  if (type === "add") {
    message.value = "Add Meal";
  }
  if (type === "available") {
    message.value = "Available Meals";
  }
  if (type === "stop") {
    message.value = "Stop List";
  }
};

const addRemoveFromStopList = async (meal, parent, index, type) => {
  try {
    let path = "";

    if (category.value == "Restaurants") {
      if (index === undefined) {
        path = `/restaurants/${meal.restaurant_name}/food/${meal.food_category}/${meal.index_in_firebase}/available`;
      } else {
        path = `/restaurants/${parent.restaurant_name}/food/${parent.food_category}/${parent.index_in_firebase}/${type}/${index}/available`;
      }
      occurances.value = getAllOccurancesOfItem(mealList.value, meal);

      // console.log(occurances.value)
    } else if (category.value == "Rewards") {
      path = `/restaurants/${meal.restaurant_name}/reward/${meal.index_in_firebase}/available`;
    }
    await set(dbRef(getDatabase(), path), !meal.available);
    if (occurances.value) meals.commit("openGenericSoldOutModal", true);
  } catch (error) {
    console.log(error);
  }
};

const mealsPerPage = 20;
const currentPage = ref(1);
const totalPages = computed(() =>
  Math.ceil(meal_list.value.length / mealsPerPage)
);
watchEffect(() => {
  searchValue.value;
  stopList.value;
  category.value;
  currentPage.value = 1;
});
const goToPage = (page) => {
  if (page >= 1 && page <= totalPages.value) {
    currentPage.value = page;
  }
};

const paginatedMeals = computed(() => {
  const startIndex = (currentPage.value - 1) * mealsPerPage;
  const endIndex = startIndex + mealsPerPage;
  return meal_list.value.slice(startIndex, endIndex);
});
</script>
          
          <style scoped>
.selecttttt {
  border: 1px black solid;
  border-radius: 4px;
}
.kitchen-meal {
  /* margin-left:   300px; */
  /* height: 100vh; */
  position: relative;
  background-color: #f5f5f5;
  /* overflow-y: auto; */
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
}
.orderClass {
  margin: 20px 30px 20px 40px;
}
/* active and completed button */
.button {
  padding: 10px 30px;
  border-radius: 5px;
  border: 1px #f60100 solid;
  background-color: #fff;
  color: #f60100;
  margin-left: 20px;
}
.button.active {
  background: linear-gradient(180deg, #ff9103 0%, #f60100 100%);
  color: white;
  border: none;
}
.kitchenBtn {
  color: black;
  padding: 8px 20px;
  border-radius: 5px;
  border-radius: rgb(87, 85, 85) 1px solid;
  margin-right: 8px;
}

.menu-div {
  text-align: center;
  border-top: 2px solid #d9d9d9;
  border-bottom: 2px solid #d9d9d9;
  padding: 20px;
  background-color: #f8f8f8;
}

.menu-div a {
  color: black;
  text-decoration: none;
  margin: 0 10px;
}

.menu-div a.active {
  color: orange;
}
.meal-grid {
  display: flex;
  /* grid-template-columns: repeat(3, 1fr); */
  gap: 20px;
  width: 33%;
  padding: 10px 0;
  position: relative;
  justify-content: center;
  /* background: #009A49; */
}
.hidden {
  display: none !important;
}
.meal {
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 90%;
}

.meal-picture {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;

  margin-bottom: 10px;
}

.meal-time {
  width: 60px;
  height: 40px;
  background-color: #fff;
  color: black;
  position: absolute;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-bottom: 10px;
  border: 1px solid #f60100;
  right: 14px;
  bottom: 100px;
}

.meal-details {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 5px 12px;
}
.meal-info {
  padding: 0;
}
.meal-shop {
  padding: 0px;
  white-space: nowrap;
}
.meal-name {
  color: rgba(0, 0, 0, 0.87);
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.meal-special-name {
  color: rgba(0, 0, 0, 0.87);
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.meal-rating {
  font-size: 14px;
  color: #888;
}

.meal-price {
  color: rgba(0, 0, 0, 0.87);
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.28px;
}

.meal-shop-link {
  font-size: 14px;
  color: #f60100;
  font-weight: 600;
  line-height: 120%; /* 12px */
  letter-spacing: -0.2px;
  text-decoration-line: underline;
}
</style>