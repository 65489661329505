<template>
  <div class="timeSlot">
    <div style="text-align: center">
      <h3 style="font-weight: bold; color: black">{{ props.title }}</h3>
      <div class="timeClock">
        <div style="text-align: center">
          <div class="row gap-[45px]">
            <div class="col">
              <h2 class="card-title timeStamp">
                <!-- {{ time.replace("mins", "").replace("hours", "") }} -->
                <time-left
                  :type="time"
                  :order="order"
                  :count-down="15"
                  minutesOnly
                  removeTimeSpentMinute
                  :editString="(string = '')=>string?.replace('mins','')"
                />
              </h2>
              <h5 class="card-title">Minutes</h5>
            </div>
            <div class="col">
              <h2 class="card-title timeStamp">
                {{ props.seconds }}

                <!-- <time-left :type="time" :order="order" :count-down="15" secondsOnly /> -->
              </h2>
              <h5 class="card-title">Seconds</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAssembledTime, getPreparationTime, getTravelTime } from "@/util";
import { ref, watchEffect } from "vue";
import { useRoute } from "vue-router";
import { httpsCallable } from "firebase/functions";
import { projectFunctions } from "@/firebase/config";
import TimeLeft from "@/components/TimeLeft.vue";
export default {
  props: {
    minunte: Number,
    seconds: Number,
    title: String,
    order: Object,
    spent: Boolean,
  },
  components: {
    TimeLeft,
  },
  setup(props) {
    const route = useRoute();
    const time = ref();
    const order = ref({});

    watchEffect(() => {
      order.value = props.order;
      if (route.path.includes("kitchen")) {
        // time.value = getPreparationTime(order.value);
        time.value = "kitchen";
      } else if (route.path.includes("assembler")) {
        time.value = getAssembledTime(order.value);
        time.value = "assembler";
      } else if (route.path.includes("logistics")) {
        // time.value = getTravelTime(order.value);

        time.value = "logistics";
      }
    });
    return {
      props,
      order,
      time,
    };
  },
};
</script>

<style lang="scss" scoped>
.timeSlot {
  width: min(553px, 100%);
  // height: 207px;
  display: flex;
  flex-shrink: 0;
  .timeClock {
    padding: 3rem;
    background-color: #f5f5f5;
    margin: 0.5rem 3rem;
    border-radius: 20px;
  }

  .timeStamp {
    // font-size: 4rem;
    // background: linear-gradient(180deg, #ff9103 0%, #f60100 100%);
    // color: white;
    // border: none;
    // border-radius: 20px;
    // padding: 1.5rem;
    // margin: 0.5rem 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 158px;
    height: 103px;
    flex-shrink: 0;
    border-radius: 11px;
    background: var(
      --badman-flows,
      linear-gradient(202deg, #ff9103 0%, #f60100 100%)
    );
    color: var(--white, #fff);
    text-align: center;
    font-family: Nunito Sans;
    font-size: 65px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }
}
</style>