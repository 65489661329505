import { get, set, getDatabase, ref as dbRef } from "firebase/database";

export const walletRefund = async ({ refundAmount, order }) => {
    // console.log(props.refundAmount, props.order?.customer?.id);
    const customer_id = order?.customer?.id;
    const refund_amount = refundAmount;

    // console.log(customer_id, refund_amount);
    const wallet_details_path = `users/${customer_id}/wallet/`;
    // const wallet_history_path = `users/${customer_id}/wallet/`;
    const prev_wallet_details =
        (await get(dbRef(getDatabase(), wallet_details_path))).val() || {};
    const prev_wallet_balance = prev_wallet_details?.balance || 0;
    const prev_wallet_history = prev_wallet_details?.history || [];
    const payload = {
        ...prev_wallet_details,
        balance: prev_wallet_balance + refund_amount,
        history: [
            ...prev_wallet_history,
            {
                amount: refund_amount,
                created: JSON.stringify(new Date().toISOString()),
                type: 'wallet-reversal',
                order: order.id,
            },
        ],
    };
    // const path = `orders/${order.value.id}/refund_completed`;
    try {
        // console.log({wallet_details_path,payload})
        await set(dbRef(getDatabase(), wallet_details_path), payload);

        //   props.onSuccess(prev_wallet_balance + refund_amount);

        //   toast.success("Wallet refund successful");

    } catch (error) {
        throw (error);
    }
};