<template>
  <div class="flex items-center gap-3 cursor-pointer">
    <input
      type="checkbox"
      class="cursor-pointer"
      :disabled="props.disabled"
      :checked="checked"
      :id="props.id"
      @input="props.input"
    />
    <label :for="props.id">
      <Text class="cursor-pointer" :weight="400" :size="14">{{
        label
      }}</Text></label
    >
  </div>
</template>

<script>
import { ref } from "vue";
import { watchEffect } from "vue";
export default {
  props: {
    checked: Boolean,
    label: String,
    id: String,
    input: Function,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const label = ref("");
    const checked = ref(false);
    watchEffect(() => {
      label.value = props.label;
      checked.value = props.checked;
    });
    return { label, checked, props };
  },
};
</script>

<style lang="scss" scoped>
</style>