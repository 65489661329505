<template>
    <Text :size="props.size ||  40" :weight="600" class="text-center "> <slot /></Text>
  </template>
    
    <script setup>
  import Text from "@/components/Text.vue";

  const props = defineProps({
  size: Number
});
  </script>
    
    <style lang="scss" scoped>

  </style>