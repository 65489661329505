<template>
  <router-link
    class="cursor-pointer"
    :to="`/operator/${stat}/${orderId}`"
    style="color: inherit"
  >
    {{ pretty }}
  </router-link>
</template>

<script setup>
import { getOrderID } from "@/util";
import { computed, ref, watchEffect } from "vue";
// import { ref } from "firebase/storage";
import { useRouter } from "vue-router";

const router = useRouter();
const props = defineProps({
  order: Object,
  id: String,
  pretty: {
    default: true,
    type: Boolean,
  },
});

const orderId = ref(props.order?.id);
const order = ref(props.order);
const pretty = ref("");
watchEffect(() => {
  pretty.value = getOrderID({id:props.id}) || getOrderID(props.order) ;
  orderId.value = props.order?.id || props.id;
  order.value = props.order;
});

const stat = computed(() => {
  if (order.value?.status?.Cancelled?.state) return "cancelled-order";
  if (order.value?.status?.AwaitingPayment?.state) return "unconfirmed";
  if (order.value?.isSchedule) return "scheduled-order";
  // if (order.value?.status?.Delivered?.state) return "Delivered";
  // if (order.value?.status?.Pickup?.state) return "Picked Up";

  return "orders";
});
</script>

<style lang="scss" scoped>
</style>