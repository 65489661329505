<template>
  <head>
    <link
      rel="stylesheet"
      href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
    />
  </head>

  <layout :hasTopNav="false">
    <div class="px-[40px] bg-light w-full h-full">
      <TopNav :onSearch="search" :searchBar="false" :PrevNextIcon="false" />
      <div class="d-flex justify-content-between orderClass p-3 w-full">
        <div class="w-full">
          <div class="flex w-full items-center justify-between">
            <h1>
              <Text :size="27" :weight="700"> MealBot Point Redeemed </Text>
              <!-- <span class="capitalize">{{ selectId }}</span> -->
            </h1>
            <!-- <ue-button @click="saveChanges" variant="md">Save</ue-button> -->
          </div>
          <div class="flex mt-5 items-center gap-[0px] justify-end">
            <!-- <button class="kitchenBtn border"> -->
          </div>
        </div>
      </div>
      <section class="!p-0 flex flex-col gap-3 mb-4">
        <table class="order-table">
          <thead>
            <tr>
              <th class="table-header"></th>
              <th class="table-header">Date</th>
              <th class="table-header">Order ID</th>
              <th class="table-header">Customer Name</th>
              <th class="table-header">MBP Used</th>
              <th class="table-header">MBP Balance</th>
              <th class="table-header">Item Redeemed</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(
                mealbotPointRedeemed, index
              ) in paginatedmealbotPointsRedeemed"
              :key="mealbotPointRedeemed.created"
              class="table-row"
              :class="index % 2 === 0 ? 'even-row' : 'odd-row'"
            >
              <td class="table-data">
                {{
                  index + 1 + (currentPage - 1) * mealbotPointsRedeemedPerPage
                }}
              </td>
              <td class="table-data">
                {{ formatDate(mealbotPointRedeemed.created) }}
              </td>
              <td class="table-data">
                <OrderID :id="mealbotPointRedeemed.orderId" />
              </td>
              <td class="table-data">
                <CustomerName :customer="mealbotPointRedeemed.user" />
              </td>

              <td class="table-data">{{ mealbotPointRedeemed.point }}</td>
              <td class="table-data">
                <!-- {{ mealbotPointRedeemed?.user?.mealPoint?.point }} -->
                {{
                  getMealBotBalanceByHistory(
                    mealbotPointRedeemed?.user?.mealPoint?.history,
                    mealbotPointRedeemed.orderId
                  )
                }}
              </td>
              <td class="table-data">
                {{ mealbotPointRedeemed.meal?.join(", ") }}
              </td>
            </tr>
          </tbody>
        </table>
        <Pagination
          class="py-3"
          :currentPage="currentPage"
          :totalPages="totalPages"
          @page-change="goToPage"
        />
      </section>
    </div>

    <!-- <OrderModal v-else-if="selectedOrder?.id" :selectedOrder="selectedOrder" :orders="orders"/> -->
  </layout>
</template>
            
          <script setup>
import { ref, computed, watchEffect } from "vue";
import TopNav from "@/components/TopNav.vue";
import Text from "@/components/Text.vue";
import OrderID from "@/components/OrderID.vue";
import CustomerTag from "@/components/CustomerTag.vue";
import Layout from "@/partials/Layout/layout.vue";
import {
  get,
  getDatabase,
  ref as dbRef,
  query,
  orderByChild,
  startAt,
} from "firebase/database";
import { formatDate } from "@/util";
import Pagination from "@/components/Pagination.vue";
import CustomerName from "@/components/CustomerName.vue";

const mealbotPointsRedeemed = ref([]);

watchEffect(async () => {
  // const users_res = await get(dbRef(getDatabase(), "users"));
  const users_res = await get(
    query(
      dbRef(getDatabase(), "users"),
      orderByChild("mealPoint/point"),
      startAt(0)
    )
  );
  // console.log(users_res.val());
  const usersWithMealPoint = Object.values(users_res?.val())?.filter(
    (user) => user?.mealPoint
  );
  let histories = [];
  usersWithMealPoint.forEach((user) => {
    const historyWithUserAdded = user.mealPoint?.history
      ?.filter?.((history) => !history?.expired)
      .map((history) => ({
        user,
        ...history,
      }));
    histories.push(historyWithUserAdded);
  });

  mealbotPointsRedeemed.value = histories
    ?.flat(1)
    ?.filter((history) => history.pointType == "subtract")
    .toSorted(
      (a, b) =>
        new Date(b.created?.replaceAll('"', "")) -
        new Date(a.created?.replaceAll('"', ""))
    );

  // console.log(mealbotPointsRedeemed.value);
});

const getMealBotBalanceByHistory = (history, orderId) => {
  let balance = 0;
  const indexByOrderId = history
    ?.map((history) => history?.orderId)
    ?.indexOf(orderId);
  const history_to_calc = history?.slice(0, indexByOrderId + 1);
  console.log({history_to_calc})
  history_to_calc.forEach((history) => {
    if (history?.pointType === "add" && !history?.expired) balance = balance + history.point;
    if (history?.pointType === "subtract" && !history?.expired) balance = balance - history.point;
  });
  return balance;
};

const mealbotPointsRedeemedPerPage = 15;
const currentPage = ref(1);
const totalPages = computed(() =>
  Math.ceil(mealbotPointsRedeemed.value.length / mealbotPointsRedeemedPerPage)
);

const goToPage = (page) => {
  if (page >= 1 && page <= totalPages.value) {
    currentPage.value = page;
  }
};
const paginatedmealbotPointsRedeemed = computed(() => {
  const startIndex = (currentPage.value - 1) * mealbotPointsRedeemedPerPage;
  const endIndex = startIndex + mealbotPointsRedeemedPerPage;
  return mealbotPointsRedeemed.value.slice(startIndex, endIndex);
});
</script>
<style scoped lang="scss">
table {
  thead {
    td {
      font-size: 16px;
      font-weight: 600;
    }
  }
  td {
    padding: 5px 10px;
  }
}
</style>