<template>
  <div class="body border !h-[min-content]" :class="modalState.state?'':'hide'">
    <slot />
  </div>
  <div class="clickaway" :class="modalState.state?'':'hide'" @click="close()"></div>
</template>

<script>
import { store } from "@/views/store/store";
import { ref, watchEffect } from "vue";
// import
export default {
  props: {
    children: Node,
    title: String,
  },
  setup() {
    const modalState = ref(false);
    watchEffect(()=>{
      modalState.value = store.state.modal
    })
    const close=()=>{
      store.commit('closeModal')
    }
    return {
      modalState,
      close
    };
  },
};
</script>

<style lang="scss" scoped>
.body {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // min-height: 300px;
  width: 500px;
  z-index: 1000;
  padding: 24px;
  border-radius: 24px;
  background: #fff;
}
.hide {
  display: none;
}
.clickaway {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  background: rgba($color: #000000, $alpha: 0.2);
}
</style>