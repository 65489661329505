<template>
  <span v-if="props.order?.isSchedule">
    {{ scheduledTimeLeft(props.order) }}
  </span>
  <span v-if="!props.order?.isSchedule">
    {{ props.editString(timeDisplay?.toString()) }}
  </span>
  <!-- <span v-if="!props.minutesOnly && !props.secondsOnly">
    {{ timeDisplay }}
  </span> -->
</template>

<script setup>
import { getTimeDisplay, scheduledTimeLeft } from "@/util";
import dayjs from "dayjs";
import { ref, watchEffect } from "vue";
const props = defineProps({
  order: Object,
  countDown: Number,
  type: String,
  minutesOnly: Boolean,
  secondsOnly: Boolean,
  removeTimeSpentMinute: Boolean,
  editString: { type: Function, default: (val) => val   },
});

const timeDisplay = ref("---");
const order = ref(props.order);
const countDown = ref(props.countDown);
const type = ref(props.type);
const countDownDate = ref(0);

watchEffect(() => {
  order.value = props.order;
  countDown.value = props.countDown;
  type.value = props.type;
});

// Set the date we're counting down to
const startTime = ref();
const toMils = (mins) => 60000 * mins;
const mins = toMils(countDown.value);

watchEffect(() => {
  startTime.value = order.value.status?.Confirmed?.time?.replaceAll('"', "");
  countDownDate.value = new Date(startTime.value).getTime() + mins;
  // Update the count down every 1 second
  var x = setInterval(function () {
    // Get today's date and time
    var now = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = countDownDate.value - now;

    // Time calculations for days, hours, minutes and seconds
    //   var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    //   var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Display the result in the element with id="demo"
    let time_left = minutes + "m " + seconds + "s ";

    if (props.minutesOnly) time_left = minutes;
    if (props.secondsOnly) time_left = seconds;


    timeDisplay.value = getTimeDisplay(order.value, time_left, type.value);

    // If the count down is finished, write some text
    if (distance < 0) {
      time_left = `0 mins`;

      if (props.minutesOnly) time_left = 0;
      if (props.secondsOnly) time_left = 0;
      clearInterval(x);
      timeDisplay.value = getTimeDisplay(order.value, time_left, type.value);
      // if(props.removeTimeSpentMinute) getTimeDisplay(order.value, time_left, type.value)?.replace('mins','');
    }
  }, 1000);
});
</script>

<style lang="scss" scoped>
</style>