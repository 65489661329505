<template>
  <ue-button
    @click="() => (openModal = true)"
    :disabled="promo_code?.ended"
    :variant="'md'"
  >
    {{ promo_code?.ended ? "Ended" : "End Promo" }}
  </ue-button>

  <v-dialog v-model="openModal">
    <div class="rounded bg-white p-3 mx-auto">
      <MealModal
        title="End Promo?"
        :content="'Are you certain that you want to end this promo?'"
        :rightClick="() => endPromo()"
        :oncancel="() => (openModal = false)"
        firstBtn="No, Cancel"
        secondBtn="YES, Confirm"
        :closeBtn="true"
        :tickBtn="false"
      />
    </div>
  </v-dialog>
</template>

<script setup>
import MealModal from "@/components/MealModal.vue";
import UeButton from "@/components/UeButton.vue";
import dayjs from "dayjs";
import { get, getDatabase, ref as dbRef, set } from "firebase/database";
import { ref, watchEffect } from "vue";

const openModal = ref(false);
const props = defineProps({
  promo_code_id: String,
  //   onEnded : Function
});

const promo_code = ref({});
// const ended =

watchEffect(async () => {
  openModal.value;
  if (props.promo_code_id) {
    const _ref = dbRef(getDatabase(), `promo_codes/${props.promo_code_id}`);
    const res = await get(_ref);
    if (res.val()) promo_code.value = res.val();
  }
});

const endPromo = async () => {
  try {
    if (props.promo_code_id) {
      console.log(promo_code.value);
      const _ref = dbRef(getDatabase(), `promo_codes/${props.promo_code_id}/`);
      const payload = {
        ...promo_code.value,
        ended: true,
        end_date: dayjs().format('YYYY-MM-DD'),
        end_time: dayjs().format('HH:mm'),
        hasEnd:true
      };
      await set(_ref, payload);

      openModal.value = false;
      // if (res.val()) promo_code.value = res.val();
    }
  } catch (error) {
    console.log(error);
  }
};
</script>

<style lang="scss" scoped>
</style>