<template>
  <head>
    <link
      rel="stylesheet"
      href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
    />
  </head>

  <div class="order-container">
    <TopNav />
    <SideBar />
    <div class="d-flex justify-content-between orderClass">
      <h1>{{ message }}</h1>
      <div>
        <div>
          <button
            class="button"
            :class="{ active: selected === 'available' }"
            @click="select('available')"
          >
            Available Meals
          </button>
          <button
            class="button"
            :class="{ active: selected === 'add' }"
            @click="select('add')"
          >
            Add Meal
          </button>
          <button
            class="button"
            :class="{ active: selected === 'stop' }"
            @click="select('stop')"
          >
            Stop List
          </button>
        </div>
        
      </div>
    </div>
   
    <section>
      <div class="menu-div">
        <a href="#" class="active">All</a>
        <a href="#">Starter</a>
        <a href="#">Seafood</a>
        <a href="#">Breakfast</a>
        <a href="#">Native Food</a>
      </div>
    </section>
    <section>
      <div class="d-flex justify-content-end mt-5">
          <div>
            <button class="kitchenBtn">
              All Kitchen <i class="fas fa-angle-down"></i>
            </button>
          </div>
        </div>
    </section>
    <section>
      <div class="meal-grid">
        <div class="meal" v-for="meal in meals" :key="meal.name">
          <img class="meal-picture" :src="meal.pictureLink" :alt="meal.name" />
          <div class="meal-time">{{ meal.minutesToPrepare }} min</div>
          <div class="meal-details">
            <div class="meal-info">
              <h4 class="meal-name">{{ meal.name }}</h4>
            <div class="meal-special-name">{{ meal.specialName }}</div>
            <div class="meal-rating">          <i class="fas fa-star" style="color: #f60100;"></i> {{ meal.rating }} (200+)</div>
            </div>
            <div class="meal-shop">
            <div class="meal-price p-2"># {{ meal.price }}</div>
            <a href="#" class="meal-shop-link p-2">Add to Stop list</a>
          </div>
          
          </div>
        
        </div>
      </div>
    </section>
    <Pagination :currentPage="currentPage" :totalPages="totalPages" @page-change="goToPage" />
  </div>
</template>
          
        <script>
import { ref, computed } from "vue";
import Pagination from "@/components/Pagination.vue";

import TopNav from "@/components/TopNav.vue";
import SideBar from '@/components/SideBar.vue'

export default {
  components: {
    TopNav,
    SideBar,
    Pagination,
  },
  setup() {
    const selected = ref("active");
    const message = ref("Available Meals");
    const select = (type) => {
      selected.value = type;
      if (type === "add") {
        message.value = 'Add Meal'
      }
      if (type === "available") {
        message.value = 'Available Meals'
      }
      if (type === "stop") {
        message.value = 'Stop List'
      }
     
    };
  
    const meals = ref([
      {
        name: "Spaghetti Bolognese",
        pictureLink:
          "https://firebasestorage.googleapis.com/v0/b/urban-eats-cloud.appspot.com/o/restuarants%2Fikoko-bg.png?alt=media&token=a1de811c-c972-49ba-a526-19640c648596",
        rating: 4.5,
        minutesToPrepare: 30,
        price: 10.99,
        specialName: "Chef's Special",
      },
      {
        name: "Grilled Salmon",
        pictureLink:
          "https://firebasestorage.googleapis.com/v0/b/urban-eats-cloud.appspot.com/o/restuarants%2Fnutribowl-bg.png?alt=media&token=a4305e10-682b-4689-93c1-857fe356f3c1",
        rating: 4.8,
        minutesToPrepare: 25,
        price: 15.99,
        specialName: "Ocean Delight",
      },
      {
        name: "Chicken Caesar Salad",
        pictureLink:
          "https://firebasestorage.googleapis.com/v0/b/urban-eats-cloud.appspot.com/o/restuarants%2Fbob%E2%80%99s-diner-bg.png?alt=media&token=77bab277-1a60-49dd-b82a-cd9135364159",
        rating: 4.3,
        minutesToPrepare: 15,
        price: 8.99,
        specialName: "Fit & Fresh",
      },
      {
        name: "Spaghetti Bolognese",
        pictureLink:
          "https://firebasestorage.googleapis.com/v0/b/urban-eats-cloud.appspot.com/o/restuarants%2Fikoko-bg.png?alt=media&token=a1de811c-c972-49ba-a526-19640c648596",
        rating: 4.5,
        minutesToPrepare: 30,
        price: 10.99,
        specialName: "Chef's Special",
      },
      {
        name: "Grilled Salmon",
        pictureLink:
          "https://firebasestorage.googleapis.com/v0/b/urban-eats-cloud.appspot.com/o/restuarants%2Fnutribowl-bg.png?alt=media&token=a4305e10-682b-4689-93c1-857fe356f3c1",
        rating: 4.8,
        minutesToPrepare: 25,
        price: 15.99,
        specialName: "Ocean Delight",
      },
      {
        name: "Chicken Caesar Salad",
        pictureLink:
          "https://firebasestorage.googleapis.com/v0/b/urban-eats-cloud.appspot.com/o/restuarants%2Fbob%E2%80%99s-diner-bg.png?alt=media&token=77bab277-1a60-49dd-b82a-cd9135364159",
        rating: 4.3,
        minutesToPrepare: 15,
        price: 8.99,
        specialName: "Fit & Fresh",
      },
    ]);

    // Pagination
    const ordersPerPage = 6;
    const currentPage = ref(1);
    const totalPages = computed(() =>
      Math.ceil(meals.value.length / ordersPerPage)
    );

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };

    const paginatedOrders = computed(() => {
      const startIndex = (currentPage.value - 1) * ordersPerPage;
      const endIndex = startIndex + ordersPerPage;
      return meals.value.slice(startIndex, endIndex);
    });

    return {
      ordersPerPage,
      currentPage,
      totalPages,
      goToPage,
      paginatedOrders,
      select,
      selected,
      message,
 
      meals,
    };
  },
};
</script>
          
          <style scoped>
.order-container {
  margin-left:   300px;
  height: 100vh;
  position: relative;
  background-color: #f5f5f5;
  overflow-y: auto;
  width: calc(100% - 300px);
  display: flex;
  flex-direction: column;
}
.orderClass {
  margin: 20px 30px 20px 40px;
}
/* active and completed button */
.button {
  padding: 10px 30px;
  border-radius: 5px;
  border: 1px #f60100 solid;
  background-color: #fff;
  color: #f60100;
  margin-left: 20px;
}
.button.active {
  background: linear-gradient(180deg, #ff9103 0%, #f60100 100%);
  color: white;
  border: none;
}
.kitchenBtn {
  color: black;
  padding: 8px 20px;
  border-radius: 5px;
  border-radius: rgb(87, 85, 85) 1px solid;
  margin-right: 8px;
}

.menu-div {
  text-align: center;
  border-top: 2px solid #d9d9d9;
  border-bottom: 2px solid #d9d9d9;
  padding: 20px;
  background-color: #f8f8f8;
}

.menu-div a {
  color: black;
  text-decoration: none;
  margin: 0 10px;
}

.menu-div a.active {
  color: orange;
}
.meal-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 40px 20px;
}

.meal {
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;

  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.meal-picture {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;

  margin-bottom: 10px;
}

.meal-time {
  width: 60px;
  height: 40px;
  background-color: #fff;
  color: black;

  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-bottom: 10px;
  border: 1px solid #f60100;
  margin-left: 250px;
margin-top: -25px;
}

.meal-details {
  display: flex;
  justify-content: space-between;


}
.meal-info {
  padding: 15px 10px;
}
.meal-shop {
  padding: 15px 10px;
}
.meal-name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.meal-special-name {
  font-size: 14px;
  margin-bottom: 5px;
  color: #888;
}

.meal-rating {
  font-size: 14px;
  color: #888;
}

.meal-price {
  font-size: 16px;
  font-weight: bold;
}

.meal-shop-link {
  font-size: 10px;
  color: #f60100;
  font-weight: 600;
line-height: 120%; /* 12px */
letter-spacing: -0.2px;
text-decoration-line: underline;
}
</style>