<template>
  <head>
    <link
      rel="stylesheet"
      href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
    />
  </head>

  <layout :hasTopNav="false">
    <v-dialog v-model="pickModal">
      <div class="bg-white rounded-[12px] p-3 min-w-[400px] mx-auto">
        <MealModalVue
          :title="'Order picked up?'"
          :content="'Are you certain that this order has been picked up?'"
          :firstBtn="'No, Cancel'"
          :secondBtn="'YES, Confirm'"
          :closeBtn="false"
          :tickBtn="true"
          :oncancel="() => (pickModal = false)"
          :rightClick="setPickUp"
          :loading="loading"
        />
      </div>
    </v-dialog>
    <div class="order-container px-[40px] flex-col gap-[40px]" v-if="!selectId">
      <top-nav :onSearch="search" />
      <div class="d-flex justify-content-between orderClass">
        <h1>
          <text :color="'red'" :size="24" :weight="400"> Pick up Orders </text>
          <span class="capitalize">{{ selectId }}</span>
        </h1>
        <!-- <div>
            <div>
              <button
                class="button"
                :class="{ active: selected === 'active' }"
                @click="select('active')"
              >
                Active Orders
              </button>
              <button
                class="button"
                :class="{ active: selected === 'incoming' }"
                @click="select('incoming')"
              >
                Incoming
              </button>
              <button
                class="button"
                :class="{ active: selected === 'completed' }"
                @click="select('completed')"
              >
                Completed Orders
              </button>
            </div>
            <div class="d-flex justify-end mt-5">
              <input-field
                :id="'sle'"
                :label="' '"
                :type="'select'"
                @input="selectKitchen"
                class="outline-none p-3"
                :placeholder="' Time left (High to Low) '"
              ></input-field>
            </div>
          </div> -->
      </div>

      <section class="orderList">
        <div>
          <div>
            <table class="order-table">
              <thead>
                <tr>
                  <th class="table-header"></th>
                  <th class="table-header">Order ID</th>
                  <th class="table-header">Date</th>
                  <th class="table-header">Customer Name</th>
                  <th class="table-header">Service</th>
                  <!-- <th class="table-header">Courier</th> -->
                  <th class="table-header">Status</th>
                  <!-- <th class="table-header">Time Spent</th> -->
                  <th class="table-header">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(order, index) in paginatedOrders"
                  :key="order.id"
                  class="table-row"
                  :class="index % 2 === 0 ? 'even-row' : 'odd-row'"
                >
                  <td class="table-data">{{ index + 1 }}</td>
                  <td class="table-data">{{ order?.id }}</td>
                  <td class="table-data">{{ formatDate(order?.date) }}</td>
                  <td class="table-data">
                    {{
                      order?.customer?.firstName +
                      " " +
                      order?.customer?.lastName
                    }}
                  </td>
                  <td class="table-data">{{ order.locationType }}</td>
                  <td class="table-data">
                    <status
                      :items="order?.items"
                      :status="order.status"
                      :order="order"
                    />
                  </td>
                  <td class="table-data">
                    <input-field
                      :checked="order.status.Pickup.state"
                      @click="
                        () => {
                          setModalData(order?.id, order);
                          pickModal = true;
                        }
                      "
                      :type="'roundcheck'"
                    />
                    <!-- <a
                        href="#"
                        class="action-link !underline"
                        @click="router.push(`/assembler/orders/${order?.id}`)"
                        >View</a
                      > -->
                  </td>
                </tr>
              </tbody>
            </table>
            <Pagination
              :currentPage="currentPage"
              :totalPages="totalPages"
              @page-change="goToPage"
            />
          </div>
        </div>
      </section>
    </div>
    <!-- <OrderModal v-else-if="selectedOrder?.id" :selectedOrder="selectedOrder" /> -->
    <div v-else>loading...</div>
  </layout>
</template>
          
        <script>
import { ref, computed, watchEffect } from "vue";
import Pagination from "@/components/Pagination.vue";
//   import OrderModal from "./OrderModal.vue";
import TopNav from "@/components/TopNav.vue";
import { formatDate, sum } from "@/util";
import Modal from "@/components/Modal.vue";
import { getData } from "@/firebase/config";
import { useRoute, useRouter } from "vue-router";
import countdown from "@/partials/countdown.vue";
import Text from "@/components/Text.vue";
import Status from "@/components/Status.vue";
import MealModalVue from "@/components/MealModal.vue";
import InputField from "@/components/InputField.vue";
import layout from "@/partials/Layout/layout.vue";
import { store } from "@/views/store/store";

export default {
  components: {
    TopNav,
    Pagination,
    //   OrderModal,
    Modal,
    countdown,
    layout,
    Text,
    Status,
    InputField,
    MealModalVue,
  },

  setup() {
    // watchEffect(()=>{  store.dispatch('updateOrders')  })
    const orders = ref(store.state.orders);
    const selected = ref("active");
    const searchValue = ref("");
    const message = ref("Active Orders");
    const kitchen = ref(getData("restaurants"));
    // boolean controlling the order modal
    const modal = ref(false);
    //selectedModal
    const selectedOrder = ref({});
    const route = useRoute();
    const router = useRouter();
    const selectId = ref(route.params.id);
    const orderId = ref(false);
    const pickModal = ref(false);
    const loading = ref(false);

    watchEffect(() => {
      selectId.value = route.params.id;
    });

    // watchEffect(() => {
    //   if (selectId.value)
    //     selectedOrder.value = store.state.orders.find(
    //       (x) => x.id === selectId.value
    //     );
    // });
    watchEffect(() => {
      let pickup_order = store.state.orders.filter(
        (order) =>
          order.status?.Assembled?.state &&
          !order.status?.Pickup?.state &&
          order.locationType === "Pickup"
      );
      // console.log(store.state.orders.filter(type => type.locationType === 'Pickup'))
      if (searchValue.value) {
        orders.value = pickup_order.filter((order) => {
          const value = searchValue.value.toLowerCase();
          const check = (str = "") => str?.toLowerCase()?.includes(value);
          return check(order.customer.name) || check(order.id);
        });
      } else {
        orders.value = pickup_order;
      }
    });

    const setModalData = (id, order) => {
      store.commit("openModal");
      orderId.value = id;
      selectedOrder.value = order;
    };
    let i = 0
    const setPickUp = async () => {
      loading.value = true;
      pickModal.value = false;
      // console.log(i++)
      await store.dispatch("setPickUp", {
        orderId: orderId.value,
        order: selectedOrder.value,
        onComplete: () => {
          // alert("Done");
          loading.value = false;
        },
      });
      // setTimeout(() => {
      // }, 4000);
    };

    const search = (e) => {
      searchValue.value = e;
    };

    // Pagination
    const ordersPerPage = 15;
    const currentPage = ref(1);
    const totalPages = computed(() =>
      Math.ceil(orders.value.length / ordersPerPage)
    );

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };

    const paginatedOrders = computed(() => {
      const startIndex = (currentPage.value - 1) * ordersPerPage;
      const endIndex = startIndex + ordersPerPage;
      return orders.value.slice(startIndex, endIndex);
    });

    watchEffect(() => {
      searchValue.value;
      currentPage.value = 1;
    });

    return {
      ordersPerPage,
      currentPage,
      totalPages,
      goToPage,
      paginatedOrders,
      // select,
      selected,
      message,
      search,
      modal,
      selectedOrder,
      kitchen,
      selectId,
      route,
      router,
      formatDate,
      setModalData,
      setPickUp,
      pickModal,
      loading,
    };
  },
};
</script>
          
          <style scoped>
.order-container {
  position: relative;
  background-color: #f5f5f5;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.orderClass {
  /* margin: 20px 30px 20px 40px; */
}
/* active and completed button */
.button {
  padding: 10px 30px;
  border-radius: 5px;
  border: 1px #f60100 solid;
  background-color: #fff;
  color: #f60100;
  margin-left: 20px;
}
.button.active {
  background: linear-gradient(180deg, #ff9103 0%, #f60100 100%);
  color: white;
  border: none;
}

.pagination-container {
  display: flex;
  column-gap: 10px;
}
.paginate-buttons {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(217, 217, 217);
  color: black;
}
.paginate-buttons:hover {
  background-color: #d8d8d8;
}
.active-page {
  background-color: #3498db;
  border: 1px solid #3498db;
  color: white;
}
.active-page:hover {
  background-color: #2988c8;
}
.orderList {
  /* margin: 20px; */
  border-radius: 20px;

  font-size: 12px;
  color: black;
}
.order-table {
  width: 100%;
  border-collapse: collapse;
  /* border: 1px solid #ccc; */
}

.table-row {
  height: 60px;
}

.table-row.even-row {
  background-color: #f2f2f2;
}

.table-row.odd-row {
  background-color: #fff;
}
.status-button {
  padding: 5px 10px;
  background: linear-gradient(
    202deg,
    rgba(255, 145, 3, 0.24) 0%,
    rgba(246, 1, 0, 0.24) 100%
  );

  border: none;
  color: #333;
  font-weight: bold;
}

.status-button2 {
  padding: 5px 10px;
  background: linear-gradient(
    202deg,
    rgba(74, 224, 117, 0.428) 0%,
    rgba(0, 246, 78, 0.24) 100%
  );

  border: none;
  color: #333;
  font-weight: bold;
}

.action-link {
  text-decoration: none;
  color: #f60100;
}
</style>