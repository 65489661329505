<template>
  <head>
    <link
      rel="stylesheet"
      href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
    />
  </head>

  <layout :hasTopNav="false">
    <div class="px-[40px] bg-light w-full h-full" v-if="!selectId">
      <TopNav :onSearch="search" />
      <div class="d-flex justify-content-between orderClass p-3 w-full">
        <div class="w-full">
          <div class="flex w-full items-center justify-between">
            <Text :size="27" :weight="700">Rejected Orders</Text>
          </div>
        </div>
      </div>
      <section class="orderList !p-0 !bg-white">
        <div>
          <table class="order-table">
            <thead>
              <tr>
                <th class="table-header"></th>
                <th class="table-header">Date</th>
                <th class="table-header">Courier</th>
                <th class="table-header">Phone</th>
                <th class="table-header">Order ID</th>
                <th class="table-header">Delivery Address</th>
                <th class="table-header">Type</th>
                <th class="table-header">Reason</th>
                <!-- <th class="table-header">Action</th> -->
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(order, index) in paginatedOrders"
                :key="order.id"
                class="table-row"
                :class="index % 2 === 0 ? 'even-row' : 'odd-row'"
              >
                <td class="table-data">{{ index + 1 }}</td>
                <td class="table-data">{{ formatDate(order.date) }}</td>
                <td class="table-data">{{ order.courier }}</td>
                <td class="table-data">{{ order.phone }}</td>
                <td class="table-data">{{ order.id }}</td>
                <td class="table-data w-[250px]">
                  {{ order.deliveryAddress }}
                </td>
                <td class="table-data">
                  {{ order.type === "rejected" ? "rejected" : "cancelled" }}
                </td>
                <td class="table-data">{{ order.reason }}</td>
              </tr>
            </tbody>
          </table>
          <Pagination
            class="py-3"
            :currentPage="currentPage"
            :totalPages="totalPages"
            @page-change="goToPage"
          />
        </div>
      </section>
    </div>
    <div v-else>loading...</div>
  </layout>
</template>
            
          <script>
import { ref, computed, watchEffect } from "vue";
import Pagination from "@/components/Pagination.vue";
// import OrderModal from "./OrderModal.vue";
import TopNav from "@/components/TopNav.vue";
import Modal from "@/components/Modal.vue";
import { getData } from "@/firebase/config";
import { useRoute, useRouter } from "vue-router";
import InputField from "@/components/InputField.vue";
import Text from "@/components/Text.vue";
import {
  sum,
  formatDate,
  getUeOrderId,
  timeLeftList,
  getTravelTime,
} from "@/util";
import Status from "@/components/Status.vue";
import Layout from "@/partials/Layout/layout.vue";
import { store } from "@/views/store/store";
import { get, ref as dbRef, getDatabase } from "firebase/database";
// import { getDatabase, onValue, ref as dbRef } from "firebase/database";

export default {
  components: {
    TopNav,
    Pagination,
    // OrderModal,
    Modal,
    InputField,
    Text,
    Status,
    Layout,
  },
  setup() {
    const orders = ref(store.state.orders);
    const status = ref("active");
    const message = ref("Active Orders");
    const kitchen = getData("restaurants");
    const selectedOrder = ref({});
    const route = useRoute();
    const router = useRouter();
    const selectId = ref(route.params.id);
    const selectedKitchen = ref("");
    const searchValue = ref("");
    const selectedTime = ref(null);
    const rejected_orders = ref([]);
    const list = ref([]);
    const selectKitchen = (e) => {
      selectedKitchen.value = e?.target?.value;
    };

    const getRejectedOrders = async () => {
      let save = [];
      const res = await get(dbRef(getDatabase(), "courier"));
      Object.values(res.val())
        .filter((x) => x?.rejected_orders)
        .map((rider, i) => {
          Object.values(rider.rejected_orders).map((rejected) => {
            console.log(rejected)
            save.push({
              ...rejected,
              courier: rider.first_name + " " + rider.last_name,
              phone: rider.phone,
              uuid: `${rider.id}-${rejected.id}`,
            });
          });
        });

      //sort "save by date"
      save.sort(
        (a, b) =>
          new Date(b.date?.replaceAll('"', "")) -
          new Date(a.date?.replaceAll('"', ""))
      );

      if (searchValue.value) {
        rejected_orders.value = save.filter((order) => {
          const check = (key) => {
            return order[key]
              ?.toLowerCase()
              ?.includes(searchValue.value.toLowerCase());
          };
          return (
            check("courier") || check("phone") || check("reason") || check("id")
          );
        });
      } else {
        rejected_orders.value = save;
      }
    };

    watchEffect(() => {
      searchValue.value;
      getRejectedOrders();
    });

    const search = (e) => {
      searchValue.value = e;
    };

    const select = (type) => {
      status.value = type;
      message.value = type === "active" ? "Active Orders" : "Completed Orders";
    };

    // Pagination
    const ordersPerPage = 15;
    const currentPage = ref(1);
    const totalPages = computed(() =>
      Math.ceil(rejected_orders.value.length / ordersPerPage)
    );

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };

    const paginatedOrders = computed(() => {
      const startIndex = (currentPage.value - 1) * ordersPerPage;
      const endIndex = startIndex + ordersPerPage;
      return rejected_orders.value.slice(startIndex, endIndex);
    });

    return {
      ordersPerPage,
      currentPage,
      totalPages,
      goToPage,
      paginatedOrders,
      select,
      status,
      message,
      sum,
      selectedOrder,
      kitchen,
      selectId,
      selectKitchen,
      router,
      search,
      formatDate,
      getUeOrderId,
      timeLeftList,
      selectedTime,
      getTravelTime,
    };
  },
};
</script>
            
            <style scoped>
/* active and completed button */
.button {
  padding: 10px 30px;
  border-radius: 5px;
  border: 1px #f60100 solid;
  background-color: #fff;
  color: #f60100;
  margin-left: 20px;
}
.button.active {
  background: linear-gradient(180deg, #ff9103 0%, #f60100 100%);
  color: white;
  border: none;
}

.pagination-container {
  display: flex;
  column-gap: 10px;
}
.paginate-buttons {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(217, 217, 217);
  color: black;
}
.paginate-buttons:hover {
  background-color: #d8d8d8;
}
.active-page {
  background-color: #3498db;
  border: 1px solid #3498db;
  color: white;
}
.active-page:hover {
  background-color: #2988c8;
}

.order-table {
  width: 100%;
  border-collapse: collapse;
  /* border: 1px solid #ccc; */
}

.table-row {
  height: 60px;
}

.table-row.even-row {
  background-color: #f2f2f2;
}

.table-row.odd-row {
  background-color: #fff;
}

.status-button {
  padding: 5px 10px;
  background: linear-gradient(
    202deg,
    rgba(255, 145, 3, 0.24) 0%,
    rgba(246, 1, 0, 0.24) 100%
  );

  border: none;
  color: #333;
  font-weight: bold;
}

.status-button2 {
  padding: 5px 10px;
  background: linear-gradient(
    202deg,
    rgba(74, 224, 117, 0.428) 0%,
    rgba(0, 246, 78, 0.24) 100%
  );

  border: none;
  color: #333;
  font-weight: bold;
}

.action-link {
  text-decoration: none;
  color: #f60100;
}
</style>