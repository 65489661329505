<template>
  <!-- <div v-if="openReceipt" >close</div> -->
  <div id="button" @click="() => (openReceipt = true)"><slot /></div>
  <v-dialog v-model="openReceipt">
    <div class="receipt-container">
      <!-- <div class="close"><span class="material-symbols-outlined "> close </span></div> -->
      <div
        class="w-[302.36px] px-3 bg-white mx-auto flex flex-col gap-4"
        :id="order.id"
      >
        <img
          :src="icons.logo_black"
          weight="64"
          height="64"
          class="mx-auto w-[64px] logo"
        />
        <!-- <div class="mx-auto text-center flex flex-col">
        <Text :weight="800" :size="17" :weight="600">Urban Eats</Text>
        <Text :weight="800" :size="17" :weight="400" class="mx-auto">urbaneratsapp.com</Text>
      </div> -->

        <div class="flex justify-between w-full items-center h-[44px]">
          <div class="">
            <img :src="icons.applestore" alt="" class="w-[100px] h-[32px]" />
          </div>
          <div class="">
            <img :src="icons.googleplay" alt="" class="w-[100px]" />
          </div>
        </div>
        <div class="flex justify-center flex-col gap-2">
          <Text
            :weight="800"
            class="items-start h-[35px] px-3 bg-dark w-[fit-content] text-white mx-auto flex"
            :size="16"
            >{{ order.locationType }}</Text
          >

          <Text :weight="800" :size="18" class="mx-auto">{{ order.id }}</Text>

          <div class="flex justify-center">
            <CustomerTag :uid="order?.customer?.id" />
          </div>
        </div>

        <table>
          <tbody>
            <tr v-if="oneoff">
              <td class="pr-1 flex">
                <Text :weight="800" class="whitespace-nowrap" :size="16">
                  Name:
                </Text>
              </td>
              <td>
                <Text :weight="800" :size="16" class="">
                  {{ order?.customer?.firstName }}
                  {{ order?.customer?.lastName }}</Text
                >
              </td>
            </tr>
            <tr v-if="oneoff">
              <td class="pr-1 flex" v-if="oneoff">
                <Text :weight="800" class="whitespace-nowrap" :size="16">
                  Email:
                </Text>
              </td>
              <td>
                <Text :weight="800" :size="16" class="">
                  {{ order?.customer?.email }}</Text
                >
              </td>
            </tr>
            <tr>
              <td class="pr-1 flex">
                <Text :weight="800" class="whitespace-nowrap" :size="16">
                  Address:
                </Text>
              </td>
              <td>
                <Text :weight="800" :size="16" class="">
                  {{
                    `${order?.customer?.deliveryAddress?.houseNo || ""}` +
                    " " +
                    order?.customer?.deliveryAddress?.houseAddress
                  }}</Text
                >
              </td>
            </tr>
            <tr>
              <td class="pr-1 flex">
                <Text :weight="800" class="whitespace-nowrap" :size="16">
                  Phone:
                </Text>
              </td>
              <td>
                <Text :weight="800" :size="16" class="">
                  {{ order?.customer?.phone }}</Text
                >
              </td>
            </tr>
            <tr>
              <td class="pr-1 flex">
                <Text :weight="800" class="whitespace-nowrap" :size="16">
                  Order time:
                </Text>
              </td>
              <td>
                <Text :weight="800" :size="16" class="">{{
                  formatDate(order?.date)
                }}</Text>
              </td>
            </tr>

            <tr v-if="oneoff">
              <td class="pr-1 flex">
                <Text :weight="800" class="whitespace-nowrap" :size="16">
                  Items cost:
                </Text>
              </td>
              <td>
                <Text :weight="800" :size="16" class="">{{
                  formatPrice(
                    order?.total_price -
                      (order?.delivery || 1000) -
                      (order?.service || 300)
                  )
                }}</Text>
              </td>
            </tr>
            <tr v-if="oneoff">
              <td class="pr-1 flex">
                <Text :weight="800" class="whitespace-nowrap" :size="16">
                  Total cost:
                </Text>
              </td>
              <td>
                <Text :weight="800" :size="16" class="">{{
                  formatPrice(order?.total_price)
                }}</Text>
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <Text :weight="800" :size="16" class="underline">Rider's note</Text>
          <Text :weight="800">{{
            order.customer?.deliveryAddress?.direction || "--"
          }}</Text>
        </div>
        <div>
          <Text :weight="800" :size="16" class="underline">Order note</Text>
          <Text :weight="800">{{ order?.order_note || "--" }}</Text>
        </div>

        <div>
          <Text :weight="800" :size="16" class="underline">Order details</Text>
          <tr v-for="item in order.items" :key="item.name">
            <td class="pr-2">
              <Text :weight="800" :size="16" class=""
                >{{ item.quantity }}x
              </Text>
            </td>
            <td>
              <Text :weight="800" :size="16" class="">
                {{ item.name }}

                <span v-if="item?.cancelled" style="font-size: small">
                  (cancelled {{ item?.cancelled }})
                </span>
                - {{ item.resturant }}

                <Text :size="12" v-if="item.extraPortion">
                  {{ item.extraPortion?.name }}
                  <br />
                </Text>

                <Text
                  :size="12"
                  v-if="
                    item?.addons?.length ||
                    item?.sides?.length ||
                    item?.customize?.length
                  "
                >
                  {{
                    item.addons
                      ? `(${item.addons
                          .map(
                            (subItem) =>
                              `${
                                subItem?.qty
                                  ? subItem?.qty * item?.quantity + "x"
                                  : ""
                              } ${subItem.name} ${cancelledCount(subItem)} `
                          )
                          .join(", ")}) `
                      : ""
                  }}
                  <br v-if="item.addons?.length" />
                  {{
                    item.sides
                      ? `(${item.sides
                          .map(
                            (subItem) =>
                              `${
                                subItem?.qty
                                  ? subItem?.qty * item?.quantity + "x"
                                  : ""
                              } ${subItem.name} ${cancelledCount(subItem)} `
                          )
                          .join(", ")}) `
                      : ""
                  }}
                  <br v-if="item.sides?.length" />
                  <!-- {{ item?.customize }}d -->
                  {{
                    item.customize
                      ? `(${item.customize
                          .map(
                            (subItem) =>
                              `${
                                subItem?.qty
                                  ? subItem?.qty * item?.quantity + "x"
                                  : ""
                              } ${subItem.name} ${cancelledCount(subItem)} `
                          )
                          .join(", ")}) `
                      : ""
                  }}
                </Text>
                <!-- {{ item?.selectedPrice }} -->
                <div v-if="item?.selectedPrice?.item" style="font-size: small">
                  <!-- <br> -->
                  {{ item.selectedPrice?.item }}
                </div>
                <div v-if="item?.note" style="font-size: small">
                  Note: {{ item?.note }}
                </div>
              </Text>
            </td>
          </tr>
        </div>
      </div>
      <div class="flex gap-4 mx-auto my-2">
        <ue-button id="close-btn" @click="() => (openReceipt = false)"
          >Close</ue-button
        >
        <ue-button @click="print">Print</ue-button>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { ref, watchEffect } from "vue";
import Text from "./Text.vue";
import { icons } from "@/assets/icons";
import { formatDate, formatPrice } from "@/util";
import UeButton from "./UeButton.vue";
import { store } from "@/views/store/store";
import html2pdf from "html2pdf.js";
import CustomerTag from "./CustomerTag.vue";
export default {
  props: {
    order: Object,
  },
  setup(props) {
    const openReceipt = ref(false);
    const oneoff = ref(false);
    const order = ref(null);
    // console.log("first");
    watchEffect(() => {
      openReceipt.value;
      order.value = props.order;
      // console.log(order?.value);
    });

    const print = async () => {
      const element = document.getElementById(order.value.id);
      var opt = {
        margin: [1.5, 0, 1.5, 0], //top, left, buttom, right,
        filename: `${order.value.id}.pdf`,
        image: { type: "jpeg", quality: 75 },
        html2canvas: { dpi: 192, scale: 2, letterRendering: true, scrollY: 0 },
        // html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: [80, 160], orientation: "portrait" },
      };
      try {
        html2pdf().from(element).set(opt).toPdf().output("dataurlnewwindow");
        store.dispatch("setOrderPrint", { order: order.value });
      } catch (error) {
        console.log(error);
      }
    };

    const cancelledCount = (extra) => {
      if (extra?.cancelled || extra?.canceled)
        return `(cancelled ${extra?.cancelled || extra?.canceled})`;
      return "";
    };
    return {
      openReceipt,
      order,
      icons,
      formatDate,
      print,
      oneoff,
      formatPrice,
      cancelledCount,
    };
  },
  components: { Text, UeButton, CustomerTag },
};
</script>

<style lang="scss" scoped>
.receipt-container {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 85vh;
  // background: red;
  padding: 0 10px;
  width: fit-content;
  margin: auto;
  // .logo{
  //   width: 64px;
  //   height: 34px;
  // }
  p {
    font-family: Arial, Helvetica, sans-serif;
  }
  position: relative;
  .close {
    display: none;
    justify-content: flex-end;
    top: 0px;
    width: 302px;
    // right: 35%;
    cursor: pointer;
  }
}
</style>