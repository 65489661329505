<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
  >
    <path
      d="M14.0293 23.333L16.2168 25.5205L20.9564 21.1455"
      stroke="auto"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.848 2.91699L7.56885 8.21074M22.1522 2.91699L27.4313 8.21074"
      stroke="auto"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.9165 11.4479C2.9165 8.75 4.36025 8.53125 6.154 8.53125H28.8457C30.6394 8.53125 32.0832 8.75 32.0832 11.4479C32.0832 14.5833 30.6394 14.3646 28.8457 14.3646H6.154C4.36025 14.3646 2.9165 14.5833 2.9165 11.4479Z"
      stroke="auto"
      stroke-width="1.5"
    />
    <path
      d="M5.104 14.583L7.16025 27.183C7.62692 30.0122 8.74984 32.083 12.9207 32.083H21.7144C26.2498 32.083 26.9207 30.0997 27.4457 27.358L29.8957 14.583"
      stroke="auto"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>