
import { assembler_routes, kitchen_routes, logistics_routes, operator_routes, routes } from '@/router';
import { store } from "./views/store/store";


let all_pages = [
    ...operator_routes.map((route) => route.name),
    ...logistics_routes.map((route) => route.name),
    ...assembler_routes.map((route) => route.name),
    ...kitchen_routes.map((route) => route.name),
];
const permission = (permissions) => {
    // console.log(permissions)

    let permissionPriority = [];
    all_pages.map((page) => {
        permissionPriority.push({
            is_permitted: permissions?.filter(
                (permission) => permission === page
            ).length,
            page,
        });
    });
    let first_page_permitted = permissionPriority.find(
        (first) => first.is_permitted
    );
    let first_page = routes.find(route => first_page_permitted.page == route.name)

    return { permissionPriority, all_pages, first_page }
}
const check_if_permitted_into_a_page = (page) => {
    // console.log(page)

    if (store.state.user.permissions) {
        let { permissionPriority } = (permission(store.state.user.permissions));
        let permitted = permissionPriority.find(permission => page == permission.page)
        if (all_pages.find(name => page == name)) {
            return {
                permitted
            }
        } else {
            return {
                permitted: {
                    is_permitted: 1
                }
            }
        }
    }else{
        return{
            permitted: {
                is_permitted: 0
            }
        }
    }

}
export { permission, check_if_permitted_into_a_page }