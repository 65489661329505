<template>
  <Line :data="data" :options="options" />
</template>
  
  <script >
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "vue-chartjs";
import { ref, watchEffect } from "vue";
import { getOrdersByTimeFrame } from "@/util";
import dayjs from "dayjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export default {
  name: "App",
  components: {
    Line,
  },
  props: {
    timeframe: Object,
  },

  setup(props) {
    const yaxis = ref([]);
    const data = ref({
      labels: ["January", "February", "March", "April", "May", "June", "July"],
      datasets: [
        {
          label: "",
          fill: true,
          backgroundColor: (ctx) => {
            const canvas = ctx.chart.ctx;
            const gradient = canvas.createLinearGradient(255, 145, 3, 300);
            return gradient;
          },
          tension: 0.25,
          borderColor: "red",
          // data: [40, 39, 10, 40, 39, 80,656],
          data: [...yaxis.value],
          fill: true,
        },
      ],
    });

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
    };

    watchEffect(async () => {
      const time = props.timeframe.time;
      const start = props.timeframe.start;
      const end = props.timeframe.end;
      const frame = props.timeframe.frame;
      const format = props.timeframe.format;
      const ordersInTimeFrame =await getOrdersByTimeFrame(start, end);

      let list = [];

      frame?.map((x, i) => {
        let total_orders_in_that_timeframe = ordersInTimeFrame.filter(
          (y) => dayjs(y.date.replaceAll('"', "")).format(format) === x
        ).length;
        list[i] = total_orders_in_that_timeframe;
      });
      // if (time === "today") {
      //   list = [];
      //   frame.map((x, i) => {
      //     let total_orders_in_that_timeframe = ordersInTimeFrame.filter(
      //       (y) => dayjs(y.date.replaceAll('"', "")).format(format) === x
      //     ).length;
      //     list[i] = total_orders_in_that_timeframe;
      //   });
      // } else if (time === "yesterday") {
      //   list = [];
      //   frame.map((x, i) => {
      //     let total_orders_in_that_timeframe = ordersInTimeFrame.filter(
      //       (y) => dayjs(y.date.replaceAll('"', "")).format(format) === x
      //     ).length;
      //     list[i] = total_orders_in_that_timeframe;
      //   });
      // } else if (time === "this-week") {
      //   list = [];
      //   frame.map((x, i) => {
      //     let total_orders_in_that_timeframe = ordersInTimeFrame.filter(
      //       (y) => dayjs(y.date.replaceAll('"', "")).format(format) === x
      //     ).length;
      //     list[i] = total_orders_in_that_timeframe;
      //   });
      // } else if (time === "this-month" || time === "last-month") {
      //   list = [];
      //   frame.map((x, i) => {
      //     let total_orders_in_that_timeframe = ordersInTimeFrame.filter(
      //       (y) => dayjs(y.date.replaceAll('"', "")).format(format) === x
      //     ).length;
      //     list[i] = total_orders_in_that_timeframe;
      //   });
      // } else if (time === "this-year" || time === "life-time") {
      //   list = [];
      //   frame.map((x, i) => {
      //     let total_orders_in_that_timeframe = ordersInTimeFrame.filter(
      //       (y) => dayjs(y.date.replaceAll('"', "")).format(format) === x
      //     ).length;
      //     list[i] = total_orders_in_that_timeframe;
      //   });
      // }

      data.value = { ...data.value, labels: frame };
      data.value = {
        ...data.value,
        datasets: [
          {
            label: "",
            fill: true,
            backgroundColor: (ctx) => {
              const canvas = ctx.chart.ctx;
              const gradient = canvas.createLinearGradient(255, 145, 3, 300);
              return gradient;
            },
            tension: 0.25,
            borderColor: "red",
            // data: [40, 39, 10, 40, 39, 80,656],
            data: [...list],
            fill: true,
          },
        ],
      };
      // console.log(data.value.datasets)
    });
    return { options, data };
  },
};
</script>
  