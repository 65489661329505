<template>
  <div :class="styles.container" class="bg-light">
    <div class="menu-icon" @click="() => (togg = !togg)">
      <span class="material-symbols-outlined"> menu </span>
    </div>

    <aside  v-if="showSideBar">
      <side-bar> </side-bar>
      <!-- <Sidebar /> -->
    </aside>
    <div class="grey-out" v-if="showSideBar"  @click="() => (togg = !togg)"></div>
    <div :class="styles.content" class="bg-light">
      <div v-if="hasTopNav">
        <top-nav
          :searchBar="props.hasSearch"
          class="bg-success"
          @click="refresh()"
        >
        </top-nav>
      </div>
      <Transition name="fade-transition" mode="out-in">
        <main id="layout-main">
          <slot /></main
      ></Transition>
    </div>
  </div>
</template>

<script>
import SideBar from "@/components/SideBar.vue";
import styles from "./layout.module.scss";
import TopNav from "@/components/TopNav.vue";
import { onMounted } from "vue";
import { computed } from "vue";
import { ref } from "vue";
// import SideBarVue
export default {
  components: {
    SideBar,
    TopNav,
  },
  props: {
    hasTopNav: {
      default: false,
      type: Boolean,
    },
    hasSearch: {
      default: false,
      type: Boolean,
    },
  },
  setup(props) {
    onMounted(() => {});
    const refresh = () => {
      // alert("tyyy");
    };
    const togg = ref(false);
    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
      togg.value = false;
    };

    onMounted(() => {
      window.addEventListener("resize", handleResize);
    });

    const showSideBar = computed(() => {
      if (windowWidth.value > 991) return true;
      return togg.value;
    });
    return {
      styles,
      refresh,
      props,
      togg,
      showSideBar,
    };
  },
};
</script>

<style lang="scss" scoped>
.menu-icon {
  position: fixed;
  top: 15px;
  left: 15px;
  display: none;
  @media screen and (max-width: 992px) {
    display: block;
    cursor: pointer;
    z-index: 10000;
  }
}
.grey-out {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  
  @media screen and (max-width: 992px) {
    display: block;
    cursor: pointer;
    z-index: 100;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .3);
  }
}
</style>