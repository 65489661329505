<template>
  <div class="flex flex-col gap-4 w-full py-3" @click="() => props.onClick()">
    <div class="flex justify-between w-ful">
      <div class="bg-white shadow rounded p-4 w-[70%]">
        <div class="flex justify-between items-center">
          <Text :size="24">{{ props?.name }}</Text>
          <div class="flex gap-3" v-if="props.sorting">
            <select
              class="border outline-none rounded py-2 px-4"
              @input="(e) => (sort = e.target.value)"
            >
              Rating:
              {{
                sort
              }}
              <option value="">Select Sorting</option>
              <option
                :value="i.value"
                v-for="i in [
                  { label: 'Sort by rating : Highest', value: 'Highest' },
                  { label: 'Sort by rating : Lowest', value: 'Lowest' },
                  { label: 'Sort by date : Newest', value: 'Newest' },
                  { label: 'Sort by date : Oldest', value: 'Oldest' },
                ]"
                :key="i.value"
              >
                {{ i.label }}
              </option>
            </select>
          </div>
        </div>
        <div>
          <div class="flex items-center gap-3">
            <Text :size="36" :weight="600">
              {{ Math.round(totalRatings * 100) / 100 }}</Text
            ><star-rating
              :read-only="true"
              :show-rating="false"
              :rating="totalRatings"
              :round-start-rating="false"
            ></star-rating>
          </div>
          <Text>
            Based on {{ reviews.length }} review{{
              reviews.length == 1 ? "" : "s"
            }}</Text
          >
        </div>
        <hr class="my-4 bg-[#EEFEF] opacity-[.3]" />

        <div class="flex flex-col gap-3">
          <div
            v-for="(review) in reviews.slice(
              0,
              showOnlyFirstReview ? 1 : reviews.length
            )"
            :key="review.date + review.orderId"
          >
            <Text :size="16">{{ review.customer }}</Text>
            <div v-if="review.items">
              <div v-for="item in review.items" :key="item.id">
                {{ item.name }} : {{ item.resturant }}
              </div>
            </div>
            <star-rating
              :read-only="true"
              :show-rating="false"
              :star-size="20"
              :round-start-rating="false"
              :rating="review.stars"
            ></star-rating>

            <div class="flex gap-3 my-3">
              <Text :size="14" color="#EEFEF">{{
                formatDate(review.date)
              }}</Text>
              <Text :size="14" color="#EEFEF">
                Order No:
                <span class="underline">{{ review.orderId }}</span></Text
              >
              <Text :size="14" color="#EEFEF">Phone: {{ review.phone }}</Text>
            </div>
            <div>{{ review.comment || "No comment" }}</div>
            <hr class="my-4 bg-[#EEFEF] opacity-[.1]" />
          </div>
        </div>

        <span v-if="!reviews.length">No reviews</span>
      </div>
      <div
        class="bg-white shadow rounded py-4 px-3 w-[29%] gap-3 flex h-[fit-content] flex-col"
      >
        <div
          class="w-full flex items-center justify-between"
          v-for="number in Object.entries(numbers).reverse()"
          :key="number[0]"
        >
          <Text class="block whitespace-nowrap w-[13%]" :size="16"
            >{{ number[0] }} star</Text
          >
          <div
            class="w-[60%] border self-center mx-auto rounded-[24px] h-[7px]"
          >
            <div
              class="bg-[gold] rounded-[24px] h-[7px]"
              :style="{
                width: lengthOfReviewsWithatOneRating
                  ? `${(number[1] / lengthOfReviewsWithatOneRating) * 100}%`
                  : '0%',
              }"
            ></div>
          </div>
          <Text :size="16" class="block w-[7%]">
            {{
              lengthOfReviewsWithatOneRating
                ? `${Math.round(
                    (number[1] / lengthOfReviewsWithatOneRating) * 100
                  )}%`
                : "0%"
            }}</Text
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Text from "./Text.vue";
import StarRating from "vue-star-rating";
import { ref, watch, watchEffect } from "vue";
import { formatDate } from "@/util";

export default {
  components: { Text, StarRating },
  props: {
    name: {
      type: String,
      default: "name",
    },
    reviews: {
      type: Array,
      default: [],
    },
    onClick: {
      type: Function,
      default: () => null,
    },
    showOnlyFirstReview: {
      type: Boolean,
      default: true,
    },
    sorting: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const reviews = ref([]);
    const totalRatings = ref(0);
    const selectedRider = ref(null);
    const showOnlyFirstReview = ref(true);
    const sort = ref("Newest");
    const lengthOfReviewsWithatOneRating = ref(0);
    const numbers = ref({
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
    });

    watchEffect(() => {
      showOnlyFirstReview.value = props.showOnlyFirstReview;
    });

    watchEffect(() => {
      if (props.reviews.length) {
        // reviews.value = props.reviews;
        lengthOfReviewsWithatOneRating.value = props.reviews.filter((x) =>
          parseInt(x.stars)
        ).length;
        sort.value;

        const date_gen = (date) => new Date(date.replaceAll('"', "")).getTime();

        if (sort.value === "Oldest") {
          reviews.value = props.reviews.sort(
            (a, b) => date_gen(a.date) - date_gen(b.date)
          );
        } else if (sort.value === "Newest") {
          reviews.value = props.reviews.sort(
            (a, b) => date_gen(b.date) - date_gen(a.date)
          );
        } else if (sort.value === "Lowest") {
          reviews.value = props.reviews.sort(
            (a, b) => parseFloat(a.stars) - parseFloat(b.stars)
          );
        } else if (sort.value === "Highest") {
          reviews.value = props.reviews.sort(
            (a, b) => parseFloat(b.stars) - parseFloat(a.stars)
          );
        }

        totalRatings.value =
          props.reviews
            ?.map((x) => parseFloat(x.stars))
            .reduce((a, b) => a + b) /
          props.reviews.filter((x) => parseInt(x.stars)).length;

        const getNumberOfSingleStar = (start, end) =>
          props.reviews.filter(
            (x) => parseFloat(x.stars) >= start && parseFloat(x.stars) < end
          ).length;
        numbers.value["1"] = getNumberOfSingleStar(1, 2);
        numbers.value["2"] = getNumberOfSingleStar(2, 3);
        numbers.value["3"] = getNumberOfSingleStar(3, 4);
        numbers.value["4"] = getNumberOfSingleStar(4, 5);
        numbers.value["5"] = getNumberOfSingleStar(5, 5.1);

      }
    });

    return {
      props,
      reviews,
      totalRatings,
      numbers,
      selectedRider,
      showOnlyFirstReview,
      sort,
      formatDate,
      lengthOfReviewsWithatOneRating,
      // sortByRatingFn,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>