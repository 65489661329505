<template>
  <div class="flex gap-2 pl-2">
    <div class="flex items-center gap-1" style="font-size: small">
      {{
        getItemQty({ subItem: addon, mainItem: item }) -
        (addon?.cancelled || 0) 
      }}
      <span
        class="material-symbols-outlined cursor-pointer"
        :class="'text-[green]'"
        style="font-size: 15px"
      >
        <!-- {{ addon?.cancelled ? "cancel" : "check_circle" }} -->
        check_circle
      </span>
    </div>

    <div
      class="flex items-center gap-1"
      style="font-size: small"
      v-if="addon?.cancelled && typeof addon?.cancelled === 'number'"
    >
      {{ addon?.cancelled }}
      <span
        class="material-symbols-outlined cursor-pointer"
        :class="'text-danger'"
        style="font-size: 15px"
      >
        cancel
      </span>
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect } from "vue";
import { getItemQty } from "@/util";


const props = defineProps({
  addon: Object,
  item: Object,
  disabled: Boolean,
});
const item = ref(props.item);
const addon = ref(props.addon);
const disabled = ref(props.disabled);

watchEffect(() => {
  item.value = props.item;
  addon.value = props.addon;
  disabled.value = props.disabled;
});
</script>

<style lang="scss" scoped>
</style>