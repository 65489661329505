import { sum } from ".";




export const canceledItems = (orders = []) => {

    let cancelledList = []
    orders?.forEach((order) => {
        order?.items?.forEach((item) => {
            const itemCancelled = item?.cancelled || item?.canceled;
            if (itemCancelled) {
                cancelledList.push({
                    order,
                    type: "main item",
                    item,
                    itemExtra: null,
                    name: item?.name,
                    num: itemCancelled
                });
            }
            const extras = ["addons", "sides", "customize"];
            extras.forEach((extra) => {
                const itemExtras = item?.[extra] || [];
                itemExtras?.forEach((itemExtra) => {
                    const itemExtraCancelled =
                        itemExtra?.cancelled || itemExtra?.canceled || 0;
                    if (itemExtraCancelled) {
                        cancelledList.push({
                            order,
                            type: `${extra} item`,
                            item,
                            itemExtra,
                            name: itemExtra?.name,
                            num: itemExtraCancelled
                        });
                    }
                });
            });
        });
    });
    const total = sum(cancelledList.map(item => item?.num))
    return { cancelledList, total }
} 