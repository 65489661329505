<template>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M15.9993 2.66699C8.65268 2.66699 2.66602 8.65366 2.66602 16.0003C2.66602 23.347 8.65268 29.3337 15.9993 29.3337C23.346 29.3337 29.3327 23.347 29.3327 16.0003C29.3327 8.65366 23.346 2.66699 15.9993 2.66699ZM22.3727 12.9337L14.8127 20.4937C14.6252 20.6809 14.371 20.7861 14.106 20.7861C13.841 20.7861 13.5868 20.6809 13.3993 20.4937L9.62601 16.7203C9.44004 16.5321 9.33575 16.2782 9.33575 16.0137C9.33575 15.7491 9.44004 15.4952 9.62601 15.307C10.0127 14.9203 10.6527 14.9203 11.0393 15.307L14.106 18.3737L20.9593 11.5203C21.346 11.1337 21.986 11.1337 22.3727 11.5203C22.7593 11.907 22.7593 12.5337 22.3727 12.9337Z"
        fill="#009A49"
      />
    </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>