import { ref } from "vue";

const activeLink = ref("home");
const modal = ref(false)

export default {
  activeLink,
  modal
};

