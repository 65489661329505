<template>
  <layout :hasSearch="false" class="vendor-body" :hasTopNav="false">
    <div class="w-full p-5 flex-col gap-4">
      <header class="flex justify-between">
        <Text :size="24" :weight="600"> Vendors </Text>
        <router-link to="/inventory/vendors/add">
          <ue-button :variant="'md'">Add Vendor </ue-button>
        </router-link>
      </header>
      <div class="w-full flex mt-5 items-center justify-between">
        <div class="search flex items-center gap-3">
          <span class="material-symbols-outlined"> search </span>
          <input type="search" placeholder="Search" v-model="search" />
        </div>
      </div>

      <section class="flex flex-col gap-[25px] pt-[100px]">
        <table>
          <thead>
            <tr>
              <th>Company Name</th>
              <th>Contact</th>
              <th>Address</th>
              <th>Ingredients</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody class="w-full border">
            <tr
              class="rounded-[16px] h-[100px] items-center bg-white justify-between my-4 px-5 border !w-full"
              v-for="vendor in paginatedList"
              :key="vendor.id"
            >
              <td>{{ vendor?.vendor_name }}</td>
              <td>{{ vendor["contact_name"] }}</td>
              <td>{{ vendor?.address }}</td>
              <td>
                {{
                  vendor?.ingredients
                    ?.map((ingredient) => ingredient.name)
                    .splice(0,3)
                    .join(", ") || 'No ingredient'
                }}
              </td>
              <td>
                <ue-button
                  :variant="'md'"
                  @click="() => router.push(`/inventory/vendors/${vendor.id}`)"
                >
                  Edit
                </ue-button>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <Pagination
        class="py-3"
        :currentPage="currentPage"
        :totalPages="totalPages"
        @page-change="goToPage"
      />
    </div>
  </layout>
</template>
  
  <script>
import layout from "@/partials/Layout/layout.vue";
import Text from "@/components/Text.vue";
import { computed, ref, watchEffect } from "vue";
import { get, getDatabase, ref as dbRef } from "firebase/database";
import { getImg } from "@/util";
import { useRouter } from "vue-router";
import Pagination from "@/components/Pagination.vue";
import UeButton from "@/components/UeButton.vue";
export default {
  components: { layout, Text, Pagination, UeButton },
  setup() {
    const openModal = ref(false);
    const search = ref("");
    const router = useRouter();

    const vendors = ref([]);

    watchEffect(async () => {
      search.value;
      const val = search.value.toLowerCase();
      const data = await get(dbRef(getDatabase(), "inventory/vendors"));

      if (data.val()) {
        let list = Object.values(data.val()).filter(
          (value) =>
            value.vendor_name.toLowerCase().includes(val) ||
            value.contact_name.toLowerCase().includes(val) ||
            value.contact_phone.toLowerCase().includes(val) ||
            value.address.toLowerCase().includes(val)
        );
        vendors.value = list;
      }
    });

    const perPage = 15;
    const currentPage = ref(1);
    const totalPages = computed(() =>
      Math.ceil(vendors.value?.length / perPage)
    );
    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };
    const paginatedList = computed(() => {
      const startIndex = (currentPage.value - 1) * perPage;
      const endIndex = startIndex + perPage;
      return vendors.value?.slice(startIndex, endIndex);
    });
    return {
      openModal,
      vendors,
      search,
      getImg,
      router,
      goToPage,
      currentPage,
      paginatedList,
      totalPages,
    };
  },
};
</script>
  
  <style lang="scss" scoped>
.vendor-body {
  img {
    width: 64px;
    height: 64px;
  }
  width: 100%;
  .search {
    border-radius: 30px;
    background: #fff;
    width: 80%;
    height: 39px;
    padding: 0 20px;
    display: flex;
    flex-shrink: 0;
    input {
      outline: none;
      height: 100%;
      width: 100%;
    }
  }
  .data_item {
    display: flex;
    justify-content: center;
  }
  .selecttttt {
    border: 1px black solid;
    border-radius: 4px;
  }
  select::-ms-expand {
    display: block !important;
  }
  table {
    border-collapse: separate;
    border-spacing: 0 1.4em;

    td:first-child {
      border-left-style: solid;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    td:last-child {
      border-right-style: solid;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
  td {
    font-size: 17px;
    font-weight: 500 !important;
  }
  th {
    font-size: 17px;
    font-weight: 600 !important;
    padding-left: 20px;
  }
  td {
    padding-left: 20px;
  }
}
</style>