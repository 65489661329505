<template>
  <layout>
    <div class="flex justify-end">
      <time-drop-down :getDate="(e) => (timeFrame = e)" />
    </div>
    <GoogleMap
      api-key="AIzaSyAUR5lKBWKOCHVAe1c5iN4e5vo0OL7_GxY"
      style="width: 100%; height: 95%"
      :center="center"
      :zoom="13"
    >
      <Marker v-if="kitchenMarkOptions" :options="kitchenMarkOptions" />

      <Marker
        v-for="options in ridersLocationMarkOptions"
        :key="options.position.lat + options.label"
        :options="options"
      />
      <!-- <Marker
        v-for="options in deliveryMarkers"
        :key="options.position.lat + options.label"
        :options="options"
      /> -->
    </GoogleMap>
    <div ref="map"></div>
  </layout>
</template>
  
  <script setup >
import Layout from "@/partials/Layout/layout.vue";
import { getOrdersByTimeFrame, riderMapCircleIcon } from "@/util";
import { store } from "@/views/store/store";
import axios from "axios";
import { getDatabase, onValue, ref as dbRef } from "firebase/database";
import { defineComponent, onMounted, ref, watchEffect } from "vue";
import { useRoute } from "vue-router";
import { GoogleMap, Polyline, Marker } from "vue3-google-map";
import TimeDropDown from "../../Operator/Dashboard/TimeDropDown.vue";
// import { initMap } from "../OrderBatch/direction";
import img from "@/assets/images/icons8-map-pin-48.png";
import distance from "./distance";
import { httpsCallable } from "firebase/functions";
import { projectFunctions } from "@/firebase/config";
// import TimeDropDown from "../../Operator/Dashboard/TimeDropDown.vue"
// console.log(document.getElementById('map'))
// window.initMap = initMap()

const props = defineProps({
  order: { type: Object, default: {} },
  defaultRider: { type: Object, default: false },
});
const ueKitchen = ref({ lat: 6.45392, lng: 3.47739 });
const orders = ref([]);
const ridersLocationMarkOptions = ref([]);
const center = ref({});
const map = ref(null);
const timeFrame = ref({});
const directions = ref({});
const deliveryMarkers = ref([]);

const distances = ref({});

const kitchenMarkOptions = {
  position: { lat: 6.45392, lng: 3.47739 },
  label: "",
  title: "Urban Eats kitchen",
  icon: "https://img.icons8.com/color/48/map-pin.png",
};
// onMounted(()=>{
//   add({ userId:'', orderId:'' });
// })

center.value = { ...ueKitchen.value }; //Lagos center coordinates

const get_order_arrived_status = (order_id) => {
  const order_arrived_status = store.state.orders.find(
    (order) => order.id == order_id
  );
  // console.log(order_arrived_status.status.Arrived)
  if (order_arrived_status) return order_arrived_status.status.Arrived;
};

watchEffect(async () => {
  onValue(dbRef(getDatabase(), `courier`), (snap) => {
    if (snap.val()) {
      const riders = Object.values(snap.val()).filter(
        (x) => x.status === "online" && x.live_location
      );
      riders.map((rider) => {
        const name = rider.first_name + " " + rider.last_name;
        const { latitude, longitude } = rider.live_location;
        const all_trips = Object.keys(rider?.accepted_orders);
        const currently_assigned_trips = all_trips
          .map(get_order_arrived_status)
          // console.log(currently_assigned_trips)
          .filter((status) => {
            if (status) return !status.state;
          });

        ridersLocationMarkOptions.value.push({
          position: { lat: latitude, lng: longitude },
          label: `${name
            ?.split(" ")
            .map((x) => x[0])
            .join("")}`,
          title: `${name} (${currently_assigned_trips.length})`,
          icon: riderMapCircleIcon,
        });
      });
    }
  });
});

// watchEffect(async () => {

  // orders.value.forEach((currentOrder) => {
  //   // const delvA = ;
  //   const currentOrderlat = currentOrder?.customer?.deliveryAddress?.latitude,
  //     lng = currentOrder?.customer?.deliveryAddress?.longitude;
  //   const currentOrderId = currentOrder?.id;
  //   const currentOrderShortId = currentOrderId?.slice(
  //     currentOrderId?.length - 4,
  //     currentOrderId?.length
  //   );
  //   orders.value.forEach((matchingOrder) => {
  //     const matchingOrderlat =
  //         matchingOrder?.customer?.deliveryAddress?.latitude,
  //       lng = matchingOrder?.customer?.deliveryAddress?.longitude;
  //     const matchingOrderId = matchingOrder?.id;
  //     const matchingOrderShortId = matchingOrderId?.slice(
  //       matchingOrderId?.length - 4,
  //       matchingOrderId?.length
  //     );
  //     console.log(`${currentOrderShortId} - ${matchingOrderShortId}`);
  //   });
    // distances.value[] =

  // });

  // const locations = orders.value?.map(
  //   (order) =>
  //     `${order?.customer?.deliveryAddress?.latitude},${order?.customer?.deliveryAddress?.longitude}`
  // );
  // console.log(locations);





</script>
