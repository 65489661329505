
import { projectFunctions } from "@/firebase/config";
import { sum } from "@/util";
import dayjs from "dayjs";
import { get, getDatabase, ref as dbRef, set } from "firebase/database";
import { httpsCallable } from "firebase/functions";
import { useToast } from 'vue-toast-notification';
import createPersistedState from "vuex-persistedstate";
import { createStore } from "vuex";
import { store } from "./store";

// const router = useRouter()
const toast = useToast({ position: 'top-right' })

export const user_location = createStore({
    state() {
        return {
            location: {}

        }
    },
    mutations: {

        updateLocation: async (state, payload) => {
            // console.log(payload)
            state.location = payload
            const uid = (store.state.user?.uid)
            // console.log(`admin_users/${uid}/coords`)
            if (payload) {
                const coords = ({ latitude: payload.latitude, longitude: payload.longitude })
                // console.log(coords)
                try {
                    const res = await set(
                        dbRef(getDatabase(), `admin_users/${uid}/coords`),
                        coords
                    );
                    // console.log(res, uid)
                } catch (error) {
                    console.log(error)
                }
            }
        }


    },
    actions: {
        // updateLocation: async (state, payload) => {
        //     // console.log(payload)
        //     state.location = payload
        //     const uid = (store.state.user?.uid)
        //     console.log(`admin_users/${uid}/coords`)
        //     if (payload) {
        //         try {
        //             const res = await set(
        //                 dbRef(getDatabase(), `admin_users/${uid}/coords`),
        //                 payload
        //             );
        //             console.log(res)
        //         } catch (error) {
        //             console.log(error)
        //         }
        //     }
        // }

    },
    plugins: [createPersistedState()]
})