
<template>
  <head>
    <link
      rel="stylesheet"
      href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
    />
  </head>
  <div class="ue-contaitner rounded bg-light w-full h-full mb-4">
    <div class="d-flex justify-content-between orderClass p-3 w-full">
      <div class="w-full">
        <div class="flex w-full items-center justify-between">
          <h1>
            <Text :size="27" :weight="700"> Recent Orders</Text>
            <span class="capitalize">{{ selectId }}</span>
          </h1>
          <div>
            <router-link to="/operator/orders">
              <button class="button" :class="{ active: status === 'active' }">
                View all orders
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <section class="orderList !p-0 !bg-white">
      <div class="responsize-table">
        <table class="order-table">
          <thead>
            <tr>
              <th class="table-header"></th>
              <th class="table-header">Order ID</th>
              <th class="table-header">Date</th>
              <th class="table-header">Customer Name</th>
              <th class="table-header">Service</th>
              <th class="table-header">Courier</th>
              <th class="table-header">Status</th>
              <th class="table-header">Time Left</th>
              <th class="table-header">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(order, index) in paginatedOrders"
              :key="index"
              class="table-row"
              :class="index % 2 === 0 ? 'even-row' : 'odd-row'"
            >
              <td class="table-data">{{ index + 1 }}</td>
              <td class="table-data"><OrderID :order="order" /></td>
              <td class="table-data text-nowrap">
                {{ formatDate(order.date) }}
              </td>
              <td class="table-data">
                <!-- {{ order.customer.firstName + " " + order.customer.lastName }} -->
                <CustomerName :customer="order?.customer" />
              </td>
              <td class="table-data">{{ order.locationType }}</td>
              <td class="table-data" v-if="order.locationType === 'Delivery'">
                {{ order.courier.name ? order.courier.name : "Unassigned" }}
              </td>
              <td class="table-data" v-else>N/A</td>
              <td class="table-data">
                <status
                  :items="order.items"
                  :status="order.status"
                  :order="order"
                />
              </td>

              <td class="table-data">{{ diffByStatus(order) }}</td>

              <td class="table-data">
                <a
                  @click="router.push(`/operator/orders/${order?.id}`)"
                  :href="'#'"
                  class="action-link !underline"
                  >View</a
                >
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <Pagination
            class="py-3"
            :currentPage="currentPage"
            :totalPages="totalPages"
            @page-change="goToPage"
          /> -->
      </div>
    </section>
  </div>
</template>
        
      <script>
import { ref, computed, watchEffect } from "vue";
import Pagination from "@/components/Pagination.vue";
// import OrderModal from "./OrderModal.vue";
import TopNav from "@/components/TopNav.vue";
import Modal from "@/components/Modal.vue";
import { getData } from "@/firebase/config";
import { useRoute, useRouter } from "vue-router";
import InputField from "@/components/InputField.vue";
import Text from "@/components/Text.vue";
import {
  diffByStatus,
  formatDate,
  getOrderID,
  getUeOrderId,
  sum,
} from "@/util";
import Status from "@/components/Status.vue";
import Layout from "@/partials/Layout/layout.vue";
import { store } from "@/views/store/store";
import OrderID from "@/components/OrderID.vue";
import CustomerName from "@/components/CustomerName.vue";

export default {
  components: {
    TopNav,
    Pagination,
    // OrderModal,
    Modal,
    InputField,
    Text,
    Status,
    Layout,CustomerName,
    OrderID,
  },
  setup() {
    const orders = ref([]);
    // const status = ref("active");
    // const message = ref("Active Orders");
    // const kitchen = getData("restaurants");
    const selectedOrder = ref({});
    const route = useRoute();
    const router = useRouter();
    const selectId = ref(route.params.id);
    // const selectedKitchen = ref("");
    // const searchValue = ref("");
    // const selectKitchen = (e) => {
    //   selectedKitchen.value = e?.target?.value;
    // };

    // const search = (e) => {
    //   // console.log(e)
    //   searchValue.value = e;
    // };
    // watchEffect(() => {
    //   selectId.value = route.params.id;
    //   if (selectId.value) {
    //     selectedOrder.value = store.state.orders.find(
    //       (x) => x.id === selectId.value
    //     );
    //   }
    // });
    watchEffect(() => {
      orders.value = store.state.orders.filter(
        (order) => {
          if (order?.locationType == "Delivery") {
            return (
              !order.status?.Delivered?.state &&
              !order?.status.Dispatched?.state
            );
          } else {
            return !order?.status?.Pickup?.state;
          }
        }
        // ||
        //
      );
      // console.log(orders.value.map((order) => order.status.Dispatched));
      // console.log(first)
    });

    // const select = (type) => {
    //   status.value = type;
    //   message.value = type === "active" ? "Active Orders" : "Completed Orders";
    // };

    //filter by kitchen and status
    // watchEffect(() => {
    //   // console.log(status.value, selectedKitchen.value);
    //   let statusFilter = store.state.orders.filter((x) => {
    //     return status.value === "active"
    //       ? !x.status?.Delivered?.state || !x?.status?.Pickup?.state
    //       : sum(x.items?.map((x) => x.prepared)) ===
    //           sum(x.items.map((x) => x.quantity)) -
    //             sum(x.items.map((x) => x.canceled));
    //   });

    //   if (searchValue.value) {
    //     orders.value = store.state.orders.filter(
    //       (x) =>
    //         x.id.includes(searchValue.value) ||
    //         x.customer?.name
    //           ?.toLowerCase()
    //           ?.includes(searchValue.value.toLowerCase())
    //     );
    //   } else {
    //     orders.value = selectedKitchen.value
    //       ? statusFilter.filter(
    //           (x) =>
    //             x.items.filter((y) => y.resturant === selectedKitchen.value)
    //               .length
    //         )
    //       : statusFilter;
    //   }
    // });
    // watchEffect(() => {

    // });

    // Pagination
    const ordersPerPage = 10;
    const currentPage = ref(1);
    const totalPages = computed(() =>
      Math.ceil(orders.value.length / ordersPerPage)
    );

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };

    const paginatedOrders = computed(() => {
      const startIndex = (currentPage.value - 1) * ordersPerPage;
      const endIndex = startIndex + ordersPerPage;
      return orders.value.slice(startIndex, endIndex);
    });

    return {
      ordersPerPage,
      currentPage,
      totalPages,
      goToPage,
      paginatedOrders,
      // select,
      // status,
      // message,
      sum,
      // selectedOrder,
      // kitchen,
      // selectId,
      // selectKitchen,
      router,
      // search,
      formatDate,
      getUeOrderId,
      diffByStatus,
      getOrderID,
    };
  },
};
</script>
        
        <style scoped>
/* active and completed button */
.button {
  padding: 10px 30px;
  border-radius: 5px;
  border: 1px #f60100 solid;
  background-color: #fff;
  color: #f60100;
  margin-left: 20px;
}
.button.active {
  background: linear-gradient(180deg, #ff9103 0%, #f60100 100%);
  color: white;
  border: none;
}

.pagination-container {
  display: flex;
  column-gap: 10px;
}
.paginate-buttons {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(217, 217, 217);
  color: black;
}
.paginate-buttons:hover {
  background-color: #d8d8d8;
}
.active-page {
  background-color: #3498db;
  border: 1px solid #3498db;
  color: white;
}
.active-page:hover {
  background-color: #2988c8;
}

.order-table {
  width: 100%;
  border-collapse: collapse;
  /* border: 1px solid #ccc; */
}

.table-row {
  height: 60px;
}

.table-row.even-row {
  background-color: #f2f2f2;
}

.table-row.odd-row {
  background-color: #fff;
}

.status-button {
  padding: 5px 10px;
  background: linear-gradient(
    202deg,
    rgba(255, 145, 3, 0.24) 0%,
    rgba(246, 1, 0, 0.24) 100%
  );

  border: none;
  color: #333;
  font-weight: bold;
}

.status-button2 {
  padding: 5px 10px;
  background: linear-gradient(
    202deg,
    rgba(74, 224, 117, 0.428) 0%,
    rgba(0, 246, 78, 0.24) 100%
  );

  border: none;
  color: #333;
  font-weight: bold;
}

.action-link {
  text-decoration: none;
  color: #f60100;
}
</style>