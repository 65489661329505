import { getDatabase, set, ref as dbRef, get } from "firebase/database";

export const removeUserPendingTransaction = async ({ customer_uid, order, order_id }) => {

    // const res = (await get(dbRef(getDatabase(), `users/${customer_uid}/pendingTransaction`))).val()
    const transaction_path = `users/${customer_uid}/pendingTransaction`

    const pendingTransaction = (await get(dbRef(getDatabase(), transaction_path))).val()
    console.log({ pendingTransaction,customer_uid })
    try {
        const pendingTransaction_after_Removed =
            pendingTransaction?.filter(
                (transaction) => transaction?.id !== (order?.id || order_id)
            ) || null;

        const payload = pendingTransaction_after_Removed?.length
            ? pendingTransaction_after_Removed
            : null
        // console.log({payload})
        await set(dbRef(getDatabase(), transaction_path), payload);
    } catch (error) {
        console.log(error)
        throw error
    }
}