<template>
  <div class="flex justify-between flex-input w-[100%] ">
    <Text class="label-text" :size="20" :weight="600">{{ props.label }}</Text>
    <div class="flex gap-3 w-[70%]">
      <div class="w-[70%]"><slot /></div>
      <Text class="unit-text whitespace-nowrap" :size="20" :weight="400">
        {{ props.unit }}</Text
      >
    </div>
  </div>
</template>

<script>
import Text from "@/components/Text.vue";

export default {
  props: {
    label: String,
    unit: String,
  },
  setup(props) {
    return {
      props,
    };
  },
  components: { Text },
};
</script>

<style lang="scss" scoped>
.flex-input {
  gap: 12px;
  .label-text {
    width: 50%;
  }
  .unit-text {
    width: 30%;
  }
}
</style>