<template>
  <div
    class="flex items-center justify-between p-5 my-4 rounded-[16px]"
  >
    <div class="w-50 bg-white  gap-3 items-center flex h-[100px] justify-evenly rounded-[16px]">
      <Text :weight="600" :size="21">Select Courier</Text>
      <input-field :type="'select'" class="w-[50%]" />
    </div>

<v-btn rounded="16px"/>
  </div>
</template>

<script>
import Text from "@/components/Text.vue";
import InputField from "@/components/InputField.vue";
export default {
  components: { Text, InputField },
  setup() {},
};
</script>

<style lang="sass" scoped>
</style>