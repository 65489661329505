<template>
  <div class="mt-3">
    <Text :size="17" :weight="600"> Extra Permissions </Text>
    <div class="flex gap-3 flex-wrap">
      <check-box
        v-for="permission in extra_permissions"
        @input="() => update_permission(permission.key)"
        :key="permission.key"
        :id="permission.key"
        :label="permission.display_name"
        :checked="admin?.extra_permissions?.includes(permission.key)"
      />
    </div>
  </div>
</template>

<script setup>
import Text from "@/components/Text.vue";
import CheckBox from "@/components/CheckBox.vue";
import { ref, watchEffect } from "vue";
import { extra_permissions_keys } from "./extra_permissions";

const extra_permissions = [
  {
    display_name: "Top up customer's wallet",
    key: extra_permissions_keys.top_wallet,
    parent: "",
  },
  {
    display_name: "Deduct customer's wallet",
    key: extra_permissions_keys.deduct_wallet,
  },
  { display_name: "Make customer vip", key: extra_permissions_keys.vip },
  { display_name: "Edit customer", key: extra_permissions_keys.edit_customer },
  { display_name: "Start trip", key: extra_permissions_keys.start_trip },
  { display_name: "Mark as arrived", key: extra_permissions_keys.arrive_trip },
  { display_name: "Mark as delivered", key: extra_permissions_keys.deliver },
  {
    display_name: "Complete Meal Preparation",
    key: extra_permissions_keys.complete_meal_preparation,
  },
  {
    display_name: "Restore Canelled Order",
    key: extra_permissions_keys.restore_cancelled_order,
  },
  {
    display_name: "Cancel single items",
    key: extra_permissions_keys.cancel_single_item,
  },
  {
    display_name: "Override confirmationm odal",
    key: extra_permissions_keys.override_confirmation_modal,
  },
  {
    display_name: "Edit Meal Stock",
    key: extra_permissions_keys.edit_meal_stock,
  },
  {
    display_name: "Edit Meal Price",
    key: extra_permissions_keys.edit_meal_price,
  },
  {
    display_name: "Cancel order after dispatch",
    key: extra_permissions_keys.cancel_order_after_dispatch,
  }
];

const props = defineProps({
  admin: Object,
  triggerSave: Function,
});

const admin_user = ref({});

const update_permission = (key) => {
  let permissions = admin_user.value?.extra_permissions || [];
  const isExist = permissions.filter((permission) => permission == key).length;
  if (isExist) {
    admin_user.value.extra_permissions = permissions.filter(
      (permission) => permission != key
    );
  } else {
    admin_user.value?.extra_permissions?.push(key);
  }
  //   console.log(admin_user.value.extra_permissions,'jbb');
  const transport = admin_user.value.extra_permissions;

  props.triggerSave(transport);
};

// const save = async () => {
//   const path = `admin_users/${admin_user.value.uid}/extra_permissions/`;
//   const value = admin_user.value.extra_permissions;
//   console.log({path, value});
// };

watchEffect(() => {
  admin_user.value = props.admin;
  if (!props.admin?.extra_permissions?.length)
    admin_user.value.extra_permissions = [];
  //  admin_user.value.extra_permissions =  props.admin?.extra_permissions
  //   console.log(admin_user.value);
});

// watchEffect(() => {
//   triggerSave.value = props.triggerSave;
//   if (triggerSave.value) {
//     console.log("trigger save");

//     triggerSave.value = false;
//   }
// });
</script>

<style lang="scss" scoped>
</style>