import { get, ref as dbRef, getDatabase, set, remove } from "firebase/database"

export const removeOrderFromBatch = async (order) => {

    const batchesRef = dbRef(getDatabase(), `batching/`)
    const batches = (await get(batchesRef)).val()
    // if(batchesRef.) return 

    let index = null
    let removeType = null
    let batchName = ''

    for (const key in batches) {
        if (Object.prototype.hasOwnProperty.call(batches, key)) {
            const batch = batches[key];

            for (let i = 0; i < batch.length; i++) {
                const element = batch[i];
                if (element?.orderId === order?.id) {
                    removeType = 'whole-batch'
                    index = i
                    batchName = key
                }
                if (Array.isArray(element?.orderId) && element?.orderId?.includes(order?.id)) {
                    removeType = 'order-in-batch'
                    index = i
                    batchName = key;
                }
            }
            // console.log({ index, removeType, batchName })
            if (index >= 0 && removeType) {
                const batchRef = dbRef(getDatabase(), `batching/${batchName}`)
                let newBatch = batch
                if (removeType === 'order-in-batch') {
                    newBatch[index].orderId = newBatch[index]?.orderId?.filter(orderId => orderId !== order?.id)
                }
                if (removeType === 'whole-batch') {
                    newBatch = batch?.filter(item => item.orderId !== order?.id)
                }
                console.log({ batch, newBatch })
                if (newBatch?.length) {
                    await set(batchRef, newBatch)
                } else {
                    await set(batchRef, null)
                }
                await remove(dbRef(getDatabase(), `orders/${order?.id}/batch`))
            }
            if (removeType) break;
        }
    }
    return;

}