<template>
  <br />
  <Text :weight="600" :size="18">Location range</Text>
  <div class="flex gap-3 flex-wrap">
    <div v-for="(value, key) in delivery_range" :key="key">
      <span class="capitalize font-bold"> {{ key }}</span> <br />
      <div v-for="(value2, key2) in value" :key="key2">
       {{ key2 }} :  {{ value2?.join(", ") }} 
      </div>
    </div>
    <!-- <div
      class="border p-3 w-[300px] rounded mb-2"
      v-for="({ key, value }) of  delivery_range"
      :key="key"
    >
      <Text :weight="600" class="capitalize"> {{ key }}</Text>
      <hr />
      <div class="mt-3">
        Delivery time
        <input class="rounded border" type="text" />
      </div>
    </div> -->
  </div>
  <!-- <ue-button class="w-[200px] p-2" @click="saveChanges">Save</ue-button> -->
  <br /><br />
</template>
  
  <script setup>
import Text from "@/components/Text.vue";
import UeButton from "@/components/UeButton.vue";
import { get, getDatabase, ref as dbRef, set } from "firebase/database";
import { onMounted, ref } from "vue";
import { useToast } from "vue-toast-notification";

const delivery_range = ref([]);
const isLoading = ref(false);

const fetchData = async () => {
  const res = await get(dbRef(getDatabase(), "range"));
  if (res?.val()) delivery_range.value = res?.val();
};

onMounted(() => {
  fetchData();
  //   console.log(delivery_locations.value);
});
const toast = useToast({ position: "top-right" });

const saveChanges = async () => {
  console.log(delivery_range.value);
  isLoading.value = true;
  try {
    const payload = {
      island: {
        latitude: [6.388276, 6.561045],
        longitude: [3.3603171, 3.6555],
      },
      lagos: { latitude: [6.393, 6.702], longitude: [3.105, 3.795] },
    };
    // await set(dbRef(getDatabase(), "range/"), payload);
    // toast.success("Success");
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
};
</script>
  
  <style lang="scss" scoped>
</style>