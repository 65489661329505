<template>
  <v-dialog v-model="addPartnerModal">
    <div
      class="bg-white rounded p-2 w-[400px] mx-auto flex gap-3 items-center flex-col"
    >
      <Text :size="25" weight="600" class="text-center">Add Partner</Text>
      <div class="w-full">
        <label for="">Partner name</label>
        <input class="ue-input-field" v-model="formValues.name" />
      </div>
      <ue-button @click="addPartner"> Add </ue-button>
    </div>
  </v-dialog>

  <ue-button @click="() => (addPartnerModal = true)" :disabled="loading">
    {{ loading ? "Loading" : "Add partner" }}</ue-button
  >
</template>

<script setup>
import Text from "@/components/Text.vue";
import UeButton from "@/components/UeButton.vue";
import {
  push,
  ref as dbRef,
  getDatabase,
  update,
  set,
} from "firebase/database";
import { ref } from "vue";
const props = defineProps({
    // onSuccess:''
})
const addPartnerModal = ref(false);
const formValues = ref({
  name: "",
  key: "",
});
const loading = ref(false);
const addPartner = async () => {
  loading.value = true;
  try {
    const { key } = await push(
      dbRef(getDatabase(), `/logistics_partners/`),
      formValues.value
    );
    await set(dbRef(getDatabase(), `/logistics_partners/${key}/key`), key);
    loading.value = false;
    addPartnerModal.value = false;
  } catch (error) {
    console.log(error);
    loading.value = false;
  }
};
</script>

<style lang="scss" scoped>
</style>