
<template>
  <link
    rel="stylesheet"
    href="https://cdn.jsdelivr.net/npm/iconsax-font-icon@1.1.0/dist/icons.min.css"
  />
  <router-view :key="id">
    <div class="w-full progression-bar mb-3" id="progression-bar">
      <div class="!flex mx-auto border bar-container !justify-center">
        <div
          class="w-100 min-w-[150px] !px-0 mx-0 justify-center"
          v-for="({ label, time, state, icon }, index) in headerIcons"
          :key="label + id"
        >
          <div :class="'divivder flex items-center '">
            <div class="state" :class="state ? 'state-active' : ''">
              <video-tick
                :class="`${state ? 'done' : 'undone'}`"
                v-if="icon === 'VideoTick'"
              />
              <bag-tick
                :class="`${state ? 'done' : 'undone'}`"
                v-if="icon === 'BagTick'"
              />
              <BoxTick
                @click="
                  () =>
                    store.dispatch('setAssembledStatus', { orderId: order?.id })
                "
                :class="`${state ? 'done' : 'undone'}`"
                v-if="icon === 'BoxTick'"
              />
              <truck-fast
                :class="`${state ? 'done' : 'undone'}`"
                v-if="icon === 'TruckFast'"
              />
              <truck-tick
                :class="`${state ? 'done' : 'undone'}`"
                v-if="icon === 'TruckTick'"
              />
            </div>
            <div
              v-if="index !== headerIcons.length - 1"
              class="bg-[#ff9103] h-[5px] w-100 min-w-[20px]"
            ></div>
          </div>
          <div style="padding-left: 1rem; margin-top: 1rem">
            <h4 class="">{{ label }}</h4>
            <h6 v-if="time">{{ time }}</h6>
          </div>
        </div>
      </div>
    </div>
  </router-view>
</template>

<script>
import { ref, watchEffect } from "vue";
import { ref as dbRef, getDatabase, onValue } from "firebase/database";
import { formatDate, sum } from "@/util";
import { useRoute } from "vue-router";
import VideoTick from "@/assets/vuesvg/VideoTick.vue";
import BagTick from "@/assets/vuesvg/BagTick.vue";
import BoxTick from "@/assets/vuesvg/BoxTick.vue";
import TruckFast from "@/assets/vuesvg/TruckFast.vue";
import TruckTick from "@/assets/vuesvg/TruckTick.vue";
import { store } from "@/views/store/store";
export default {
  props: {
    order: Object,
  },
  components: {
    VideoTick,
    BagTick,
    BoxTick,
    TruckFast,
    TruckTick,
  },

  setup(props, components) {
    const order = ref(props.order);
    const prePro = ref("0/0");
    const assem = ref("0/0");
    const route = useRoute();
    const { id } = route.params;
    // console.log(route.params);

    watchEffect(() => {
      order.value = props.order;
      onValue(dbRef(getDatabase(), "orders/" + id), (snapshot) => {
        if (snapshot.val()) {
          const { status, items } = snapshot.val();
          prePro.value = `(${sum(items.map((x) => x.prepared))}  / ${
            sum(items.map((x) => x.quantity)) -
            sum(items.map((x) => x.cancelled || x?.canceled || 0))
          })`;
          assem.value = `(${
            status?.Prepared?.state
              ? sum(items.map((x) => x.quantity)) -
                sum(items.map((x) => x.cancelled || x?.canceled || 0)) -
                sum(items?.filter((x) => !x.assembled).map((x) => x.prepared))
              : 0
          } / ${
            sum(items.map((x) => x.quantity)) -
            sum(items.map((x) => x.cancelled || x?.canceled || 0))
          })`;
          // console.log(items,
          //   sum(items.map((x) => x.quantity)) ,
          //     (items.map((x) => x.cancelled || x?.canceled))
          // );
        }
      });
    });

    const headerIcons = ref([]);
    const isDelivery = ref(order.value.locationType === "Delivery");

    watchEffect(async () => {
      order.value = props.order;
      onValue(
        dbRef(getDatabase(), "orders/" + route.params.id),
        async (snapshot) => {
          await snapshot.val();
          if (snapshot.val()) {
            const { status, date, locationType } = snapshot.val();
            isDelivery.value = locationType === "Delivery";

            headerIcons.value = [
              {
                label: status.Confirmed ? "Confirmed" : "Confirmed",
                time: formatDate(status.Confirmed?.time),
                state: status?.Confirmed?.state,
                icon: "VideoTick",
              },
              {
                label: `Prepared ${prePro.value}`,
                time: status.Prepared?.time,
                state: status.Prepared.state,
                icon: "BagTick",
              },
              {
                label: `Assembled ${assem.value}`,
                time: status.Assembled?.time,
                state: status.Assembled?.state,
                icon: "BoxTick",
              },
              {
                label: isDelivery.value ? "Dispatched" : "Picked Up",
                time: isDelivery.value
                  ? status?.Dispatched?.time
                  : status?.Pickup?.time,
                state: isDelivery.value
                  ? status?.Dispatched?.state
                  : status?.Pickup?.state,
                icon: "TruckFast",
              },
            ];
            isDelivery.value
              ? headerIcons.value.push({
                  label: `Delivered`,
                  time: status.Delivered?.time,
                  state: status.Delivered?.state,
                  icon: "TruckTick",
                })
              : headerIcons.value;
          }
        }
      );
    });

    return {
      headerIcons,
      components,
      isDelivery,
      order,
      id,
      route,
      store,
    };
  },
};
</script>

<style lang="scss" scoped>
.progression-bar {
  @media screen and (max-width: 992px) {
    // overflow-x: auto;
  }
}
.bar-container {
  padding: 1.5rem 0%;
  background-color: #fff;
  margin: 0.5rem 3rem;
  border-radius: 20px;
  padding-left: 10%;
  // background: red;
  // min-width: 450px;
  @media screen and (max-width: 600px) {
    // padding: 1.5rem 100px !important;
    // background-color: #fff;
    margin-left: -100px;
    // // padding-left:;
    padding-left: 100px;
    overflow-x: auto;
    justify-content: center;
    // max-width: 450px;
  }
}
.done {
  stroke: #ffffff !important;
  // display: none;
}
.undone {
  stroke: #ff9103 !important;
  // display: none;
}
</style>