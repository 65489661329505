
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getFunctions } from "firebase/functions";
import { getAuth, OAuthProvider, GoogleAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getAnalytics } from 'firebase/analytics'
import { useDatabaseList, useDatabaseObject } from "vuefire";
import { ref as dbRef } from "firebase/database";

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_apiKey,
    authDomain: process.env.VUE_APP_FIREBASE_authDomain,
    databaseURL: process.env.VUE_APP_FIREBASE_databaseURL,
    projectId: process.env.VUE_APP_FIREBASE_projectId,
    storageBucket: process.env.VUE_APP_FIREBASE_storageBucket,
    messagingSenderId: process.env.VUE_APP_FIREBASE_messagingSenderId,
    appId: process.env.VUE_APP_FIREBASE_appId,
    measurementId: process.env.VUE_APP_FIREBASE_measurementId
};

export const app = initializeApp(firebaseConfig);
export const projectAuth = getAuth(app);
export const projectStorage = getStorage(app);
export const projectAppleAuth = new OAuthProvider('apple.com');
export const projectGoogleAuth = new GoogleAuthProvider();

export const projectDatabase = getDatabase(app);

export const projectFunctions = getFunctions(app);
export const projectAnalytics = getAnalytics(app)


export const getData = name => {
    if (name) return useDatabaseList(dbRef(projectDatabase, name))
    return {}
};
// export const getDataObj = name => useDatabaseObject(dbRef(projectDatabase, name));


export const firebaseApp = initializeApp(firebaseConfig);