
import { projectFunctions } from "@/firebase/config";
import { sum } from "@/util";
import dayjs from "dayjs";
import { get, getDatabase, ref as dbRef, set, child, query, orderByChild, limitToFirst, startAt, equalTo } from "firebase/database";
import { httpsCallable } from "firebase/functions";
import { useToast } from 'vue-toast-notification';
import createPersistedState from "vuex-persistedstate";
import { createStore } from "vuex"
import { getMealPointPrice } from "@/util/getMealPointPrice";
import { store } from "./store";
// import {
//     equalTo,
//     getDatabase,
//     orderByChild,
//     query,
//     ref as dbRef,
//     get,
//   } from "firebase/database";
// import { useRouter } from "vue-router";




// const router = useRouter()
const toast = useToast({ position: 'top-right' })

export const customer_store = createStore({
    state() {
        return {
            customers: [],
            failReg: [],
            customer_orders: {},

        }
    },
    mutations: {
        updateUsers: (state, list) => {
            // console.log({ list })
            state.customers = list
        },
        saveFailRegs: (state, list) => {
            // console.log({list})
            state.failReg = list
        }


    },
    actions: {
        async getCustomers({ commit },) {

            const giveDateToCustomer = (customer) => {
                if (customer?.created) return customer;
                if (!customer?.created)
                    return {
                        ...customer,
                        created: "2023-11-17T12:00:24.841+01:00",
                    };
            };

            const getCustomersOrders = (uid, customer) => {
                if (customer) {
                    const orders = (customer?.order_history?.processing?.orders || [])?.concat(customer?.order_history?.completed?.orders || [])
                    const amount_spent = (customer?.order_history?.processing?.amount || 0) + (customer?.order_history?.completed?.amount || 0)
                    return { amount_spent, number_of_orders: orders.length || 0, orders };
                }
                return { amount_spent: 0, number_of_orders: orders?.length || 0, orders: [] };


            };



            const res = await get(dbRef(getDatabase(), "users"));
            let users = Object.values(res.val())
                .filter(customer => customer?.firstName && customer?.lastName)
                .map(giveDateToCustomer)
                .map(user => ({ ...user, ...getCustomersOrders(user.uid, user) }))
            // console.log()
            let months = {}
            // users?.map(user => dayjs(user?.created?.replaceAll('"', '')).format('YYYY-MMM')).forEach(element => {
            //     if (!months[element]) months[element] = 1
            //     months[element]++
            // });

            // console.log(months)

            commit('updateUsers', users)
        },
        async setVip({ state }, { uid, status, onSuccess }) {
            console.log(uid)
            try {
                await set(dbRef(getDatabase(), `users/${uid}/isVip`), status)
                toast.success("Successful")
                onSuccess(status)
            } catch (error) {

            }

        },

        async getCustomersOrders({ state }, payload) {
            // console.log({ payload });

            const uid = payload?.uid

            const res = await get(
                query(
                    dbRef(getDatabase(), "orders"),
                    orderByChild("customer/id"),
                    equalTo(uid)
                )
            );
            const yourOrders = Object.values(res.val() || {})
                .filter(
                    (order) =>
                        !order?.status?.Cancelled?.state &&
                        !order?.status?.AwaitingPayment?.state
                )
                .toReversed();
            if (yourOrders.filter((x) => x.total_price).length) {
                state.customer_orders[uid] = {
                    amount_spent: yourOrders
                        .map((x) => x.total_price)
                        .reduce((a, b) => a + b),
                    number_of_orders: yourOrders?.length || 0,
                    list: yourOrders,
                };
            } else {
                state.customer_orders[uid] = {
                    amount_spent: 0,
                    number_of_orders: yourOrders.length || 0,
                    list: [],
                };
            }
        }
    },
    plugins: [createPersistedState()]
})